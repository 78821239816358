import { post } from 'utilities/DB.js';

export const apiLoadSubExpenses = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subExpenses/loadSubExpenses", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSubExpense = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subExpenses/insertSubExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSubExpense = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subExpenses/updateSubExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSubExpense = (subExpenseId) => {
    const params = {
        id: subExpenseId
    }
    return new Promise((resolve, reject) => {
        post("api/subExpenses/deleteSubExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSubExpense = (subExpenseId) => {
    const params = {
        id: subExpenseId
    }
    return new Promise((resolve, reject) => {
        post("api/subExpenses/getSubExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsSubExpenseExists = (subExpenseName) => {
    const params = {
        accountName: subExpenseName
    }
    return new Promise((resolve, reject) => {
        post("api/subExpenses/isSubExpenseExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
