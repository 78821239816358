import { post, uploadImage } from 'utilities/DB.js';

export const apiLoadSuppliers = (params) => {
    return new Promise((resolve, reject) => {
        post("api/suppliers/loadSuppliers", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSupplier = (params) => {
    return new Promise((resolve, reject) => {
        post("api/suppliers/insertSupplier", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSupplier = (params) => {
    return new Promise((resolve, reject) => {
        post("api/suppliers/updateSupplier", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSupplier = (supplierId) => {
    const params = {
        id: supplierId
    }
    return new Promise((resolve, reject) => {
        post("api/suppliers/deleteSupplier", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSupplier = (supplierId) => {
    const params = {
        id: supplierId
    }
    return new Promise((resolve, reject) => {
        post("api/suppliers/getSupplier", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsSupplierExists = (supplierName) => {
    const params = {
        accountName: supplierName
    }
    return new Promise((resolve, reject) => {
        post("api/suppliers/isSupplierExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/suppliers/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
