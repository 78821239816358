import { post } from 'utilities/DB.js';

export const apiLoadWarehouses = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/loadWarehouses", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertWarehouse = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/insertWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateWarehouse = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/updateWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteWarehouse = (warehouseId) => {
    const params = {
        id: warehouseId
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/deleteWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetWarehouse = (warehouseId) => {
    const params = {
        id: warehouseId
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/getWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsWarehouseExists = (warehouseName) => {
    const params = {
        warehouseName: warehouseName
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/isWarehouseExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
