/* #region Imports */
import React, { useRef, useEffect } from 'react';
import 'style/utilities/settings.css';
import {
    apiUpdateSettings,
} from "api/utilities/SettingsApi";
/* #endregion Imports */

const BarcodeLabelsComponent = (props) => {

    /* #region Variables */
    const formId = "settingsForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);

    // Checkbox
    const saleUnderStockCheckbox = useRef();
    const costWarningCheckbox = useRef();
    const stockWarningCheckbox = useRef();
    const qtyChangeableCheckbox = useRef();
    const saleCartonsCheckbox = useRef();
    const fourRateSystemCheckbox = useRef();
    const showCustomerLastRateCheckbox = useRef();
    const showItemStockCheckbox = useRef();
    const loadAutoCompleteItemsCheckbox = useRef();
    const autoCreditSelectCheckbox = useRef();

    // Middle
    const printLanguageCB = useRef();
    const isDefaultRateRetailCB = useRef();
    const isDefaultDiscRsCB = useRef();

    // Passwords
    const deleteItemTB = useRef();
    const deleteAccountTB = useRef();
    const deleteEntryTB = useRef();
    const deleteSalesBillTB = useRef();
    const deletePurchaseBillTB = useRef();
    const editSalesBillTB = useRef();
    const editPurchaseBillTB = useRef();

    const deleteItemCheckbox = useRef();
    const deleteAccountCheckbox = useRef();
    const deleteEntryCheckbox = useRef();
    const deleteSalesBillCheckbox = useRef();
    const deletePurchaseBillCheckbox = useRef();
    const editSalesBillCheckbox = useRef();
    const editPurchaseBillCheckbox = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
    }
    const loadData = () => {
        // apiGetSettings().then((result) => {
        //     setFormData(result);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const updateData = async () => {
        const data = getFormData();
        apiUpdateSettings(data)
            .then((result) => {
                toast.current.show("Setting saved successfully.", "s");
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    // eslint-disable-next-line
    const setFormData = (data) => {
        const settings = data.settings;
        const passwords = data.passwords;

        saleUnderStockCheckbox.current.setChecked(settings.saleUnderStock);
        costWarningCheckbox.current.setChecked(settings.costWarning);
        stockWarningCheckbox.current.setChecked(settings.stockWarning);
        qtyChangeableCheckbox.current.setChecked(settings.qtyChangeable);
        saleCartonsCheckbox.current.setChecked(settings.saleCartons);
        fourRateSystemCheckbox.current.setChecked(settings.fourRateSystem);
        showCustomerLastRateCheckbox.current.setChecked(settings.showCustomerLastRate);
        showItemStockCheckbox.current.setChecked(settings.showItemStock);
        loadAutoCompleteItemsCheckbox.current.setChecked(settings.loadAutoCompleteItems);
        autoCreditSelectCheckbox.current.setChecked(settings.autoCreditSelect);

        printLanguageCB.current.setValue(settings.printLanguage === 1 ? 0 : 1);
        isDefaultRateRetailCB.current.setValue(settings.isDefaultRateRetail === true ? 0 : 1);
        isDefaultDiscRsCB.current.setValue(settings.isDefaultDiscRs === true ? 1 : 0);

        deleteItemTB.current.setText(passwords.deleteItem);
        deleteAccountTB.current.setText(passwords.deleteAccount);
        deleteEntryTB.current.setText(passwords.deleteEntry);
        deleteSalesBillTB.current.setText(passwords.deleteSalesBill);
        deletePurchaseBillTB.current.setText(passwords.deletePurchaseBill);
        editSalesBillTB.current.setText(passwords.editSalesBill);
        editPurchaseBillTB.current.setText(passwords.editPurchaseBill);

        deleteItemCheckbox.current.setChecked(passwords.useDeleteItem);
        deleteAccountCheckbox.current.setChecked(passwords.useDeleteAccount);
        deleteEntryCheckbox.current.setChecked(passwords.useDeleteEntry);
        deleteSalesBillCheckbox.current.setChecked(passwords.useDeleteSalesBill);
        deletePurchaseBillCheckbox.current.setChecked(passwords.useDeletePurchaseBill);
        editSalesBillCheckbox.current.setChecked(passwords.useEditSalesBill);
        editPurchaseBillCheckbox.current.setChecked(passwords.useEditPurchaseBill);
    }
    const getFormData = () => {
        const data = {};

        const settings = {
            saleUnderStock: saleUnderStockCheckbox.current.isChecked(),
            costWarning: costWarningCheckbox.current.isChecked(),
            stockWarning: stockWarningCheckbox.current.isChecked(),
            qtyChangeable: qtyChangeableCheckbox.current.isChecked(),
            saleCartons: saleCartonsCheckbox.current.isChecked(),
            fourRateSystem: fourRateSystemCheckbox.current.isChecked(),
            showCustomerLastRate: showCustomerLastRateCheckbox.current.isChecked(),
            showItemStock: showItemStockCheckbox.current.isChecked(),
            loadAutoCompleteItems: loadAutoCompleteItemsCheckbox.current.isChecked(),
            autoCreditSelect: autoCreditSelectCheckbox.current.isChecked(),

            printLanguage: printLanguageCB.current.getValue() === 0 ? 1 : 2,
            isDefaultRateRetail: isDefaultRateRetailCB.current.getValue() === 0 ? true : false,
            isDefaultDiscRs: isDefaultDiscRsCB.current.getValue() === 0 ? false : true,
        }

        const passwords = {
            deleteItem: deleteItemTB.current.getText(),
            deleteAccount: deleteAccountTB.current.getText(),
            deleteEntry: deleteEntryTB.current.getText(),
            deleteSalesBill: deleteSalesBillTB.current.getText(),
            deletePurchaseBill: deletePurchaseBillTB.current.getText(),
            editSalesBill: editSalesBillTB.current.getText(),
            editPurchaseBill: editPurchaseBillTB.current.getText(),

            useDeleteItem: deleteItemCheckbox.current.isChecked(),
            useDeleteAccount: deleteAccountCheckbox.current.isChecked(),
            useDeleteEntry: deleteEntryCheckbox.current.isChecked(),
            useDeleteSalesBill: deleteSalesBillCheckbox.current.isChecked(),
            useDeletePurchaseBill: deletePurchaseBillCheckbox.current.isChecked(),
            useEditSalesBill: editSalesBillCheckbox.current.isChecked(),
            useEditPurchaseBill: editPurchaseBillCheckbox.current.isChecked(),
        }

        data.settings = settings;
        data.passwords = passwords;
        return data;
    }
    /* #endregion */

    /* #region Clicks */
    
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "utilities")
            return;

        if (activeTab.current !== "barcodelabels")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            updateData();
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "barcodelabels" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "barcodelabels" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1>Barcode Labels</h1>
                </div>

            </div>
        </>
    )
}

export default BarcodeLabelsComponent;