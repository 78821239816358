import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import { apiLoadItemslist } from "api/reports/otherReportsApi";
import ReportA4Style from "../styles/ReportA4Style";
import { getClientId } from "utilities/DB";

const ItemsListWithImages = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    // Header
    const [branchName, setBranchName] = useState('');
    const [branchGroupName, setBranchGroupName] = useState('');

    // Footer
    const [totalItems, setTotalItems] = useState(0);

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadItemslist(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalItems(result.total.totalItems);
                    setBranchName(result.total.branchName);
                    setBranchGroupName(result.total.branchGroupName);

                    props.setIsLoading(false);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 itemsList itemsListWithImages" ref={ref}>
                <ReportA4Style />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Items List</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'flex-start' }}>

                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>
                        <div className="dRow" style={{ justifyContent: 'center', marginLeft: '16px' }}>
                            <p className="totalHeading">Branch Group:</p>
                            <p className="labelMarginL">{branchGroupName}</p>
                        </div>

                    </div>
                </div>

                {/* Report body */}
                <table>
                    <thead>
                        <tr>
                            <th>Itemname</th>
                            {/* <th>Urduname</th> */}
                            <th>Rate</th>
                            <th>Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    <td className="itemname">{row["itemname"]}<span className={`urdu ${row["urduname"] === "" ? 'd-none' : 'd-inline'}`}> {row["urduname"]}</span></td>
                                    {/* <td>{row["urduname"]}</td> */}
                                    <td>{row["retail"]}</td>
                                    <td><img src={`${process.env.REACT_APP_API_URL}/${getClientId()}${row["imageUrl"]}?` + new Date().getTime()} alt="Not available" /></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Items:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalItems}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default ItemsListWithImages;