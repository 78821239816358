import React, { useRef, forwardRef, useImperativeHandle } from 'react';

const Checkbox = forwardRef((props, ref) => {
    const label = props.label;
    const style = props.style;
    const className = props.className;
    const onChange = props.onChange ? props.onChange : ()=>{};
    const isChecked = props.checked ? props.checked : false;
    const id = "checkbox" + Math.ceil(Math.random() * 1000000);
    
    // Variables
    const reference = useRef();
    useImperativeHandle(ref, () => {
        return {
            isChecked: ()=> reference.current.checked,
            setChecked: (val)=>{reference.current.checked = val; onChange(val)},
            enable:()=>{reference.current.disabled = false},
            disable:()=>{reference.current.disabled = true},
        };
    });

    const onChangeHandle = (e) => {
        onChange(e.target.checked);
    }

    return (
        <>
            <div className={`myCheckbox form-switch ${className}`} style={style}>
                <input ref={reference} className="form-check-input" type="checkbox" id={id} role="switch" onChange={onChangeHandle} defaultChecked={isChecked}/>
                <label htmlFor={id}>{label}</label>
            </div>
        </>
    )
});

export default Checkbox;