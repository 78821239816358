import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const TextboxNumber = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const onKeyDown = props.onKeyDown ? props.onKeyDown : () => { };
    const onFocus = props.onFocus ? props.onFocus : () => { };
    const style = props.style;
    const text = props.text ? props.text : "1";
    const tabIndex = props.tabIndex;

    // Variables
    const [value, setValue] = useState(text);
    const [readonly, setReadonly] = useState(false);
    // eslint-disable-next-line
    const [focused, setFocused] = useState(false);
    const reference = useRef();
    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            getText: () => Number(current.current),
            setText: (val) => { setValue(val); },
            focus: () => { reference.current.focus(); },
            enable: () => { reference.current.disabled = false },
            disable: () => { reference.current.disabled = true },
            setReadonly: setReadonly,
        };
    });

    const onChangeHandle = (e) => {
        setValue(e.target.value);
    }

    const onFocusHandle = (e) => {
        setFocused(true);
        onFocus();
    }

    useEffect(() => {
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div className={`tb ${className}`} style={style}>
                <input
                    ref={reference}
                    type="number"
                    style={style}
                    value={value}
                    min="1"
                    onChange={onChangeHandle}
                    onFocus={onFocusHandle}
                    onKeyDown={onKeyDown} tabIndex={tabIndex} id={`tb${tabIndex}`} spellCheck="false" readOnly={readonly} />
            </div>
        </>
    )
});

export default TextboxNumber;