/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import 'style/reports/stockReports.css';
import ThinButton from "components/tools/ThinButton";
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import VendorCBReports from "components/tools/reportsDropdowns/VendorCBReports";
import { apiIsBarcodeExists, apiLoadReportsData } from "api/reports/reportsApi";
import { reportsReducerLoadData } from "state/reducers/reportsReducer";
import { useDispatch, useSelector } from "react-redux";
import { convertToInt, getReportsData } from "utilities/Utils";
import ButtonIcon from "components/tools/ButtonIcon";
import CategoryCBReports from "components/tools/reportsDropdowns/CategoryCBReports";
import SubCategoryCBReports from "components/tools/reportsDropdowns/SubCategoryCBReports";
import AutoCompleteTextboxReports from "components/tools/AutoCompleteTextboxReports";
import BranchGroupsCB from "components/tools/dropdowns/BranchGroupsCB";
import TextboxNumber from "components/tools/TextboxNumber";
import StockReport from "./stock/StockReport";
import ComboBox from "components/tools/ComboBox";
import { getBranchGroupId, getBranchId } from "utilities/DB";
import WarehouseCBReports from "components/tools/reportsDropdowns/WarehouseCBReports";
/* #endregion */

const StockReportComponent = (props) => {

    /* #region Variables */
    const formId = "stockReportsForm";
    const dispatch = useDispatch();
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const userRights = useSelector(state => state.main.userRights);
    const user = useSelector(state => state.main.user);
    const [activeReport, setActiveReport] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const isOpeningStock = useRef();

    const stockTypeAll = useRef();
    const stockTypeZero = useRef();
    const stockTypeNotZero = useRef();
    const stockTypeUnder = useRef();
    const stockTypeOver = useRef();

    const stockExpiryAll = useRef();
    const stockExpiryExpirable = useRef();
    const stockExpiryExpired = useRef();
    const stockExpiryWithinDays = useRef();
    const stockExpiryDays = useRef();

    const stockAtCost = useRef();
    const stockAtRetail = useRef();
    const stockAtWholesale = useRef();

    const branchCB = useRef();
    const branchGroupCB = useRef();
    const branchDataCB = useRef();
    const branchGroupDataCB = useRef();

    const itemnameTB = useRef();
    const categoryCB = useRef();
    const subCategoryCB = useRef();
    const vendorCB = useRef();
    const warehouseCB = useRef();
    const itemId = useRef();

    const reportRef = useRef();
    const reportParams = useRef({});

    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        if (userRights.branchWiseReports) {
            const branchId = getBranchId();
            branchCB.current.setValue(branchId);
            branchDataCB.current.setValue(branchId);

            const branchGroupId = getBranchGroupId();
            branchGroupCB.current.setValue(branchGroupId);
            branchGroupDataCB.current.setValue(branchGroupId);
        }

        isFirstTime.current = false;
    }
    const loadData = (branchId, branchGroupId) => {
        if (isFirstTime.current)
            return;

        apiLoadReportsData({
            branchId: branchId,
            branchGroupId: branchGroupId,
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const itemWiseReport = () => {
        if (itemId.current !== 0) {
            const params = getParams();
            params.itemId = itemId.current;

            loadReport("stockReport", params);
        } else {
            toast.current.show("Please Select Item.", "i");
        }
    }
    const categoryWiseReport = () => {
        if (categoryCB.current.getValue() !== 0) {
            const params = getParams();
            params.categoryId = categoryCB.current.getValue();

            loadReport("stockReport", params);
        } else {
            toast.current.show("Please Select Category.", "i");
        }
    }
    const subCategoryWiseReport = () => {
        if (subCategoryCB.current.getValue() !== 0) {
            const params = getParams();
            params.subCategoryId = subCategoryCB.current.getValue();

            loadReport("stockReport", params);
        } else {
            toast.current.show("Please Select Sub-Category.", "i");
        }
    }
    const vendorWiseReport = () => {
        if (vendorCB.current.getValue() !== 0) {
            const params = getParams();
            params.vendorId = vendorCB.current.getValue();

            loadReport("stockReport", params);
        } else {
            toast.current.show("Please Select Vendor.", "i");
        }
    }
    const totalStockReport = () => {
        loadReport("stockReport", getParams());
    }
    const loadReport = (report, params) => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                reportParams.current = params;

                setActiveReport("");
                setTimeout(() => {
                    setActiveReport(report);
                }, 10);
            } else {
                toast.current.show("Please Select Branch.", "i");
            }
        } else {
            params.branchId = user.branchId;
            params.branchGroupId = user.branchGroupId;
            reportParams.current = params;

            setActiveReport("");
            setTimeout(() => {
                setActiveReport(report);
            }, 10);
        }

        // if (branchCB.current.getValue() !== 0) {
        //     reportParams.current = params;

        //     setActiveReport("");
        //     setTimeout(() => {
        //         setActiveReport(report);
        //     }, 10);
        // } else {
        //     toast.current.show("Please Select Branch.", "i");
        // }
    }
    const getParams = () => {
        return {
            branchId: branchCB.current.getValue(),
            branchGroupId: branchGroupCB.current.getValue(),
            warehouseId: warehouseCB.current.getValue(),
            stockType: getStockType(),
            stockExpiry: getStockExpirable(),
            stockAt: getStockAt(),
            expiryDays: convertToInt(stockExpiryDays.current.getText()),
            isOpeningStock: isOpeningStock.current.getValue() === 0 ? false : true,
        }
    }
    const getStockAt = () => {
        if (stockAtCost.current.checked)
            return "cost";
        else if (stockAtRetail.current.checked)
            return "retail";
        else if (stockAtWholesale.current.checked)
            return "wholesale";
    }
    const getStockType = () => {
        if (stockTypeZero.current.checked)
            return "zero";
        else if (stockTypeNotZero.current.checked)
            return "notZero";
        else if (stockTypeUnder.current.checked)
            return "under";
        else if (stockTypeOver.current.checked)
            return "over";
        else
            return "all";
    }
    const getStockExpirable = () => {
        if (stockExpiryExpirable.current.checked)
            return "expirable";
        else if (stockExpiryExpired.current.checked)
            return "expired";
        else if (stockExpiryWithinDays.current.checked)
            return "withInDays";
    }
    const printReport = useReactToPrint({
        content: () => reportRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            // setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */

    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "reports")
            return;

        if (activeTab.current !== "stock")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printReport();
        }
    }
    const onBarcodeTBKeyDown = async (barcode) => {
        if (barcode !== '') {
            try {
                const result = await apiIsBarcodeExists({
                    branchId: userRights.branchWiseReports ? branchDataCB.current.getValue() : user.branchId,
                    branchGroupId: userRights.branchWiseReports ? branchGroupDataCB.current.getValue() : user.branchGroupId,
                    barcode: barcode,
                });
                if (result.isExists) {
                    const item = result.data;

                    itemnameTB.current.setText(item.itemname);
                    itemId.current = Number(item.id);
                } else {
                    alert("Barcode not exists");
                    itemnameTB.current.setText("");
                    itemId.current = 0;
                }
            } catch (err) {
                alert(err.message);
            }
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onItemnameChange = () => {
        if (itemnameTB.current.getText() === '') {
            itemId.current = 0;
        }
    }
    const onBranchDataCBChange = (selectedValue, selectedText) => {
        if (branchDataCB.current.getValue() === 0) {
            if (branchGroupDataCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(0, branchGroupDataCB.current.getValue());
            }
        } else {
            loadData(branchDataCB.current.getValue(), 0);
        }
    }
    const onBranchGroupDataCBChange = (selectedValue, selectedText) => {
        if (branchGroupDataCB.current.getValue() === 0) {
            if (branchDataCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(branchDataCB.current.getValue(), 0);
            }
        } else {
            loadData(branchDataCB.current.getValue(), branchGroupDataCB.current.getValue());
        }
    }
    const onCategoryCBChange = (selectedValue, selectedText) => {
        subCategoryCB.current.loadData(selectedValue);
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "stock" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "stock" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className="mainPanel">
                    <div className="leftPanel">
                        <div className={`${userRights.branchWiseReports ? 'd-block' : 'd-none'}`}>
                            <BranchesCB
                                ref={branchCB}
                                title="Select Branch"
                                style={{ margin: '0px', marginBottom: 'var(--defaultMargin)' }}
                                onChange={() => { }} />

                            <BranchGroupsCB
                                ref={branchGroupCB}
                                title="Select Branch Group"
                                style={{ margin: '0px', marginBottom: 'var(--defaultMargin)' }}
                                onChange={() => { }} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <AutoCompleteTextboxReports
                                ref={itemnameTB}
                                label="Item"
                                onEnter={onBarcodeTBKeyDown}
                                onChange={onItemnameChange}
                                style={{ width: '100%', margin: '0px' }} />
                            <ThinButton
                                label="Show"
                                style={{ margin: '0px' }}
                                onClick={itemWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <CategoryCBReports
                                ref={categoryCB}
                                onChange={onCategoryCBChange} />
                            <ThinButton
                                label="Show"
                                onClick={categoryWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <SubCategoryCBReports
                                ref={subCategoryCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={subCategoryWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <VendorCBReports
                                ref={vendorCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={vendorWiseReport} />
                        </div>

                        <ThinButton
                            label="Total Stock"
                            className="totalBtn"
                            style={{ marginRight: '70px' }}
                            onClick={totalStockReport} />
                    </div>
                    <div className="rightPanel">
                        <div className={`reportShowBtnGroup ${userRights.branchWiseReports ? 'd-flex' : 'd-none'}`}>
                            <BranchesCB
                                ref={branchDataCB}
                                title="Select Branch to Load Data"
                                onChange={onBranchDataCBChange} />
                            <BranchGroupsCB
                                ref={branchGroupDataCB}
                                title="Select Branch Group to Load Data"
                                className="defaultMarginLeft"
                                onChange={onBranchGroupDataCBChange} />
                        </div>

                        <ComboBox
                            ref={isOpeningStock}
                            label="Type"
                            data={[{ id: 0, name: 'Show Current Stock' }, { id: 1, name: 'Show Opening Stock' }]} />

                        <div className="radioGroup">
                            <div className="radioBody">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input
                                            ref={stockTypeAll}
                                            className="form-check-input" id="stockTypeAll" type="radio" name="stockType" defaultChecked
                                            onChange={() => { }} />
                                        <label htmlFor="stockTypeAll">All</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockTypeZero}
                                            className="form-check-input" id="stockTypeZero" type="radio" name="stockType" onChange={() => { }} />
                                        <label htmlFor="stockTypeZero">Zero Stock</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockTypeNotZero}
                                            className="form-check-input" id="stockTypeNotZero" type="radio" name="stockType" onChange={() => { }} />
                                        <label htmlFor="stockTypeNotZero">Not Zero</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockTypeUnder}
                                            className="form-check-input" id="stockTypeUnder" type="radio" name="stockType" onChange={() => { }} />
                                        <label htmlFor="stockTypeUnder">About to End</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockTypeOver}
                                            className="form-check-input" id="stockTypeOver" type="radio" name="stockType" onChange={() => { }} />
                                        <label htmlFor="stockTypeOver">Over Stock</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="radioGroup">
                            <div className="radioBody">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input
                                            ref={stockExpiryAll}
                                            className="form-check-input" id="stockExpiryAll" type="radio" name="stockExpirable" defaultChecked
                                            onChange={() => { }} />
                                        <label htmlFor="stockExpiryAll">All</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockExpiryExpirable}
                                            className="form-check-input" id="stockExpiryExpirable" type="radio" name="stockExpirable" onChange={() => { }} />
                                        <label htmlFor="stockExpiryExpirable">Expirable</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockExpiryExpired}
                                            className="form-check-input" id="stockExpiryExpired" type="radio" name="stockExpirable" onChange={() => { }} />
                                        <label htmlFor="stockExpiryExpired">Expired</label>
                                    </div>
                                    <div className="radioItem">
                                        <TextboxNumber
                                            ref={stockExpiryDays}
                                            text="30"
                                            className="tbNumber" />
                                        <input
                                            ref={stockExpiryWithinDays}
                                            className="form-check-input"
                                            style={{ marginLeft: '4px' }}
                                            id="stockExpiryWithinDays"
                                            type="radio" name="stockExpirable" onChange={() => { }} />
                                        <label htmlFor="stockExpiryWithinDays">Days Within</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="radioGroup">
                            <div className="radioBody">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input
                                            ref={stockAtCost}
                                            id="stockAtCost"
                                            className="form-check-input" type="radio" name="stockAt" defaultChecked
                                            onChange={() => { }} />
                                        <label htmlFor="stockAtCost">At Cost</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockAtRetail}
                                            id="stockAtRetail"
                                            className="form-check-input" type="radio" name="stockAt" onChange={() => { }} />
                                        <label htmlFor="stockAtRetail">At Retail</label>
                                    </div>
                                    <div className="radioItem">
                                        <input
                                            ref={stockAtWholesale}
                                            id="stockAtWholesale"
                                            className="form-check-input" type="radio" name="stockAt" onChange={() => { }} />
                                        <label htmlFor="stockAtWholesale">At Wholesale</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <WarehouseCBReports
                            ref={warehouseCB}
                            onChange={() => { }} />

                    </div>
                </div>

                {/* Report & Viewer */}
                <div className="reportViewer" style={{ marginTop: '8px' }}>
                    <div className={`loadingBarContainer ${isLoading ? 'd-block' : 'd-none'}`}>
                        <div className="loadingBar"></div>
                    </div>

                    {(() => {
                        switch (activeReport) {
                            case "stockReport":
                                return <StockReport
                                    ref={reportRef}
                                    onDoubleClick={() => { }}
                                    messageBox={messageBox}
                                    setIsLoading={setIsLoading}
                                    params={reportParams.current} />
                            default:
                                return <div></div>;
                        }
                    })()}

                    <div className="printPanel">
                        <ButtonIcon
                            icon="icons/buttons/print.png"
                            onClick={printReport} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default StockReportComponent;