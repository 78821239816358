import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const Textbox = forwardRef((props, ref) => {
    // Props
    const label = props.label;
    const className = props.className;
    const style = props.style;
    const tabIndex = props.tabIndex;
    const onChange = props.onChange ? props.onChange : () => { };
    const onFocus = props.onFocus ? props.onFocus : () => { };
    const onKeyDown = props.onKeyDown ? props.onKeyDown : () => { };
    const autoSelect = props.autoSelect ? props.autoSelect : false;

    // Variables
    const [value, setValue] = useState("");
    const [readonly, setReadonly] = useState(false);
    const [focused, setFocused] = useState(false);
    const reference = useRef();

    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            getText: () => current.current,
            setText: (val) => { setValue(val);},
            focus: () => { reference.current.focus(); },
            enable: () => { reference.current.disabled = false },
            disable: () => { reference.current.disabled = true },
            isEnabled: () => !reference.current.disabled,
            isDisabled: () => reference.current.disabled,
            select: () => { reference.current.select() },
            setReadonly: setReadonly,
        };
    });

    const onChangeHandle = (e) => {
        setValue(e.target.value);
    }

    const onFocusHandle = (e) => {
        setFocused(true);
        onFocus();
    }

    useEffect(() => {
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if(autoSelect && focused){
            reference.current.select();
        }
        // eslint-disable-next-line
    }, [focused]);

    return (
        <>
            <div className={`tb ${className}`} style={style}>
                <div><label>{label}</label></div>
                <input
                    ref={reference}
                    type="text"
                    value={value}
                    onChange={onChangeHandle}
                    onKeyDown={onKeyDown}
                    tabIndex={tabIndex}
                    id={`tb${tabIndex}`}
                    spellCheck="false"
                    readOnly={readonly}
                    onFocus={onFocusHandle}
                    onBlur={()=>{setFocused(false)}}
                    />
            </div>
        </>
    )
});

export default Textbox;