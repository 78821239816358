/* #region Imports */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import {
    apiLoadAccountCategories,
    apiInsertAccountCategory,
    apiUpdateAccountCategory,
    apiDeleteAccountCategory,
    apiGetAccountCategory,
    apiIsAccountCategoryExists
} from "api/accounts/AccountCategoriesApi";

import 'style/accounts/accountCategories.css';
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import Textarea from 'components/tools/Textarea';
import ButtonIcon from 'components/tools/ButtonIcon';
/* #endregion Imports */

const AccountCategoriesComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "accountCategoriesForm";
    const isActive = useRef(false);

    const categoryNameTB = useRef();
    const remarksTB = useRef();
    const searchTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalCategories, setTotalCategories] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const itemId = useRef(0);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Category Name', row: "categoryName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if(tableRef.current !== null)
            tableRef.current.setLoading(true);
        
        apiLoadAccountCategories({
            text:searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalCategories(result.total.totalCategories);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;
    
        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateAccountCategory(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Category updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        props.loadAccountCategoriesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertAccountCategory(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Category saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        props.loadAccountCategoriesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }else{
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetAccountCategory(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
        if (window.confirm("Are you sure to delete this Category?")) {
                apiDeleteAccountCategory(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Category deleted successfully.", "s");
                        loadData();
                        props.loadAccountCategoriesCB(result);
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Category?")) {
                apiDeleteAccountCategory(updateId.current)
                    .then((result) => {
                        toast.current.show("Category deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                        props.loadAccountCategoriesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        categoryNameTB.current.setText("");
        remarksTB.current.setText("");

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        categoryNameTB.current.focus();
    }
    const setFormData = (category) => {
        formData.current = category;

        categoryNameTB.current.setText(category.categoryName);
        remarksTB.current.setText(category.remarks);

        categoryNameTB.current.focus();
    }
    const getFormData = () => {
        const category = {
            categoryName: categoryNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText(),
        }

        return category;
    }
    const validation = async () => {
        const categoryName = categoryNameTB.current.getText().trim();
        const isValid = await isValidCategory(categoryName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidCategory = async (categoryName) => {
        if (categoryName === '') {
            toast.current.show("Please Enter Category Name.", "i");
            categoryNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (categoryName.toLowerCase() === formData.current.categoryName.toLowerCase())
                    return true;
                else
                    return isCategoryExists(categoryName);
            }
            else
                return isCategoryExists(categoryName);
        }
    }
    const isCategoryExists = async (categoryName) => {
        const result = await apiIsAccountCategoryExists(categoryName);
        if (result.isExists) {
            toast.current.show("Category Name already exists.", "i");
            categoryNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onBarcodeTBKeyDown = (e) => {
        if (e.key.toLowerCase() === "enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    const show = (tempId) => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        itemId.current = tempId;
        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;
    }

    const formLoad = () => {
        clearTextboxes();
        loadData();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none'}}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow:'auto' }}>

                        <Textbox
                            ref={categoryNameTB}
                            label="Category Name"
                            tabIndex={9}
                            onKeyDown={onBarcodeTBKeyDown}
                            style={{}} />

                        <Textarea
                            ref={remarksTB}
                            label="Remarks"
                            tabIndex={10}
                            onKeyDown={onRemarksTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={11} />

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            myTable='accountCategoriesTable'
                            isActive={isActive}
                            onDoubleClick={onEditBtnClick}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Categories: </b>{totalCategories}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default AccountCategoriesComponent;