import React, { useState } from "react";

const Report = (props) => {
    const onClick = props.onClick ? props.onClick : () => { };
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : () => { };
    const columns = props.columns ? props.columns : [];
    const sort = props.sort !== undefined ? props.sort : true;
    const data = props.data ? props.data : [];
    const setData = props.setData;

    const [sortOrder, setSortOrder] = useState(1);

    const sortData = (sortByCol, sortBy) => {
        if (sortOrder === 1) {
            setSortOrder(-1);
            if (sortBy === "string")
                setData([...data].sort((a, b) => (a[sortByCol] > b[sortByCol]) ? 1 : -1));
            else
                setData([...data].sort((a, b) => { return a[sortByCol] - b[sortByCol] }));
        }
        else {
            setSortOrder(1);
            if (sortBy === "string")
                setData([...data].sort((a, b) => (a[sortByCol] < b[sortByCol]) ? 1 : -1));
            else
                setData([...data].sort((a, b) => { return b[sortByCol] - a[sortByCol] }));
        }
    }

    return (
        <>
            <table>
                <thead>
                    <tr>
                        {
                            columns.map((col, i) => {
                                return (
                                    <th key={i} onClick={() => { if(sort) sortData(col.row, col.sortBy) }}>
                                        <span>{col.column}</span>
                                    </th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex} onClick={() => { onClick(row) }} onDoubleClick={() => { onDoubleClick(row) }}>
                                {
                                    columns.map((col, colIndex) => {
                                        return (
                                            <td className={col.className} key={colIndex} title={row[col.row]}>{row[col.row]}</td>
                                        )
                                    })
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    )
}

export default Report;