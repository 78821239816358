import { post } from 'utilities/DB.js';

export const apiLoadFixedAccounts = (params) => {
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/loadFixedAccounts", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertFixedAccount = (params) => {
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/insertFixedAccount", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateFixedAccount = (params) => {
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/updateFixedAccount", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteFixedAccount = (supplierId) => {
    const params = {
        id: supplierId
    }
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/deleteFixedAccount", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetFixedAccount = (supplierId) => {
    const params = {
        id: supplierId
    }
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/getFixedAccount", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsFixedAccountExists = (supplierName) => {
    const params = {
        accountName: supplierName
    }
    return new Promise((resolve, reject) => {
        post("api/fixedAccounts/isFixedAccountExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
