import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';

const AutoCompleteTextboxSales = forwardRef((props, ref) => {

    // Props
    const label = props.label;
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const onEnter = props.onEnter ? props.onEnter : () => { };
    const onKeyDown = props.onKeyDown ? props.onKeyDown : () => { };
    const style = props.style;
    const tabIndex = props.tabIndex;
    const autocompleteTB = props.autocompleteTB || "autocompletetb";

    // Variables
    const settings = useSelector(state => state.main.settings);
    const data = useSelector((state) => { return settings.loadAutoCompleteItems === true ? state.main.items : [] });
    const [readonly, setReadonly] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [value, setValue] = useState("");
    const reference = useRef();
    let current = value;

    useImperativeHandle(ref, () => {
        return {
            getText: () => current,
            setText: setValue,
            focus: () => { reference.current.focus(); },
            enable: () => { reference.current.disabled = false },
            disable: () => { reference.current.disabled = true },
            setReadonly: setReadonly,
        };
    });

    const onChangeHandle = (e) => {
        const val = e.target.value;
        current = val;

        setValue(val);
        onChange(val);

        filterData(val);
    }

    const onKeyDownHandle = (e) => {
        onKeyDown(e);
        if (e.key === "ArrowDown") {
            e.preventDefault();
            const rows = document.querySelectorAll(`.${autocompleteTB} .list p`);
            const list = document.querySelector(`.${autocompleteTB} .list`);
            const currentRow = document.querySelector(`.${autocompleteTB} .list p.autocompleteSelectedRow`);
            if (!currentRow && rows.length > 0) {
                rows[0].classList.add("autocompleteSelectedRow");
                return;
            }

            if (currentRow) {
                var nextRow = currentRow.nextElementSibling;
                if (nextRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    list.scrollTop += rowRect.height;

                    currentRow.classList.remove("autocompleteSelectedRow");
                    nextRow.classList.add("autocompleteSelectedRow");
                }
            }
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            const rows = document.querySelectorAll(`.${autocompleteTB} .list p`);
            const list = document.querySelector(`.${autocompleteTB} .list`);
            const currentRow = document.querySelector(`.${autocompleteTB} .list p.autocompleteSelectedRow`);
            if (!currentRow && rows.length > 0) {
                rows[rows.length - 1].classList.add("autocompleteSelectedRow");
                list.scrollTop = list.scrollHeight;
                return;
            }

            if (currentRow) {
                var prevRow = currentRow.previousElementSibling;
                if (prevRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    list.scrollTop -= rowRect.height;

                    currentRow.classList.remove("autocompleteSelectedRow");
                    prevRow.classList.add("autocompleteSelectedRow");
                }
            }
        } else if (e.key === "Enter") {
            e.preventDefault();
            onRowSelect();
            onBarcodeTBKeyDown(current);
        } else if (e.key === "Escape") {
            e.preventDefault();
            setFilteredData([]);
        }
    }
    const onRowSelect = () => {
        const currentRow = document.querySelector(`.${autocompleteTB} .list p.autocompleteSelectedRow`);
        if (currentRow) {
            const val = currentRow.getAttribute('data-name');
            setValue(val)
            current = val;
        }

        setFilteredData([]);
    }
    const onBarcodeTBKeyDown = (barcode) => {
        onEnter(barcode);

        // if (barcode !== '') {
        //     try {
        //         let actualBarcode = barcode;

        //         if(barcode[0] === "+" || barcode[0] === "-"){
        //             actualBarcode = barcode.slice(1);
        //         }

        //         const result = await apiIsBarcodeExists(actualBarcode);
        //         if (result.isExists) {
        //             const item = result.data;

        //             if(barcode[0] === "+"){
        //                 item.qtyChangeable = true;    
        //             }

        //             if(barcode[0] === "-"){
        //                 item.lessItem = true;   
        //             }

        //             onEnter(item);
        //         } else {
        //             alert("Barcode not exists");
        //             onBarcodeNotFound(current);
        //             current = '';
        //             setValue('');
        //         }
        //     } catch (err) {
        //         alert(err.message);
        //     }
        // }
    }

    const onListClick = (e) => {
        const currentRow = document.querySelector(`.${autocompleteTB} .list p.autocompleteSelectedRow`);
        if (currentRow) {
            currentRow.classList.remove("autocompleteSelectedRow");
        }
        e.currentTarget.classList.add("autocompleteSelectedRow");

        onRowSelect();
        onBarcodeTBKeyDown(current);
    }

    const onLeaveHandle = (e) => {
        setTimeout(() => {
            setFilteredData([]);
        }, 100);
    }

    const filterData = (val) => {
        if (val === '') {
            setFilteredData([]);
        } else {
            const temp = data.filter((e) => {
                return e.name.toLowerCase().startsWith(val.toLowerCase());
            })

            setFilteredData([...temp]);
        }
    }

    return (
        <>
            <div className='autocompletetextbox'>
                <div className={`tb ${autocompleteTB} ${className}`} style={style}>
                    <div><label>{label}</label></div>
                    <input ref={reference} type="text" value={value} onBlur={onLeaveHandle} onChange={onChangeHandle} onKeyDown={onKeyDownHandle} tabIndex={tabIndex} id={`tb${tabIndex}`} readOnly={readonly} />
                    {
                        filteredData.length > 0 && <div className="list">
                            {filteredData.map((e) => {
                                return (
                                    <p onClick={onListClick} key={e.id} data-name={e.name}>{e.name}</p>
                                );
                            })}
                        </div>
                    }

                </div>
            </div>
        </>
    )
});

export default AutoCompleteTextboxSales;