import { post, uploadImage } from 'utilities/DB.js';

export const apiLoadMainUsers = (params) => {
    return new Promise((resolve, reject) => {
        post("api/users/loadMainUsers", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertMainUser = (params) => {
    return new Promise((resolve, reject) => {
        post("api/users/insertUser", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateMainUser = (params) => {
    return new Promise((resolve, reject) => {
        post("api/users/updateUser", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteMainUser = (userId) => {
    const params = {
        id: userId
    }
    return new Promise((resolve, reject) => {
        post("api/users/deleteUser", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetMainUser = (userId) => {
    const params = {
        id: userId
    }
    return new Promise((resolve, reject) => {
        post("api/users/getUser", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsMainUserExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/users/isUserExists", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/users/uploadImage", formData, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
