/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/warehouse/createWarehouse.css';
import { useDispatch } from "react-redux";

import {
    apiLoadWarehouses,
    apiInsertWarehouse,
    apiUpdateWarehouse,
    apiDeleteWarehouse,
    apiGetWarehouse,
    apiIsWarehouseExists
} from "api/warehouse/WarehouseApi";
import { selectNextElement } from "utilities/Utils";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import { mainReducerLoadWarehouses } from 'state/reducers/mainReducer';
/* #endregion Imports */

const CreateWarehouseComponent = (props) => {

    /* #region Variables */
    const formId = "createWarehouseForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    // const passwords = useSelector(state => state.main.passwords);

    const warehouseNameTB = useRef();
    const remarksTB = useRef();

    const searchTB = useRef();

    // Tools References
    // const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalWarehouses, setTotalWarehouses] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Warehouse Name', row: "warehouseName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Remarks', row: "remarks", sortBy: "string", style: { minWidth: '400px' } },
        // { column: 'Address', row: "address", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();

        warehouseNameTB.current.focus();
    }
    const loadWarehousesCB = (result) => {
        dispatch(mainReducerLoadWarehouses(result));
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadWarehouses({
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalWarehouses(result.total.totalWarehouses);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateWarehouse(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Warehouse updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadWarehousesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertWarehouse(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Warehouse saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadWarehousesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetWarehouse(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Warehouse?")) {
                apiDeleteWarehouse(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Warehouse deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadWarehousesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Warehouse?")) {
                apiDeleteWarehouse(updateId.current)
                    .then((result) => {
                        toast.current.show("Warehouse deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadWarehousesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        warehouseNameTB.current.setText("");
        remarksTB.current.setText("");

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        warehouseNameTB.current.focus();
    }
    const setFormData = (warehouse) => {
        formData.current = warehouse;

        warehouseNameTB.current.setText(warehouse.warehouseName);
        remarksTB.current.setText(warehouse.remarks);

        warehouseNameTB.current.focus();
    }
    const getFormData = () => {
        const warehouse = {
            warehouseName: warehouseNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText(),
        }

        return warehouse;
    }
    const validation = async () => {
        const warehouseName = warehouseNameTB.current.getText().trim();
        const isValid = await isValidWarehouse(warehouseName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidWarehouse = async (warehouseName) => {
        if (warehouseName === '') {
            toast.current.show("Please Enter Warehouse Name.", "i");
            warehouseNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (warehouseName.toLowerCase() === formData.current.warehouseName.toLowerCase())
                    return true;
                else
                    return isWarehouseExists(warehouseName);
            }
            else
                return isWarehouseExists(warehouseName);
        }
    }
    const isWarehouseExists = async (warehouseName) => {
        const result = await apiIsWarehouseExists(warehouseName);
        if (result.isExists) {
            toast.current.show("Warehouse Name already exists.", "i");
            warehouseNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "warehouse")
            return;

        if (activeTab.current !== "createwarehouse")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "createwarehouse" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "createwarehouse" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className='top'>
                    <Textbox
                        ref={warehouseNameTB}
                        label="Warehouse Name"
                        tabIndex={1201}
                        onKeyDown={onTextboxesKeyDown} />
                    <Textarea
                        ref={remarksTB}
                        label="Remarks"
                        tabIndex={1202}
                        style={{ height: '120px !important' }}
                        onKeyDown={onLastTextboxKeyDown} />
                </div>

                <div className="buttons">
                    <ButtonIcon
                        label="Save"
                        icon="icons/buttons/save.png"
                        onClick={onSaveBtnClick} />

                    <ButtonIcon
                        label="Edit"
                        icon="icons/buttons/edit.png"
                        onClick={onEditBtnClick} />

                    <ButtonIcon
                        label="Delete"
                        icon="icons/buttons/delete.png"
                        onClick={onDeleteBtnClick} />

                    <ButtonIcon
                        label="Cancel"
                        icon="icons/buttons/cancel.png"
                        onClick={onCancelBtnClick} />
                </div>

                <div className="searchContainer">
                    <Textbox
                        ref={searchTB}
                        label="Search"
                        style={{ flex: '1' }}
                        onKeyDown={onSearchTBKeydown}
                        tabIndex={1203} />
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='warehousesTable'
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="tableFooter">
                    <p><b>Total Warehouses: </b>{totalWarehouses}</p>
                </div>

            </div>
        </>
    )
}

export default CreateWarehouseComponent;