/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/reports/cash/manageCash.css';
import { mainReducerLoadAccounts } from 'state/reducers/mainReducer';
import { apiLoadAccounts } from "api/main/mainApi";
import { useDispatch } from "react-redux";
import FixedAccountsComponent from './FixedAccountsComponent';
import CashClosingComponent from './CashClosingComponent';
/* #endregion */

const ManageCashComponent = forwardRef((props, ref) => {

    const formId = "manageCashForm";
    const isActive = useRef(false);
    const dispatch = useDispatch();
    const className = props.className ? props.className : '';

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;

    const activeBtn = useRef(null);
    const [activeComponent, setActiveComponent] = useState(`reports`);

    const flatButtons = [
        "Reports",
        "Create",
    ];

    const loadAccounts = ()=>{
        apiLoadAccounts().then((result) => {
            dispatch(mainReducerLoadAccounts(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    
    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        loadAccounts();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;
        
        props.setActiveComponent(null);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    const onWindowKeyDown = (e) => {
        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
        // if (e.ctrlKey && e.key === "1") {
        //     e.preventDefault();
        //     shortKeyClick("Receipts");
        // }
        // else if (e.ctrlKey && e.key === "2") {
        //     e.preventDefault();
        //     shortKeyClick("Payments");
        // }
    }

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        if (activeBtn.current == null) {
            const btn = document.getElementById(`flatBtnCashReports`);
            btn.classList.add("flatBtnClicked");

            activeBtn.current = btn.id;
        }

        show();
        
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const flatBtnClick = (e) => {
        changeActiveBtn(e.currentTarget);

        const btnName = e.currentTarget.getAttribute('data-name');
        setActiveComponent(btnName);
    };

    const changeActiveBtn = (btn) => {
        // Get Old Button
        const oldBtn = document.getElementById(activeBtn.current);
        oldBtn.classList.remove("flatBtnClicked");
        // const oldImg = document.querySelector(`#${oldBtn.id} img`);
        // oldImg.setAttribute("src", `${FlatButtonIcons[oldBtn.getAttribute("data-name")]}`);

        // Set Button
        btn.classList.add("flatBtnClicked");
        activeBtn.current = btn.id;
        // const img = document.querySelector(`#${btn.id} img`);
        // img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
    }

    // const shortKeyClick = (button) => {
    //     const btn = document.getElementById(`flatBtn${button}`);
    //     changeActiveBtn(btn);
    //     const btnName = btn.getAttribute('data-name');
    //     setActiveComponent(btnName);
    // };

    return (
        <>
            <div id={formId} style={{ display: 'none' }} className={className}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div className="flatBtnBody">
                            <div className="topBar"></div>
                            <div className="flatBtnTop">
                                {flatButtons.map((e, i) => {
                                    return (
                                        <div id={`flatBtnCash${e.replace(" ", "")}`} className="flatBtn" key={e} data-name={e.toLowerCase().replace(" ", "")} onClick={flatBtnClick}>
                                            <p className="my-auto">{e}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="flatBtnContainer containerWidth" style={{overflow: 'auto' }}>
                                {(() => {
                                    switch (activeComponent) {
                                        case "reports":
                                            return <CashClosingComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} isActive={isActive}/>;
                                        case "create":
                                            return <FixedAccountsComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} isActive={isActive}/>;
                                        default:
                                            return <div></div>;
                                    }
                                })()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default ManageCashComponent;