import { getUserId, post } from 'utilities/DB.js';

export const apiGetBalance = (accountId) => {
    const params = {
        accountId: accountId,
        date: new Date()
    }
    return new Promise((resolve, reject) => {
        post("api/accounts/getBalance", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadEntries = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accounts/loadEntries", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiPassEntry = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/accounts/passEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteEntry = (entryId) => {
    const params = {
        id: entryId
    }
    return new Promise((resolve, reject) => {
        post("api/accounts/deleteEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
