import React from "react";

const ReportThermalStyle = () => {
    return (
        <>
            <div>
                <style>
                    {`@media print {
                                @page{
                                    width: 260px;
                                    height: auto;
                                    margin: 0px;
                                }
                            }
                        `}
                </style>
            </div>
        </>
    )
}

export default ReportThermalStyle;