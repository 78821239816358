import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import 'style/purchase/bills/purchaseBillA5English.css';
import parse from 'html-react-parser';
import { toDecimal } from "utilities/Utils";
import ReportA5Style from "components/reports/styles/ReportA5Style";

const A5English = forwardRef((props, ref) => {
    const data = props.data;
    const billsCode = useSelector(state => state.main.billsCode);
    const bill = billsCode.purchaseA5English;

    return (
        <>
            <div className="purchaseBillA5English reportA5" ref={ref}>
                <ReportA5Style />
                {
                    !bill && <div>
                        <div id="purchaseBillData">
                            Nothing to print
                        </div>
                    </div>
                }

                {
                    bill &&
                    <div className="dCol" style={{ height: '100%' }}>
                        {/* Style */}
                        <div>
                            {parse(bill.style)}
                        </div>

                        {/* Header */}
                        <div className="header">
                            {parse(bill.header)}
                        </div>

                        <div className="line"></div>

                        {/* Body */}
                        <div className="body">
                            {parse(bill.body)}

                            {
                                data.length > 0 &&
                                <div>
                                    <div className="dRow header1">
                                        <div className="dCol" style={{ flex: 'auto' }}>
                                            <p className="heading vendor">Vendor:</p>
                                            <p className="label vendorName">{data[0].vendorName}</p>
                                            <p className="label vendorContact">{data[0].vendorContact}</p>
                                            <p className="label vendorAddress">{data[0].vendorAddress}</p>
                                        </div>
                                        <div className="dCol" style={{ minWidth: '200px' }}>
                                            <p className="heading date">Date/Time</p>
                                            <p className="label date">{`${data[0].date} ${data[0].time}`}</p>
                                            <p className="heading invoiceNo">Invoice #</p>
                                            <p className="label invoiceNo">{data[0].invoiceNo}</p>
                                        </div>
                                        <div className="dCol" style={{ minWidth: '100px' }}>
                                            <p className="heading cashier">User</p>
                                            <p className="label cashier">{data[0].user}</p>
                                            <p className="heading purchaseOn">Bill On</p>
                                            <p className="label purchaseOn">{data[0].purchaseOn}</p>
                                        </div>
                                    </div>

                                    <div className="header1">
                                        <div className={`${data[0].billType !== "Original" ? "d-block" : "d-none"}`}>
                                            <p className="tac" style={{ fontSize: '12px' }}>{data[0].billType}</p>
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div id="purchaseBillData" style={{ marginTop: '2px' }}>

                                        <table>
                                            <thead>
                                                <tr>
                                                    {bill.showSr === true && <th>Sr.</th>}
                                                    <th style={{ textAlign: 'left' }}>Itemname</th>
                                                    <th>Qty</th>
                                                    {data[0].saleCartons === true && <th>Crtn</th>}
                                                    <th>P.Cost</th>
                                                    {data[0].saleCartons === true && <th>C.Cost</th>}
                                                    {data[0].saleCartons === true && <th className="crtnSizeCol d-none">Crtn Size</th>}
                                                    {bill.showDisc === true && <th>Disc</th>}
                                                    {bill.showDiscPercent === true && <th>Disc</th>}
                                                    {bill.showTotalDisc === true && <th>Disc</th>}
                                                    <th>Total</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={rowIndex}>
                                                            {bill.showSr === true && <td className="borderLeft sr">{rowIndex + 1}</td>}
                                                            <td className={`itemname ${bill.showSr === true ? "borderLeft" : ""}`}>
                                                                <div className="dRow jcsb">
                                                                    <span>{row["itemname"]}</span>
                                                                    <span className="urdu urduname">{`${bill.showUrdu === true ? `${row["urduname"]}` : ''}`}</span>
                                                                </div>
                                                            </td>
                                                            <td className={`borderLeft qty ${row["qty"] < 0 ? "returnBill" : ""}`}>{row["qty"]}</td>
                                                            {data[0].saleCartons === true && <td className={`borderLeft crtn ${row["crtn"] < 0 ? "returnBill" : ""}`}>{row["crtn"]}</td>}
                                                            <td className="borderLeft cost">{row["crtnSize"] === 1 ? 0 : toDecimal(row["cost"])}</td>
                                                            {data[0].saleCartons === true && <td className="borderLeft costCrtn">{row["crtn"] > 0 ? toDecimal(row["costCrtn"]) : 0}</td>}
                                                            {data[0].saleCartons === true && <td className="borderLeft crtnSize d-none">{toDecimal(row["crtnSize"])}</td>}
                                                            {bill.showDisc === true && <td className="borderLeft disc">{toDecimal(row["disc"])}</td>}
                                                            {bill.showDiscPercent === true && <td className="borderLeft discPercent">{`${toDecimal(row["disc"])}%`}</td>}
                                                            {bill.showTotalDisc === true && <td className="borderLeft totalDisc">{toDecimal(row["totalDisc"])}</td>}
                                                            <td className="borderLeft total">{toDecimal(row["total"], 0)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <div className="line"></div> */}

                                    {/* Footer 1 */}
                                    <div className={`footer1`}>
                                        <div className="dRow jcsb">
                                            <div className="dCol">
                                                <div><p>Total Items: <span style={{ fontWeight: '600' }}>{data.length}</span></p></div>
                                                <div className="sumQty d-none"><p>Total Qty: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { qty }) => sum + qty, 0)}</span></p></div>
                                                <div className="sumCrtn d-none"><p>Total Crtn: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { crtn }) => sum + crtn, 0)}</span></p></div>
                                            </div>

                                            {/* Cash Footer */}
                                            <div className={`dCol ${data[0].purchaseOn === "Cash" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'end' }}>
                                                <div className="dRow subTotal">
                                                    <p className="heading subTotalH">Sub Total: </p>
                                                    <p className="label subTotalL">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow grandDisc">
                                                    <p className="heading grandDiscH">Discount: </p>
                                                    <p className="label underline grandDiscL">{toDecimal(data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow grandTotal">
                                                    <p className="heading grandTotalH">Grand Total: </p>
                                                    <p className="label dUnderline grandTotalL">{toDecimal(data[0].grandTotal)}</p>
                                                </div>
                                            </div>

                                            {/* Credit Footer */}
                                            <div className={`dCol ${data[0].purchaseOn === "Credit" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'end' }}>
                                                <div className="dRow subTotal">
                                                    <p className="heading subTotalH">Sub Total: </p>
                                                    <p className="label subTotalL">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow grandDisc">
                                                    <p className="heading grandDiscH">Discount: </p>
                                                    <p className="label underline grandDiscL">{toDecimal(data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow grandTotal">
                                                    <p className="heading grandTotalH">Grand Total: </p>
                                                    <p className="label grandTotalL">{toDecimal(data[0].grandTotal)}</p>
                                                </div>

                                                <div className="dRow oldBalance">
                                                    <p className="heading oldBalanceH">Old Balance: </p>
                                                    <p className="label underline oldBalanceL">{toDecimal(data[0].oldBalance)}</p>
                                                </div>
                                                <div className="dRow newBalance">
                                                    <p className="heading newBalanceH">Net Balance: </p>
                                                    <p className="label dUnderline newBalanceL">{toDecimal(data[0].newBalance)}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{ height: '100%' }}></div>

                        {/* Footer */}
                        <div className="footer">
                            {parse(bill.footer)}
                        </div>

                    </div>
                }

            </div>
        </>
    )
});

export default A5English;