/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/accounts/expenses.css';
import { mainReducerLoadExpenses, mainReducerLoadSubExpenses } from 'state/reducers/mainReducer';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadExpenses,
    apiInsertExpense,
    apiUpdateExpense,
    apiDeleteExpense,
    apiGetExpense,
    apiIsExpenseExists
} from "api/accounts/ExpensesApi";

import {
    apiLoadSubExpenses,
    apiInsertSubExpense,
    apiUpdateSubExpense,
    apiDeleteSubExpense,
    apiGetSubExpense,
    apiIsSubExpenseExists
} from "api/accounts/SubExpensesApi";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import ExpenseCB from "components/tools/dropdowns/ExpenseCB";
/* #endregion Imports */

const ExpensesComponent = (props) => {

    /* #region Variables */
    const formId = "expensesForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const passwords = useSelector(state => state.main.passwords);

    const expenseNameTB = useRef();
    const remarksTB = useRef();
    const searchExpenseTB = useRef();

    const expenseCB = useRef();
    const subExpenseNameTB = useRef();
    const searchSubExpenseTB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableERef = useRef();
    const tableSERef = useRef();
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalSubExpenses, setTotalSubExpenses] = useState(0);

    // Other Variables
    const updateIdE = useRef(0);
    const isUpdateE = useRef(false);
    const updateIdSE = useRef(0);
    const isUpdateSE = useRef(false);

    const formDataE = useRef(null);
    const formDataSE = useRef(null);

    const columnsE = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Expense Name', row: "expenseName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    const columnsSE = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Sub-Expense Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadExpenses();

        expenseNameTB.current.focus();
    }
    /* #region Expenses */
    const loadExpenses = () => {
        if (tableERef.current !== null)
            tableERef.current.setLoading(true);

        apiLoadExpenses({
            text: searchExpenseTB.current.getText()
        }).then((result) => {
            try {
                setTotalExpenses(result.total.totalExpenses);
                tableERef.current.setData([...result.rows]);
                tableERef.current.setLoading(false);
            } catch (error) {
                tableERef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableERef.current.setLoading(false);
        });
    }
    const loadExpensesCB = (result) => {
        dispatch(mainReducerLoadExpenses(result));
        expenseCB.current.setValue(0);
        // apiLoadMainExpenses().then((result) => {
        //     dispatch(mainReducerLoadExpenses(result));
        //     expenseCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateExpense = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationC();

        if (isValid) {
            if (isUpdateE.current) {
                const data = getFormDataC();
                data.id = updateIdE.current;
                apiUpdateExpense(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Expense updated successfully.", "s");
                        loadExpenses();
                        clearTextboxesC();
                        loadExpensesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertExpense(getFormDataC())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Expense saved successfully.", "s");
                        loadExpenses();
                        clearTextboxesC();
                        loadExpensesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editExpense = () => {
        const row = tableERef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetExpense(tempId)
                .then((result) => {
                    updateIdE.current = tempId;
                    isUpdateE.current = true;

                    setFormDataC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteExpense = () => {
        const row = tableERef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteExpense(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Expense deleted successfully.", "s");
                                loadExpenses();
                                clearTextboxesC();
                                loadExpensesCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Expense?")) {
                    apiDeleteExpense(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Expense deleted successfully.", "s");
                            loadExpenses();
                            clearTextboxesC();
                            loadExpensesCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        };
    }
    const deleteExpenseIsUpdate = () => {
        if (isUpdateE.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteExpense(updateIdE.current)
                            .then((result) => {
                                toast.current.show("Expense deleted successfully.", "s");
                                loadExpenses();
                                clearTextboxesC();
                                loadExpensesCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Expense?")) {
                    apiDeleteExpense(updateIdE.current)
                        .then((result) => {
                            toast.current.show("Expense deleted successfully.", "s");
                            loadExpenses();
                            clearTextboxesC();
                            loadExpensesCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxesC = () => {
        expenseNameTB.current.setText("");
        remarksTB.current.setText("");

        updateIdE.current = 0;
        isUpdateE.current = false;
        formDataE.current = null;

        expenseNameTB.current.focus();
    }
    const setFormDataC = (expense) => {
        formDataE.current = expense;

        expenseNameTB.current.setText(expense.expenseName);
        remarksTB.current.setText(expense.remarks);

        expenseNameTB.current.focus();

    }
    const getFormDataC = () => {
        const expense = {
            expenseName: expenseNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText().trim(),
        }

        return expense;
    }
    const validationC = async () => {
        const expenseName = expenseNameTB.current.getText().trim();
        const isValid = await isValidExpense(expenseName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidExpense = async (expenseName) => {
        if (expenseName === '') {
            toast.current.show("Please Enter Expense Name.", "i");
            expenseNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateE.current) {
                if (expenseName.toLowerCase() === formDataE.current.expenseName.toLowerCase())
                    return true;
                else
                    return isExpenseExists(expenseName);
            }
            else
                return isExpenseExists(expenseName);
        }
    }
    const isExpenseExists = async (expenseName) => {
        const result = await apiIsExpenseExists(expenseName);
        if (result.isExists) {
            toast.current.show("Expense Name already exists.", "i");
            expenseNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Sub-Expenses */
    const loadSubExpenses = () => {
        const expenseId = expenseCB.current.getValue();
        if (expenseId !== 0) {
            if (tableSERef.current !== null)
                tableSERef.current.setLoading(true);

            apiLoadSubExpenses({
                text: searchSubExpenseTB.current.getText(),
                expenseId: expenseId,
            }).then((result) => {
                try {
                    setTotalSubExpenses(result.total.totalSubExpenses);
                    tableSERef.current.setData([...result.rows]);
                    tableSERef.current.setLoading(false);
                } catch (error) {
                    tableSERef.current.setLoading(false);
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                tableSERef.current.setLoading(false);
            });
        } else {
            tableSERef.current.clearData();
        }
    }
    const loadSubExpensesCB = (result) => {
        dispatch(mainReducerLoadSubExpenses(result));
        // apiLoadMainSubExpenses().then((result) => {
        //     dispatch(mainReducerLoadSubExpenses(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted1 = useRef(true);
    const insertOrUpdateSubExpense = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationSC();

        if (isValid) {
            if (isUpdateSE.current) {
                const data = getFormDataSC();
                data.id = updateIdSE.current;
                apiUpdateSubExpense(data)
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Expense updated successfully.", "s");
                        loadSubExpenses();
                        clearTextboxesSC();
                        loadSubExpensesCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSubExpense(getFormDataSC())
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Expense saved successfully.", "s");
                        loadSubExpenses();
                        clearTextboxesSC();
                        loadSubExpensesCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editSubExpense = () => {
        const row = tableSERef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSubExpense(tempId)
                .then((result) => {
                    updateIdSE.current = tempId;
                    isUpdateSE.current = true;

                    setFormDataSC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteSubExpense = () => {
        const row = tableSERef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSubExpense(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Sub-Expense deleted successfully.", "s");
                                loadSubExpenses();
                                clearTextboxesSC();
                                loadSubExpensesCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Sub-Expense?")) {
                    apiDeleteSubExpense(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Sub-Expense deleted successfully.", "s");
                            loadSubExpenses();
                            clearTextboxesSC();
                            loadSubExpensesCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }


        };
    }
    const deleteSubExpenseIsUpdate = () => {
        if (isUpdateSE.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSubExpense(updateIdSE.current)
                            .then((result) => {
                                toast.current.show("Sub-Expense deleted successfully.", "s");
                                loadSubExpenses();
                                clearTextboxesSC();
                                loadSubExpensesCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Sub-Expense?")) {
                    apiDeleteSubExpense(updateIdSE.current)
                        .then((result) => {
                            toast.current.show("Sub-Expense deleted successfully.", "s");
                            loadSubExpenses();
                            clearTextboxesSC();
                            loadSubExpensesCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxesSC = () => {
        subExpenseNameTB.current.setText("");

        updateIdSE.current = 0;
        isUpdateSE.current = false;
        formDataSE.current = null;

        subExpenseNameTB.current.focus();
    }
    const setFormDataSC = (subExpense) => {
        formDataSE.current = subExpense;

        subExpenseNameTB.current.setText(subExpense.accountName);

        subExpenseNameTB.current.focus();
    }
    const getFormDataSC = () => {
        const subExpense = {
            expenseId: expenseCB.current.getValue(),
            accountName: subExpenseNameTB.current.getText().trim(),
        }

        return subExpense;
    }
    const validationSC = async () => {
        const subExpenseName = subExpenseNameTB.current.getText().trim();
        const isValid = await isValidSubExpense(subExpenseName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidSubExpense = async (subExpenseName) => {
        if (expenseCB.current.getValue() === 0) {
            toast.current.show("Please Select Expense.", "i");
            return false;
        }
        else if (subExpenseName === '') {
            toast.current.show("Please Enter Sub-Expense Name.", "i");
            subExpenseNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateSE.current) {
                if (subExpenseName.toLowerCase() === formDataSE.current.accountName.toLowerCase())
                    return true;
                else
                    return isSubExpenseExists(subExpenseName);
            }
            else
                return isSubExpenseExists(subExpenseName);
        }
    }
    const isSubExpenseExists = async (subExpenseName) => {
        const result = await apiIsSubExpenseExists(subExpenseName);
        if (result.isExists) {
            toast.current.show("Sub-Expense Name already exists.", "i");
            subExpenseNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */
    /* #endregion */

    /* #region Clicks */
    /* #region Expenses */
    const onExpenseSaveBtnClick = () => {
        insertOrUpdateExpense();
    }
    const onExpenseEditBtnClick = () => {
        editExpense();
    }
    const onExpenseDeleteBtnClick = () => {
        deleteExpenseIsUpdate();
    }
    const onExpenseCancelBtnClick = () => {
        clearTextboxesC();
    }
    const expensesTableOnclick = (row) => {
        const id = Number(row['id']);
        expenseCB.current.setValue(id);
    }
    /* #endregion */

    /* #region Sub-Expenses */
    const onSubExpenseSaveBtnClick = () => {
        insertOrUpdateSubExpense();
    }
    const onSubExpenseEditBtnClick = () => {
        editSubExpense();
    }
    const onSubExpenseDeleteBtnClick = () => {
        deleteSubExpenseIsUpdate();
    }
    const onSubExpenseCancelBtnClick = () => {
        clearTextboxesSC();
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (activeTab.current !== "expenses")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateExpense();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editExpense();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteExpenseIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableERef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateSubExpense();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editSubExpense();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteSubExpenseIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesSC();
        } else if (e.altKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableSERef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onExpenseSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadExpenses();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableERef.current.focus();
        }
    }
    const onSubExpenseSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadSubExpenses();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableSERef.current.focus();
        }
    }
    const onExpenseNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateExpense();
        }
    }
    const onSubExpenseNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateSubExpense();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Expense Combobox Change
    const onExpenseCBChange = (selectedValue, selectedText) => {
        loadSubExpenses();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "expenses" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "expenses" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className='expensesPanel'>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Expenses</h4>
                        </div>
                        <Textbox
                            ref={expenseNameTB}
                            label="Expense Name"
                            tabIndex={601}
                            onKeyDown={onExpenseNameTBKeydown}
                            style={{}} />

                        <Textarea
                            ref={remarksTB}
                            label="Remarks"
                            tabIndex={602}
                            onKeyDown={onRemarksTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onExpenseSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onExpenseEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onExpenseDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onExpenseCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchExpenseTB}
                            label="Search"
                            onKeyDown={onExpenseSearchTBKeydown}
                            tabIndex={603} />

                        <Table
                            ref={tableERef}
                            columns={columnsE.current}
                            myTable='expensesTable'
                            isActive={isActive}
                            onEnterKeyDown={expensesTableOnclick}
                            onClick={expensesTableOnclick}
                            onDoubleClick={onExpenseEditBtnClick}
                            editBtn={{ visible: false, onClick: onExpenseEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteExpense() } }} />

                        <div className="tableFooter">
                            <p><b>Total Expenses: </b>{totalExpenses}</p>
                        </div>

                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Sub-Expenses</h4>
                        </div>

                        <ExpenseCB
                            ref={expenseCB}
                            onChange={onExpenseCBChange} />

                        <Textbox
                            ref={subExpenseNameTB}
                            label="Sub-Expense Name"
                            tabIndex={604}
                            onKeyDown={onSubExpenseNameTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSubExpenseSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onSubExpenseEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onSubExpenseDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onSubExpenseCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchSubExpenseTB}
                            label="Search"
                            onKeyDown={onSubExpenseSearchTBKeydown}
                            tabIndex={605} />

                        <Table
                            ref={tableSERef}
                            columns={columnsSE.current}
                            myTable='subExpensesTable'
                            isActive={isActive}
                            onDoubleClick={onSubExpenseEditBtnClick}
                            editBtn={{ visible: false, onClick: onSubExpenseEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteSubExpense() } }} />

                        <div className="tableFooter">
                            <p><b>Total Sub-Expenses: </b>{totalSubExpenses}</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default ExpensesComponent;