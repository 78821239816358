import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import dateFormat from "dateformat";
import { apiLoadCustomersBalanceList } from "api/reports/accountReportsApi";
import ReportA4Style from "../styles/ReportA4Style";

const CustomersBalanceList = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [branchName, setBranchName] = useState('');
    const [categoryName, setCategoryName] = useState('');

    // Footer
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [totalReceipts, setTotalReceipts] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadCustomersBalanceList(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalCustomers(result.rows.length);
                    setTotalSales(result.total.totalSales);
                    setTotalReceipts(result.total.totalReceipts);
                    setTotalBalance(result.total.totalBalance);

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);

                    setCategoryName(result.total.categoryName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 ledger" ref={ref}>
                <ReportA4Style />
                
                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Customers Balance List</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader" style={{ marginTop: '4px' }}>
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">From Date:</p>
                                <p className="totalLabel alignL">{fromDate}</p>
                            </div>
                        </div>

                        {/* Center */}
                        <div style={{ justifyContent: 'center' }} className={`dRow ${categoryName !== 'none' ? 'd-flex' : 'd-none' }`}>
                            <p className="totalHeading">Category:</p>
                            <p className="labelMarginL">{categoryName}</p>
                        </div>

                        {/* Center */}
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">To Date:</p>
                            </div>
                            <div className="dCol">
                                <p className="totalLabel alignL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <table>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Sabka</th>
                            <th>Sales</th>
                            <th>Total</th>
                            <th>Receipts</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    <td title={row['customerName']}>{row['customerName']}</td>
                                    <td title={row['oldBalance']}>{row['oldBalance']}</td>
                                    <td title={row['sales']}>{row['sales']}</td>
                                    <td title={row['oldBalance'] + row['sales']}>{row['oldBalance'] + row['sales']}</td>
                                    <td title={row['receipts']}>{row['receipts']}</td>
                                    <td title={row['newBalance']}>{row['newBalance']}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* Report Footer */}
                <div className="reportFooter">
                <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Customers:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalCustomers}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Total Sales:</p>
                                <p className="totalHeading">Total Receipts:</p>
                                <p className="totalHeading">Total Balance:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel">{totalSales}</p>
                                <p className="totalLabel">{totalReceipts}</p>
                                <p className="totalLabel">{totalBalance}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default CustomersBalanceList;