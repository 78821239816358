/* #region Imports */
import React, { useState, useRef, useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { mainReducerLoadData } from "state/reducers/mainReducer";
import { apiLoadData as apiLoadMainData } from "api/main/mainApi";
import { useDispatch, useSelector } from "react-redux";
import { reportsReducerLoadData } from "state/reducers/reportsReducer";
import SidebarComponent from "components/main/SidebarComponent.js";
import DashboardComponent from "components/main/DashboardComponent.js";
import ItemsComponent from "components/items/ItemsComponent.js";
import CategoriesComponent from "components/items/CategoriesComponent.js";
import SalesComponent from "components/sales/SalesComponent.js";
import PurchaseComponent from "components/purchase/PurchaseComponent.js";
import AccountsComponent from "components/accounts/AccountsComponent.js";
import WarehouseComponent from "components/warehouse/WarehouseComponent.js";
import ReportsComponent from "components/reports/ReportsComponent.js";
import UtilitiesComponent from "components/utilities/UtilitiesComponent.js";
import { apiLoadReportsData } from "api/reports/reportsApi";
import { getBranchGroupId, getBranchId } from "utilities/DB";

// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from "react-router-dom";
/* #endregion */

const MainComponent = (props) => {
    const dispatch = useDispatch();

    const [mainActiveTab, setActiveTab] = useState("dashboard");
    // eslint-disable-next-line
    const [activeComponent, setActiveComponent] = useState("dashboard");
    const [loaded, setLoaded] = useState(false);

    // Tools References
    const userRights = useSelector(state => state.main.userRights);
    const flatButtons = props.flatButtons;
    const accountsFlatButtons = useRef([]);
    const warehouseFlatButtons = useRef([]);
    const reportsFlatButtons = useRef([]);
    const utilitiesFlatButtons = useRef([]);

    const messageBox = props.messageBox;
    const passwordBox = props.passwordBox;
    const toast = props.toast;

    const formLoad = () => {
        loadFlatButtons();

        apiLoadMainData()
            .then((result) => {
                dispatch(mainReducerLoadData(result));
                setLoaded(true);
            })
            .catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });

        apiLoadReportsData({
            branchId: getBranchId(),
            branchGroupId: getBranchGroupId(),
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }

    const loadFlatButtons = () => {
        // Accounts
        if (userRights.customers)
            accountsFlatButtons.current.push("Customers");
        if (userRights.vendors)
            accountsFlatButtons.current.push("Vendors");
        if (userRights.expenses)
            accountsFlatButtons.current.push("Expenses");
        if (userRights.banks)
            accountsFlatButtons.current.push("Banks");
        if (userRights.suppliers)
            accountsFlatButtons.current.push("Suppliers");

        // Warehouse
        if (userRights.warehouse){
            warehouseFlatButtons.current.push("Create Warehouse");
            warehouseFlatButtons.current.push("Gatepass");
            warehouseFlatButtons.current.push("Transfer Stock");
        }
                
        // Utilities
        if (userRights.users)
            utilitiesFlatButtons.current.push("Users");
        if (userRights.settings)
            utilitiesFlatButtons.current.push("Settings");
        if (userRights.barcodeLabels)
            utilitiesFlatButtons.current.push("Barcode Labels");

        // Reports
        if (userRights.salesReport)
            reportsFlatButtons.current.push("Sales");
        if (userRights.purchaseReport)
            reportsFlatButtons.current.push("Purchase");
        if (userRights.profitReport)
            reportsFlatButtons.current.push("Profit");
        if (userRights.stockReport)
            reportsFlatButtons.current.push("Stock");
        if (userRights.accountsReport)
            reportsFlatButtons.current.push("Accounts");
        if (userRights.othersReport)
            reportsFlatButtons.current.push("Others");

    }

    useEffect(() => {
        formLoad();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="mainScreen background">
                <div className="sideBar">
                    <SidebarComponent setActiveComponent={setActiveComponent} setActiveTab={setActiveTab} flatButtons={flatButtons} />
                </div>
                <div className="mainControl">

                    {
                        loaded &&
                        <div>
                            <div className={`${mainActiveTab === "dashboard" ? 'd-block' : 'd-none'}`}>
                                <DashboardComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} mainActiveTab={mainActiveTab} onBranchChange={props.onBranchChange}/>
                            </div>
                            <div className={`${mainActiveTab === "items" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.items &&
                                    <ItemsComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "categories" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.categories &&
                                    <CategoriesComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "sales" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.sales &&
                                    <SalesComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "purchase" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.purchase &&
                                    <PurchaseComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "accounts" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.accounts &&
                                    <AccountsComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} flatButtons={accountsFlatButtons} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "warehouse" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.warehouse &&
                                    <WarehouseComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} flatButtons={warehouseFlatButtons}  mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "reports" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.reports &&
                                    <ReportsComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} flatButtons={reportsFlatButtons} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                            <div className={`${mainActiveTab === "utilities" ? 'd-block' : 'd-none'}`}>
                                {
                                    userRights.utilities &&
                                    <UtilitiesComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} flatButtons={utilitiesFlatButtons} mainActiveTab={mainActiveTab} />
                                }
                            </div>
                        </div>
                    }


                    {/* {(() => {
                        switch (activeComponent) {
                            case "dashboard":
                                return <ReportsComponent messageBox={messageBox} toast={toast} />;
                            case "items":
                                return <ItemsComponent messageBox={messageBox} toast={toast} />;
                            case "categories":
                                return <CategoriesComponent messageBox={messageBox} toast={toast} />;
                            case "sales":
                                return <SalesComponent messageBox={messageBox} toast={toast} />;
                            case "purchase":
                                return <PurchaseComponent messageBox={messageBox} toast={toast} />;
                            case "accounts":
                                return <AccountsComponent messageBox={messageBox} toast={toast} flatButtons={accountsFlatButtons} />;
                            case "warehouse":
                                return <WarehouseComponent messageBox={messageBox} toast={toast} />;
                            case "reports":
                                return <ReportsComponent messageBox={messageBox} toast={toast} flatButtons={reportsFlatButtons} />;
                            case "utilities":
                                return <UtilitiesComponent messageBox={messageBox} toast={toast} flatButtons={utilitiesFlatButtons} />;
                            default:
                                return <DashboardComponent messageBox={messageBox} toast={toast} />;
                        }
                    })()} */}

                    {/* <Router>
            <Routes>
              <Route path="/" element={<DashboardComponent />} />
              <Route path="/categories" element={<CategoriesComponent />} />
              <Route path="/items" element={<ItemsComponent messageBox={messageBox} toast={toast}/>} />
            </Routes>
          </Router> */}

                </div>
            </div>
        </>
    );
}

export default MainComponent;
