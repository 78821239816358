import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';

const Table = forwardRef((props, ref) => {
    // Props
    const columns = props.columns;
    // const editBtn = props.editBtn;
    const deleteBtn = props.deleteBtn;
    const className = props.className;
    const style = props.style;
    const onClick = props.onClick ? props.onClick : () => { };
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : () => { };
    const onEnterKeyDown = props.onEnterKeyDown ? props.onEnterKeyDown : () => { };
    const autoSelectFirstRow = props.autoSelectFirstRow ? props.autoSelectFirstRow : false;
    const myTable = useRef("table" + Math.ceil(Math.random() * 1000000));
    const selectedRow = `tableSelectedRow`;

    // Variables
    const [sortOrder, setSortOrder] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const sortData = (sortByCol, sortBy) => {
        if (sortOrder === 1) {
            setSortOrder(-1);
            if (sortBy === "string")
                setData([...data].sort((a, b) => (a[sortByCol] > b[sortByCol]) ? 1 : -1));
            else
                setData([...data].sort((a, b) => { return a[sortByCol] - b[sortByCol] }));
        }
        else {
            setSortOrder(1);
            if (sortBy === "string")
                setData([...data].sort((a, b) => (a[sortByCol] < b[sortByCol]) ? 1 : -1));
            else
                setData([...data].sort((a, b) => { return b[sortByCol] - a[sortByCol] }));
        }
    }

    useImperativeHandle(ref, () => {
        return {
            setLoading: setIsLoading,
            getData: () => data,
            setData: setData,
            getSelectedRow: getSelectedRow,
            focus: focus,
            clearData: () => { setData([]); },
        };
    });
    const getTable = () => {
        return myTable.current;
    }
    const querySelector = () => {
        return document.querySelector(`#${getTable()} tbody tr.${selectedRow}`);
    }
    const querySelectorAll = () => {
        return document.querySelectorAll(`#${getTable()} tbody tr`);
    }
    const gridviewQuerySelector = () => {
        return document.querySelector(`#${getTable()}`);
    }

    const focus = () => {
        const element = gridviewQuerySelector();
        if (element)
            element.focus();
    }

    const getSelectedRow = () => {
        const currentRow = querySelector();
        if (currentRow) {
            const data = JSON.parse(currentRow.getAttribute('data-row'));
            return data;
        } else
            return null
    }

    const tableRowOnClick = (e) => {
        const currentRow = querySelector();
        if (currentRow) {
            currentRow.classList.remove(selectedRow);
        }
        e.currentTarget.classList.add(selectedRow);

        const data = getSelectedRow();
        onClick(data);
    }

    const tableRowOnDoubleClick = (e) => {
        const currentRow = querySelector();;
        if (currentRow) {
            currentRow.classList.remove(selectedRow);
        }
        e.currentTarget.classList.add(selectedRow);

        const data = JSON.parse(e.currentTarget.getAttribute('data-row'));
        onDoubleClick(data);
    }

    const onWindowKeyDown = (e) => {
        // If parent not active return
        if (!props.isActive.current)
            return;

        // If keydown other than table
        if (!e.target.className.includes("tablePanel"))
            return;

        // If keydown other than focused table
        if (e.target.getAttribute("id") !== getTable())
            return;

        if (e.key === "ArrowDown") {
            e.preventDefault();

            const rows = querySelectorAll();
            const currentRow = querySelector();
            if (!currentRow && rows.length > 0) {
                rows[0].classList.add(selectedRow);
                return;
            }

            const gridview = gridviewQuerySelector();
            if (currentRow) {
                const nextRow = currentRow.nextElementSibling;
                if (nextRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    gridview.scrollTop += rowRect.height;

                    currentRow.classList.remove(selectedRow);
                    nextRow.classList.add(selectedRow);
                }
            }
        }
        else if (e.key === "ArrowUp") {
            e.preventDefault();
            const gridview = gridviewQuerySelector();
            const currentRow = querySelector();

            if (currentRow) {
                var nextRow = currentRow.previousElementSibling;
                if (nextRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    gridview.scrollTop -= rowRect.height;

                    currentRow.classList.remove(selectedRow);
                    nextRow.classList.add(selectedRow);
                }
            }
        }
        else if (e.key === "Enter") {
            e.preventDefault();
            const currentRow = querySelector();
            if (currentRow) {
                const data = JSON.parse(currentRow.getAttribute('data-row'));
                onEnterKeyDown(data);
            }
        }
        else if (e.key === "Delete") {
            e.preventDefault();
            const currentRow = querySelector();
            if (currentRow) {
                // const data = JSON.parse(currentRow.getAttribute('data-row'));
                deleteBtn.onClick();
            }
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Auto select first row
        if (autoSelectFirstRow) {
            // If old row selected
            const oldRow = querySelector();
            if (oldRow) {
                oldRow.classList.remove(selectedRow);
            }

            // Select first row
            const currentRow = document.querySelector(`#${getTable()} tbody tr:first-child`);
            if (currentRow) {
                currentRow.classList.add(selectedRow);
                const gridview = gridviewQuerySelector();
                gridview.scrollTop = 0;
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            <div className={`tablePanel ${className}`} style={style} id={`${getTable()}`} tabIndex={-1}>

                <div className={`gridview ${!isLoading ? 'd-block' : 'd-block'}`}>
                    <table>
                        <thead>
                            <tr>
                                {
                                    columns.map((col, i) => {
                                        return (
                                            <th style={col.style} key={i} onClick={() => { sortData(col.row, col.sortBy) }}>
                                                <span>{col.column}</span>
                                                <img src="/icons/sort.png" alt="" />
                                            </th>
                                        );
                                    })
                                }
                                {/* <th><span>Edit</span></th>
                                <th><span>Delete</span></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, rowIndex) => {
                                return (
                                    <tr key={rowIndex} onClick={tableRowOnClick} onDoubleClick={tableRowOnDoubleClick} data-row={JSON.stringify(row)}>
                                        {
                                            columns.map((col, colIndex) => {
                                                return (
                                                    <td key={colIndex}>{row[col.row]}</td>
                                                    // <td key={colIndex} title={row[col.row]}>{row[col.row]}</td>
                                                )
                                            })
                                        }

                                        {/* {editBtn.visible && <td><span onClick={() => { editBtn.onClick(row) }}><img className='gridviewBtn' src="/icons/edit.png" alt="" /></span></td>} */}

                                        {/* there is error in delete btn don't use it */}
                                        {/* {deleteBtn.visible && <td><span onClick={deleteBtn.onClick}><img className='gridviewBtn' src="/icons/delete.png" alt="" /></span></td>} */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

            </div>

            <div className={`loadingBarContainer ${isLoading ? 'visible' : 'invisible'}`}>
                <div className="loadingBar"></div>
            </div>

        </>
    )
});

export default Table;