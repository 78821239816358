/* #region Imports */
import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/sales/margin.css';
import Table from 'components/tools/Table';
import { useSelector } from 'react-redux';
import { toDecimal } from 'utilities/Utils';

/* #endregion */

const MarginComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "marginForm";
    const isActive = useRef(false);
    const settings = useSelector(state => state.main.settings);

    const tableRef = useRef();

    const columnsC = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '241px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '100px' } },
        // { column: 'Cost', row: "totalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Profit', row: "profit", sortBy: "number", style: { minWidth: '100px' } },
    ]);

    const columns = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '387px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Cost', row: "cost", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '98px' } },
        // { column: 'Cost', row: "totalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Profit', row: "profit", sortBy: "number", style: { minWidth: '100px' } },
    ]);

    /* #endregion */

    /* #region Methods */

    /* #endregion */

    /* #region Clicks */

    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;
        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        tableRef.current.setData([...props.items]);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>
                        <div className="panel">
                            <Table
                                ref={tableRef}
                                columns={settings.saleCartons ? columnsC.current : columns.current}
                                isActive={isActive}
                                onDoubleClick={() => { }}
                                editBtn={{ visible: false, onClick: () => { } }}
                                deleteBtn={{ visible: false, onClick: () => { } }} />

                            <div className="bottomPanel">
                                <div className='dCol' style={{width:'130px'}}>
                                    <h4>Total Bill: </h4>
                                    <h4>Total Cost: </h4>
                                    <h4>Total Profit: </h4>
                                </div>
                                <div className="dCol" style={{marginLeft:'8px'}}>
                                    <h4>{props.data.total}</h4>
                                    <h4>{props.data.cost}</h4>
                                    <h4>{props.data.profit} ({toDecimal((props.data.profit / props.data.total)*100)}%)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default MarginComponent;