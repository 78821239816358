/* #region Imports */
import React, { forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import 'style/main/user.css';
import Textbox from 'components/tools/Textbox';
import Textarea from 'components/tools/Textarea';
import ButtonIcon from 'components/tools/ButtonIcon';
import TextboxPassword from "components/tools/TextboxPassword";
import { isFileSizeValid, selectNextElement } from "utilities/Utils";
import DateTimePicker from "components/tools/DateTimePicker";
import { useSelector } from "react-redux";
import { apiGetUser, apiIsUserExists, apiUpdateUser, apiUploadImage } from "api/utilities/UsersApi";
import PTag from "components/tools/PTag";
import Image from "components/tools/Image";
import { getClientId } from "utilities/DB";
/* #endregion Imports */

const UserComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "userForm";
    const isActive = useRef(false);
    const user = useSelector(state => state.main.user);

    const usernameLB = useRef();
    const usernameTB = useRef();
    const passwordTB = useRef();
    const confirmPasswordTB = useRef();
    const contactTB = useRef();
    const emailTB = useRef();
    const dobTB = useRef();
    const addressTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        apiGetUser(user.id)
            .then((result) => {
                if (isActive.current) {
                    setFormData(result.user);
                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                }
            })
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();
        if (isValid) {
            const data = getFormData();
            data.id = user.id;
            apiUpdateUser(data)
                .then((result) => {
                    inserted.current = true;
                    toast.current.show("User updated refresh page to see changes.", "s");
                    close();
                }).catch((err) => {
                    inserted.current = true;
                    messageBox.current.show(err.message, "Error", "e");
                });
        } else {
            inserted.current = true;
        }
    }
    const setFormData = (user) => {
        usernameLB.current.setText(user.username);
        usernameTB.current.setText(user.username);
        passwordTB.current.setText(user.password);
        confirmPasswordTB.current.setText(user.password);
        contactTB.current.setText(user.contact);
        emailTB.current.setText(user.email);
        dobTB.current.setText(user.dateOfBirth);
        addressTB.current.setText(user.address);

        if (user.imageUrl !== '')
            document.getElementById("userImage1").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${user.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("userImage1").src = `${process.env.REACT_APP_API_URL}/images/0.png`;

        usernameTB.current.focus();
    }
    const getFormData = () => {
        const data = {};

        const user = {
            username: usernameTB.current.getText().trim(),
            password: passwordTB.current.getText(),
            contact: contactTB.current.getText(),
            email: emailTB.current.getText(),
            address: addressTB.current.getText(),
            dateOfBirth: dobTB.current.getText(),
        }

        const userRights = {
            // items: itemsCheckbox.current.isChecked(),
        }

        data.user = user;
        data.userRights = userRights;
        return data;
    }
    const validation = async () => {
        const username = usernameTB.current.getText().trim();
        const password = passwordTB.current.getText();
        const confirmPassword = confirmPasswordTB.current.getText();
        const isValid = await isValidUser(username, password, confirmPassword);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidUser = async (username, password, confirmPassword) => {
        if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
            return false;
        }
        else if (confirmPassword === '') {
            toast.current.show("Please Re-Enter Password.", "i");
            confirmPasswordTB.current.focus();
            return false;
        }
        else if (password !== confirmPassword) {
            toast.current.show("Password didn't match.", "i");
            passwordTB.current.focus();
            return false;
        }
        else {
            if (username.toLowerCase() === user.username.toLowerCase())
                return true;
            else
                return isUserExists(username, password);
        }
    }
    const isUserExists = async (username, password) => {
        const result = await apiIsUserExists(username, password);
        if (result.isExists) {
            toast.current.show("Username already exists.", "i");
            usernameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onUserImageUpload = (imageDialog) => {
        if (imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)) {
            messageBox.current.show("File size too large", "Error", "e");
            return;
        }

        const formData = new FormData();
        formData.append('id', user.id);
        formData.append('image', imageDialog.files[0]);

        apiUploadImage(formData)
            .then((result) => {
                if (result.data !== '') {
                    document.getElementById("userImage1").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                    toast.current.show("Image uploaded successfylly.", "s");
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onUserUploadBtnClick = (imageDialog) => {
        imageDialog.click();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            selectNextElement(e);
        }
    }
    const onLastTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        loadData();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div className='imagePanel'>
                            <Image
                                width={150}
                                height={150}
                                id="userImageId1"
                                imageId="userImage1"
                                imageDialogId="userImageDialog1"
                                onUploadBtnClick={(e) => {
                                    onUserUploadBtnClick(e);
                                }}
                                onUpload={(e) => {
                                    onUserImageUpload(e);
                                }}
                            />
                        </div>
                        <PTag ref={usernameLB} className="username" />
                        <div className="line"></div>

                        <Textbox
                            ref={usernameTB}
                            label="Username"
                            tabIndex={1101}
                            onKeyDown={onTextboxesKeyDown} />

                        <TextboxPassword
                            ref={passwordTB}
                            label="Password"
                            tabIndex={1102}
                            onKeyDown={onTextboxesKeyDown} />

                        <TextboxPassword
                            ref={confirmPasswordTB}
                            label="Confirm Password"
                            tabIndex={1103}
                            onKeyDown={onTextboxesKeyDown} />

                        <Textbox
                            ref={contactTB}
                            label="Contact"
                            tabIndex={1104}
                            onKeyDown={onTextboxesKeyDown} />

                        <Textbox
                            ref={emailTB}
                            label="Email"
                            tabIndex={1105}
                            onKeyDown={onTextboxesKeyDown} />

                        <DateTimePicker
                            ref={dobTB}
                            label="Date of Birth"
                            time={false} />

                        <Textarea
                            ref={addressTB}
                            label="Address"
                            tabIndex={1106}
                            onKeyDown={onLastTBKeydown}
                            style={{}} />

                        <div className="dRow jcc" style={{}}>
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default UserComponent;