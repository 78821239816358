/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import 'style/reports/mainReports.css';
import ThinButton from "components/tools/ThinButton";
import TextboxNumber from "components/tools/TextboxNumber";
import DateTimePicker from "components/tools/DateTimePicker";
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import BillWisePurchaseReport from "./purchase/BillWisePurchaseReport";
import BranchGroupsCB from "components/tools/dropdowns/BranchGroupsCB";
import CategoryCBReports from "components/tools/reportsDropdowns/CategoryCBReports";
import SubCategoryCBReports from "components/tools/reportsDropdowns/SubCategoryCBReports";
import VendorCBReports from "components/tools/reportsDropdowns/VendorCBReports";
import UsersCBReports from "components/tools/reportsDropdowns/UsersCBReports";
import { apiLoadReportsData } from "api/reports/reportsApi";
import { reportsReducerLoadData } from "state/reducers/reportsReducer";
import { useDispatch, useSelector } from "react-redux";
import { getFromDate, getReportsData, getToDate } from "utilities/Utils";
import Checkbox from "components/tools/Checkbox";
import AutoCompleteTextboxReports from "components/tools/AutoCompleteTextboxReports";
import { apiIsBarcodeExists } from "api/reports/reportsApi";
import ItemsPurchaseReport from "./purchase/ItemsPurchaseReport";
import BriefPurchaseReport from "./purchase/BriefPurchaseReport";
import BranchesPurchaseReport from "./purchase/BranchesPurchaseReport";
import ButtonIcon from "components/tools/ButtonIcon";
import { apiLoadChartDaily, apiLoadChartMonthly, apiLoadChartWeekly, apiLoadChartYearly } from "api/reports/purchaseReportsApi";
import Chart from "./Chart";
import PostedBillsComponent from "components/purchase/PostedBillsComponent";
import { getBranchGroupId, getBranchId } from "utilities/DB";
import WarehouseCBReports from "components/tools/reportsDropdowns/WarehouseCBReports";
/* #endregion */

const PurchaseReportComponent = (props) => {

    /* #region Variables */
    const formId = "mainReportsForm";
    const dispatch = useDispatch();
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const userRights = useSelector(state => state.main.userRights);
    const user = useSelector(state => state.main.user);
    const [activeReport, setActiveReport] = useState("");
    const [activeComponent, setActiveComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const postedBillsFormRef = useRef(null);

    const purchaseTypeBothRB = useRef();
    const purchaseTypePurchaseRB = useRef();
    const purchaseTypeReturnRB = useRef();
    const purchaseOnBothRB = useRef();
    const purchaseOnCashRB = useRef();
    const purchaseOnCreditRB = useRef();
    const mopBothRB = useRef();
    const mopCashRB = useRef();
    const mopCreditRB = useRef();
    const fromDateTB = useRef();
    const toDateTB = useRef();
    const branchCB = useRef();
    const branchDataCB = useRef();
    const branchGroupDataCB = useRef();

    const chartDateTB = useRef();
    const chartDailyRB = useRef();
    const chartWeeklyRB = useRef();
    const chartMonthlyRB = useRef();
    const chartYearlyRB = useRef();
    const chartDaysTB = useRef();
    const chartWeeksTB = useRef();
    const chartMonthsTB = useRef();
    const chartYearsTB = useRef();
    const [isChart, setIsChart] = useState(false);


    const itemnameTB = useRef();
    const categoryCB = useRef();
    const subCategoryCB = useRef();
    const vendorCB = useRef();
    const warehouseCB = useRef();
    const userCB = useRef();
    const sumCheckbox = useRef();
    const itemId = useRef();

    const reportRef = useRef();
    const reportParams = useRef({});
    const chartData = useRef({});

    const messageBox = props.messageBox;
    const toast = props.toast;

    const invoiceNo = useRef(0);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        if (userRights.branchWiseReports) {
            const branchId = getBranchId();
            branchCB.current.setValue(branchId);
            branchDataCB.current.setValue(branchId);

            const branchGroupId = getBranchGroupId();
            branchGroupDataCB.current.setValue(branchGroupId);

            // if (user.branchGroupId === 0) {
            //     // const branchId = user.branchId;
            //     const branchId = getBranchId();
            //     branchCB.current.setValue(branchId);
            //     branchDataCB.current.setValue(branchId);
            // } else {
            //     // const branchGroupId = user.branchGroupId;
            //     const branchId = getBranchId();
            //     branchCB.current.setValue(branchId);
            //     branchDataCB.current.setValue(branchId);

            //     const branchGroupId = getBranchGroupId();
            //     branchGroupDataCB.current.setValue(branchGroupId);
            // }
        }

        isFirstTime.current = false;
    }
    const loadData = (branchId, branchGroupId) => {
        if (isFirstTime.current)
            return;

        apiLoadReportsData({
            branchId: branchId,
            branchGroupId: branchGroupId,
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const billWiseReport = () => {
        loadReport("billWiseReport", getParams());
    }
    const itemWiseReport = () => {
        if (itemId.current !== 0) {
            const params = getItemsParams();
            params.itemId = itemId.current;

            loadReport("itemsReport", params);
        } else {
            toast.current.show("Please Select Item.", "i");
        }
    }
    const categoryWiseReport = () => {
        if (categoryCB.current.getValue() !== 0) {
            const params = getItemsParams();
            params.categoryId = categoryCB.current.getValue();

            loadReport("itemsReport", params);
        } else {
            toast.current.show("Please Select Category.", "i");
        }
    }
    const subCategoryWiseReport = () => {
        if (subCategoryCB.current.getValue() !== 0) {
            const params = getItemsParams();
            params.subCategoryId = subCategoryCB.current.getValue();

            loadReport("itemsReport", params);
        } else {
            toast.current.show("Please Select Sub-Category.", "i");
        }
    }
    const vendorWiseReport = () => {
        if (vendorCB.current.getValue() !== 0) {
            const params = getItemsParams();
            params.vendorId = vendorCB.current.getValue();

            loadReport("billWiseReport", params);
        } else {
            toast.current.show("Please Select Vendor.", "i");
        }
    }
    const warehouseWiseReport = () => {
        if (warehouseCB.current.getValue() !== 0) {
            const params = getItemsParams();
            params.warehouseId = warehouseCB.current.getValue();

            loadReport("billWiseReport", params);
        } else {
            toast.current.show("Please Select Warehouse.", "i");
        }
    }
    const userWiseReport = () => {
        if (userCB.current.getValue() !== 0) {
            const params = getParams();
            params.userId = userCB.current.getValue();

            loadReport("billWiseReport", params);
        } else {
            toast.current.show("Please Select User.", "i");
        }
    }
    const briefReport = () => {
        const params = {
            branchId: branchCB.current.getValue(),
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
        }

        loadReport("briefReport", params);
    }
    const allBranchesReport = () => {
        const params = {
            branchId: branchCB.current.getValue(),
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
        }

        loadReport("branchesReport", params);
    }
    const allItemsReport = () => {
        loadReport("itemsReport", getItemsParams());
    }
    const filterReport = () => {
        const params = getItemsParams();
        params.categoryId = categoryCB.current.getValue();
        params.subCategoryId = subCategoryCB.current.getValue();
        params.vendorId = vendorCB.current.getValue();
        params.warehouseId = warehouseCB.current.getValue();
        params.userId = userCB.current.getValue();
        loadReport("itemsReport", params);
    }
    const loadReport = (report, params) => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                reportParams.current = params;

                setIsChart(false);
                setActiveReport("");
                setTimeout(() => {
                    setActiveReport(report);
                }, 10);
            } else {
                toast.current.show("Please Select Branch.", "i");
            }
        } else {
            params.branchId = user.branchId;
            reportParams.current = params;

            setIsChart(false);
            setActiveReport("");
            setTimeout(() => {
                setActiveReport(report);
            }, 10);
        }
    }
    const getParams = () => {
        return {
            branchId: branchCB.current.getValue(),
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
            purchaseOn: purchaseOnBothRB.current.checked ? 0 : purchaseOnCashRB.current.checked ? 1 : 2,
            purchaseType: purchaseTypeBothRB.current.checked ? 0 : purchaseTypePurchaseRB.current.checked ? 1 : 2,
            isMopCashBank: mopBothRB.current.checked ? 2 : mopCashRB.current.checked ? 1 : 0,
        }
    }
    const getItemsParams = () => {
        const params = getParams();
        params.sum = sumCheckbox.current.isChecked();

        return params;
    }
    const chartDaily = () => {
        if (!chartValidation)
            return;

        const days = chartDaysTB.current.getText();
        const params = getChartParams();
        params.days = days;

        apiLoadChartDaily(params)
            .then((result) => {
                if (result) {
                    chartData.current = result;
                    loadChart();
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    const chartWeekly = () => {
        if (!chartValidation)
            return;

        const weeks = chartWeeksTB.current.getText();
        const params = getChartParams();
        params.weeks = weeks;

        apiLoadChartWeekly(params)
            .then((result) => {
                if (result) {
                    chartData.current = result;
                    loadChart();
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    const chartMonthly = () => {
        if (!chartValidation)
            return;

        const months = chartMonthsTB.current.getText();
        const params = getChartParams();
        params.months = months;

        apiLoadChartMonthly(params)
            .then((result) => {
                if (result) {
                    chartData.current = result;
                    loadChart();
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    const chartYearly = () => {
        if (!chartValidation)
            return;

        const years = chartYearsTB.current.getText();
        const params = getChartParams();
        params.years = years;

        apiLoadChartYearly(params)
            .then((result) => {
                if (result) {
                    chartData.current = result;
                    loadChart();
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    const getChartParams = () => {
        return {
            branchId: getBranchIdForChart(),
            date: chartDateTB.current.getText(),
            purchaseOn: purchaseOnBothRB.current.checked ? 0 : purchaseOnCashRB.current.checked ? 1 : 2,
            purchaseType: purchaseTypeBothRB.current.checked ? 0 : purchaseTypePurchaseRB.current.checked ? 1 : 2,
            isMopCashBank: mopBothRB.current.checked ? 2 : mopCashRB.current.checked ? 1 : 0,
        }
    }
    const loadChart = () => {
        if (branchCB.current.getValue() !== 0) {
            setIsChart(true);
            setActiveReport("");
            setTimeout(() => {
                setActiveReport("chart");
            }, 10);
        } else {
            toast.current.show("Please Select Branch.", "i");
        }

    }
    const chartValidation = () => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                return true;
            } else {
                toast.current.show("Please Select Branch.", "i");
                return false;
            }
        } else {
            return true;
        }
    }
    const getBranchIdForChart = () => {
        if (userRights.branchWiseReports) {
            return branchCB.current.getValue();
        } else {
            return user.branchId;
        }
    }
    const printReport = useReactToPrint({
        content: () => reportRef.current,
        // pageStyle: `@media print {
        //     @page {
        //       size: 794px 1123px;
        //       margin: 0;
        //     }
        //   }`,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            // setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    const showPostedBillsForm = (bill) => {
        invoiceNo.current = bill.invoiceNo;
        setActiveComponent('posted');
    }
    /* #endregion */

    /* #region Clicks */
    const onResetTimeBtnClick = () => {
        fromDateTB.current.setText(getFromDate());
        toDateTB.current.setText(getToDate());
    }
    const chartBtnClick = () => {
        if (chartDailyRB.current.checked) {
            chartDaily();
        } else if (chartWeeklyRB.current.checked) {
            chartWeekly();
        } else if (chartMonthlyRB.current.checked) {
            chartMonthly();
        } else if (chartYearlyRB.current.checked) {
            chartYearly();
        }
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "reports")
            return;

        if (activeTab.current !== "purchase")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printReport();
        }
    }
    const onBarcodeTBKeyDown = async (barcode) => {
        if (barcode !== '') {
            try {
                const result = await apiIsBarcodeExists({
                    branchId: userRights.branchWiseReports ? branchDataCB.current.getValue() : user.branchId,
                    branchGroupId: userRights.branchWiseReports ? branchGroupDataCB.current.getValue() : user.branchGroupId,
                    barcode: barcode,
                });
                if (result.isExists) {
                    const item = result.data;

                    itemnameTB.current.setText(item.itemname);
                    itemId.current = Number(item.id);
                } else {
                    alert("Barcode not exists");
                    itemnameTB.current.setText("");
                    itemId.current = 0;
                }
            } catch (err) {
                alert(err.message);
            }
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onCategoryCBChange = (selectedValue, selectedText) => {
        subCategoryCB.current.loadData(selectedValue);
    }
    const onBranchDataCBChange = (selectedValue, selectedText) => {
        if (branchDataCB.current.getValue() === 0) {
            if (branchGroupDataCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(0, branchGroupDataCB.current.getValue());
            }
        } else {
            loadData(branchDataCB.current.getValue(), 0);
        }
    }
    const onBranchGroupDataCBChange = (selectedValue, selectedText) => {
        if (branchGroupDataCB.current.getValue() === 0) {
            if (branchDataCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(branchDataCB.current.getValue(), 0);
            }
        } else {
            loadData(branchDataCB.current.getValue(), branchGroupDataCB.current.getValue());
        }
    }
    const onItemnameChange = () => {
        if (itemnameTB.current.getText() === '') {
            itemId.current = 0;
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "purchase" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "purchase" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            {/* Modals */}
            <div className={formId}>
                {(() => {
                    switch (activeComponent) {
                        case "posted":
                            return <PostedBillsComponent
                                ref={postedBillsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onEdit={() => { }}
                                showInReports={true}
                                invoiceNo={invoiceNo.current}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                {/* Top Filters Radion Group */}
                <div className='topCB'>
                    <div className="radioGroup">
                        <div className="header">
                            <p>Purchase Type</p>
                        </div>
                        <div className="radioBody">
                            <div className="myRadio">
                                <div className="radioItem">
                                    <input
                                        ref={purchaseTypeBothRB}
                                        id="reportPurchaseTypeBothRB"
                                        className="form-check-input" type="radio" name="reportPurchaseType" defaultChecked
                                        onChange={() => { }} />
                                    <label htmlFor="reportPurchaseTypeBothRB">Both</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={purchaseTypePurchaseRB}
                                        id="reportPurchaseTypePurchaseRB"
                                        className="form-check-input" type="radio" name="reportPurchaseType" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseTypePurchaseRB">Purchase</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={purchaseTypeReturnRB}
                                        id="reportPurchaseTypeReturnRB"
                                        className="form-check-input" type="radio" name="reportPurchaseType" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseTypeReturnRB">Return</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="radioGroup defaultMarginLeft">
                        <div className="header">
                            <p>Purchase On</p>
                        </div>
                        <div className="radioBody">
                            <div className="myRadio">
                                <div className="radioItem">
                                    <input
                                        ref={purchaseOnBothRB}
                                        id="reportPurchaseOnBothRB"
                                        className="form-check-input" type="radio" name="reportPurchaseOn" defaultChecked
                                        onChange={() => { }} />
                                    <label htmlFor="reportPurchaseOnBothRB">Both</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={purchaseOnCashRB}
                                        id="reportPurchaseOnCashRB"
                                        className="form-check-input" type="radio" name="reportPurchaseOn" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseOnCashRB">Cash</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={purchaseOnCreditRB}
                                        id="reportPurchaseOnCreditRB"
                                        className="form-check-input" type="radio" name="reportPurchaseOn" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseOnCreditRB">Credit</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="radioGroup defaultMarginLeft">
                        <div className="header">
                            <p>M.O.P</p>
                        </div>
                        <div className="radioBody">
                            <div className="myRadio">
                                <div className="radioItem">
                                    <input
                                        ref={mopBothRB}
                                        id="reportPurchaseMopBothRB"
                                        className="form-check-input" type="radio" name="reportPurchaseMop" defaultChecked
                                        onChange={() => { }} />
                                    <label htmlFor="moreportPurchaseMopBothRBpBothRB">Both</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={mopCashRB}
                                        id="reportPurchaseMopCashRB"
                                        className="form-check-input" type="radio" name="reportPurchaseMop" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseMopCashRB">Cash</label>
                                </div>
                                <div className="radioItem">
                                    <input
                                        ref={mopCreditRB}
                                        id="reportPurchaseMopCreditRB"
                                        className="form-check-input" type="radio" name="reportPurchaseMop" onChange={() => { }} />
                                    <label htmlFor="reportPurchaseMopCreditRB">Bank</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Middle Panel */}
                <div className="middlePanel">
                    <div className="leftPanel">
                        <div className="dateGroup">
                            <DateTimePicker
                                ref={fromDateTB}
                                label="From Date"
                                time={false}
                                text={getFromDate()}
                                onChange={() => { }}
                                style={{ flex: 1 }}
                                tabIndex={100} />

                            <DateTimePicker
                                ref={toDateTB}
                                label="To Date"
                                time={false}
                                text={getToDate()}
                                onChange={() => { }}
                                className="defaultMarginLeft"
                                style={{ flex: 1 }}
                                tabIndex={100} />

                            <div className='resetTimeBtn' onClick={onResetTimeBtnClick}>
                                <img src="/icons/refresh.png" alt="" />
                            </div>
                        </div>
                        <div className="reportShowBtnGroup">
                            <AutoCompleteTextboxReports
                                ref={itemnameTB}
                                label="Item"
                                onEnter={onBarcodeTBKeyDown}
                                onChange={onItemnameChange}
                                style={{ width: '100%' }} />
                            <ThinButton
                                label="Show"
                                onClick={itemWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <CategoryCBReports
                                ref={categoryCB}
                                onChange={onCategoryCBChange} />
                            <ThinButton
                                label="Show"
                                onClick={categoryWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <SubCategoryCBReports
                                ref={subCategoryCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={subCategoryWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <VendorCBReports
                                ref={vendorCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={vendorWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <WarehouseCBReports
                                ref={warehouseCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={warehouseWiseReport} />
                        </div>
                        <div className="reportShowBtnGroup">
                            <UsersCBReports
                                ref={userCB}
                                onChange={() => { }} />
                            <ThinButton
                                label="Show"
                                onClick={userWiseReport} />
                        </div>

                        <div className="buttons thinButtons">
                            <ThinButton
                                label="Brief Report"
                                onClick={briefReport} />

                            <ThinButton
                                label="Bill Wise Report"
                                onClick={billWiseReport} />

                            <ThinButton
                                label="All Branches"
                                className={`${userRights.branchWiseReports ? 'd-block' : 'd-none'}`}
                                onClick={allBranchesReport} />

                            <ThinButton
                                label="All Items"
                                onClick={allItemsReport} />

                            <ThinButton
                                label="Filter Report"
                                onClick={filterReport} />

                            <Checkbox
                                ref={sumCheckbox}
                                label="Sum"
                                checked={false}
                                onChange={() => { }} />

                        </div>

                    </div>
                    <div className="rightPanel">
                        <div className={`reportShowBtnGroup ${userRights.branchWiseReports ? 'd-flex' : 'd-none'}`}>
                            <div className="branchCB">
                                <BranchesCB
                                    ref={branchCB}
                                    title="Select Branch to Show Report"
                                    onChange={() => { }} />
                            </div>
                        </div>
                        <div className={`reportShowBtnGroup ${userRights.branchWiseReports ? 'd-flex' : 'd-none'}`}>
                            <BranchesCB
                                ref={branchDataCB}
                                title="Select Branch to Load Data"
                                onChange={onBranchDataCBChange} />
                            <BranchGroupsCB
                                ref={branchGroupDataCB}
                                title="Select Branch Group to Load Data"
                                className="defaultMarginLeft"
                                onChange={onBranchGroupDataCBChange} />
                        </div>
                        <div className="chartHeader">
                            <p className="chartHeaderText">Charts</p>
                        </div>
                        <div className="chartPanel">
                            <div className="chartItem" style={{ marginTop: "2px" }}>
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input ref={chartDailyRB} id="purchaseChartDaily" className="form-check-input" type="radio" name="purchaseChart" defaultChecked />
                                        <label htmlFor="purchaseChartDaily">Daily</label>
                                    </div>
                                </div>
                                <label className="chartLabel">Chart for</label>
                                <TextboxNumber
                                    ref={chartDaysTB}
                                />
                                <label className="chartLabel">days</label>
                            </div>
                            <div className="chartItem">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input ref={chartWeeklyRB} id="purchaseChartWeely" className="form-check-input" type="radio" name="purchaseChart" />
                                        <label htmlFor="purchaseChartWeely">Weekly</label>
                                    </div>
                                </div>
                                <label className="chartLabel">Chart for</label>
                                <TextboxNumber
                                    ref={chartWeeksTB} />
                                <label className="chartLabel">weeks</label>
                            </div>
                            <div className="chartItem">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input ref={chartMonthlyRB} id="purchaseChartMonthly" className="form-check-input" type="radio" name="purchaseChart" />
                                        <label htmlFor="purchaseChartMonthly">Monthly</label>
                                    </div>
                                </div>
                                <label className="chartLabel">Chart for</label>
                                <TextboxNumber
                                    ref={chartMonthsTB} />
                                <label className="chartLabel">months</label>
                            </div>
                            <div className="chartItem">
                                <div className="myRadio">
                                    <div className="radioItem">
                                        <input ref={chartYearlyRB} id="purchaseChartYearly" className="form-check-input" type="radio" name="purchaseChart" />
                                        <label htmlFor="purchaseChartYearly">Yearly</label>
                                    </div>
                                </div>
                                <label className="chartLabel">Chart for</label>
                                <TextboxNumber
                                    ref={chartYearsTB} />
                                <label className="chartLabel">years</label>
                            </div>
                            <div className="chartItem" style={{ marginTop: "0px" }}>
                                <DateTimePicker
                                    ref={chartDateTB}
                                    label="Date"
                                    style={{ minWidth: '264px' }}
                                    time={false} />

                                <ThinButton
                                    label="Show Chart"
                                    onClick={chartBtnClick} />

                            </div>
                        </div>
                    </div>
                </div>

                {/* Report & Viewer */}
                <div className={`reportViewer ${isChart ? "d-none" : 'd-flex'}`} >
                    <div className={`loadingBarContainer ${isLoading ? 'd-block' : 'd-none'}`}>
                        <div className="loadingBar"></div>
                    </div>

                    {(() => {
                        switch (activeReport) {
                            case "billWiseReport":
                                return <BillWisePurchaseReport
                                    ref={reportRef}
                                    onDoubleClick={(bill) => { showPostedBillsForm(bill) }}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "itemsReport":
                                return <ItemsPurchaseReport
                                    ref={reportRef}
                                    onDoubleClick={(bill) => { showPostedBillsForm(bill) }}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />;
                            case "briefReport":
                                return <BriefPurchaseReport
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />;
                            case "branchesReport":
                                return <BranchesPurchaseReport
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />;
                            default:
                                return <div></div>;
                        }
                    })()}

                    <div className="printPanel">
                        <ButtonIcon
                            icon="icons/buttons/print.png"
                            onClick={printReport} />
                    </div>
                </div>

                <div className={`chartViewer ${isChart ? "d-block" : 'd-none'}`} >
                    {(() => {
                        switch (activeReport) {
                            case "chart":
                                return <Chart
                                    data={chartData.current} />;
                            default:
                                return <div></div>;
                        }
                    })()}

                </div>
            </div>
        </>
    )
}

export default PurchaseReportComponent;