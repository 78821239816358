import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import dateFormat from "dateformat";
import { apiLoadCreditors } from "api/reports/accountReportsApi";
import ReportA4Style from "../styles/ReportA4Style";

const Creditors = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    // Header
    const [date, setDate] = useState('');
    const [branchName, setBranchName] = useState('');

    // Footer
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    const columns = [
        { column: 'Ac #', row: "id", sortBy: "number" },
        { column: 'Vendor Name', row: "accountName", sortBy: "string", className: "accountName" },
        { column: 'Contact', row: "contact", sortBy: "string" },
        { column: 'Balance', row: "balance", sortBy: "number" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadCreditors(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalAccounts(result.rows.length);
                    setGrandTotal(result.total.grandTotal);

                    setDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 ledger" ref={ref}>
                <ReportA4Style />
                
                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Vendors</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">Date:</p>
                                <p className="totalLabel alignL">{date}</p>

                                <p className="totalHeading">Branch:</p>
                                <p className="labelMarginL">{branchName}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={columns}
                    data={data}
                    setData={setData}
                    onDoubleClick={(row) => { }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Vendors:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalAccounts}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Grand Total:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel dUnderline" style={{ textAlign: 'center', minWidth: '120px' }}>{grandTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Creditors;