import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const TextboxCB = forwardRef((props, ref) => {
    // Props
    const label = props.label;
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const onCBChange = props.onCBChange ? props.onCBChange : () => { };
    const onKeyDown = props.onKeyDown ? props.onKeyDown : () => { };
    const style = props.style;
    const tabIndex = props.tabIndex;

    // Variables
    let cbRef = useRef();
    const [value, setValue] = useState("");
    const [readonly, setReadonly] = useState(false);
    const reference = useRef();
    const data = [{ id: 0, name: '%' }, { id: 1, name: 'Rs' }];
    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            getText:()=> current.current,
            setText:(val)=>{setValue(val); },
            focus:()=>{reference.current.focus();},
            getCBValue: () => { return Number(cbRef.current.value) },
            setCBValue: (val) => { cbRef.current.value = val },
            getCBText: ()=>{return cbRef.current[cbRef.current.selectedIndex].text},
            enable:()=>{reference.current.disabled = false},
            disable:()=>{reference.current.disabled = true},
            setReadonly:setReadonly,
        };
    });

    const onChangeHandle = (e) => {
        setValue(e.target.value);
    }

    useEffect(()=>{
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div className={`tb tbcb ${className}`} style={style}>
                <div><label>{label}</label></div>
                <input ref={reference} type="text" value={value} onChange={onChangeHandle} onKeyDown={onKeyDown} tabIndex={tabIndex} id={`tb${tabIndex}`} spellCheck="false" readOnly={readonly}/>
                <select ref={cbRef} onChange={(e)=>{onCBChange(Number(e.target.value), e.target[e.target.selectedIndex].text)}} >
                    {data.map((e) => {
                        return (
                            <option key={e.id} value={e.id}>{e.name}</option>
                        );
                    })}
                    {/* <option value={0}>%</option>
                    <option value={1}>Rs</option> */}
                </select>
            </div>
        </>
    )
});

export default TextboxCB;