import React, { forwardRef } from "react";
import 'style/reports/report.css';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Chart = forwardRef((props, ref) => {
    const data = props.data;

    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 20,
                        left: 10,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" fill="#2E6FF2" activeBar={<Rectangle fill="#0554F2" stroke="#0554F2" />} />
                </BarChart>
            </ResponsiveContainer>
        </>
    )
});

export default Chart;