import { post } from 'utilities/DB.js';

export const apiLoadStockReport = (params) => {
    return new Promise((resolve, reject) => {
        post("api/stockReports/stockReport", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}