import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './reducers/mainReducer.js';
import reportsReducer from './reducers/reportsReducer.js';

export const store = configureStore({
    reducer: {
        main:mainReducer,
        reports:reportsReducer,
    }
});
