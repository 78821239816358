import dashboard from 'icons/flatbuttons/dashboard.png';
import items from 'icons/flatbuttons/items.png';
import categories from 'icons/flatbuttons/categories.png';
import sales from 'icons/flatbuttons/sales.png';
import purchase from 'icons/flatbuttons/purchase.png';
import accounts from 'icons/flatbuttons/accounts.png';
import warehouse from 'icons/flatbuttons/warehouse.png';
import reports from 'icons/flatbuttons/reports.png';
import utilities from 'icons/flatbuttons/utilities.png';

import customers from 'icons/flatbuttons/customer_accounts.png';
import vendors from 'icons/flatbuttons/vendor_accounts.png';
import suppliers from 'icons/flatbuttons/supplier_accounts.png';
import expenses from 'icons/flatbuttons/expense_accounts.png';
import banks from 'icons/flatbuttons/bank_accounts.png';

import users from 'icons/flatbuttons/users.png';
import settings from 'icons/flatbuttons/settings.png';
import barcodelabels from 'icons/flatbuttons/barcode_labels.png';

const FlatButtonIcons = {
    dashboard,
    items,
    categories,
    sales,
    purchase,
    accounts,
    warehouse,
    reports,
    utilities,

    customers,
    vendors,
    suppliers,
    expenses,
    banks,

    users,
    settings,
    barcodelabels,    
}

export default FlatButtonIcons;