/* #region Imports */
import { apiLoadClients, apiDeleteClient, apiGetBranch, apiGetClient, apiInsertBranch, apiInsertClient, apiIsBranchExists, apiIsClientExists, apiLoadBranchGroups, apiLoadBranches, apiUpdateBranch, apiUpdateClient, apiUpdateBranchGroup, apiInsertBranchGroup, apiGetBranchGroup, apiDeleteBranchGroup, apiDeleteBranch, apiIsBranchGroupExists } from "api/main/ClientsApi";
import ButtonIcon from "components/tools/ButtonIcon";
import Table from "components/tools/Table";
import Textarea from "components/tools/Textarea";
import Textbox from "components/tools/Textbox";
import ClientsCB from "components/tools/dropdowns/ClientsCB";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainReducerLoadClients } from "state/reducers/mainReducer";
import {
    apiLoadClients as apiLoadMainClients,
} from "api/main/mainApi";
import 'style/main/clients.css';
import { selectNextElement } from "utilities/Utils";
import TextboxPassword from "components/tools/TextboxPassword";
import { apiIsUserExists } from "api/utilities/UsersApi";
import MainUsersComponent from "./MainUsersComponent";
/* #endregion */

const ClientsComponent = (props) => {

    /* #region Variables */
    const formId = "clientsForm";
    const isActive = useRef(true);
    const dispatch = useDispatch();

    const enableTB = useRef();

    const clientNameTB = useRef();
    const usernameTB = useRef();
    const passwordTB = useRef();
    const confirmPasswordTB = useRef();
    const clientContactTB = useRef();
    const clientEmailTB = useRef();
    const clientCityTB = useRef();
    const clientAddressTB = useRef();

    const branchClientsCB = useRef();
    const branchNameTB = useRef();
    const branchCityTB = useRef();
    const branchAddressTB = useRef();

    const branchGroupClientsCB = useRef();
    const branchGroupNameTB = useRef();

    const searchClientTB = useRef();
    const searchBranchTB = useRef();
    const searchBranchGroupTB = useRef();

    const updateIdC = useRef(0);
    const updateIdB = useRef(0);
    const updateIdBG = useRef(0);
    const isUpdateC = useRef(false);
    const isUpdateB = useRef(false);
    const isUpdateBG = useRef(false);
    const formDataC = useRef(null);
    const formDataB = useRef(null);
    const formDataBG = useRef(null);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableCRef = useRef();
    const tableBRef = useRef();
    const tableBGRef = useRef();
    const [totalClients, setTotalClients] = useState(0);
    const [totalBranches, setTotalBranches] = useState(0);
    const [totalBranchGroups, setTotalBranchGroups] = useState(0);

    // Modals
    const mainUsersFormRef = useRef(null);

    const columnsC = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Client Name', row: "clientName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    const columnsB = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Branch Name', row: "branchName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    const columnsBG = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Branch Group Name', row: "branchGroupName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    /* #region Clients */
    const loadClients = () => {
        tableCRef.current.setLoading(true);
        apiLoadClients({
            text: searchClientTB.current.getText(),
        }).then((result) => {
            setTotalClients(result.total.totalClients);
            tableCRef.current.setData([...result.rows]);
            tableCRef.current.setLoading(false);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadClientsCB = () => {
        apiLoadMainClients().then((result) => {
            dispatch(mainReducerLoadClients(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const insertOrUpdateClient = async () => {
        const isValid = await validationC();

        if (isValid) {
            if (isUpdateC.current) {
                const data = getFormDataC();
                data.id = updateIdC.current;

                apiUpdateClient(data)
                    .then((result) => {
                        toast.current.show("Client updated successfully.", "s");
                        loadClients();
                        clearTextboxesC();
                        loadClientsCB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertClient(getFormDataC())
                    .then((result) => {
                        toast.current.show("Client saved successfully.", "s");
                        loadClients();
                        clearTextboxesC();
                        loadClientsCB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const editClient = () => {
        const row = tableCRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetClient(tempId)
                .then((result) => {
                    updateIdC.current = tempId;
                    isUpdateC.current = true;

                    setFormDataC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteClientIsUpdate = () => {
        if (isUpdateC.current) {
            if (window.confirm("Are you sure to delete this Client?")) {
                apiDeleteClient(updateIdC.current)
                    .then((result) => {
                        toast.current.show("Client deleted successfully.", "s");
                        loadClients();
                        clearTextboxesC();
                        loadClientsCB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesC = () => {
        clientNameTB.current.setText("");
        usernameTB.current.setText("");
        passwordTB.current.setText("");
        confirmPasswordTB.current.setText("");
        clientContactTB.current.setText("");
        clientEmailTB.current.setText("");
        clientCityTB.current.setText("");
        clientAddressTB.current.setText("");

        updateIdC.current = 0;
        isUpdateC.current = false;
        formDataC.current = null;

        clientNameTB.current.focus();
    }
    const setFormDataC = (client) => {
        formDataC.current = client;

        clientNameTB.current.setText(client.clientName);
        usernameTB.current.setText(client.username);
        passwordTB.current.setText(client.password);
        confirmPasswordTB.current.setText(client.password);
        clientContactTB.current.setText(client.contact);
        clientEmailTB.current.setText(client.email);
        clientCityTB.current.setText(client.city);
        clientAddressTB.current.setText(client.address);

        clientNameTB.current.focus();
    }
    const getFormDataC = () => {
        const data = {
            clientName: clientNameTB.current.getText().trim(),
            username: usernameTB.current.getText().trim(),
            password: passwordTB.current.getText(),
            contact: clientContactTB.current.getText(),
            email: clientEmailTB.current.getText(),
            city: clientCityTB.current.getText(),
            address: clientAddressTB.current.getText(),
        }

        return data;
    }
    const validationC = async () => {
        const username = usernameTB.current.getText().trim();
        const password = passwordTB.current.getText();
        const confirmPassword = confirmPasswordTB.current.getText();

        if (clientNameTB.current.getText() === '') {
            toast.current.show("Please Enter Client Name.", "i");
            clientNameTB.current.focus();
            return false;
        }
        else if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
            return false;
        }
        else if (confirmPassword === '') {
            toast.current.show("Please Re-Enter Password.", "i");
            confirmPasswordTB.current.focus();
            return false;
        }
        else if (password !== confirmPassword) {
            toast.current.show("Password didn't match.", "i");
            passwordTB.current.focus();
            return false;
        }
        else {
            let ok = false;

            if (isUpdateC.current) {
                if (clientNameTB.current.getText().toLowerCase() === formDataC.current.clientName.toLowerCase())
                    ok = true;
                else
                    ok = isClientExists();
            }
            else
                ok = isClientExists();

            if (ok === true) {
                if (isUpdateC.current) {
                    if (username.toLowerCase() === formDataC.current.username.toLowerCase())
                        ok = true;
                    else
                        ok = isUserExists(username, password);
                }
                else
                    ok = isUserExists(username, password);
            }

            return ok;
        }
    }
    const isClientExists = async () => {
        const result = await apiIsClientExists({
            clientName: clientNameTB.current.getText()
        });
        if (result.isExists) {
            toast.current.show("Client Name already exists.", "i");
            clientNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const isUserExists = async (username, password) => {
        const result = await apiIsUserExists(username, password);
        if (result.isExists) {
            toast.current.show("Username already exists.", "i");
            usernameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Branch */
    const loadBranches = () => {
        tableBRef.current.setLoading(true);
        apiLoadBranches({
            clientId: branchClientsCB.current.getValue(),
            text: searchBranchTB.current.getText(),
        }).then((result) => {
            setTotalBranches(result.total.totalBranches);
            tableBRef.current.setData([...result.rows]);
            tableBRef.current.setLoading(false);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const insertOrUpdateBranch = async () => {
        const isValid = await validationB();

        if (isValid) {
            if (isUpdateB.current) {
                const data = getFormDataB();
                data.id = updateIdB.current;

                apiUpdateBranch(data)
                    .then((result) => {
                        toast.current.show("Branch updated successfully.", "s");
                        loadBranches();
                        clearTextboxesB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertBranch(getFormDataB())
                    .then((result) => {
                        toast.current.show("Branch saved successfully.", "s");
                        loadBranches();
                        clearTextboxesB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const editBranch = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetBranch(tempId)
                .then((result) => {
                    updateIdB.current = tempId;
                    isUpdateB.current = true;

                    setFormDataB(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteBranchIsUpdate = () => {
        if (isUpdateB.current) {
            if (window.confirm("Are you sure to delete this Branch?")) {
                apiDeleteBranch(updateIdB.current)
                    .then((result) => {
                        toast.current.show("Branch deleted successfully.", "s");
                        loadBranches();
                        clearTextboxesB();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesB = () => {
        branchNameTB.current.setText("");
        branchCityTB.current.setText("");
        branchAddressTB.current.setText("");

        updateIdB.current = 0;
        isUpdateB.current = false;
        formDataB.current = null;

        branchNameTB.current.focus();
    }
    const setFormDataB = (branch) => {
        formDataB.current = branch;

        branchNameTB.current.setText(branch.branchName);
        branchCityTB.current.setText(branch.city);
        branchAddressTB.current.setText(branch.address);

        branchNameTB.current.focus();
    }
    const getFormDataB = () => {
        const data = {
            clientId: branchClientsCB.current.getValue(),
            branchName: branchNameTB.current.getText().trim(),
            city: branchCityTB.current.getText(),
            address: branchAddressTB.current.getText(),
        }

        return data;
    }
    const validationB = async () => {
        if (branchClientsCB.current.getValue() === 0) {
            toast.current.show("Please Select Client.", "i");
            branchNameTB.current.focus();
            return false;
        }
        else if (branchNameTB.current.getText() === '') {
            toast.current.show("Please Enter Branch Name.", "i");
            branchNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateB.current) {
                if (branchNameTB.current.getText().toLowerCase() === formDataB.current.branchName.toLowerCase())
                    return true;
                else
                    return isBranchExists();
            }
            else
                return isBranchExists();
        }
    }
    const isBranchExists = async () => {
        const result = await apiIsBranchExists({
            clientId: branchClientsCB.current.getValue(),
            branchName: branchNameTB.current.getText(),
        });
        if (result.isExists) {
            toast.current.show("Branch Name already exists.", "i");
            branchNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Branch Groups */
    const loadBranchGroups = () => {
        tableBGRef.current.setLoading(true);
        apiLoadBranchGroups({
            clientId: branchGroupClientsCB.current.getValue(),
            text: searchBranchGroupTB.current.getText(),
        }).then((result) => {
            setTotalBranchGroups(result.total.totalBranchGroups);
            tableBGRef.current.setData([...result.rows]);
            tableBGRef.current.setLoading(false);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const insertOrUpdateBranchGroup = async () => {
        const isValid = await validationBG();

        if (isValid) {
            if (isUpdateBG.current) {
                const data = getFormDataBG();
                data.id = updateIdBG.current;

                apiUpdateBranchGroup(data)
                    .then((result) => {
                        toast.current.show("Branch Group updated successfully.", "s");
                        loadBranchGroups();
                        clearTextboxesBG();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertBranchGroup(getFormDataBG())
                    .then((result) => {
                        toast.current.show("Branch Group saved successfully.", "s");
                        loadBranchGroups();
                        clearTextboxesBG();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const editBranchGroup = () => {
        const row = tableBGRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetBranchGroup(tempId)
                .then((result) => {
                    updateIdBG.current = tempId;
                    isUpdateBG.current = true;

                    setFormDataBG(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteBranchGroupIsUpdate = () => {
        if (isUpdateBG.current) {
            if (window.confirm("Are you sure to delete this Branch Group?")) {
                apiDeleteBranchGroup(updateIdBG.current)
                    .then((result) => {
                        toast.current.show("Branch Group deleted successfully.", "s");
                        loadBranchGroups();
                        clearTextboxesBG();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesBG = () => {
        branchGroupNameTB.current.setText("");

        updateIdBG.current = 0;
        isUpdateBG.current = false;
        formDataBG.current = null;

        branchGroupNameTB.current.focus();
    }
    const setFormDataBG = (branchGroup) => {
        formDataBG.current = branchGroup;

        branchGroupNameTB.current.setText(branchGroup.branchGroupName);

        branchGroupNameTB.current.focus();
    }
    const getFormDataBG = () => {
        const data = {
            clientId: branchGroupClientsCB.current.getValue(),
            branchGroupName: branchGroupNameTB.current.getText().trim(),
        }

        return data;
    }
    const validationBG = async () => {
        if (branchGroupClientsCB.current.getValue() === 0) {
            toast.current.show("Please Select Client.", "i");
            branchGroupNameTB.current.focus();
            return false;
        }
        else if (branchGroupNameTB.current.getText() === '') {
            toast.current.show("Please Enter Branch Group Name.", "i");
            branchGroupNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateBG.current) {
                if (branchGroupNameTB.current.getText().toLowerCase() === formDataBG.current.branchGroupName.toLowerCase())
                    return true;
                else
                    return isBranchGroupExists();
            }
            else
                return isBranchGroupExists();
        }
    }
    const isBranchGroupExists = async () => {
        const result = await apiIsBranchGroupExists({
            clientId: branchGroupClientsCB.current.getValue(),
            branchGroupName: branchGroupNameTB.current.getText(),
        });
        if (result.isExists) {
            toast.current.show("Branch Group Name already exists.", "i");
            branchGroupNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    const showUsersForm = () => {
        mainUsersFormRef.current.show();
    }
    /* #endregion */

    /* #region Clicks */
    const onClientSaveBtnClick = () => {
        insertOrUpdateClient();
    }
    const onClientEditBtnClick = () => {
        editClient();
    }
    const onClientDeleteBtnClick = () => {
        deleteClientIsUpdate();
    }
    const onClientCancelBtnClick = () => {
        clearTextboxesC();
    }
    const clientsTableOnclick = (row) => {
        const id = Number(row['id']);
        branchClientsCB.current.setValue(id);
        branchGroupClientsCB.current.setValue(id);
    }

    const onBranchSaveBtnClick = () => {
        insertOrUpdateBranch();
    }
    const onBranchEditBtnClick = () => {
        editBranch();
    }
    const onBranchDeleteBtnClick = () => {
        deleteBranchIsUpdate();
    }
    const onBranchCancelBtnClick = () => {
        clearTextboxesB();
    }

    const onBranchGroupSaveBtnClick = () => {
        insertOrUpdateBranchGroup();
    }
    const onBranchGroupEditBtnClick = () => {
        editBranchGroup();
    }
    const onBranchGroupDeleteBtnClick = () => {
        deleteBranchGroupIsUpdate();
    }
    const onBranchGroupCancelBtnClick = () => {
        clearTextboxesBG();
    }

    const onUsersBtnClick = () => {
        showUsersForm();
    }

    /* #endregion */

    /* #region Keydown */
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    const onSearchClientTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadClients();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableCRef.current.focus();
        }
    }
    const onSearchBranchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadBranches();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableBRef.current.focus();
        }
    }
    const onSearchBranchGroupTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadBranchGroups();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableBGRef.current.focus();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onBranchClientsCBChange = (selectedValue, selectedText) => {
        loadBranches();
    }
    const onBranchGroupClientsCBChange = (selectedValue, selectedText) => {
        loadBranchGroups();
    }
    /* #endregion */
    
    const onEnableTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            enableForm();
        }
    }
    const enableForm = () => {
        const password = enableTB.current.getText();
        if(password === "admin@123"){
            const confirmDiv = document.getElementById(formId);
            confirmDiv.style.display = "flex";

            const form = document.getElementById("enableForm");
            form.style.display = "none";
        }
    }

    useEffect(() => {
        loadClients();
        loadClientsCB();
        enableTB.current.focus();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {/* Modals */}
            <div>
                <MainUsersComponent
                    ref={mainUsersFormRef}
                    messageBox={messageBox}
                    toast={toast}
                    isActive={isActive}
                />
            </div>

            <div id="enableForm" style={{display:'block'}}>
                <TextboxPassword
                    ref={enableTB}
                    label=""
                    onKeyDown={onEnableTBKeydown}/>
            </div>

            <div id={formId} style={{display:'none'}}>
                {/* Left Panel */}
                <div className="panel">
                    <h4 className='headingLabel'>Clients</h4>
                    <Textbox
                        ref={clientNameTB}
                        label="Client Name"
                        tabIndex={1}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={usernameTB}
                        label="Username"
                        tabIndex={2}
                        onKeyDown={onTextboxesKeyDown} />

                    <TextboxPassword
                        ref={passwordTB}
                        label="Password"
                        tabIndex={3}
                        onKeyDown={onTextboxesKeyDown} />

                    <TextboxPassword
                        ref={confirmPasswordTB}
                        label="Confirm Password"
                        tabIndex={4}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={clientContactTB}
                        label="Contact"
                        tabIndex={5}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={clientEmailTB}
                        label="Email"
                        tabIndex={6}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={clientCityTB}
                        label="City"
                        tabIndex={7}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textarea
                        ref={clientAddressTB}
                        label="Address"
                        tabIndex={8}
                        onKeyDown={onTextboxesKeyDown} />

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onClientSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onClientEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onClientDeleteBtnClick} />

                        <ButtonIcon
                            label="Users"
                            icon="icons/buttons/categories.png"
                            onClick={onUsersBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onClientCancelBtnClick} />
                    </div>

                    <Textbox
                        ref={searchClientTB}
                        label="Search"
                        onKeyDown={onSearchClientTBKeydown}
                        tabIndex={9} />

                    <Table
                        ref={tableCRef}
                        columns={columnsC.current}
                        myTable='clientsTable'
                        isActive={isActive}
                        onClick={clientsTableOnclick}
                        onDoubleClick={editClient}
                        editBtn={{ visible: false, onClick: editClient }}
                        deleteBtn={{ visible: true, onClick: () => { } }} />

                    <div className="tableFooter">
                        <p><b>Total Clients: </b>{totalClients}</p>
                    </div>

                </div>

                <div className='centerPanel'>
                    <div className="centerLine"></div>
                </div>

                {/* Middle Panel */}
                <div className="panel">
                    <h4 className='headingLabel'>Branches</h4>

                    <ClientsCB
                        ref={branchClientsCB}
                        onChange={onBranchClientsCBChange} />

                    <Textbox
                        ref={branchNameTB}
                        label="Branch Name"
                        tabIndex={10}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={branchCityTB}
                        label="City"
                        tabIndex={11}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textarea
                        ref={branchAddressTB}
                        label="Address"
                        tabIndex={12}
                        onKeyDown={onTextboxesKeyDown} />

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onBranchSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onBranchEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onBranchDeleteBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onBranchCancelBtnClick} />
                    </div>

                    <Textbox
                        ref={searchBranchTB}
                        label="Search"
                        onKeyDown={onSearchBranchTBKeydown}
                        tabIndex={13} />

                    <Table
                        ref={tableBRef}
                        columns={columnsB.current}
                        myTable='branchesTable'
                        isActive={isActive}
                        onDoubleClick={editBranch}
                        editBtn={{ visible: false, onClick: onClientEditBtnClick }}
                        deleteBtn={{ visible: true, onClick: () => { } }} />

                    <div className="tableFooter">
                        <p><b>Total Branches: </b>{totalBranches}</p>
                    </div>

                </div>

                <div className='centerPanel'>
                    <div className="centerLine"></div>
                </div>

                {/* Right Panel */}
                <div className="panel">
                    <h4 className='headingLabel'>Branch Groups</h4>

                    <ClientsCB
                        ref={branchGroupClientsCB}
                        onChange={onBranchGroupClientsCBChange} />

                    <Textbox
                        ref={branchGroupNameTB}
                        label="Branch Group Name"
                        tabIndex={14}
                        onKeyDown={onTextboxesKeyDown} />

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onBranchGroupSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onBranchGroupEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onBranchGroupDeleteBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onBranchGroupCancelBtnClick} />
                    </div>

                    <Textbox
                        ref={searchBranchGroupTB}
                        label="Search"
                        onKeyDown={onSearchBranchGroupTBKeydown}
                        tabIndex={15} />

                    <Table
                        ref={tableBGRef}
                        columns={columnsBG.current}
                        myTable='branchGroupsTable'
                        isActive={isActive}
                        onDoubleClick={editBranchGroup}
                        editBtn={{ visible: false, onClick: onClientEditBtnClick }}
                        deleteBtn={{ visible: true, onClick: () => { } }} />

                    <div className="tableFooter">
                        <p><b>Total Branch Groups: </b>{totalBranchGroups}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ClientsComponent;