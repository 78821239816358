import { post } from 'utilities/DB.js';

export const apiLoadPackageItems = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packageItems/loadPackageItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertPackageItem = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packageItems/insertPackageItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePackageItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/packageItems/updatePackageItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePackageItem = (packageItemId) => {
    const params = {
        id: packageItemId
    }
    return new Promise((resolve, reject) => {
        post("api/packageItems/deletePackageItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPackageItem = (packageItemId) => {
    const params = {
        id: packageItemId
    }
    return new Promise((resolve, reject) => {
        post("api/packageItems/getPackageItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsPackageItemExists = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packageItems/isPackageItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
