import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import 'style/purchase/bills/purchaseBillThermalEnglish.css';
import parse from 'html-react-parser';
import { toDecimal } from "utilities/Utils";
import ReportThermalStyle from "components/reports/styles/ReportThermalStyle";

const ThermalEnglish = forwardRef((props, ref) => {
    const data = props.data;
    const billsCode = useSelector(state => state.main.billsCode);
    const bill = billsCode.purchaseThermalEnglish;

    return (
        <>
            <div className="purchaseBillThermalEnglish reportThermal" ref={ref}>
                <ReportThermalStyle />
                {
                    !bill && <div>
                        <div id="purchaseBillData">
                            Nothing to print
                        </div>
                    </div>
                }

                {
                    bill &&
                    <div>
                        {/* Style */}
                        <div>
                            {parse(bill.style)}
                        </div>

                        {/* Header */}
                        <div className="header">
                            {parse(bill.header)}
                        </div>

                        {/* Body */}
                        <div className="body">
                            {parse(bill.body)}

                            {
                                data.length > 0 &&
                                <div>
                                    <div className="header1">
                                        <div className={`vendorName ${data[0].vendorName !== "" ? "d-block" : "d-none"}`} style={{ margin: '0px' }}>
                                            <p className="tac">{data[0].vendorName}</p>
                                        </div>
                                        <div className={`billType ${data[0].billType !== "Original" ? "d-block" : "d-none"}`}>
                                            <p className="tac">{data[0].billType}</p>
                                        </div>
                                    </div>

                                    <div className="header1">
                                        <div className="dRow jcsb">
                                            <p className="date">Date: {data[0].date}</p>
                                            <p className="cashier">User: {data[0].user}</p>
                                        </div>
                                        <div className="dRow jcsb">
                                            <p className="time">Time: {data[0].time}</p>
                                            <p className="purchaseOn">{data[0].purchaseOn}</p>
                                            <p className="invoiceNo">Inv #: {data[0].id}</p>
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div id="purchaseBillData">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {bill.showSr === true && <th>Sr.</th>}
                                                    <th style={{ textAlign: 'left' }}>Itemname</th>
                                                    <th>Qty</th>
                                                    {data[0].saleCartons === true && <th>Crtn</th>}
                                                    <th>P.Cost</th>
                                                    {data[0].saleCartons === true && <th>C.Cost</th>}
                                                    {data[0].saleCartons === true && <th className="crtnSizeCol d-none">Crtn Size</th>}
                                                    {bill.showDisc === true && <th>Disc</th>}
                                                    {bill.showDiscPercent === true && <th>Disc</th>}
                                                    {bill.showTotalDisc === true && <th>Disc</th>}
                                                    <th>Total</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={rowIndex}>
                                                            {bill.showSr === true && <td className="borderLeft sr">{rowIndex + 1}</td>}
                                                            <td className={`itemname ${bill.showSr === true ? "borderLeft" : ""}`}>
                                                                <div className="dRow jcsb">
                                                                    <span>{row["itemname"]}</span>
                                                                    <span className="urdu urduname">{`${bill.showUrdu === true ? `${row["urduname"]}` : ''}`}</span>
                                                                </div>
                                                            </td>
                                                            <td className={`borderLeft qty ${row["qty"] < 0 ? "returnBill" : ""}`}>{row["qty"]}</td>
                                                            {data[0].saleCartons === true && <td className={`borderLeft crtn ${row["crtn"] < 0 ? "returnBill" : ""}`}>{row["crtn"]}</td>}
                                                            <td className="borderLeft cost">{row["crtnSize"] === 1 ? 0 : toDecimal(row["cost"])}</td>
                                                            {data[0].saleCartons === true && <td className="borderLeft costCrtn">{row["crtn"] > 0 ? toDecimal(row["costCrtn"]) : 0}</td>}
                                                            {data[0].saleCartons === true && <td className="borderLeft crtnSize d-none">{toDecimal(row["crtnSize"])}</td>}
                                                            {bill.showDisc === true && <td className="borderLeft disc">{toDecimal(row["disc"])}</td>}
                                                            {bill.showDiscPercent === true && <td className="borderLeft discPercent">{`${toDecimal(row["disc"])}%`}</td>}
                                                            {bill.showTotalDisc === true && <td className="borderLeft totalDisc">{toDecimal(row["totalDisc"])}</td>}
                                                            <td className="borderLeft total">{toDecimal(row["total"], 0)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Footer 1 */}
                                    <div className={`footer1`}>
                                        <div className="dRow jcsb">
                                            <div className="dCol">
                                                <div><p>Total Items: <span style={{ fontWeight: '600' }}>{data.length}</span></p></div>
                                                <div className="sumQty d-none"><p>Total Qty: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { qty }) => sum + qty, 0)}</span></p></div>
                                                <div className="sumCrtn d-none"><p>Total Crtn: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { crtn }) => sum + crtn, 0)}</span></p></div>
                                            </div>

                                            <div className={`dCol ${data[0].purchaseOn === "Cash" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'end' }}>
                                                <div className="dRow">
                                                    <p className="heading">Sub Total: </p>
                                                    <p className="label">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className={`dRow`}>
                                                    <p className="heading">Discount: </p>
                                                    <p className="label underline">{toDecimal(data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow">
                                                    <p className="heading">Grand Total: </p>
                                                    <p className="label dUnderline">{toDecimal(data[0].grandTotal)}</p>
                                                </div>
                                            </div>

                                            <div className={`dCol ${data[0].purchaseOn === "Credit" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'end' }}>
                                                <div className="dRow">
                                                    <p className="heading">Sub Total: </p>
                                                    <p className="label">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className={`dRow`}>
                                                    <p className="heading">Discount: </p>
                                                    <p className="label underline">{toDecimal(data[0].grandTotalDisc)}</p>
                                                </div>
                                                <div className="dRow">
                                                    <p className="heading">Grand Total: </p>
                                                    <p className="label">{toDecimal(data[0].grandTotal)}</p>
                                                </div>
                                                <div className="dRow">
                                                    <p className="heading">Old Balance: </p>
                                                    <p className="label underline">{toDecimal(data[0].oldBalance)}</p>
                                                </div>
                                                <div className="dRow">
                                                    <p className="heading">Net Balance: </p>
                                                    <p className="label dUnderline">{toDecimal(data[0].newBalance)}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            }
                        </div>

                        {/* Footer */}
                        <div className="footer">
                            {parse(bill.footer)}
                        </div>

                    </div>
                }

            </div>
        </>
    )
});

export default ThermalEnglish;