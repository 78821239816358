import { post } from 'utilities/DB.js';

export const apiLoadBanks = (params) => {
    return new Promise((resolve, reject) => {
        post("api/banks/loadBanks", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertBank = (params) => {
    return new Promise((resolve, reject) => {
        post("api/banks/insertBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBank = (params) => {
    return new Promise((resolve, reject) => {
        post("api/banks/updateBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteBank = (bankId) => {
    const params = {
        id: bankId
    }
    return new Promise((resolve, reject) => {
        post("api/banks/deleteBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBank = (bankId) => {
    const params = {
        id: bankId
    }
    return new Promise((resolve, reject) => {
        post("api/banks/getBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBankExists = (bankName) => {
    const params = {
        bankName: bankName
    }
    return new Promise((resolve, reject) => {
        post("api/banks/isBankExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
