import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import 'style/sales/bills/salesBillA4Urdu.css';
import parse from 'html-react-parser';
import { toDecimal } from "utilities/Utils";
import ReportA4Style from "components/reports/styles/ReportA4Style";

const A4Urdu = forwardRef((props, ref) => {
    const data = props.data;
    const billsCode = useSelector(state => state.main.billsCode);
    const bill = billsCode.salesA4Urdu;

    return (
        <>
            <div className="salesBillA4Urdu reportA4" ref={ref}>
                <ReportA4Style />
                {
                    !bill && <div>
                        <div id="salesBillData">
                            Nothing to print
                        </div>
                    </div>
                }

                {
                    bill &&
                    <div className="dCol" style={{ height: '100%' }}>
                        {/* Style */}
                        <div>
                            {parse(bill.style)}
                        </div>

                        {/* Header */}
                        <div className="header">
                            {parse(bill.header)}
                        </div>

                        <div className="line"></div>

                        {/* Body */}
                        <div className="body">
                            {parse(bill.body)}

                            {
                                data.length > 0 &&
                                <div>
                                    <div className="dRow header1">
                                        <div className="dCol" style={{ flex: 'auto' }}>
                                            <p className="heading customer">Customer:</p>
                                            <p className="label customerName">{data[0].customerName}</p>
                                            <p className="label customerContact">{data[0].customerContact}</p>
                                            <p className="label customerAddress">{data[0].customerAddress}</p>
                                        </div>
                                        <div className="dCol" style={{ minWidth: '200px' }}>
                                            <p className="heading date">Date/Time</p>
                                            <p className="label date">{`${data[0].date} ${data[0].time}`}</p>
                                            <div className="dRow invoiceNo">
                                                <p className="heading">Invoice #:</p>
                                                <p className="label">{data[0].invoiceNo}</p>
                                            </div>
                                        </div>
                                        <div className="dCol" style={{ minWidth: '100px' }}>
                                            <div className="dRow cashier">
                                                <p className="heading">Cashier: </p>
                                                <p className="label">{data[0].user}</p>
                                            </div>
                                            <div className="dRow salesOn">
                                                <p className="heading">Bill On:</p>
                                                <p className="label">{data[0].salesOn}</p>
                                            </div>
                                            <div className="dCol supplierName">
                                                <p className="heading">Dispatched By</p>
                                                <p className="label">{data[0].supplierName}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="header1">
                                        <div className={`${data[0].billType !== "Original" ? "d-block" : "d-none"}`}>
                                            <p className="tac" style={{ fontSize: '12px' }}>{data[0].billType}</p>
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div id="salesBillData" style={{ marginTop: '2px' }}>

                                        <table>
                                            <thead>
                                                <tr className="urdu">
                                                    <th>ٹوٹل</th>
                                                    {bill.showTotalDisc === true && <th>ڈسکاؤنٹ</th>}
                                                    {bill.showDiscPercent === true && <th>%ڈسکاؤنٹ</th>}
                                                    {bill.showDisc === true && <th>ڈسکاؤنٹ</th>}
                                                    {data[0].saleCartons === true && <th>قیمت کارٹن</th>}
                                                    <th>قیمت</th>
                                                    {data[0].saleCartons === true && <th>کارٹن</th>}
                                                    <th>تعداد</th>
                                                    <th style={{ textAlign: 'center' }}>تفصیل سامان</th>
                                                    {bill.showSr === true && <th>سیریل</th>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td className="borderLeft total">{toDecimal(row["total"], 0)}</td>
                                                            {bill.showTotalDisc === true && <td className="borderLeft totalDisc">{toDecimal(row["totalDisc"])}</td>}
                                                            {bill.showDiscPercent === true && <td className="borderLeft discPercent">{`${toDecimal(row["disc"])}%`}</td>}
                                                            {bill.showDisc === true && <td className="borderLeft disc">{toDecimal(row["disc"])}</td>}
                                                            {data[0].saleCartons === true && <td className="borderLeft crtnRate">{row["crtn"] > 0 ? toDecimal(row["crtnRate"]) : 0}</td>}
                                                            <td className="borderLeft rate">{row["qty"] > 0 ? toDecimal(row["rate"]) : 0}</td>
                                                            {data[0].saleCartons === true && <td className={`borderLeft crtn ${row["crtn"] < 0 ? "returnBill" : ""}`}>{row["crtn"]}</td>}
                                                            <td className={`borderLeft qty ${row["qty"] < 0 ? "returnBill" : ""}`}>{row["qty"]}</td>
                                                            <td className={`itemname urdu borderLeft`}>{row["urduname"]}</td>
                                                            {bill.showSr === true && <td className="borderLeft sr">{rowIndex + 1}</td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <div className="line"></div> */}

                                    {/* Footer 1 */}
                                    <div className={`footer1`} style={{ marginTop: '4px' }}>
                                        <div className="dRow jcsb">

                                            {/* Cash Footer */}
                                            <div className={`dCol ${data[0].salesOn === "Cash" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'start' }}>
                                                <div className="dRow subTotal">
                                                    <p className="label subTotalL">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                    <p className="heading subTotalH">سب ٹوٹل</p>
                                                </div>
                                                <div className="dRow grandDisc">
                                                    <p className="label underline grandDiscL">{toDecimal(data[0].grandTotalDisc)}</p>
                                                    <p className="heading grandDiscH">ڈسکاؤنٹ</p>
                                                </div>
                                                <div className="dRow grandTotal">
                                                    <p className="label dUnderline grandTotalL">{toDecimal(data[0].grandTotal)}</p>
                                                    <p className="heading grandTotalH">ٹوٹل</p>
                                                </div>
                                            </div>

                                            {/* Credit Footer */}
                                            <div className={`dCol ${data[0].salesOn === "Credit" ? "d-flex" : "d-none"}`} style={{ minWidth: '160px', alignItems: 'start' }}>
                                                <div className="dRow subTotal">
                                                    <p className="label subTotalL">{toDecimal(data[0].grandTotal + data[0].grandTotalDisc)}</p>
                                                    <p className="heading subTotalH">سب ٹوٹل</p>
                                                </div>
                                                <div className="dRow grandDisc">
                                                    <p className="label underline grandDiscL">{toDecimal(data[0].grandTotalDisc)}</p>
                                                    <p className="heading grandDiscH">ڈسکاؤنٹ</p>
                                                </div>
                                                <div className="dRow grandTotal">
                                                    <p className="label grandTotalL">{toDecimal(data[0].grandTotal)}</p>
                                                    <p className="heading grandTotalH">ٹوٹل</p>
                                                </div>

                                                <div className="dRow oldBalance">
                                                    <p className="label underline oldBalanceL">{toDecimal(data[0].oldBalance)}</p>
                                                    <p className="heading oldBalanceH">سابقہ</p>
                                                </div>
                                                <div className="dRow">
                                                    <p className="label totalL">{toDecimal(data[0].grandTotal + data[0].oldBalance)}</p>
                                                    <p className="heading totalH">ٹوٹل</p>
                                                </div>
                                                <div className="dRow payment">
                                                    <p className="label underline paymentL">{toDecimal(data[0].payment)}</p>
                                                    <p className="heading paymentH">وصولی</p>
                                                </div>
                                                <div className="dRow newBalance">
                                                    <p className="label dUnderline newBalanceL">{toDecimal(data[0].newBalance)}</p>
                                                    <p className="heading newBalanceH">نیا بیلنس</p>
                                                </div>
                                            </div>

                                            <div className="dCol" style={{ width: '100%', alignItems: 'end' }}>
                                                <div className="dRow" style={{ width: '100px' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.length}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '16px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل آئٹمز</p>
                                                    </div>
                                                </div>
                                                <div className="dRow sumQty d-none" style={{ width: '100px' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.reduce((sum, { qty }) => sum + qty, 0)}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '16px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل تعداد</p>
                                                    </div>
                                                </div>
                                                <div className="dRow sumCrtn d-none" style={{ width: '100px' }}>
                                                    <div style={{ flex: '1' }}>
                                                        <p style={{ textAlign: 'center' }}>{data.reduce((sum, { crtn }) => sum + crtn, 0)}</p>
                                                    </div>
                                                    <div style={{ flex: '1' }}>
                                                        <p className="urdu" style={{ fontSize: '16px', textAlign: 'right', marginRight: '4px' }}>ٹوٹل کارٹن</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{ height: '100%' }}></div>

                        {/* Footer */}
                        <div className="footer">
                            {parse(bill.footer)}
                        </div>

                    </div>
                }

            </div>
        </>
    )
});

export default A4Urdu;