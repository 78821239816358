import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import 'style/warehouse/gatepassThermal.css';
import parse from 'html-react-parser';
import ReportThermalStyle from "components/reports/styles/ReportThermalStyle";

const GatepassThermal = forwardRef((props, ref) => {
    const data = props.data;
    const settings = useSelector(state => state.main.settings);
    const billsCode = useSelector(state => state.main.billsCode);
    const bill = billsCode.gatepassThermal;
    return (
        <>
            <div className="gatepassThermal reportThermal" ref={ref}>
                <ReportThermalStyle />
                {
                    !bill && <div>
                        <div id="gatepassData">
                            Nothing to print
                        </div>
                    </div>
                }

                {
                    bill &&
                    <div>
                        {/* Style */}
                        <div>
                            {parse(bill.style)}
                        </div>

                        {/* Header */}
                        <div className="header">
                            {parse(bill.header)}
                        </div>

                        {/* Body */}
                        <div className="body">
                            {parse(bill.body)}

                            {
                                data.length > 0 &&
                                <div>
                                    <div className="header1">
                                        <div className={`warehouseName ${data[0].warehouseName !== "" ? "d-block" : "d-none"}`} style={{ margin: '0px' }}>
                                            <p className="tac">{data[0].warehouseName}</p>
                                        </div>
                                        <div className={`gatepassName ${data[0].gatepassName !== "" ? "d-block" : "d-none"}`} style={{ margin: '0px' }}>
                                            <p className="tac">{data[0].gatepassName}</p>
                                        </div>
                                    </div>

                                    <div className="header1">
                                        <div className="dRow jcsb">
                                            <p className="date">Date: {data[0].date}</p>
                                            <p className="Sr">Sr #: {data[0].id}</p>
                                        </div>
                                        <div className="dRow jcsb">
                                        </div>
                                    </div>

                                    {/* Details */}
                                    <div id="gatepassData">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'left' }}>Itemname</th>
                                                    <th>Qty</th>
                                                    {settings.saleCartons === true && <th>Crtn</th>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td className={`itemname ${bill.showSr === true ? "borderLeft" : ""}`}>{row["itemname"]}<span className="urdu">{`${bill.showUrdu === true ? ` | ${row["urduname"]}` : ''}`}</span></td>
                                                            <td className="borderLeft qty">{row["qty"]}</td>
                                                            {settings.saleCartons === true && <td className="borderLeft crtn">{row["crtn"]}</td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Footer 1 */}
                                    <div className={`footer1`}>
                                        <div className="dRow jcsb">
                                            <div className="dCol">
                                                <div><p>Total Items: <span style={{ fontWeight: '600' }}>{data.length}</span></p></div>
                                                <div className="sumQty d-none"><p>Total Qty: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { qty }) => sum + qty, 0)}</span></p></div>
                                                <div className="sumCrtn d-none"><p>Total Crtn: <span style={{ fontWeight: '600' }}>{data.reduce((sum, { crtn }) => sum + crtn, 0)}</span></p></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`footer1`}>
                                        <div className="dCol">
                                            <div><p style={{ fontWeight: '600' }}>Remarks: <span style={{ fontWeight: '400' }}>{data[0].remarks}</span></p></div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>

                        {/* Footer */}
                        <div className="footer">
                            {parse(bill.footer)}
                        </div>

                    </div>
                }

            </div>
        </>
    )
});

export default GatepassThermal;