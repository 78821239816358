import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import { apiLoadStockReport } from "api/reports/stockReportsApi";
import ReportA4Style from "../styles/ReportA4Style";
import { useSelector } from "react-redux";

const StockReport = forwardRef((props, ref) => {
    const messageBox = props.messageBox;
    const settings = useSelector(state => state.main.settings);

    const params = props.params;
    const onClick = props.onClick ? props.onClick : () => { };
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : () => { };
    const [data, setData] = useState([]);

    // Header
    const [branchName, setBranchName] = useState('');

    // Footer
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [totalStockPcs, setTotalStockPcs] = useState(0);
    const [totalStockCrtn, setTotalStockCrtn] = useState(0);

    const columns = params.isOpeningStock === true ? 
    settings.saleCartons ? 
    [
        { column: 'Barcode', row: "barcode", sortBy: "string"},
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Pcs', row: "openingStockPcs", sortBy: "number" },
        { column: 'Crtn', row: "openingStockCrtn", sortBy: "number" },
    ] : 
    [
        { column: 'Barcode', row: "barcode", sortBy: "string"},
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Pcs', row: "openingStockPcs", sortBy: "number" },
    ]
    :
    settings.saleCartons ? 
    [
        { column: 'Barcode', row: "barcode", sortBy: "string"},
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Pcs', row: "stockPcs", sortBy: "number" },
        { column: 'Crtn', row: "stockCrtn", sortBy: "number" },
        { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Retail', row: "retail", sortBy: "number" },
        { column: 'W.Sale', row: "wholesale", sortBy: "number" },
        { column: 'Total', row: "total", sortBy: "number" },
        { column: 'Warehouse', row: "warehouseName", sortBy: "string"},
    ] : 
    [
        { column: 'Barcode', row: "barcode", sortBy: "string"},
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Pcs', row: "stockPcs", sortBy: "number" },
        { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Retail', row: "retail", sortBy: "number" },
        { column: 'W.Sale', row: "wholesale", sortBy: "number" },
        { column: 'Total', row: "total", sortBy: "number" },
        { column: 'Warehouse', row: "warehouseName", sortBy: "string"},
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadStockReport(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalItems(result.total.totalItems);
                    setGrandTotal(result.total.grandTotal);

                    setTotalStockPcs(result.total.totalStockPcs);
                    setTotalStockCrtn(result.total.totalStockCrtn);

                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 itemsReport" ref={ref}>
                <ReportA4Style />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Stock Report</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Center */}
                        <div className="dRow">
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={columns}
                    data={data}
                    setData={setData}
                    onClick={(row) => { onClick(row.salesId) }}
                    onDoubleClick={(row) => { onDoubleClick(row.salesId) }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dRow" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Items:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalItems}</p>
                            </div>

                            <div className="dRow" style={{marginLeft:'16px'}}>
                                <p className="totalHeading">Total Pcs:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalStockPcs}</p>
                            </div>

                            <div className="dRow" style={{marginLeft:'16px'}}>
                                <p className="totalHeading">Total Crtn:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalStockCrtn}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Total Stock Value:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel dUnderline" style={{ textAlign: 'center' }}>{params.isOpeningStock === true ? 0 : grandTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default StockReport;