import React, { forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import TextboxPassword from "./TextboxPassword";
import { useSelector } from "react-redux";

const PasswordBox = forwardRef((props, ref) => {
    const passwordTB = useRef(null);
    const passwords = useSelector(state => state.main.passwords);
    const passwordFor = useRef();
    let callback = ()=>{};
    
    const show = (passwordUseFor, callbackFun) => {
        passwordTB.current.setText("");
        passwordFor.current = passwordUseFor;
        callback = callbackFun;

        const confirmDiv = document.getElementById("passwordBox");
        confirmDiv.style.display = "block";

        passwordTB.current.focus();
    }

    const close = () => {
        const confirmDiv = document.getElementById("passwordBox");
        confirmDiv.style.display = "none";
    }

    const onKeyDown = (e)=>{
        if (e.key.toLowerCase() === "enter") {
            e.preventDefault();
            okBtnClick();
        }
    }
    const okBtnClick = ()=>{
        if(passwordTB.current.getText() === ""){
            alert("Enter Password");
            return;
        }

        switch(passwordFor.current){
            case "deleteItem":
                matchPassword(passwords.deleteItem);
                break;
            case "deleteAccount":
                matchPassword(passwords.deleteAccount);
                break;
            case "deleteEntry":
                matchPassword(passwords.deleteEntry);
                break;
            case "deleteSalesBill":
                matchPassword(passwords.deleteSalesBill);
                break;
            case "editSalesBill":
                matchPassword(passwords.editSalesBill);
                break;
            case "deletePurchaseBill":
                matchPassword(passwords.deletePurchaseBill);
                break;
            case "editPurchaseBill":
                matchPassword(passwords.editPurchaseBill);
                break;
            default:
                break;
        }
    }
    const matchPassword = (dbPassword)=>{
        if(passwordTB.current.getText() === dbPassword){
            callback(true);
            close();
        }
        else{
            alert("Wrong Password");
        }
    }
    const onWindowKeyDown = (e) => {
        if (e.key && e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }

    useImperativeHandle(ref, () => {
        return {
            show: show
        };
    });

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div id="passwordBox">
                <div className="passwordBoxBg"></div>
                <div className="passwordBoxContainer">
                    <div className="header">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <p className="passwordLabel">Enter Password</p>
                    <div className="passwordBoxBody">
                        <TextboxPassword
                            ref={passwordTB}
                            tabIndex={987897}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                    <div className="passwordBoxFooter">
                        <button onClick={okBtnClick}>Ok</button>
                        <button onClick={close}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default PasswordBox;