/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/utilities/settings.css';
import {
    apiGetSettings,
    apiUpdateSettings,
} from "api/utilities/SettingsApi";
import { useDispatch } from "react-redux";
import TextboxPassword from 'components/tools/TextboxPassword';
import ButtonIcon from 'components/tools/ButtonIcon';
import Checkbox from 'components/tools/Checkbox';
import ComboBox from 'components/tools/ComboBox';
import { mainReducerLoadPasswords, mainReducerLoadSettings } from 'state/reducers/mainReducer';
import BillDesignerComponent from './BillDesignerComponent';
import ThinButton from 'components/tools/ThinButton';
import BillsCB from 'components/tools/dropdowns/BillsCB';
import PrintSizesCB from 'components/tools/dropdowns/PrintSizesCB';
import PrintLanguagesCB from 'components/tools/dropdowns/PrintLanguagesCB';
/* #endregion Imports */

const SettingsComponent = (props) => {

    /* #region Variables */
    const formId = "settingsForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const [activeComponent, setActiveComponent] = useState(null);

    // Checkbox
    const saleUnderStockCheckbox = useRef();
    const costWarningCheckbox = useRef();
    const stockWarningCheckbox = useRef();
    const qtyChangeableCheckbox = useRef();
    const saleCartonsCheckbox = useRef();
    const fourRateSystemCheckbox = useRef();
    const showCustomerLastRateCheckbox = useRef();
    const showItemStockCheckbox = useRef();
    const loadAutoCompleteItemsCheckbox = useRef();
    const autoCreditSelectCheckbox = useRef();
    const showBillPreviewCheckbox = useRef();
    const isPaymentNecessaryCheckbox = useRef();
    const sameDateBillEditCheckbox = useRef();
    const itemExistsInSalesWarningCheckbox = useRef();

    const printLanguageCB = useRef();
    const printSizeCB = useRef();
    const isDefaultRateRetailCB = useRef();
    const isDefaultDiscRsCB = useRef();

    // Passwords
    const deleteItemTB = useRef();
    const deleteAccountTB = useRef();
    const deleteEntryTB = useRef();
    const deleteSalesBillTB = useRef();
    const deletePurchaseBillTB = useRef();
    const editSalesBillTB = useRef();
    const editPurchaseBillTB = useRef();

    const deleteItemCheckbox = useRef();
    const deleteAccountCheckbox = useRef();
    const deleteEntryCheckbox = useRef();
    const deleteSalesBillCheckbox = useRef();
    const deletePurchaseBillCheckbox = useRef();
    const editSalesBillCheckbox = useRef();
    const editPurchaseBillCheckbox = useRef();

    // Bill Settings
    const salesThermalEnglishCB = useRef();
    const salesThermalUrduCB = useRef();
    const salesA4EnglishCB = useRef();
    const salesA4UrduCB = useRef();
    const salesA5EnglishCB = useRef();
    const salesA5UrduCB = useRef();

    const purchaseThermalEnglishCB = useRef();
    const purchaseThermalUrduCB = useRef();
    const purchaseA4EnglishCB = useRef();
    const purchaseA4UrduCB = useRef();
    const purchaseA5EnglishCB = useRef();
    const purchaseA5UrduCB = useRef();

    const purchaseOrderThermalCB = useRef();
    const purchaseOrderA4CB = useRef();

    const gatepassThermalCB = useRef();


    // Middle
    const billDesignerFormRef = useRef(null);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
    }
    const loadData = () => {
        apiGetSettings().then((result) => {
            try {
                setFormData(result);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadSettings = (result) => {
        dispatch(mainReducerLoadSettings(result.settings));
        dispatch(mainReducerLoadPasswords(result.passwords));

        // apiLoadSettings().then((result) => {
        //     dispatch(mainReducerLoadSettings(result.settings));
        //     dispatch(mainReducerLoadPasswords(result.passwords));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const updateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const data = getFormData();
        apiUpdateSettings(data)
            .then((result) => {
                inserted.current = true;
                loadSettings(result);
                toast.current.show("Setting saved successfully.", "s");
            }).catch((err) => {
                inserted.current = true;
                messageBox.current.show(err.message, "Error", "e");
            });
    }
    const setFormData = (data) => {
        const settings = data.settings;
        const passwords = data.passwords;
        const billSettings = data.billSettings;

        // Settings
        saleUnderStockCheckbox.current.setChecked(settings.saleUnderStock);
        costWarningCheckbox.current.setChecked(settings.costWarning);
        stockWarningCheckbox.current.setChecked(settings.stockWarning);
        qtyChangeableCheckbox.current.setChecked(settings.qtyChangeable);
        saleCartonsCheckbox.current.setChecked(settings.saleCartons);
        fourRateSystemCheckbox.current.setChecked(settings.fourRateSystem);
        showCustomerLastRateCheckbox.current.setChecked(settings.showCustomerLastRate);
        showItemStockCheckbox.current.setChecked(settings.showItemStock);
        loadAutoCompleteItemsCheckbox.current.setChecked(settings.loadAutoCompleteItems);
        autoCreditSelectCheckbox.current.setChecked(settings.autoCreditSelect);
        showBillPreviewCheckbox.current.setChecked(settings.showBillPreview);
        isPaymentNecessaryCheckbox.current.setChecked(settings.isPaymentNecessary);
        sameDateBillEditCheckbox.current.setChecked(settings.sameDateBillEdit);
        itemExistsInSalesWarningCheckbox.current.setChecked(settings.itemExistsInSalesWarning);

        printLanguageCB.current.setValue(settings.printLanguage);
        printSizeCB.current.setValue(settings.printSize);
        isDefaultRateRetailCB.current.setValue(settings.isDefaultRateRetail === true ? 0 : 1);
        isDefaultDiscRsCB.current.setValue(settings.isDefaultDiscRs === true ? 1 : 0);

        // Passwords
        deleteItemTB.current.setText(passwords.deleteItem);
        deleteAccountTB.current.setText(passwords.deleteAccount);
        deleteEntryTB.current.setText(passwords.deleteEntry);
        deleteSalesBillTB.current.setText(passwords.deleteSalesBill);
        deletePurchaseBillTB.current.setText(passwords.deletePurchaseBill);
        editSalesBillTB.current.setText(passwords.editSalesBill);
        editPurchaseBillTB.current.setText(passwords.editPurchaseBill);

        deleteItemCheckbox.current.setChecked(passwords.useDeleteItem);
        deleteAccountCheckbox.current.setChecked(passwords.useDeleteAccount);
        deleteEntryCheckbox.current.setChecked(passwords.useDeleteEntry);
        deleteSalesBillCheckbox.current.setChecked(passwords.useDeleteSalesBill);
        deletePurchaseBillCheckbox.current.setChecked(passwords.useDeletePurchaseBill);
        editSalesBillCheckbox.current.setChecked(passwords.useEditSalesBill);
        editPurchaseBillCheckbox.current.setChecked(passwords.useEditPurchaseBill);

        // Bill Settings
        salesThermalEnglishCB.current.setValue(billSettings.salesThermalEnglish);
        salesThermalUrduCB.current.setValue(billSettings.salesThermalUrdu);
        salesA4EnglishCB.current.setValue(billSettings.salesA4English);
        salesA4UrduCB.current.setValue(billSettings.salesA4Urdu);
        salesA5EnglishCB.current.setValue(billSettings.salesA5English);
        salesA5UrduCB.current.setValue(billSettings.salesA5Urdu);

        purchaseThermalEnglishCB.current.setValue(billSettings.purchaseThermalEnglish);
        purchaseThermalUrduCB.current.setValue(billSettings.purchaseThermalUrdu);
        purchaseA4EnglishCB.current.setValue(billSettings.purchaseA4English);
        purchaseA4UrduCB.current.setValue(billSettings.purchaseA4Urdu);
        purchaseA5EnglishCB.current.setValue(billSettings.purchaseA5English);
        purchaseA5UrduCB.current.setValue(billSettings.purchaseA5Urdu);

        purchaseOrderThermalCB.current.setValue(billSettings.purchaseOrderThermal);
        purchaseOrderA4CB.current.setValue(billSettings.purchaseOrderA4);

        gatepassThermalCB.current.setValue(billSettings.gatepassThermal);
    }
    const getFormData = () => {
        const data = {};

        const settings = {
            saleUnderStock: saleUnderStockCheckbox.current.isChecked(),
            costWarning: costWarningCheckbox.current.isChecked(),
            stockWarning: stockWarningCheckbox.current.isChecked(),
            qtyChangeable: qtyChangeableCheckbox.current.isChecked(),
            saleCartons: saleCartonsCheckbox.current.isChecked(),
            fourRateSystem: fourRateSystemCheckbox.current.isChecked(),
            showCustomerLastRate: showCustomerLastRateCheckbox.current.isChecked(),
            showItemStock: showItemStockCheckbox.current.isChecked(),
            loadAutoCompleteItems: loadAutoCompleteItemsCheckbox.current.isChecked(),
            autoCreditSelect: autoCreditSelectCheckbox.current.isChecked(),
            showBillPreview: showBillPreviewCheckbox.current.isChecked(),
            isPaymentNecessary: isPaymentNecessaryCheckbox.current.isChecked(),
            sameDateBillEdit: sameDateBillEditCheckbox.current.isChecked(),
            itemExistsInSalesWarning: itemExistsInSalesWarningCheckbox.current.isChecked(),

            printLanguage: printLanguageCB.current.getValue(),
            printSize: printSizeCB.current.getValue(),
            isDefaultRateRetail: isDefaultRateRetailCB.current.getValue() === 0 ? true : false,
            isDefaultDiscRs: isDefaultDiscRsCB.current.getValue() === 0 ? false : true,
        }

        const passwords = {
            deleteItem: deleteItemTB.current.getText(),
            deleteAccount: deleteAccountTB.current.getText(),
            deleteEntry: deleteEntryTB.current.getText(),
            deleteSalesBill: deleteSalesBillTB.current.getText(),
            deletePurchaseBill: deletePurchaseBillTB.current.getText(),
            editSalesBill: editSalesBillTB.current.getText(),
            editPurchaseBill: editPurchaseBillTB.current.getText(),

            useDeleteItem: deleteItemCheckbox.current.isChecked(),
            useDeleteAccount: deleteAccountCheckbox.current.isChecked(),
            useDeleteEntry: deleteEntryCheckbox.current.isChecked(),
            useDeleteSalesBill: deleteSalesBillCheckbox.current.isChecked(),
            useDeletePurchaseBill: deletePurchaseBillCheckbox.current.isChecked(),
            useEditSalesBill: editSalesBillCheckbox.current.isChecked(),
            useEditPurchaseBill: editPurchaseBillCheckbox.current.isChecked(),
        }

        const billSettings = {
            salesThermalEnglish: salesThermalEnglishCB.current.getValue(),
            salesThermalUrdu: salesThermalUrduCB.current.getValue(),
            salesA4English: salesA4EnglishCB.current.getValue(),
            salesA4Urdu: salesA4UrduCB.current.getValue(),
            salesA5English: salesA5EnglishCB.current.getValue(),
            salesA5Urdu: salesA5UrduCB.current.getValue(),

            purchaseThermalEnglish: purchaseThermalEnglishCB.current.getValue(),
            purchaseThermalUrdu: purchaseThermalUrduCB.current.getValue(),
            purchaseA4English: purchaseA4EnglishCB.current.getValue(),
            purchaseA4Urdu: purchaseA4UrduCB.current.getValue(),
            purchaseA5English: purchaseA5EnglishCB.current.getValue(),
            purchaseA5Urdu: purchaseA5UrduCB.current.getValue(),

            purchaseOrderThermal: purchaseOrderThermalCB.current.getValue(),
            purchaseOrderA4: purchaseOrderA4CB.current.getValue(),

            gatepassThermal: gatepassThermalCB.current.getValue(),
        }

        data.settings = settings;
        data.passwords = passwords;
        data.billSettings = billSettings;
        return data;
    }
    const showBillDesignerForm = () => {
        setActiveComponent('bill');
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        updateData();
    }

    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "utilities")
            return;

        if (activeTab.current !== "settings")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            updateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSaleCartonsCheckboxChange = () => {
        if (saleCartonsCheckbox.current.isChecked()) {
            fourRateSystemCheckbox.current.setChecked(false);
        }
    }
    const onFourRateCheckboxChange = () => {
        if (fourRateSystemCheckbox.current.isChecked()) {
            saleCartonsCheckbox.current.setChecked(false);
            isDefaultRateRetailCB.current.setValue(0);
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "settings" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "settings" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            {(() => {
                switch (activeComponent) {
                    case "bill":
                        return <BillDesignerComponent
                            ref={billDesignerFormRef}
                            messageBox={messageBox}
                            toast={toast}
                            mainActiveTab={props.mainActiveTab}
                            activeTab={props.activeTab}
                            setActiveComponent={setActiveComponent}
                            isActive={isActive} />
                    default:
                        return <div></div>;
                }
            })()}


            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='panel'>
                            <div className='dRow'>
                                <div className="dCol" style={{ flex: 1 }}>
                                    <Checkbox
                                        ref={saleUnderStockCheckbox}
                                        label="Sale Under Stock"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={costWarningCheckbox}
                                        label="Cost Warning"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={stockWarningCheckbox}
                                        label="Stock Warning"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={autoCreditSelectCheckbox}
                                        label="Auto Credit Select"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={showBillPreviewCheckbox}
                                        label="Show Bill Preview"
                                        onChange={() => { }} />
                                </div>
                                <div className="dCol" style={{ flex: 1 }}>

                                    <Checkbox
                                        ref={qtyChangeableCheckbox}
                                        label="Qty Changeable"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={saleCartonsCheckbox}
                                        label="Sale Cartons"
                                        onChange={onSaleCartonsCheckboxChange} />
                                    <Checkbox
                                        ref={fourRateSystemCheckbox}
                                        label="Four Rate System"
                                        onChange={onFourRateCheckboxChange} />
                                    <Checkbox
                                        ref={sameDateBillEditCheckbox}
                                        label="Same Date Bill Edit"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={showItemStockCheckbox}
                                        label="Show Stock in Sales"
                                        onChange={() => { }} />
                                </div>
                            </div>

                            <Checkbox
                                ref={showCustomerLastRateCheckbox}
                                label="Show Customer Last Rate in Sales"
                                onChange={() => { }} />

                            <Checkbox
                                ref={isPaymentNecessaryCheckbox}
                                label="Payment Necessary in POS"
                                onChange={() => { }} />
                            <Checkbox
                                ref={loadAutoCompleteItemsCheckbox}
                                label="Load Auto Complete Items"
                                onChange={() => { }} />
                            <Checkbox
                                ref={itemExistsInSalesWarningCheckbox}
                                label="Item Exists in Sales Warning"
                                onChange={() => { }} />
                        </div>
                        <div className='panel' style={{ marginLeft: '16px' }}>
                            <PrintLanguagesCB
                                ref={printLanguageCB}
                                label="Default Print Language"
                                onChange={() => { }} />
                            <PrintSizesCB
                                ref={printSizeCB}
                                label="Default Print Size"
                                onChange={() => { }} />
                            <ComboBox
                                ref={isDefaultRateRetailCB}
                                label="Default Rate"
                                data={[{ id: 0, name: 'Retail' }, { id: 1, name: 'Wholesale' }]}
                                onChange={() => { }} />
                            <ComboBox
                                ref={isDefaultDiscRsCB}
                                label="Default Discount"
                                data={[{ id: 0, name: '%' }, { id: 1, name: 'Rs.' }]}
                                onChange={() => { }} />
                            <ThinButton
                                label="Bill Designer"
                                onClick={showBillDesignerForm} />
                        </div>
                        <div className='panel passwordPanel'>
                            <h3 className='headingLabel' style={{ margin: '0px', marginTop: '2px' }}>Passowrds</h3>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={deleteItemTB}
                                    label="Delete Item"
                                    tabIndex={1}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={deleteItemCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={deleteAccountTB}
                                    label="Delete Account"
                                    tabIndex={2}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={deleteAccountCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={deleteEntryTB}
                                    label="Delete Entry"
                                    tabIndex={3}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={deleteEntryCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={deleteSalesBillTB}
                                    label="Delete Sales Bill"
                                    tabIndex={4}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={deleteSalesBillCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={deletePurchaseBillTB}
                                    label="Delete Purchase Bill"
                                    tabIndex={5}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={deletePurchaseBillCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={editSalesBillTB}
                                    label="Edit Sales Bill"
                                    tabIndex={6}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={editSalesBillCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                            <div className='panel'>
                                <TextboxPassword
                                    ref={editPurchaseBillTB}
                                    label="Edit Purchase Bill"
                                    tabIndex={7}
                                    onKeyDown={() => { }} />
                                <Checkbox
                                    ref={editPurchaseBillCheckbox}
                                    label="Use"
                                    onChange={() => { }} />
                            </div>
                        </div>
                    </div>

                    <div className='bottomPanel'>
                        <div className="groupBox" style={{ marginTop: 'var(--defaultMargin)' }}>
                            <div className="header">Sales Bills</div>
                            <div className="body">
                                <BillsCB
                                    ref={salesThermalEnglishCB}
                                    label="Thermal English"
                                />
                                <BillsCB
                                    ref={salesThermalUrduCB}
                                    label="Thermal Urdu"
                                />
                                <BillsCB
                                    ref={salesA4EnglishCB}
                                    label="A4 English"
                                />
                                <BillsCB
                                    ref={salesA4UrduCB}
                                    label="A4 Urdu"
                                />
                                <BillsCB
                                    ref={salesA5EnglishCB}
                                    label="A5 English"
                                />
                                <BillsCB
                                    ref={salesA5UrduCB}
                                    label="A5 Urdu"
                                />
                            </div>
                        </div>

                        <div className="groupBox" style={{ marginTop: 'var(--defaultMargin)', marginLeft: '16px' }}>
                            <div className="header">Purchase Bills</div>
                            <div className="body">
                                <BillsCB
                                    ref={purchaseThermalEnglishCB}
                                    label="Thermal English"
                                />
                                <BillsCB
                                    ref={purchaseThermalUrduCB}
                                    label="Thermal Urdu"
                                />
                                <BillsCB
                                    ref={purchaseA4EnglishCB}
                                    label="A4 English"
                                />
                                <BillsCB
                                    ref={purchaseA4UrduCB}
                                    label="A4 Urdu"
                                />
                                <BillsCB
                                    ref={purchaseA5EnglishCB}
                                    label="A5 English"
                                />
                                <BillsCB
                                    ref={purchaseA5UrduCB}
                                    label="A5 Urdu"
                                />
                            </div>
                        </div>

                        <div className="groupBox" style={{ marginTop: 'var(--defaultMargin)', marginLeft: '16px' }}>
                            <div className="header">Others</div>
                            <div className="body">
                                <BillsCB
                                    ref={purchaseOrderThermalCB}
                                    label="Order Thermal"
                                />
                                <BillsCB
                                    ref={purchaseOrderA4CB}
                                    label="Order A4"
                                />
                                <BillsCB
                                    ref={gatepassThermalCB}
                                    label="Gatepass Thermal"
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ bottom: '16px', position: 'fixed' }}>
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsComponent;