/* #region Imports */
import React, { useEffect, useRef, useState } from "react";
import "style/main/main.css";
import LoginComponent from "components/main/LoginComponent";
import MainComponent from "components/main/MainComponent";
import MessageBox from "components/tools/MessageBox.js";
import Toast from "components/tools/Toast.js";
import ClientLoginComponent from "components/main/ClientLoginComponent";
import ClientsComponent from "components/main/ClientsComponent";
import PasswordBox from "components/tools/PasswordBox";
import { getClientId } from "utilities/DB";

function App() {
  const [activeComponent, setActiveComponent] = useState("clientLoginForm"); // default clientLoginForm change it later

  // // Tools References
  const passwordBox = useRef();
  const messageBox = useRef();
  const toast = useRef();

  const sidePanelButtons = useRef();

  const onWindowKeyDown = (e) => {
    if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === "b") {
      e.preventDefault();
      setActiveComponent("clientsForm");
    }
  }

  const loadMainForm = (flatButtons) => {
    sidePanelButtons.current = flatButtons;

    setActiveComponent("mainForm");
  }

  const onBranchChange = () => {
    setActiveComponent("");
    setTimeout(() => {
      setActiveComponent("mainForm");
    }, 500);
  }

  useEffect(() => {
    document.addEventListener("keydown", onWindowKeyDown);

    // Change it later
    if (getClientId() !== 0) {
      setActiveComponent("loginForm");
    }

    return () => {
      document.removeEventListener("keydown", onWindowKeyDown);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>

      <PasswordBox ref={passwordBox} />
      <MessageBox ref={messageBox} />
      <Toast ref={toast} />

      {(() => {
        switch (activeComponent) {
          case "clientsForm":
            return <ClientsComponent messageBox={messageBox} toast={toast} setActiveComponent={setActiveComponent} />;
          case "clientLoginForm":
            return <ClientLoginComponent messageBox={messageBox} toast={toast} setActiveComponent={setActiveComponent} />;
          case "loginForm":
            return <LoginComponent messageBox={messageBox} toast={toast} loadMainForm={loadMainForm} />;
          case "mainForm":
            return <MainComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} flatButtons={sidePanelButtons.current} setActiveComponent={setActiveComponent} onBranchChange={onBranchChange} />;
          default:
            return <div></div>;
        }
      })()}


    </>
  );
}

export default App;
