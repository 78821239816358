import { getBranchGroupId, getUserId, post } from 'utilities/DB.js';

export const apiLoadStockEntries = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/loadStockEntries", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertStockEntry = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/insertStockEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateStockEntry = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/updateStockEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteStockEntry = (stockEntryId) => {
    const params = {
        id: stockEntryId
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/deleteStockEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetStockEntry = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/getStockEntry", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsStockEntryExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/isStockEntryExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}



export const apiLoadWarehouseEntries = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/loadWarehouseEntries", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadWarehouseEntryItems = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/loadWarehouseEntryItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiStockTransferToWarehouse = (warehouseId) => {
    const params = {
        warehouseId: warehouseId,
        userId: getUserId(),
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/stockTransferToWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiStockReceiveFromWarehouse = (warehouseId) => {
    const params = {
        warehouseId: warehouseId,
        userId: getUserId(),
    }
    return new Promise((resolve, reject) => {
        post("api/warehouse/stockReceiveFromWarehouse", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGenerateStockEntries = (params) => {
    return new Promise((resolve, reject) => {
        post("api/warehouse/generateStockEntries", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeExists = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/warehouse/isBarcodeExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
