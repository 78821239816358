import React, { useState, useEffect, useRef } from "react";
import 'style/warehouse/warehouse.css';
import CreateWarehouseComponent from "./CreateWarehouseComponent";
import GatepassComponent from "./GatepassComponent";
import TransferStockComponent from "./TransferStockComponent";

const WarehouseComponent = (props) => {
    const formId = "warehouseForm";
    const flatButtons = props.flatButtons;

    const mainActiveTab = useRef(null);

    const activeBtn = useRef(null);
    const [activeTab, setActiveTab] = useState(flatButtons.current[0].toLowerCase().replace(" ", ""));
    // eslint-disable-next-line
    const [activeComponent, setActiveComponent] = useState("createwarehouse");

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;

    const onWindowKeyDown = (e) => {
        if (e.ctrlKey && e.key === "1") {
            e.preventDefault();
            shortKeyClick("CreateWarehouse");
        }
        else if (e.ctrlKey && e.key === "2") {
            e.preventDefault();
            shortKeyClick("Gatepass");
        }
        else if (e.ctrlKey && e.key === "3") {
            e.preventDefault();
            shortKeyClick("TransferStock");
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        if (activeBtn.current == null) {
            const btn = document.getElementById(`flatBtn${flatButtons.current[0].replace(" ", "")}`);
            btn.classList.add("flatBtnClicked");

            // const img = document.querySelector(`#${btn.id} img`);
            // img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);

            activeBtn.current = btn.id;
        }

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    const flatBtnClick = (e) => {
        changeActiveBtn(e.currentTarget);

        const btnName = e.currentTarget.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    const changeActiveBtn = (btn) => {
        // Get Old Button
        const oldBtn = document.getElementById(activeBtn.current);
        oldBtn.classList.remove("flatBtnClicked");
        // const oldImg = document.querySelector(`#${oldBtn.id} img`);
        // oldImg.setAttribute("src", `${FlatButtonIcons[oldBtn.getAttribute("data-name")]}`);

        // Set Button
        btn.classList.add("flatBtnClicked");
        activeBtn.current = btn.id;
        // const img = document.querySelector(`#${btn.id} img`);
        // img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
    }

    const shortKeyClick = (button) => {
        const btn = document.getElementById(`flatBtn${button}`);
        changeActiveBtn(btn);
        const btnName = btn.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    return (
        <>
            <div id={formId} className="flatBtnBody">
                <div className="topBar"></div>
                <div className="flatBtnTop">
                    {flatButtons.current.map((e, i) => {
                        return (
                            <div id={`flatBtn${e.replace(" ", "")}`} className="flatBtn" key={e} data-name={e.toLowerCase().replace(" ", "")} onClick={flatBtnClick}>
                                {/* <img className="my-auto" src={FlatButtonIcons[e.toLowerCase().replace(" ", "")]} alt="" /> */}
                                <p className="my-auto">{e}</p>
                            </div>
                        );
                    })}
                </div>

                <div className="flatBtnContainer containerWidth">
                    <div className={`${activeTab === "createwarehouse" ? 'd-block' : 'd-none'}`}>
                        <CreateWarehouseComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                    </div>
                    <div className={`${activeTab === "gatepass" ? 'd-block' : 'd-none'}`}>
                        <GatepassComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                    </div>
                    <div className={`${activeTab === "transferstock" ? 'd-block' : 'd-none'}`}>
                        <TransferStockComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WarehouseComponent;