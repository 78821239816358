import { ceil, floor } from "mathjs";
import dateFormat from "dateformat";
import { getBranchId, getClientId } from "./DB";

export const getReportsData = () => {
    return {
        items: [],
        categories: [],
        subCategories: [],
        customers: [],
        vendors: [],
        suppliers: [],
        fixedAccounts: [],
        users: [],
        expenses: [],
        subExpenses: [],
        banks: [],
        subBanks: [],
        accountCategories: [],
        warehouses: [],
    }
}

export const accounts = {
    cash: 1,
    sales: 2,
    salesReturn: 3,
    purchase: 4,
    purchaseReturn: 5,
}

export const accountTypes = {
    fixedAccount: 1,
    customer: 2,
    vendor: 3,
    bank: 4,
    expense: 5,
    supplier: 6,
}

export const entryTypes = {
    receipt: 1,
    payment: 2,
    expense: 3,
    sales: 4,
    salesReturn: 5,
    purchase: 6,
    purchaseReturn: 7,
    journal: 8,
    stock: 9,
}

export const printSizes = {
    thermal: 1,
    a4: 2,
    a5: 3,
}

export const printLanguages = {
    english: 1,
    urdu: 2,
}

export const selectNextElement = (e) => {
    let next = Number(e.target.tabIndex) + 1;
    const nextElement = document.getElementById(`tb${next}`);
    if (nextElement) {
        nextElement.focus();
    }
}

export const selectNextElement2 = (next) => {
    const nextElement = document.getElementById(`tb${next}`);
    if (nextElement) {
        nextElement.focus();
    }
}

export const convertToNumber = (text) => {
    if (text === '')
        return 0;

    if (isNaN(Number(text)))
        return 0;
    else
        return Number(text);
}

export const convertToInt = (text) => {
    if (text === '')
        return 0;

    if (isNaN(Number(text)))
        return 0;
    else
        return parseInt(Number(text));
}
export const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
}
export const isExpression = (expression) => {
    if (expression.includes("+") || expression.includes("-") || expression.includes("*") || expression.includes("/") || expression.includes("%"))
        return true;
    else
        return false;
}

export const getStockPcs = (stock, crtnSize) => {
    if (crtnSize === 0)
        return stock;
    else
        return (stock % crtnSize);
}

export const getStockCrtn = (stock, crtnSize) => {
    if (crtnSize !== 0) {
        if ((stock / crtnSize) < 0)
            return ceil(stock / crtnSize);
        else
            return floor(stock / crtnSize);
    }
    else
        return 0;
}

export const getFromDate = () => {
    const date = new Date();

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
}

export const getToDate = () => {
    const date = new Date();

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
}

export const concatDateTime = (date1, time1) => {
    const date = new Date(date1);
    const time = new Date(time1)

    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());

    return date;
}

export const getDiffDays = (date2, date1) => {
    date2 = new Date(dateFormat(date2, "yyyy-mm-dd"));
    date1 = new Date(dateFormat(date1, "yyyy-mm-dd"));

    const days = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
    return days;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toDecimal = (number, decimalPlaces = 2) => {
    return Number(Number(number).toFixed(decimalPlaces));
}

export const isFileSizeValid = (imageDialog) => {
    const file = imageDialog.files[0];
    if (file.size >= 1048576)
        return false;
    else
        return true;
}
export const getRootElement = () => {
    return document.querySelector(":root");
}
export const setRootProperty = (variable, value) => {
    const root = getRootElement();
    root.style.setProperty(variable, value);
}
export const getTheme = () => {
    const branch = getClientId() + getBranchId();
    let themeObject = JSON.parse(localStorage.getItem('theme'));

    if (themeObject) {
        const theme = themeObject[branch];
        if (theme) {
            if (theme.theme) {
                if (theme.theme === 0) {
                    theme.theme = 1;
                }
                return theme.theme;
            } else
                return 1;
        } else
            return 1;
    } else
        return 1;
}
export const setTheme = (theme) => {
    const branch = getClientId() + getBranchId();

    let themeObject = JSON.parse(localStorage.getItem('theme'));
    if (themeObject) {
        themeObject[branch] = {
            theme: theme
        };
        localStorage.setItem("theme", JSON.stringify(themeObject));
    } else {
        const themeObject = {};
        themeObject[branch] = {
            theme: theme
        };

        localStorage.setItem("theme", JSON.stringify(themeObject));
    }
}
export const changeTheme = (theme) => {
    setTheme(theme);
    loadTheme();
}
export const loadTheme = () => {
    switch (getTheme()) {
        case 1:
            defaultTheme();
            break;
        case 2:
            redTheme();
            break;
        case 3:
            greenTheme();
            break;
        case 4:
            blackTheme();
            break;
        default:
            break;
    }
}

const defaultTheme = () => {
    setRootProperty('--mainColor', '#0554F2');
    setRootProperty('--mainColorHover', '#2E6FF2');
    setRootProperty('--backgroundColor', 'white');
    setRootProperty('--flatBtnImgSelected', 'invert(16%) sepia(99%) saturate(4952%) hue-rotate(218deg) brightness(99%) contrast(102%)');
    setRootProperty('--checkboxBg', '#e2e2e2');
}
const redTheme = () => {
    setRootProperty('--mainColor', '#bf0212');
    setRootProperty('--mainColorHover', '#d40012');
    setRootProperty('--backgroundColor', 'rgb(240, 240, 240)');
    setRootProperty('--flatBtnImgSelected', 'invert(12%) sepia(84%) saturate(6299%) hue-rotate(358deg) brightness(108%) contrast(112%)');
    setRootProperty('--checkboxBg', '#d3d3d3');
}
const greenTheme = () => {
    setRootProperty('--mainColor', 'green');
    setRootProperty('--mainColorHover', 'rgb(0, 148, 0)');
    setRootProperty('--backgroundColor', 'rgb(240, 240, 240)');
    setRootProperty('--flatBtnImgSelected', 'invert(18%) sepia(96%) saturate(4506%) hue-rotate(105deg) brightness(100%) contrast(103%)');
    setRootProperty('--checkboxBg', '#d3d3d3');
}
const blackTheme = () => {
    setRootProperty('--mainColor', '#1a1a1a');
    setRootProperty('--mainColorHover', '#383838');
    setRootProperty('--backgroundColor', 'rgb(240, 240, 240)');
    setRootProperty('--flatBtnImgSelected', 'filter: invert(0%) sepia(86%) saturate(7443%) hue-rotate(262deg) brightness(111%) contrast(112%)');
    setRootProperty('--checkboxBg', '#d3d3d3');
}
