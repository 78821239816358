import React from "react";

const ButtonIcon = (props) => {
    const label = props.label;
    const icon = props.icon;
    const onClick = props.onClick ? props.onClick : () => { };
    const className = props.className;
    const style = props.style;

    return (
        <>
            <div className={`myBtn ${className}`} style={style} onClick={onClick}>
                <img className="my-auto" src={icon} alt="" />
                <p className="my-auto">{label}</p>
            </div>
        </>
    )
}

export default ButtonIcon;