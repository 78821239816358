/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/purchase/pendingBills.css';
import Table from 'components/tools/Table';
import ButtonIcon from 'components/tools/ButtonIcon';
import { apiDeletePurchase, apiGetBill, apiLoadPendingBillItems, apiLoadPendingBills } from 'api/purchase/PurchaseApi';
import { useReactToPrint } from "react-to-print";
import Bill from "components/purchase/Bill";
import { useSelector } from 'react-redux';

/* #endregion */

const PendingBillsComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "purchasePendingBillsForm";
    const isActive = useRef(false);
    const onEdit = props.onEdit ? props.onEdit : () => { };
    const settings = useSelector(state => state.main.settings);
    const user = useSelector(state => state.main.user);

    const tableBRef = useRef();
    const tableBIRef = useRef();
    const messageBox = props.messageBox;
    const toast = props.toast;
    const [totalBills, setTotalBills] = useState(0);
    const [totalBillItems, setTotalBillItems] = useState(0);

    const billRef = useRef();
    const [billData, setBillData] = useState([]);

    const columnsB = useRef([
        { column: 'Bill No.', row: "id", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '120px' } },
        { column: 'Vendor', row: "vendorName", sortBy: "string", style: { minWidth: '400px', width: '100%' } },
        { column: 'On', row: "purchaseOn", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Type', row: "purchaseType", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'MOP', row: "mop", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '140px' } },
        { column: 'Warehouse', row: "warehouseName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'User', row: "username", sortBy: "string", style: { minWidth: '150px' } },
    ]);

    const columnsBIC = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '270px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Cost', row: "finalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Gross Cost', row: "cost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Disc', row: "calculatedDisc", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Tax', row: "tax", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '120px' } },
    ]);

    const columnsBI = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '300px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Cost', row: "finalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Gross Cost', row: "cost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Disc', row: "calculatedDisc", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Tax', row: "tax", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '120px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadBills = () => {
        tableBRef.current.setLoading(true);
        apiLoadPendingBills().then((result) => {
            if (isActive.current) {
                if (result.rows.length > 0) {
                    setTotalBills(result.total.totalBills);
                    tableBRef.current.setData([...result.rows]);
                    tableBRef.current.setLoading(false);

                    const purchaseId = result.rows[0]['id'];
                    loadBillItems(purchaseId);
                } else {
                    tableBRef.current.clearData();
                    tableBIRef.current.clearData();
                    tableBRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableBRef.current.setLoading(false);
            }
        });
    }
    const loadBillItems = (purchaseId) => {
        tableBIRef.current.setLoading(true);
        apiLoadPendingBillItems({ purchaseId: purchaseId })
            .then((result) => {
                if (isActive.current) {
                    if (result.rows.length > 0) {
                        setTotalBillItems(result.total.totalBillItems);
                        tableBIRef.current.setData([...result.rows]);
                        tableBIRef.current.setLoading(false);
                    }
                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                    tableBIRef.current.setLoading(false);
                }
            });
    }
    const editData = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row !== null) {
            const id = Number(row['id']);
            onEdit(id);
            close();
        }
    }
    const deleteData = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Bill?")) {
                apiDeletePurchase({
                    id: Number(row['id']),
                    isPostedBill: false
                }).then((result) => {
                    if (isActive.current) {
                        toast.current.show("Bill deleted successfully.", "s");
                        loadBills();
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
            }
        };
    }
    const exportBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const iFrame = printIframe.contentDocument;
            if (iFrame) {
                const dataDiv = iFrame.getElementsByClassName("dataDiv")[0];
                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    exportBill(); // Recall untill populated completely
                    return;
                }

                // const main = iFrame.getElementsByClassName("main")[0];
                // main.style.display = "block";

                // // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(main, options);
                // await exporter.getPdf(options);

                // // For Print to Printer
                // printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    const printBill = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row !== null) {
            apiGetBill({
                id: Number(row['id']),
                billType: 2,
                isPendingBill: true,
                userId: user.id,
            }).then((result) => {
                props.printBill(result);
                // setBillData([...result]);
                // if (isExport === false)
                //     printBill();
                // else
                //     exportBill();
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onBillTableClick = (row) => {
        const id = Number(row['id']);
        loadBillItems(id);
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteData();
    }
    const onPrintBtnClick = () => {
        printBill();
    }

    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printBill();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.altKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteData();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    const onTableEnterKeyDown = () => {
        const row = tableBRef.current.getSelectedRow();
        const id = Number(row['id']);
        loadBillItems(id);
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
        // tableBRef.current.clearData();
        // tableBIRef.current.clearData();
    }

    const formLoad = () => {
        tableBRef.current.focus();
        loadBills();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div style={{ display: "none" }}>
                <Bill ref={billRef} data={billData} />
            </div>

            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>
                        <div className='panel'>
                            <div className='topPanel'>
                                {/* Table Layout */}
                                <Table
                                    ref={tableBRef}
                                    columns={columnsB.current}
                                    className='purchasePendingBillsTable'
                                    isActive={isActive}
                                    autoSelectFirstRow={true}
                                    onEnterKeyDown={onTableEnterKeyDown}
                                    onClick={onBillTableClick}
                                    onDoubleClick={onEditBtnClick}
                                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                                <div className="paginationFooter">
                                    <p><b>Total Bills: </b>{totalBills}</p>
                                </div>

                                <div className="buttons">
                                    <ButtonIcon
                                        label="Edit"
                                        icon="icons/buttons/edit.png"
                                        onClick={onEditBtnClick} />

                                    <ButtonIcon
                                        label="Delete"
                                        icon="icons/buttons/delete.png"
                                        onClick={onDeleteBtnClick} />

                                    <ButtonIcon
                                        label="Print"
                                        icon="icons/buttons/print.png"
                                        onClick={onPrintBtnClick} />

                                    {/* <ButtonIcon
                                        label="Export"
                                        icon="icons/buttons/save.png"
                                        onClick={onExportBtnClick} /> */}
                                </div>

                            </div>

                            <div className='bottomPanel'>
                                <Table
                                    ref={tableBIRef}
                                    columns={settings.saleCartons ? columnsBIC.current : columnsBI.current}
                                    className='purchasePendingBillItemsTable'
                                    isActive={isActive}
                                    onDoubleClick={onEditBtnClick}
                                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                                <div className="tableFooter">
                                    <p><b>Total Bill Items: </b>{totalBillItems}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default PendingBillsComponent;