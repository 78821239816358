import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import CustomComboBox from '../CustomComboBox';

const SubCategoryCBReports = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const style = props.style;

    // Variables
    const subCategories = useSelector(state => state.reports.subCategories);
    const [data, setData] = useState(subCategories);
    let reference = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: reference.current.getValue,
            setValue: reference.current.setValue,
            getText: reference.current.getText,
            loadData: loadSubCategories,
            enable:reference.current.enable,
            disable:reference.current.disable,
        };
    });

    useEffect(() => {
        setData([...subCategories]);
        // eslint-disable-next-line
    }, [subCategories]);

    const loadSubCategories = (categoryId) => {
        if(categoryId === 0){
            setData([...subCategories]);
            reference.current.setValue(0);
        }else{
            let temp1 = [
                { id: 0, name: 'None', categoryId: 0 }
            ];
    
            const temp2 = subCategories.filter((subCategory) => Number(subCategory.categoryId) === categoryId);
            if (temp2.length > 0) {
                temp1 = temp1.concat(temp2);
            }
            setData([...temp1]);
        }
    }

    return (
        <>
            <CustomComboBox
                ref={reference}
                label="Sub-Category"
                className={className}
                data={data}
                onChange={onChange}
                style={style} />
        </>
    )
});

export default SubCategoryCBReports;