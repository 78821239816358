import { post, getUserId, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadSalesItems = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/salesItems/loadSalesItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSalesItem = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/salesItems/insertSalesItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSalesItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/salesItems/updateSalesItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSalesItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/salesItems/deleteSalesItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSalesItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/salesItems/getSalesItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsSalesItemExists = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/salesItems/isSalesItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeExists = (params) => {
    params.branchGroupId = getBranchGroupId();
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/salesItems/isBarcodeExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiAddPurchaseOrder = (itemId) => {
    const params = {
        itemId: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/salesItems/addPurchaseOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
