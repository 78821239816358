import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import { apiLoadBillWiseReport } from "api/reports/purchaseReportsApi";
import dateFormat from "dateformat";
import ReportA4Style from "../styles/ReportA4Style";

const BillWisePurchaseReport = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const onClick = props.onClick ? props.onClick : ()=>{};
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : ()=>{};
    const [data, setData] = useState([]);

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [branchName, setBranchName] = useState('');

    // Footer
    const [total, setTotal] = useState(0);
    const [totalDisc, setTotalDisc] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalBills, setTotalBills] = useState(0);

    const columns = [
        { column: 'Date', row: "date", sortBy: "string" },
        { column: 'Inv #', row: "invoiceNo", sortBy: "number" },
        { column: 'Vendor', row: "vendorName", sortBy: "string", className: "vendor" },
        { column: 'On', row: "purchaseOn", sortBy: "string" },
        { column: 'Type', row: "purchaseType", sortBy: "string" },
        { column: 'MOP', row: "mop", sortBy: "string" },
        { column: 'Gross', row: "grossTotal", sortBy: "number" },
        { column: 'Disc', row: "totalDisc", sortBy: "number" },
        { column: 'Total', row: "total", sortBy: "number" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadBillWiseReport(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotal(result.total.total);
                    setTotalDisc(result.total.totalDisc);
                    setGrandTotal(result.total.grandTotal);
                    setTotalBills(result.total.totalBills);

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 billWiseReport" ref={ref}>
                <ReportA4Style />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Purchase Report</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">From Date:</p>
                                <p className="totalLabel alignL">{fromDate}</p>
                            </div>
                        </div>

                        {/* Center */}
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">To Date:</p>
                            </div>
                            <div className="dCol">
                                <p className="totalLabel alignL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={columns}
                    data={data}
                    setData={setData}
                    onClick={(row) => { onClick(row) }} 
                    onDoubleClick={(row) => { onDoubleClick(row) }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Bills:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalBills}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Total:</p>
                                <p className="totalHeading">Discount:</p>
                                <p className="totalHeading">Grand Total:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel">{total}</p>
                                <p className="totalLabel underline">{totalDisc}</p>
                                <p className="totalLabel dUnderline">{grandTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default BillWisePurchaseReport;