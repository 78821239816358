import { post } from 'utilities/DB.js';

export const apiLoadClients = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/loadClients", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertClient = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/insertClient", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateClient = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/updateClient", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteClient = (clientId) => {
    const params = {
        id: clientId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/deleteClient", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetClient = (clientId) => {
    const params = {
        id: clientId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/getClient", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsClientExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/isClientExists", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}


export const apiLoadBranches = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/loadBranches", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertBranch = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/insertBranch", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBranch = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/updateBranch", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteBranch = (branchId) => {
    const params = {
        id: branchId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/deleteBranch", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBranch = (branchId) => {
    const params = {
        id: branchId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/getBranch", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBranchExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/isBranchExists", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}


export const apiLoadBranchGroups = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/loadBranchGroups", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertBranchGroup = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/insertBranchGroup", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBranchGroup = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/updateBranchGroup", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteBranchGroup = (branchGroupId) => {
    const params = {
        id: branchGroupId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/deleteBranchGroup", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBranchGroup = (branchGroupId) => {
    const params = {
        id: branchGroupId
    }
    return new Promise((resolve, reject) => {
        post("api/clients/getBranchGroup", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBranchGroupExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/clients/isBranchGroupExists", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiClientLogin = (username, password) => {
    const params = {
        username: username,
        password: password
    }
    return new Promise((resolve, reject) => {
        post("api/clients/clientLogin", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
