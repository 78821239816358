import { post, uploadImage, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadCategories = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/categories/loadCategories", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertCategory = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/categories/insertCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateCategory = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/categories/updateCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteCategory = (categoryId) => {
    const params = {
        id: categoryId,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/categories/deleteCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetCategory = (categoryId) => {
    const params = {
        id: categoryId
    }
    return new Promise((resolve, reject) => {
        post("api/categories/getCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsCategoryExists = (categoryName) => {
    const params = {
        categoryName: categoryName,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/categories/isCategoryExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/categories/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
