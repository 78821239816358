import React, { forwardRef } from "react";
import 'style/accounts/vouchers/paymentVoucher.css';
import { toDecimal } from "utilities/Utils";
import ReportThermalStyle from "components/reports/styles/ReportThermalStyle";

const PaymentVoucher = forwardRef((props, ref) => {
    const data = props.data;
    // const data = {
    //     date:'12/08/2024 12:15AM',
    //     accountName:'Admin',
    //     username:'Admin',
    //     naration:'Cash Received from Waqee',
    //     oldBalance:500,
    //     amount:100,
    //     newBalance:400,

    // }
    
    return (
        <>
            <div className="paymentVoucher reportThermal" ref={ref}>
                <ReportThermalStyle />
                <h2 style={{ textAlign: 'center' }}>Payment Voucher</h2>
                {
                    <div>
                        {
                            data.date &&
                            <div id="paymentVoucherData" className="body">
                                <div className="dCol">
                                    <div className="header1">
                                        <div className="dRow jcsb">
                                            <p className="date">Date: {data.date}</p>
                                        </div>
                                        <div className="dRow jcsb">
                                            <p className="date">Received By: {data.username}</p>
                                        </div>
                                    </div>

                                    <div className="line"></div>

                                    <p>Account Name:</p>
                                    <p style={{ textAlign: 'center', fontWeight: '600' }}>{data.accountName}</p>

                                    <div className="dCol" style={{ alignItems: 'end', marginTop: '8px' }}>
                                        <div className="dRow">
                                            <p className="heading">Old Balance: </p>
                                            <p className="label">{toDecimal(data.oldBalance)}</p>
                                        </div>
                                        <div className="dRow">
                                            <p className="heading">Paid: </p>
                                            <p className="label underline">{toDecimal(data.amount)}</p>
                                        </div>
                                        <div className="dRow">
                                            <p className="heading">New Balance: </p>
                                            <p className="label dUnderline">{toDecimal(data.newBalance)}</p>
                                        </div>
                                    </div>

                                    <p style={{ fontWeight: '600' }}>Naration:</p>
                                    <p>{data.naration}</p>

                                    <div style={{ borderBottom: '1px solid black', width: '100px', alignSelf: 'center', marginTop: '120px' }}></div>
                                    <div style={{ alignSelf: 'center' }}>
                                        <p>Signature</p>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                }

            </div>
        </>
    )
});

export default PaymentVoucher;