import React from "react";

const ThinButton = (props) => {
    const style = props.style;
    const className = props.className;
    const {label, onClick} = props;

    return (
        <>
            <div className={`myBtn thinBtn ${className}`} style={style} onClick={onClick}>
                <p className="my-auto">{label}</p>
            </div>
        </>
    )
}

export default ThinButton;