import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Datetime from 'react-datetime';
// import dateFormat from "dateformat";

const DateTimePicker = forwardRef((props, ref) => {
    const label = props.label;
    const className = props.className;
    const onChange = props.onChange ? props.onChange : ()=>{};
    const text = props.text ? props.text : new Date();
    const style = props.style;
    const time = props.time;
    const closeOnClickOutside = props.closeOnClickOutside !== undefined ? props.closeOnClickOutside : true;
    
    // Variables
    const [value, setValue] = useState(text);
    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            setText: (val)=>{setValue(new Date(val))},
            getText: () => new Date(current.current),
            getDateTime: () => {
                const time = new Date();
                const date = new Date(current.current);

                date.setHours(time.getHours());
                date.setMinutes(time.getMinutes());
                date.setSeconds(time.getSeconds());
                
                return date;
            },
        };
    });

    const onChangeHandle = (val) => {
        setValue(val);
        // let date = new Date(val);
        // date = dateFormat(date, "dd/mm/yyyy");
    }

    useEffect(() => {
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div className={`datetime ${className}`} style={style}>
                <div className="labelDiv"><label>{label}</label></div>
                <img src="/icons/date.png" alt="" />
                <Datetime
                    // initialValue={value}
                    onChange={onChangeHandle} 
                    dateFormat="DD/MM/yyyy"
                    timeFormat={time}
                    value={value}
                    closeOnClickOutside={closeOnClickOutside}
                    closeOnSelect={true}/>
            </div>
        </>
    )
});

export default DateTimePicker;