import React, { useState, forwardRef, useImperativeHandle } from "react";

const ToastComponent = forwardRef((props, ref) => {
    const [title, setTitle] = useState("Success");
    const [message, setMessage] = useState("Data saved successfully");
    const [icon, setIcon] = useState("success");
    const [color, setColor] = useState("red");

    let interval = null
    const show = (message, icon) => {
        
        setMessage(message);
        
        if (icon === "i") {
            setTitle("Info");
            setIcon("information");
            setColor('blue');
        }
        else if (icon === "w") {
            setTitle("Warning");
            setIcon("warning");
            setColor('yellow');
        }
        else if (icon === "e") {
            setTitle("Error");
            setIcon("error");
            setColor('red');
        }
        else if (icon === "s") {
            setTitle("Success");
            setIcon("success");
            setColor('#64DA7F');
        }


        const confirmDiv = document.getElementById("toast");
        confirmDiv.style.display = "flex";

        interval = setTimeout(() => {
            confirmDiv.style.display = "none";
        }, 3000);
    }

    const clostBtnClick = ()=>{
        const confirmDiv = document.getElementById("toast");
        confirmDiv.style.display = "none";
        clearInterval(interval);
    }

    useImperativeHandle(ref, () => {
        return {
            show: show
        };
    });

    return (
        <>
            <div id="toast">
                <div className="left" style={{backgroundColor:color}}></div>
                <div>
                    <img src={`icons/${icon}.png`} alt="" />
                </div>
                <div className="body">
                    <span className="title">{title}</span>
                    <p className="message">{message}</p>
                </div>
                <div className="close">
                    <span onClick={clostBtnClick}>x</span>
                </div>
            </div>
        </>
    )
});

export default ToastComponent;