/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/main/mainUsers.css';
import Table from 'components/tools/Table';
import ButtonIcon from 'components/tools/ButtonIcon';
import {
    apiLoadMainUsers,
    apiInsertMainUser,
    apiUpdateMainUser,
    apiDeleteMainUser,
    apiGetMainUser,
    apiIsMainUserExists,
    apiUploadImage as apiUploadUserImage,
} from "api/main/MainUsersApi";
import { useDispatch } from "react-redux";
import { mainReducerLoadBranches, mainReducerLoadBranchGroups } from "state/reducers/mainReducer";
import {
    apiLoadBranches,
    apiLoadBranchGroups,
} from "api/main/mainApi";
import Image from 'components/tools/Image';
import Textbox from 'components/tools/Textbox';
import TextboxPassword from 'components/tools/TextboxPassword';
import Checkbox from 'components/tools/Checkbox';
import ClientsCB from 'components/tools/dropdowns/ClientsCB';
import BranchesCB from 'components/tools/dropdowns/BranchesCB';
import BranchGroupsCB from 'components/tools/dropdowns/BranchGroupsCB';
import { isFileSizeValid } from 'utilities/Utils';
import { getClientId } from 'utilities/DB';

/* #endregion */

const MainUsersComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "mainUsersForm";
    const isActive = useRef(false);
    const dispatch = useDispatch();

    // User
    const clientCB = useRef();
    const branchCB = useRef();
    const branchGroupCB = useRef();
    const usernameTB = useRef();
    const passwordTB = useRef();
    const confirmPasswordTB = useRef();
    const isMainUserCheckbox = useRef();
    const searchTB = useRef();

    // User Rights
    const branchWisePurchaseCheckbox = useRef();
    const branchWiseReportsCheckbox = useRef();

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalUsers, setTotalUsers] = useState(0);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Username', row: "username", sortBy: "string", style: { minWidth: '300px', width: '100%' } },
        { column: 'Type', row: "type", sortBy: "string", style: { minWidth: '70px' } },
        { column: 'Branch', row: "branchName", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Branch Group', row: "branchGroupName", sortBy: "string", style: { minWidth: '200px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        tableRef.current.setLoading(true);
        apiLoadMainUsers({
            clientId: clientCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            console.log(result);
            setTotalUsers(result.total.totalUsers);
            tableRef.current.setData([...result.rows]);
            tableRef.current.setLoading(false);

            clearTextboxes();
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadBranchesCB = () => {
        if (clientCB.current.getValue() !== 0) {
            apiLoadBranches({
                clientId: clientCB.current.getValue()
            }).then((result) => {
                dispatch(mainReducerLoadBranches(result));
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    const loadBranchGroupsCB = () => {
        if (clientCB.current.getValue() !== 0) {
            apiLoadBranchGroups({
                clientId: clientCB.current.getValue()
            }).then((result) => {
                dispatch(mainReducerLoadBranchGroups(result));
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    const insertOrUpdateData = async () => {
        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateMainUser(data)
                    .then((result) => {
                        toast.current.show("User updated successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertMainUser(getFormData())
                    .then((result) => {
                        toast.current.show("User saved successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetMainUser(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this User?")) {
                apiDeleteMainUser(Number(row['id']))
                    .then((result) => {
                        toast.current.show("User deleted successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this User?")) {
                apiDeleteMainUser(updateId.current)
                    .then((result) => {
                        toast.current.show("User deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        usernameTB.current.setText("");
        passwordTB.current.setText("");
        confirmPasswordTB.current.setText("");

        branchWisePurchaseCheckbox.current.setChecked(false);
        branchWiseReportsCheckbox.current.setChecked(false);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        usernameTB.current.focus();

        document.getElementById("userImageDialog").value = "";
        document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (data) => {
        formData.current = data.user;

        const user = data.user;
        const userRights = data.userRights;

        branchCB.current.setValue(user.branchId);
        branchGroupCB.current.setValue(user.branchGroupId);

        // User
        usernameTB.current.setText(user.username);
        passwordTB.current.setText(user.password);
        confirmPasswordTB.current.setText(user.password);
        isMainUserCheckbox.current.setChecked(user.isMainUser);

        branchWisePurchaseCheckbox.current.setChecked(userRights.branchWisePurchase);
        branchWiseReportsCheckbox.current.setChecked(userRights.branchWiseReports);

        usernameTB.current.focus();

        if (user.imageUrl !== '')
            document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${user.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const data = {
            clientId: clientCB.current.getValue(),
            branchId: branchCB.current.getValue(),
            branchGroupId: branchGroupCB.current.getValue(),
        };

        const user = {
            username: usernameTB.current.getText().trim(),
            password: passwordTB.current.getText(),
            isMainUser: isMainUserCheckbox.current.isChecked(),
        }

        const userRights = {
            branchWisePurchase: branchWisePurchaseCheckbox.current.isChecked(),
            branchWiseReports: branchWiseReportsCheckbox.current.isChecked(),
        }

        data.user = user;
        data.userRights = userRights;
        return data;
    }
    const validation = async () => {
        const username = usernameTB.current.getText().trim();
        const password = passwordTB.current.getText();
        const confirmPassword = confirmPasswordTB.current.getText();
        const isValid = await isValidUser(username, password, confirmPassword);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidUser = async (username, password, confirmPassword) => {
        if (clientCB.current.getValue() === 0) {
            toast.current.show("Please Select Client.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (branchCB.current.getValue() === 0) {
            toast.current.show("Please Select Branch.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
            return false;
        }
        else if (confirmPassword === '') {
            toast.current.show("Please Re-Enter Password.", "i");
            confirmPasswordTB.current.focus();
            return false;
        }
        else if (password !== confirmPassword) {
            toast.current.show("Password didn't match.", "i");
            passwordTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (username.toLowerCase() === formData.current.username.toLowerCase())
                    return true;
                else
                    return isUserExists(username, password);
            }
            else
                return isUserExists(username, password);
        }
    }
    const isUserExists = async (username, password) => {
        const result = await apiIsMainUserExists({
            clientId: clientCB.current.getValue(),
            username: username,
            password: password
        });
        if (result.isExists) {
            toast.current.show("Username already exists.", "i");
            usernameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onUserImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadUserImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    const onUserUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key && e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onUsernameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            passwordTB.current.focus();
        }
    }
    const onPasswordTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            confirmPasswordTB.current.focus();
        }
    }
    const onConfirmPasswordTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    const onClientCBChange = () => {
        loadData();
        loadBranchesCB();
        loadBranchGroupsCB();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;
    }

    const formLoad = () => {
        tableRef.current.focus();
        loadData();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>
                        {/* Users Panel */}
                        <div className='leftPanel'>

                            <div className='imagePanel' style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                                <Image
                                    width={150}
                                    height={106}
                                    id="userImageId"
                                    imageId="userImage"
                                    imageDialogId="userImageDialog"
                                    onUploadBtnClick={(e) => {
                                        onUserUploadBtnClick(e);
                                    }}
                                    onUpload={(e) => {
                                        onUserImageUpload(e);
                                    }}
                                />
                            </div>

                            <ClientsCB
                                ref={clientCB}
                                onChange={onClientCBChange} />

                            <BranchesCB
                                ref={branchCB}
                                onChange={() => { }} />

                            <BranchGroupsCB
                                ref={branchGroupCB}
                                onChange={() => { }} />

                            <Textbox
                                ref={usernameTB}
                                label="Username"
                                tabIndex={16}
                                onKeyDown={onUsernameTBKeydown} />

                            <TextboxPassword
                                ref={passwordTB}
                                label="Password"
                                tabIndex={17}
                                onKeyDown={onPasswordTBKeydown} />

                            <TextboxPassword
                                ref={confirmPasswordTB}
                                label="Confirm Password"
                                tabIndex={18}
                                onKeyDown={onConfirmPasswordTBKeydown} />

                            <div className="buttons">
                                <ButtonIcon
                                    label="Save"
                                    icon="icons/buttons/save.png"
                                    onClick={onSaveBtnClick} />

                                <ButtonIcon
                                    label="Edit"
                                    icon="icons/buttons/edit.png"
                                    onClick={onEditBtnClick} />

                                <ButtonIcon
                                    label="Delete"
                                    icon="icons/buttons/delete.png"
                                    onClick={onDeleteBtnClick} />

                                <ButtonIcon
                                    label="Cancel"
                                    icon="icons/buttons/cancel.png"
                                    onClick={onCancelBtnClick} />
                            </div>

                            <Textbox
                                ref={searchTB}
                                label="Search"
                                onKeyDown={onSearchTBKeydown}
                                tabIndex={19} />

                            <Table
                                ref={tableRef}
                                columns={columns.current}
                                myTable='usersTable'
                                isActive={isActive}
                                onDoubleClick={onEditBtnClick}
                                editBtn={{ visible: false, onClick: onEditBtnClick }}
                                deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                            <div className="tableFooter">
                                <p><b>Total Users: </b>{totalUsers}</p>
                            </div>

                        </div>

                        {/* User Rights Panel */}
                        <div className='rightPanel'>
                            <Checkbox
                                ref={isMainUserCheckbox}
                                label="Main User"
                                checked={true} />
                            <Checkbox
                                ref={branchWisePurchaseCheckbox}
                                label="Branch Wise Purchase"
                                checked={true}  />
                            <Checkbox
                                ref={branchWiseReportsCheckbox}
                                label="Branch Wise Reports" 
                                checked={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default MainUsersComponent;