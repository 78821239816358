/* #region Imports */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import 'style/utilities/billDesigner.css';
import 'style/utilities/bill.css';

import parse from 'html-react-parser';
import { useReactToPrint } from "react-to-print";
import Textarea from "components/tools/Textarea";
import ThinButton from "components/tools/ThinButton";
import ButtonIcon from "components/tools/ButtonIcon";
import PrintLanguagesCB from "components/tools/dropdowns/PrintLanguagesCB";
import PrintSizesCB from "components/tools/dropdowns/PrintSizesCB";
import { printSizes } from "utilities/Utils";
import Textbox from "components/tools/Textbox";
import { apiDeleteImage, apiDeleteBill, apiGetBill, apiInsertBill, apiLoadImages, apiUpdateBill, apiUploadImage } from "api/utilities/SettingsApi";
import { useDispatch, useSelector } from "react-redux";
import { mainReducerLoadBills, mainReducerLoadBillsCode } from "state/reducers/mainReducer";
import Image from "components/tools/Image";
import ComboBox from "components/tools/ComboBox";
import ReportA4Style from "components/reports/styles/ReportA4Style";
import ReportThermalStyle from "components/reports/styles/ReportThermalStyle";
import ReportA5Style from "components/reports/styles/ReportA5Style";
import BillsCB from "components/tools/dropdowns/BillsCB";
import Checkbox from "components/tools/Checkbox";
import { getBranchId, getClientId } from "utilities/DB";
/* #endregion Imports */

const BillDesignerComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "billDesigner";
    const isActive = useRef(false);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector(state => state.main.user);

    const nameTB = useRef();
    const printSizesCB = useRef();
    const printLanguagesCB = useRef();
    const headerCodeTB = useRef();
    const footerCodeTB = useRef();
    const styleCodeTB = useRef();
    const [billsImages, setBillsImages] = useState([]);
    const imageNameTB = useRef();
    const showSrCheckbox = useRef();
    const showDiscCheckbox = useRef();
    const showDiscPercentCheckbox = useRef();
    const showTotalDiscCheckbox = useRef();
    const showUrduCheckbox = useRef();

    const billRef = useRef();
    const billsImagesCB = useRef();
    const reportRef = useRef();
    // eslint-disable-next-line
    const [reportData, setReportData] = useState([]);
    const [activeBox, setActiveBox] = useState("header");
    // eslint-disable-next-line
    const [data, setData] = useState([]);

    const [headerData, setHeaderData] = useState("");
    const [footerData, setFooterData] = useState("");
    const [styleData, setStyleData] = useState("");
    const [activePageSize, setActivePageSize] = useState("thermal");

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const loadBill = () => {
        if (billRef.current.getValue() !== 0) {
            apiGetBill({
                id: billRef.current.getValue()
            }).then((result) => {
                try {
                    setFormData(result);
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            clearTextboxes();
        }
    }
    const loadImages = () => {
        apiLoadImages({
            clientId: user.clientId,
            branchId: user.branchId,
        }).then((result) => {
            try {
                setBillsImages(result);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (billRef.current.getValue() !== 0) {
                const data = getFormData();
                data.id = billRef.current.getValue();
                apiUpdateBill(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Bill updated successfully.", "s");
                        refresh();

                        dispatch(mainReducerLoadBills(result.bills));
                        dispatch(mainReducerLoadBillsCode(result));
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertBill(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Bill saved successfully.", "s");
                        refresh();

                        dispatch(mainReducerLoadBills(result.bills));
                        dispatch(mainReducerLoadBillsCode(result));
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const deleteBill = () => {
        if (billRef.current.getValue() !== 0) {
            if (window.confirm("Are you sure to delete this Bill?")) {
                apiDeleteBill({ id: billRef.current.getValue() })
                    .then((result) => {
                        toast.current.show("Bill deleted successfully.", "s");
                        clearTextboxes();
                        dispatch(mainReducerLoadBills(result));
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const deleteImage = () => {
        if (billsImagesCB.current.getValue() !== 0) {
            if (window.confirm("Are you sure to delete this File?")) {
                apiDeleteImage({ filename: billsImagesCB.current.getText() })
                    .then((result) => {
                        loadImages();
                        toast.current.show("File deleted successfully.", "s");
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        headerCodeTB.current.setText('');
        footerCodeTB.current.setText('');
        styleCodeTB.current.setText('');
        nameTB.current.setText('');
        showSrCheckbox.current.setChecked(false);
        showDiscCheckbox.current.setChecked(false);
        showDiscPercentCheckbox.current.setChecked(false);
        showTotalDiscCheckbox.current.setChecked(false);
        showUrduCheckbox.current.setChecked(false);

        setTimeout(() => {
            refresh();
        }, 100);
    }
    const setFormData = (salesBill) => {
        nameTB.current.setText(salesBill.name);
        printSizesCB.current.setValue(salesBill.printSize);
        printLanguagesCB.current.setValue(salesBill.printLanguage);

        headerCodeTB.current.setText(salesBill.header);
        footerCodeTB.current.setText(salesBill.footer);
        styleCodeTB.current.setText(salesBill.style);

        showSrCheckbox.current.setChecked(salesBill.showSr);
        showDiscCheckbox.current.setChecked(salesBill.showDisc);
        showDiscPercentCheckbox.current.setChecked(salesBill.showDiscPercent);
        showTotalDiscCheckbox.current.setChecked(salesBill.showTotalDisc);
        showUrduCheckbox.current.setChecked(salesBill.showUrdu);

        nameTB.current.focus();

        setTimeout(() => {
            refresh();
        }, 100);
    }
    const getFormData = () => {
        const salesBill = {
            name: nameTB.current.getText().trim(),
            printSize: printSizesCB.current.getValue(),
            printLanguage: printLanguagesCB.current.getValue(),
            header: headerCodeTB.current.getText(),
            footer: footerCodeTB.current.getText(),
            style: styleCodeTB.current.getText(),
            showSr: showSrCheckbox.current.isChecked(),
            showDisc: showDiscCheckbox.current.isChecked(),
            showDiscPercent: showDiscPercentCheckbox.current.isChecked(),
            showTotalDisc: showTotalDiscCheckbox.current.isChecked(),
            showUrdu: showUrduCheckbox.current.isChecked(),
        }

        return salesBill;
    }
    const validation = async () => {
        const name = nameTB.current.getText().trim();
        if (name === '') {
            toast.current.show("Please Enter Name.", "i");
            nameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onImageUpload = (imageDialog) => {
        // const filename = imageDialog.files[0].name.split('.')[0];
        const filename = imageNameTB.current.getText();
        if (filename !== '') {
            const formData = new FormData();
            formData.append('image', imageDialog.files[0]);
            formData.append('filename', filename);
            formData.append('clientId', getClientId());
            formData.append('branchId', getBranchId());

            apiUploadImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("billsImage").src = `${process.env.REACT_APP_API_URL}${result.data}?` + new Date().getTime();
                        loadImages();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        } else {
            toast.current.show("Please Enter Filename.", "i");
        }
    }
    const refresh = () => {
        setHeaderData(headerCodeTB.current.getText());
        setFooterData(footerCodeTB.current.getText());
        setStyleData(styleCodeTB.current.getText());
    }
    const printBill = useReactToPrint({
        content: () => reportRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                // const dataDiv = document.getElementsByClassName("dataDiv")[0];

                // // If data not exists
                // if (!dataDiv) {
                //     // Data not populated yet
                //     printBill(); // Recall untill populated completely
                //     return;
                // }

                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            setReportData([]);
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onImageUploadBtnClick = (imageDialog) => {
        imageDialog.click();
    }
    const onInsertImageBtnClick = () => {
        if (billsImagesCB.current.getValue() !== 0) {
            const filename = billsImagesCB.current.getText();
            const url = `${process.env.REACT_APP_API_URL}/images/bills/${getClientId()}/${getBranchId()}/${filename}`;

            const element = `<img src="${url}"/>`
            headerCodeTB.current.setText(headerCodeTB.current.getText() + element);
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        
        if (mainActiveTab.current !== "utilities")
            return;

        if (activeTab.current !== "settings")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printBill();
        } else if (e.ctrlKey && e.key.toLowerCase() === "enter") {
            e.preventDefault();
            refresh();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    /* #endregion */

    /* #region Others */
    const onPrintSizeChange = () => {
        const element = document.querySelector('#billDesigner .bill');
        if (element) {
            if (printSizesCB.current.getValue() === printSizes.thermal) {
                element.classList.remove("reportA4");
                element.classList.remove("reportA5");
                element.classList.add("reportThermal");
                setActivePageSize("thermal");
            } else if (printSizesCB.current.getValue() === printSizes.a4) {
                element.classList.remove("reportA5");
                element.classList.remove("reportThermal");
                element.classList.add("reportA4");
                setActivePageSize("a4");
            } else if (printSizesCB.current.getValue() === printSizes.a5) {
                element.classList.remove("reportA4");
                element.classList.remove("reportThermal");
                element.classList.add("reportA5");
                setActivePageSize("a5");
            }
        }
    }
    const onBillsImageCBChange = () => {
        const filename = billsImagesCB.current.getText();
        if (filename === 'None') {
            document.getElementById("billsImage").src = `${process.env.REACT_APP_API_URL}/images/0.png?` + new Date().getTime();
        } else {
            document.getElementById("billsImage").src = `${process.env.REACT_APP_API_URL}/images/bills/${user.clientId}/${user.branchId}/${filename}?` + new Date().getTime();
        }
    }
    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
        setActivePageSize(null);
    }

    const formLoad = () => {
        loadImages();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        show();

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])
    /* #endregion */

    useEffect(() => {
        activeTab.current = props.activeTab;
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        {(() => {
                            switch (activePageSize) {
                                case "thermal":
                                    return <ReportThermalStyle />;
                                case "a4":
                                    return <ReportA4Style />;
                                case "a5":
                                    return <ReportA5Style />;
                                default:
                                    return <div></div>;
                            }
                        })()}

                        <div className="">
                            {/* Top */}
                            <div className="dRow jcsb">
                                <div className="dRow">
                                    <ThinButton
                                        label="Header"
                                        onClick={() => { setActiveBox('header') }} />
                                    <ThinButton
                                        label="Footer"
                                        onClick={() => { setActiveBox('footer') }} />
                                    <ThinButton
                                        label="Style"
                                        onClick={() => { setActiveBox('style') }} />
                                </div>

                                <div className="reportShowBtnGroup" style={{ marginTop: '-8px' }}>
                                    <BillsCB
                                        ref={billRef}
                                        style={{ width: '300px' }}
                                        onChange={loadBill} />
                                    <ThinButton
                                        label="Delete"
                                        onClick={deleteBill} />
                                </div>

                            </div>

                            {/* Middle */}
                            <div className="middle">
                                <div className="dRow" style={{ marginTop: '12px' }}>
                                    <div className="codeBox">
                                        <div className={`${activeBox === 'header' ? 'd-block' : 'd-none'}`}>
                                            <Textarea
                                                ref={headerCodeTB}
                                                label="Header Code"
                                                className="codeTB" />
                                        </div>
                                        <div className={`${activeBox === 'footer' ? 'd-block' : 'd-none'}`}>
                                            <Textarea
                                                ref={footerCodeTB}
                                                label="Footer Code"
                                                className="codeTB" />
                                        </div>
                                        <div className={`${activeBox === 'style' ? 'd-block' : 'd-none'}`}>
                                            <Textarea
                                                ref={styleCodeTB}
                                                label="Style Sheet"
                                                className="codeTB" />
                                        </div>
                                    </div>
                                    <div className="imagePanel">
                                        <div className="reportShowBtnGroup" style={{ width: '100%' }}>
                                            <ComboBox
                                                ref={billsImagesCB}
                                                label="Images"
                                                data={billsImages}
                                                style={{ width: '100%' }}
                                                onChange={onBillsImageCBChange} />
                                            <ThinButton
                                                label="Delete"
                                                onClick={deleteImage} />
                                        </div>
                                        <div className="reportShowBtnGroup" style={{ width: '100%' }}>

                                            <Textbox
                                                ref={imageNameTB}
                                                style={{ width: '100%' }}
                                                label="Filename" />
                                            <ThinButton
                                                label="Insert"
                                                onClick={onInsertImageBtnClick} />
                                        </div>

                                        <Image
                                            width={160}
                                            height={103}
                                            id="billsImageId"
                                            imageId="billsImage"
                                            imageDialogId="billsImageDialog"
                                            onUploadBtnClick={(e) => {
                                                onImageUploadBtnClick(e);
                                            }}
                                            onUpload={(e) => {
                                                onImageUpload(e);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="dRow" style={{ marginTop: '-4px' }}>
                                    <Textbox
                                        ref={nameTB}
                                        style={{ width: '100%' }}
                                        label="Name" />
                                    <PrintLanguagesCB
                                        ref={printLanguagesCB}
                                        className="defaultMarginLeft"
                                        style={{ minWidth: '200px' }}
                                        onChange={() => { }} />

                                    <PrintSizesCB
                                        ref={printSizesCB}
                                        className="defaultMarginLeft"
                                        style={{ minWidth: '200px' }}
                                        onChange={onPrintSizeChange} />
                                </div>
                                <div className="dRow">
                                    <Checkbox
                                        ref={showSrCheckbox}
                                        label="Show Sr."
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={showDiscCheckbox}
                                        label="Show Discount"
                                        className="defaultMarginLeft"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={showDiscPercentCheckbox}
                                        label="Show Discount Percent"
                                        className="defaultMarginLeft"
                                        onChange={() => { }} />
                                    <Checkbox
                                        ref={showTotalDiscCheckbox}
                                        label="Show Total Discount"
                                        className="defaultMarginLeft"
                                        onChange={() => { }} />
                                        <Checkbox
                                        ref={showUrduCheckbox}
                                        label="Show Urdu"
                                        className="defaultMarginLeft"
                                        onChange={() => { }} />
                                </div>
                                <div className="dRow" style={{ justifyContent: 'center', marginTop: '8px' }}>
                                    <ButtonIcon
                                        label="Save"
                                        icon="icons/buttons/save.png"
                                        style={{ marginLeft: '16px' }}
                                        onClick={onSaveBtnClick} />

                                    <ButtonIcon
                                        label="Print"
                                        icon="icons/buttons/print.png"
                                        onClick={printBill} />
                                </div>
                            </div>

                            {/* Bottom */}
                            <div className="bottom">
                                <div className={`reportViewer`} style={{ paddingTop: '4px', marginTop: '8px' }}>
                                    {/* Bill */}
                                    <div ref={reportRef} className="bill reportThermal">

                                        {/* Style */}
                                        <div className="header">
                                            {parse(styleData)}
                                        </div>

                                        {/* Header */}
                                        <div className="header">
                                            {parse(headerData)}
                                        </div>

                                        {/* Body */}
                                        {
                                            data.length > 0 &&
                                            <div className="dataDiv">
                                                {data.map((e, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <span className="details">{e.itemname}, {e.rate}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }

                                        {/* Footer */}
                                        <div className="header">
                                            {parse(footerData)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default BillDesignerComponent;