import { getBranchGroupId, post, uploadImage } from 'utilities/DB.js';

export const apiLoadUsers = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/users/loadUsers", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertUser = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/users/insertUser", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateUser = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/users/updateUser", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteUser = (userId) => {
    const params = {
        id: userId
    }
    return new Promise((resolve, reject) => {
        post("api/users/deleteUser", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetUser = (userId) => {
    const params = {
        id: userId
    }
    return new Promise((resolve, reject) => {
        post("api/users/getUser", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsUserExists = (username, password) => {
    const params = {
        username: username,
        password: password
    }
    return new Promise((resolve, reject) => {
        post("api/users/isUserExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/users/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLogin = (username, password) => {
    const params = {
        username: username,
        password: password
    }
    return new Promise((resolve, reject) => {
        post("api/users/login", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
