import { post } from 'utilities/DB.js';

export const apiLoadPurchaseOrders = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/loadPurchaseOrders", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertPurchaseOrder = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/insertPurchaseOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePurchaseOrder = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/updatePurchaseOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePurchaseOrder = (purchaseOrderId) => {
    const params = {
        id: purchaseOrderId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/deletePurchaseOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPurchaseOrder = (purchaseOrderId) => {
    const params = {
        id: purchaseOrderId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/getPurchaseOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsPurchaseOrderExists = (purchaseOrderName) => {
    const params = {
        purchaseOrderName: purchaseOrderName,
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/isPurchaseOrderExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetOrder = (purchaseOrderId) => {
    const params = {
        id: purchaseOrderId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrders/getOrder", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
