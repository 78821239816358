import { post, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadSubBarcodes = (itemId) => {
    const params = {
        itemId: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/subBarcodes/loadSubBarcodes", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSubBarcode = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/subBarcodes/insertSubBarcode", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSubBarcode = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subBarcodes/updateSubBarcode", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSubBarcode = (subBarcodeId) => {
    const params = {
        id: subBarcodeId
    }
    return new Promise((resolve, reject) => {
        post("api/subBarcodes/deleteSubBarcode", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSubBarcode = (subBarcodeId) => {
    const params = {
        id: subBarcodeId
    }
    return new Promise((resolve, reject) => {
        post("api/subBarcodes/getSubBarcode", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
