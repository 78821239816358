import { post } from 'utilities/DB.js';

export const apiLoadSubBanks = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subBanks/loadSubBanks", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSubBank = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subBanks/insertSubBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSubBank = (params) => {
    return new Promise((resolve, reject) => {
        post("api/subBanks/updateSubBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSubBank = (subBankId) => {
    const params = {
        id: subBankId
    }
    return new Promise((resolve, reject) => {
        post("api/subBanks/deleteSubBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSubBank = (subBankId) => {
    const params = {
        id: subBankId
    }
    return new Promise((resolve, reject) => {
        post("api/subBanks/getSubBank", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsSubBankExists = (subBankName) => {
    const params = {
        accountName: subBankName
    }
    return new Promise((resolve, reject) => {
        post("api/subBanks/isSubBankExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
