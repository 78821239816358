/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/utilities/users.css';
import {
    apiLoadUsers,
    apiInsertUser,
    apiUpdateUser,
    apiDeleteUser,
    apiGetUser,
    apiIsUserExists,
    apiUploadImage as apiUploadUserImage
} from "api/utilities/UsersApi";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ComboBox from 'components/tools/ComboBox';
import Checkbox from 'components/tools/Checkbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Image from "components/tools/Image";
import TextboxPassword from 'components/tools/TextboxPassword';
import { isFileSizeValid } from 'utilities/Utils';
import { getClientId } from 'utilities/DB';
/* #endregion Imports */

const UsersComponent = (props) => {

    /* #region Variables */
    const formId = "usersForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);

    // User
    const usernameTB = useRef();
    const passwordTB = useRef();
    const confirmPasswordTB = useRef();
    const userTypeCB = useRef();
    const shiftCB = useRef();

    // User Rights
    // Main
    const itemsCheckbox = useRef();
    const salesCheckbox = useRef();
    const purchaseCheckbox = useRef();
    const categoriesCheckbox = useRef();
    const warehouseCheckbox = useRef();
    // Accounts
    const customersCheckbox = useRef();
    const vendorsCheckbox = useRef();
    const expensesCheckbox = useRef();
    const banksCheckbox = useRef();
    const suppliersCheckbox = useRef();
    // Users
    const usersCheckbox = useRef();
    const settingsCheckbox = useRef();
    const barcodeLabelsCheckbox = useRef();
    // POS
    const changeRatesCheckbox = useRef();
    const seeMarginCheckbox = useRef();
    const salesReturnCheckbox = useRef();
    const discountCheckbox = useRef();
    const entryCheckbox = useRef();
    const payBillCheckbox = useRef();
    const dateWiseSalesCheckbox = useRef();
    // Others
    const dateWisePurchaseCheckbox = useRef();
    const dateWiseEntryCheckbox = useRef();
    // Reports
    const salesReportsCheckbox = useRef();
    const purchaseReportsCheckbox = useRef();
    const profitReportsCheckbox = useRef();
    const stockReportsCheckbox = useRef();
    const accountsReportsCheckbox = useRef();
    const incomeStatementCheckbox = useRef();
    const othersReportsCheckbox = useRef();

    const userTypesData = useRef([
        { id: 1, name: 'Administrator' },
        { id: 2, name: 'POS User' },
        { id: 3, name: 'Inventory Manager' },
    ]);
    const shiftData = useRef([
        { id: 1, name: 'Morning' },
        { id: 2, name: 'Evening' },
    ]);

    const searchTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalUsers, setTotalUsers] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Username', row: "username", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
        selectAllCheckbox(true);

        usernameTB.current.focus();
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadUsers({
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalUsers(result.total.totalUsers);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateUser(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("User updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertUser(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("User saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetUser(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this User?")) {
                apiDeleteUser(Number(row['id']))
                    .then((result) => {
                        toast.current.show("User deleted successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this User?")) {
                apiDeleteUser(updateId.current)
                    .then((result) => {
                        toast.current.show("User deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        usernameTB.current.setText("");
        passwordTB.current.setText("");
        confirmPasswordTB.current.setText("");
        userTypeCB.current.setValue(1);
        shiftCB.current.setValue(1);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        usernameTB.current.focus();

        document.getElementById("userImageDialog").value = "";
        document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (data) => {
        formData.current = data.user;

        const user = data.user;
        const userRights = data.userRights;

        // User
        usernameTB.current.setText(user.username);
        passwordTB.current.setText(user.password);
        confirmPasswordTB.current.setText(user.password);
        userTypeCB.current.setValue(user.userType);
        shiftCB.current.setValue(user.shift);

        // User Rights
        // Main
        itemsCheckbox.current.setChecked(userRights.items);
        salesCheckbox.current.setChecked(userRights.sales);
        purchaseCheckbox.current.setChecked(userRights.purchase);
        categoriesCheckbox.current.setChecked(userRights.categories);
        warehouseCheckbox.current.setChecked(userRights.warehouse);
        // Accounts
        customersCheckbox.current.setChecked(userRights.customers);
        vendorsCheckbox.current.setChecked(userRights.vendors);
        expensesCheckbox.current.setChecked(userRights.expenses);
        banksCheckbox.current.setChecked(userRights.banks);
        suppliersCheckbox.current.setChecked(userRights.suppliers);
        // Utilities
        usersCheckbox.current.setChecked(userRights.users);
        settingsCheckbox.current.setChecked(userRights.settings);
        barcodeLabelsCheckbox.current.setChecked(userRights.barcodeLabels);
        // POS
        changeRatesCheckbox.current.setChecked(userRights.changeRates);
        seeMarginCheckbox.current.setChecked(userRights.seeMargin);
        salesReturnCheckbox.current.setChecked(userRights.salesReturn);
        discountCheckbox.current.setChecked(userRights.discount);
        entryCheckbox.current.setChecked(userRights.entry);
        payBillCheckbox.current.setChecked(userRights.payBill);
        dateWiseSalesCheckbox.current.setChecked(userRights.dateWiseSales);
        // Others
        dateWiseEntryCheckbox.current.setChecked(userRights.dateWiseEntry);
        dateWisePurchaseCheckbox.current.setChecked(userRights.dateWisePurchase);
        // Reports
        salesReportsCheckbox.current.setChecked(userRights.salesReport);
        purchaseReportsCheckbox.current.setChecked(userRights.purchaseReport);
        profitReportsCheckbox.current.setChecked(userRights.profitReport);
        stockReportsCheckbox.current.setChecked(userRights.stockReport);
        accountsReportsCheckbox.current.setChecked(userRights.accountsReport);
        incomeStatementCheckbox.current.setChecked(userRights.incomeStatement);
        othersReportsCheckbox.current.setChecked(userRights.othersReport);

        usernameTB.current.focus();


        if (user.imageUrl !== '')
            document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${user.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const data = {};

        const user = {
            username: usernameTB.current.getText().trim(),
            password: passwordTB.current.getText(),
            userType: userTypeCB.current.getValue(),
            shift: shiftCB.current.getValue(),
        }

        const userRights = {
            items: itemsCheckbox.current.isChecked(),
            sales: salesCheckbox.current.isChecked(),
            purchase: purchaseCheckbox.current.isChecked(),
            categories: categoriesCheckbox.current.isChecked(),
            warehouse: warehouseCheckbox.current.isChecked(),

            accounts: isAccountsRight(),
            customers: customersCheckbox.current.isChecked(),
            vendors: vendorsCheckbox.current.isChecked(),
            expenses: expensesCheckbox.current.isChecked(),
            banks: banksCheckbox.current.isChecked(),
            suppliers: suppliersCheckbox.current.isChecked(),

            utilities: isUtilitiesRight(),
            users: usersCheckbox.current.isChecked(),
            settings: settingsCheckbox.current.isChecked(),
            barcodeLabels: barcodeLabelsCheckbox.current.isChecked(),

            changeRates: changeRatesCheckbox.current.isChecked(),
            seeMargin: seeMarginCheckbox.current.isChecked(),
            salesReturn: salesReturnCheckbox.current.isChecked(),
            discount: discountCheckbox.current.isChecked(),
            entry: entryCheckbox.current.isChecked(),
            payBill: payBillCheckbox.current.isChecked(),
            dateWiseSales: dateWiseSalesCheckbox.current.isChecked(),

            reports: isReportsRight(),
            salesReport: salesReportsCheckbox.current.isChecked(),
            purchaseReport: purchaseReportsCheckbox.current.isChecked(),
            profitReport: profitReportsCheckbox.current.isChecked(),
            stockReport: stockReportsCheckbox.current.isChecked(),
            accountsReport: accountsReportsCheckbox.current.isChecked(),
            incomeStatement: incomeStatementCheckbox.current.isChecked(),
            othersReport: othersReportsCheckbox.current.isChecked(),

            dateWiseEntry: dateWiseEntryCheckbox.current.isChecked(),
            dateWisePurchase: dateWisePurchaseCheckbox.current.isChecked(),
        }

        data.user = user;
        data.userRights = userRights;
        return data;
    }
    const validation = async () => {
        const username = usernameTB.current.getText().trim();
        const password = passwordTB.current.getText();
        const confirmPassword = confirmPasswordTB.current.getText();
        const isValid = await isValidUser(username, password, confirmPassword);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidUser = async (username, password, confirmPassword) => {
        if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
            return false;
        }
        else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
            return false;
        }
        else if (confirmPassword === '') {
            toast.current.show("Please Re-Enter Password.", "i");
            confirmPasswordTB.current.focus();
            return false;
        }
        else if (password !== confirmPassword) {
            toast.current.show("Password didn't match.", "i");
            passwordTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (username.toLowerCase() === formData.current.username.toLowerCase())
                    return true;
                else
                    return isUserExists(username, password);
            }
            else
                return isUserExists(username, password);
        }
    }
    const isUserExists = async (username, password) => {
        const result = await apiIsUserExists(username, password);
        if (result.isExists) {
            toast.current.show("Username already exists.", "i");
            usernameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onUserImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadUserImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("userImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    const selectAllCheckbox = (value) => {
        // Main
        itemsCheckbox.current.setChecked(value);
        salesCheckbox.current.setChecked(value);
        purchaseCheckbox.current.setChecked(value);
        categoriesCheckbox.current.setChecked(value);
        warehouseCheckbox.current.setChecked(value);
        // Accounts
        customersCheckbox.current.setChecked(value);
        vendorsCheckbox.current.setChecked(value);
        expensesCheckbox.current.setChecked(value);
        banksCheckbox.current.setChecked(value);
        suppliersCheckbox.current.setChecked(value);
        // Utilities
        usersCheckbox.current.setChecked(value);
        settingsCheckbox.current.setChecked(value);
        barcodeLabelsCheckbox.current.setChecked(value);
        // POS
        changeRatesCheckbox.current.setChecked(value);
        seeMarginCheckbox.current.setChecked(value);
        salesReturnCheckbox.current.setChecked(value);
        discountCheckbox.current.setChecked(value);
        entryCheckbox.current.setChecked(value);
        payBillCheckbox.current.setChecked(value);
        dateWiseSalesCheckbox.current.setChecked(value);
        // Others
        dateWisePurchaseCheckbox.current.setChecked(value);
        dateWiseEntryCheckbox.current.setChecked(value);
        // Reports
        salesReportsCheckbox.current.setChecked(value);
        purchaseReportsCheckbox.current.setChecked(value);
        profitReportsCheckbox.current.setChecked(value);
        stockReportsCheckbox.current.setChecked(value);
        accountsReportsCheckbox.current.setChecked(value);
        incomeStatementCheckbox.current.setChecked(value);
        othersReportsCheckbox.current.setChecked(value);
    }
    const selectPOSCheckbox = () => {
        selectAllCheckbox(false);
        salesCheckbox.current.setChecked(true);
    }
    const selectINVCheckbox = () => {
        selectAllCheckbox(false);
        itemsCheckbox.current.setChecked(true);
        purchaseCheckbox.current.setChecked(true);
        categoriesCheckbox.current.setChecked(true);
        warehouseCheckbox.current.setChecked(true);
        vendorsCheckbox.current.setChecked(true);
        barcodeLabelsCheckbox.current.setChecked(true);
    }
    const isUtilitiesRight = () => {
        if (usersCheckbox.current.isChecked())
            return true;
        else if (settingsCheckbox.current.isChecked())
            return true;
        else if (barcodeLabelsCheckbox.current.isChecked())
            return true;
        else return false;
    }
    const isAccountsRight = () => {
        if (customersCheckbox.current.isChecked())
            return true;
        else if (vendorsCheckbox.current.isChecked())
            return true;
        else if (expensesCheckbox.current.isChecked())
            return true;
        else if (banksCheckbox.current.isChecked())
            return true;
        else if (suppliersCheckbox.current.isChecked())
            return true;
        else
            return false;
    }
    const isReportsRight = () => {
        if (salesReportsCheckbox.current.isChecked())
            return true;
        else if (purchaseReportsCheckbox.current.isChecked())
            return true;
        else if (profitReportsCheckbox.current.isChecked())
            return true;
        else if (stockReportsCheckbox.current.isChecked())
            return true;
        else if (accountsReportsCheckbox.current.isChecked())
            return true;
        else if (incomeStatementCheckbox.current.isChecked())
            return true;
        else if (othersReportsCheckbox.current.isChecked())
            return true;
        else
            return false;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    const onUserUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "utilities")
            return;

        if (activeTab.current !== "users")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key && e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onUsernameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            passwordTB.current.focus();
        }
    }
    const onPasswordTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            confirmPasswordTB.current.focus();
        }
    }
    const onConfirmPasswordTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onUserTypeChange = () => {
        if (userTypeCB.current.getValue() === 1) {
            selectAllCheckbox(true);
        } else if (userTypeCB.current.getValue() === 2) {
            selectPOSCheckbox();
        } else if (userTypeCB.current.getValue() === 3) {
            selectINVCheckbox();
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "users" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "utilities" && props.activeTab === "users" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'row' }}>
                {/* Users Panel */}
                <div className='leftPanel'>

                    <div className='imagePanel' style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                        <Image
                            width={150}
                            height={106}
                            id="userImageId"
                            imageId="userImage"
                            imageDialogId="userImageDialog"
                            onUploadBtnClick={(e) => {
                                onUserUploadBtnClick(e);
                            }}
                            onUpload={(e) => {
                                onUserImageUpload(e);
                            }}
                        />
                    </div>

                    <Textbox
                        ref={usernameTB}
                        label="Username"
                        tabIndex={1}
                        onKeyDown={onUsernameTBKeydown} />

                    <TextboxPassword
                        ref={passwordTB}
                        label="Password"
                        tabIndex={2}
                        onKeyDown={onPasswordTBKeydown} />

                    <TextboxPassword
                        ref={confirmPasswordTB}
                        label="Confirm Password"
                        tabIndex={3}
                        onKeyDown={onConfirmPasswordTBKeydown} />

                    <ComboBox
                        ref={userTypeCB}
                        label="User Type"
                        onChange={onUserTypeChange}
                        data={userTypesData.current} />

                    <ComboBox
                        ref={shiftCB}
                        label="Shift"
                        onChange={() => { }}
                        data={shiftData.current} />

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onCancelBtnClick} />
                    </div>

                    <Textbox
                        ref={searchTB}
                        label="Search"
                        onKeyDown={onSearchTBKeydown}
                        tabIndex={4} />

                    <Table
                        ref={tableRef}
                        columns={columns.current}
                        myTable='usersTable'
                        isActive={isActive}
                        autoSelectFirstRow={true}
                        onDoubleClick={onEditBtnClick}
                        editBtn={{ visible: false, onClick: onEditBtnClick }}
                        deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                    <div className="tableFooter">
                        <p><b>Total Users: </b>{totalUsers}</p>
                    </div>

                </div>

                {/* User Rights Panel */}
                <div className='rightPanel'>
                    <div className='panel'>
                        <h5 className='headingLabel'>Expenses</h5>
                        <Checkbox
                            ref={itemsCheckbox}
                            label="Items" />
                        <Checkbox
                            ref={salesCheckbox}
                            label="Sales" />
                        <Checkbox
                            ref={purchaseCheckbox}
                            label="Purchase" />
                        <Checkbox
                            ref={categoriesCheckbox}
                            label="Categories" />
                        <Checkbox
                            ref={warehouseCheckbox}
                            label="Warehouse" />

                        <h5 className='headingLabel' style={{ marginTop: '16px' }}>Utilities</h5>
                        <Checkbox
                            ref={usersCheckbox}
                            label="Users" />
                        <Checkbox
                            ref={settingsCheckbox}
                            label="Settings" />
                        <Checkbox
                            ref={barcodeLabelsCheckbox}
                            label="Barcode Labels" />
                    </div>
                    <div className='panel'>
                        <h5 className='headingLabel'>Accounts</h5>
                        <Checkbox
                            ref={customersCheckbox}
                            label="Customers" />
                        <Checkbox
                            ref={vendorsCheckbox}
                            label="Vendors" />
                        <Checkbox
                            ref={expensesCheckbox}
                            label="Expenses" />
                        <Checkbox
                            ref={banksCheckbox}
                            label="Banks" />
                        <Checkbox
                            ref={suppliersCheckbox}
                            label="Suppliers" />

                        <h5 className='headingLabel' style={{ marginTop: '16px' }}>Others</h5>
                        <Checkbox
                            ref={dateWisePurchaseCheckbox}
                            label="Date Wise Purchase" />
                        <Checkbox
                            ref={dateWiseEntryCheckbox}
                            label="Date Wise Entry" />
                    </div>
                    <div className='panel'>
                        <h5 className='headingLabel'>POS</h5>
                        <Checkbox
                            ref={changeRatesCheckbox}
                            label="Change Rates" />
                        <Checkbox
                            ref={seeMarginCheckbox}
                            label="See Margin" />
                        <Checkbox
                            ref={salesReturnCheckbox}
                            label="Sales Return" />
                        <Checkbox
                            ref={discountCheckbox}
                            label="Discount" />
                        <Checkbox
                            ref={entryCheckbox}
                            label="Entry" />
                        <Checkbox
                            ref={payBillCheckbox}
                            label="Pay Bill" />
                        <Checkbox
                            ref={dateWiseSalesCheckbox}
                            label="Date Wise Sales" />
                    </div>
                    <div className='panel'>
                        <h5 className='headingLabel'>Reports</h5>
                        <Checkbox
                            ref={salesReportsCheckbox}
                            label="Sales" />
                        <Checkbox
                            ref={purchaseReportsCheckbox}
                            label="Purchase" />
                        <Checkbox
                            ref={profitReportsCheckbox}
                            label="Profit" />
                        <Checkbox
                            ref={stockReportsCheckbox}
                            label="Stock" />
                        <Checkbox
                            ref={accountsReportsCheckbox}
                            label="Accounts" />
                        <Checkbox
                            ref={incomeStatementCheckbox}
                            label="Income Statement" />
                        <Checkbox
                            ref={othersReportsCheckbox}
                            label="Others" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersComponent;