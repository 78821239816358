import { post, uploadImage } from 'utilities/DB.js';

export const apiLoadCustomers = (params) => {
    return new Promise((resolve, reject) => {
        post("api/customers/loadCustomers", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertCustomer = (params) => {
    return new Promise((resolve, reject) => {
        post("api/customers/insertCustomer", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateCustomer = (params) => {
    return new Promise((resolve, reject) => {
        post("api/customers/updateCustomer", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteCustomer = (customerId) => {
    const params = {
        id: customerId
    }
    return new Promise((resolve, reject) => {
        post("api/customers/deleteCustomer", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetCustomer = (customerId) => {
    const params = {
        id: customerId
    }
    return new Promise((resolve, reject) => {
        post("api/customers/getCustomer", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsCustomerExists = (customerName) => {
    const params = {
        accountName: customerName
    }
    return new Promise((resolve, reject) => {
        post("api/customers/isCustomerExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/customers/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
