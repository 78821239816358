import React, { useState, forwardRef, useImperativeHandle } from 'react';

const Span = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const style = props.style;
    const text = props.text ? props.text : "";

    // Variables
    const [value, setValue] = useState(text);
    let current = value;
    useImperativeHandle(ref, () => {
        return {
            getText: () => current,
            setText: setLabelValue
        };
    });

    const setLabelValue = (val) => {
        current = val;
        setValue(val);
    }

    return (
        <>
            <span className={className} style={style}>{value}</span>
        </>
    )
});

export default Span;