import React, { forwardRef, useImperativeHandle } from 'react';
import { useRef } from 'react';

const ComboBox = forwardRef((props, ref) => {
    // Props
    const label = props.label;
    const className = props.className;
    const data = props.data;
    const onChange = props.onChange ? props.onChange : () => { };
    const style = props.style;

    // Variables
    let reference = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: () => { return Number(reference.current.value) },
            setValue: (val) => { reference.current.value = Number(val); onChange(Number(val), ''); },
            setValue2: setValue, //(val) => { reference.current.value = Number(val); onChange(Number(val), ''); },
            getText: () => { return reference.current[reference.current.selectedIndex].text },
            enable: () => { reference.current.disabled = false },
            disable: () => { reference.current.disabled = true },
        };
    });
    const setValue = (val) => {
        reference.current.value = Number(val);
        setTimeout(() => {
            onChange(Number(val), '');
        }, 50);
    }
    return (
        <>
            <div className={`cb ${className}`} style={style}>
                <div><label>{label}</label></div>
                <select
                    onChange={(e) => { onChange(Number(e.target.value), e.target[e.target.selectedIndex].text) }} ref={reference}>
                    {data.map((e) => {
                        return (
                            <option key={e.id} value={e.id}>{e.name}</option>
                        );
                    })}
                </select>
            </div>
        </>
    )
});

export default ComboBox;