import { post, uploadImage } from 'utilities/DB.js';

export const apiLoadVendors = (params) => {
    return new Promise((resolve, reject) => {
        post("api/vendors/loadVendors", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertVendor = (params) => {
    return new Promise((resolve, reject) => {
        post("api/vendors/insertVendor", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateVendor = (params) => {
    return new Promise((resolve, reject) => {
        post("api/vendors/updateVendor", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteVendor = (vendorId) => {
    const params = {
        id: vendorId
    }
    return new Promise((resolve, reject) => {
        post("api/vendors/deleteVendor", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetVendor = (vendorId) => {
    const params = {
        id: vendorId
    }
    return new Promise((resolve, reject) => {
        post("api/vendors/getVendor", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsVendorExists = (vendorName) => {
    const params = {
        accountName: vendorName
    }
    return new Promise((resolve, reject) => {
        post("api/vendors/isVendorExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/vendors/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
