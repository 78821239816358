import { createSlice } from "@reduxjs/toolkit";

const reportsReducer = createSlice({
    name: 'reports',
    initialState: {
        items: [],
        categories: [],
        subCategories: [],
        customers: [],
        vendors: [],
        suppliers: [],
        fixedAccounts: [],
        users: [],
        expenses: [],
        subExpenses: [],
        banks: [],
        subBanks: [],
        accountCategories: [],
        warehouses: [],
    },
    reducers: {
        reportsReducerLoadData: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.items = action.payload.items;
            state.categories = temp.concat(action.payload.categories);
            state.subCategories = temp.concat(action.payload.subCategories);
            state.customers = temp.concat(action.payload.customers);
            state.vendors = temp.concat(action.payload.vendors);
            state.suppliers = temp.concat(action.payload.suppliers);
            state.fixedAccounts = temp.concat(action.payload.fixedAccounts);
            state.users = temp.concat(action.payload.users);

            state.expenses = temp.concat(action.payload.expenses);
            state.subExpenses = temp.concat(action.payload.subExpenses);
            state.banks = temp.concat(action.payload.banks);
            state.subBanks = temp.concat(action.payload.subBanks);

            state.accountCategories = temp.concat(action.payload.accountCategories);
            state.warehouses = temp.concat(action.payload.warehouses);
        },
        reportsReducerLoadFixedAccounts: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.fixedAccounts = temp.concat(action.payload.fixedAccounts);
        },
    }
})

export const { 
    reportsReducerLoadData, 
    reportsReducerLoadFixedAccounts,
} = reportsReducer.actions;

export default reportsReducer.reducer;