import React from "react";

const ReportA4Style = () => {
    return (
        <>
            <div>
                <style>
                    {`@media print {
                                @page{
                                    size: A4 portrait;
                                    margin: 6mm;
                                }
                            }
                        `}
                </style>
            </div>
        </>
    )
}

export default ReportA4Style;