import { post } from 'utilities/DB.js';

export const apiLoadLinkedItems = (params) => {
    return new Promise((resolve, reject) => {
        post("api/linkedItems/loadLinkedItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertLinkedItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/linkedItems/insertLinkedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateLinkedItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/linkedItems/updateLinkedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteLinkedItem = (linkedItemId) => {
    const params = {
        id: linkedItemId
    }
    return new Promise((resolve, reject) => {
        post("api/linkedItems/deleteLinkedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetLinkedItem = (linkedItemId) => {
    const params = {
        id: linkedItemId
    }
    return new Promise((resolve, reject) => {
        post("api/linkedItems/getLinkedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsLinkedItemExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/linkedItems/isLinkedItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
