import { post } from 'utilities/DB.js';

export const apiLoadBillWiseReport = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/billWiseReport", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadItemsReport = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/itemsReport", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadBriefReport = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/briefReport", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadBranchesReport = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/branchesReport", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadChartDaily = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/chartDaily", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadChartWeekly = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/chartWeekly", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadChartMonthly = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/chartMonthly", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadChartYearly = (params) => {
    return new Promise((resolve, reject) => {
        post("api/profitReports/chartYearly", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
