/* #region Imports */
import AutoCompleteTextboxSales from "components/tools/AutoCompleteTextboxSales";
import ComboBox from "components/tools/ComboBox";
import Table from "components/tools/Table";
import Textbox from "components/tools/Textbox";
import ThinButton from "components/tools/ThinButton";
import BankCBMOP from "components/tools/dropdowns/BankCBMOP";
import SubBankCBMOP from "components/tools/dropdowns/SubBankCBMOP";
import React, { useEffect, useState, useRef, } from "react";
import 'style/sales/sales.css';
import DateTimePicker from "components/tools/DateTimePicker";
import TextboxCB from "components/tools/TextboxCB";
import Checkbox from "components/tools/Checkbox";
import CustomerCB from "components/tools/dropdowns/CustomerCB";
import { apiDeleteSales, apiGetNextId, apiGetSales, apiInsertSales, apiLoadPendingBills, apiTempClose, apiUpdateSales } from "api/sales/SalesApi";
import { apiAddPurchaseOrder, apiDeleteSalesItem, apiGetSalesItem, apiInsertSalesItem, apiIsBarcodeExists, apiLoadSalesItems, apiUpdateSalesItem } from "api/sales/SalesItemsApi";
import { addDays, concatDateTime, convertToInt, convertToNumber, getDiffDays, printLanguages, printSizes } from "utilities/Utils";
import { apiGetBalance } from "api/accounts/AccountsApi";
import PTag from "components/tools/PTag";
import EntriesComponent from "components/accounts/EntriesComponent";
import SearchItemsComponent from "components/items/SearchItemsComponent";
import Span from "components/tools/Span";
import PendingBillsComponent from "components/sales/PendingBillsComponent";
import PostedBillsComponent from "components/sales/PostedBillsComponent";
import { useSelector } from 'react-redux';
import dateFormat from "dateformat";
import { useReactToPrint } from "react-to-print";
import ThermalEnglish from "./bills/ThermalEnglish";
import PrintSizesCB from "components/tools/dropdowns/PrintSizesCB";
import PrintLanguagesCB from "components/tools/dropdowns/PrintLanguagesCB";
import ThermalUrdu from "./bills/ThermalUrdu";
import A4English from "./bills/A4English";
import A4Urdu from "./bills/A4Urdu";
import A5English from "./bills/A5English";
import A5Urdu from "./bills/A5Urdu";
import SuppliersCB from "components/tools/dropdowns/SuppliersCB";
import MarginComponent from "./MarginComponent";
import GeneratePackageComponent from "./GeneratePackageComponent";
import UserComponent from "components/main/UserComponent";
import { getClientId } from "utilities/DB";
/* #endregion */

const SalesComponent = (props) => {

    /* #region Variables */
    const formId = "salesForm";
    const isActive = useRef(true);
    const mainActiveTab = useRef(false);
    const settings = useSelector(state => state.main.settings);
    const qtyChangeableGlobal = useRef(settings.qtyChangeable);
    const userRights = useSelector(state => state.main.userRights);
    const user = useSelector(state => state.main.user);

    const invoiceId = useRef(0);
    const isPendingBill = useRef(false);
    const isPostedBill = useRef(false);

    // Sales
    const customerCB = useRef();
    const balanceLB = useRef(0);
    const balanceLabel = useRef("(R)");
    const grandTotalLB = useRef(0);
    const grandTotal = useRef(0);
    const grandCost = useRef(0);
    const grandProfit = useRef(0);
    const totalItems = useRef();
    const paymentTB = useRef();
    const changeTB = useRef();
    const mopCB = useRef();
    const bankCB = useRef();
    const subBankCB = useRef();
    const narationTB = useRef();
    const customerNameTB = useRef();
    const supplierCB = useRef();
    const salesOnCashRB = useRef();
    const salesOnCreditRB = useRef();
    const salesTypeSalesRB = useRef();
    const salesTypeReturnRB = useRef();
    const salesTypeRejectionRB = useRef();
    const isRetailCheckbox = useRef();
    const dateTB = useRef();
    const dueDateTB = useRef();
    const sDiscTB = useRef();
    const sTotalDiscLB = useRef(0);

    // Sales Items
    const itemnameTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const rateTB = useRef();
    const crtnRateTB = useRef();
    const oldRateTB = useRef();
    const oldCrtnRateTB = useRef();
    const discTB = useRef();
    const isDiscRsPerCB = useRef();
    const calculatedDiscLB = useRef(0);
    const totalDisc = useRef(0);
    const totalCost = useRef(0);
    const profit = useRef(0);
    const totalTB = useRef(0);
    const currentStockPcsLB = useRef();
    const currentStockCrtnLB = useRef();

    const usernameLB = useRef();
    const userTypeLB = useRef();
    const dateLB = useRef();
    const timeLB = useRef();
    let dateTimeInterval = null;
    const printCheckbox = useRef();
    const printSizeCB = useRef();
    const printLanguageCB = useRef();

    const tableRef = useRef();
    const pendingBillsTableRef = useRef();
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);
    const formDataS = useRef(null);
    const account = useRef(null);

    // Payment Calculator
    const paymentCalculatorTB = useRef();
    const paymentTotalLB = useRef(0);
    const [payments, setPayments] = useState([]);


    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const [totalQty, setTotalQty] = useState(0);
    const [totalCrtn, setTotalCrtn] = useState(0);
    const [costLabel, setCostLabel] = useState(0);
    const [activeComponent, setActiveComponent] = useState(null);
    const [activeBill, setActiveBill] = useState(null);

    // Modals
    const pendingBillsFormRef = useRef(null);
    const postedBillsFormRef = useRef(null);
    const entriesFormRef = useRef(null);
    const searchItemsFormRef = useRef(null);

    const billRef = useRef();
    const [billData, setBillData] = useState([]);

    const ratesCB = useRef();
    const [rates, setRates] = useState([]);

    const columnsC = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '241px' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '98px' } },
    ]);

    const columns = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '387px' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Disc', row: "disc", sortBy: "number", style: { minWidth: '73px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '98px' } },
    ]);

    const pendingBillColumns = useRef([
        { column: 'Bill No.', row: "id", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Customer', row: "customerName", sortBy: "string", style: { width: '100%' } },
    ]);


    /* #endregion */

    /* #region Methods */
    /* #region Sales Items */
    const formLoad = () => {
        loadSettings();
        reloadData();

        itemnameTB.current.focus();
    }
    const loadSettings = () => {
        isRetailCheckbox.current.setChecked(settings.isDefaultRateRetail);
        printSizeCB.current.setValue(settings.printSize);
        printLanguageCB.current.setValue(settings.printLanguage);
        sDiscTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);

        if (!userRights.changeRates) {
            rateTB.current.disable();
            crtnRateTB.current.disable();
        }

        if (!userRights.discount) {
            discTB.current.disable();
        }

        if (!userRights.salesReturn) {
            salesTypeReturnRB.current.disabled = true;
            salesTypeRejectionRB.current.disabled = true;
        }

        usernameLB.current.setText(user.username);
        userTypeLB.current.setText(user.userTypeDescription);
        if (user.imageUrl !== '')
            document.getElementById("userImageBox").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${user.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("userImageBox").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
        if (dateLB.current) {
            dateTimeInterval = setInterval(() => {
                try {
                    if (dateLB.current) {
                        const date = dateFormat(new Date(), "dd/mm/yyyy");
                        const time = dateFormat(new Date(), "h:MM:ss TT");

                        dateLB.current.setText(date);
                        timeLB.current.setText(time);
                    }
                } catch (err) {

                }
            }, 1000);
        }
    }
    const reloadData = () => {
        apiGetNextId().then((nextId) => {
            invoiceId.current = Number(nextId);
            loadData();
            loadPendingBills();
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadSalesItems({
            salesId: invoiceId.current,
            isPostedBill: isPostedBill.current,
            isPendingBill: isPendingBill.current,
        }).then((result) => {
            try {
                grandTotalLB.current.setText(result.total.grandTotal);
                grandTotal.current = result.total.grandTotal;
                grandCost.current = result.total.grandCost;
                grandProfit.current = result.total.grandProfit;

                totalItems.current.setText(result.total.totalItems);
                setTotalQty(result.total.totalQty);
                setTotalCrtn(result.total.totalCrtn);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);

                onDiscTextChange();
                onPaymentTBChange();
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();
        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateSalesItem(data)
                    .then((result) => {
                        inserted.current = true;
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSalesItem(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        // toast.current.show("Item saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSalesItem({
                id: tempId,
                isPostedBill: isPostedBill.current
            })
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        // if (window.confirm("Are you sure to delete this item?")) {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            apiDeleteSalesItem({
                id: Number(row['id']),
                isPostedBill: isPostedBill.current
            })
                .then((result) => {
                    toast.current.show("Item deleted successfully.", "s");
                    loadData();
                    // clearTextboxes();
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
        // };
    }
    const clearTextboxes = () => {
        itemnameTB.current.setText("");
        setCostLabel("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");
        rateTB.current.setText("");
        oldRateTB.current.setText("");
        crtnRateTB.current.setText("");
        oldCrtnRateTB.current.setText("");
        discTB.current.setText("");
        calculatedDiscLB.current.setText(0);
        isDiscRsPerCB.current.setValue(true ? 0 : 1);
        totalTB.current.setText("");

        currentStockPcsLB.current.setText("");
        currentStockCrtnLB.current.setText("");
        setRates([]);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        itemnameTB.current.focus();
    }
    const setFormData = (item) => {
        formData.current = item;

        itemnameTB.current.setText(item.itemname);
        setCostLabel(item.cost);
        qtyTB.current.setText(item.qty);
        crtnTB.current.setText(item.crtn);
        rateTB.current.setText(item.rate);
        crtnRateTB.current.setText(item.crtnRate);
        discTB.current.setText(item.disc);
        isDiscRsPerCB.current.setValue(item.isDiscRsPer ? 1 : 0);
        totalDisc.current = item.totalDisc;
        totalTB.current.setText(item.total);

        if (settings.fourRateSystem) {
            setRates([
                { id: 0, name: item.retail },
                { id: 1, name: item.wholesale },
                { id: 2, name: item.rate3 },
                { id: 3, name: item.rate4 },
            ]);
        } else {
            setRates([
                { id: 0, name: item.retail },
                { id: 1, name: item.wholesale },
            ]);
        }

        // Show item stock in label
        if (settings.showItemStock) {
            currentStockPcsLB.current.setText(formData.current.stockPcs);
            currentStockCrtnLB.current.setText(formData.current.stockCrtn);

            // let currentStock = formData.current.stockPcs + (formData.current.stockCrtn * formData.current.crtnSize);

            // const soldStock = formData.current.qty + (formData.current.crtn * formData.current.crtnSize);
            // currentStock += soldStock;

            // const stockPcs = getStockPcs(currentStock, formData.current.crtnSize);
            // const stockCrtn = getStockCrtn(currentStock, formData.current.crtnSize);

            // currentStockPcsLB.current.setText(stockPcs);
            // currentStockCrtnLB.current.setText(stockCrtn);
        }

        qtyTB.current.focus();
    }
    const getFormData = () => {
        const item = {
            itemId: formData.current.itemId,
            salesId: invoiceId.current,
            itemname: itemnameTB.current.getText().trim(),
            urduname: formData.current.urduname,
            cost: convertToNumber(formData.current.cost),
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToInt(crtnTB.current.getText()),
            rate: convertToNumber(rateTB.current.getText()),
            crtnRate: convertToNumber(crtnRateTB.current.getText()),
            retail: convertToNumber(formData.current.retail),
            wholesale: convertToNumber(formData.current.wholesale),
            rate3: convertToNumber(formData.current.rate3),
            rate4: convertToNumber(formData.current.rate4),
            disc: convertToNumber(discTB.current.getText()),
            isDiscRsPer: isDiscRsPerCB.current.getValue() === 1 ? true : false,
            calculatedDisc: convertToNumber(calculatedDiscLB.current.getText()),
            totalDisc: totalDisc.current,
            total: convertToNumber(totalTB.current.getText()),

            crtnSize: formData.current.crtnSize,
            totalCost: totalCost.current,
            profit: profit.current,
            isRetail: isRetailCheckbox.current.isChecked(),

            isPostedBill: isPostedBill.current,
            // isRepeatable: formData.current.isRepeatable,
            // isExists: formData.current.isExists,
        }

        return item;
    }
    const showDataIntoTextboxes = (item) => {
        formData.current = item;
        formData.current.itemId = item.id;

        // Showing data into textboxes
        itemnameTB.current.setText(item.itemname);
        setCostLabel(item.cost);

        // Rates
        crtnRateTB.current.setText(item.crtnRate);
        if (settings.fourRateSystem) {
            setRates([
                { id: 0, name: item.retail },
                { id: 1, name: item.wholesale },
                { id: 2, name: item.rate3 },
                { id: 3, name: item.rate4 },
            ]);
        } else {
            setRates([
                { id: 0, name: item.retail },
                { id: 1, name: item.wholesale },
            ]);
        }
        if (settings.fourRateSystem) {
            rateTB.current.setText(item.retail);
        } else {
            if (isRetailCheckbox.current.isChecked()) {
                rateTB.current.setText(item.retail);
            } else {
                rateTB.current.setText(item.wholesale);
            }
        }

        // Discount
        const customerId = customerCB.current.getValue();
        if (customerId !== 0 && account.current !== null) {
            discTB.current.setText(account.current.disc);
            isDiscRsPerCB.current.setValue(account.current.isDiscRsPer ? 1 : 0);
        } else {
            discTB.current.setText(item.disc);
            isDiscRsPerCB.current.setValue(item.isDiscRsPer ? 1 : 0);
        }

        // Enable or Disable Boxes according to crtnSize
        if (item.crtnSize === 0) {
            qtyTB.current.enable();
            crtnTB.current.disable();

            rateTB.current.enable();
            crtnRateTB.current.disable();
        } else if (item.crtnSize === 1) {
            qtyTB.current.disable();
            crtnTB.current.enable();

            rateTB.current.disable();
            crtnRateTB.current.enable();
        } else if (item.crtnSize > 1) {
            qtyTB.current.enable();
            crtnTB.current.enable();

            rateTB.current.enable();
            crtnRateTB.current.enable();
        }

        // Show item stock in label
        if (settings.showItemStock) {
            currentStockPcsLB.current.setText(item.stockPcs);
            currentStockCrtnLB.current.setText(item.stockCrtn);
        }

        // Code for Lock Item
        if (settings.saleCartons) {
            if (item.lockPcs && item.lockCrtn) {
                toast.current.show("Item is locked, you cannot sale this item.", "i");
                clearTextboxes();
                return;
            }
        } else {
            if (item.lockPcs) {
                toast.current.show("Item is locked, you cannot sale this item.", "i");
                clearTextboxes();
                return;
            }
        }

        // Code for SaleUnderStock
        const stock = Number(item.stockPcs) + (Number(item.stockCrtn) * Number(item.crtnSize));
        if (!saleUnderStock() && stock <= 0 && salesTypeSalesRB.current.checked) {
            toast.current.show("Item stock not available.", "i");
            clearTextboxes();
            return;
        }

        // Code for Stock Warning
        if (settings.stockWarning && Number(item.stockWarningMin) > 0 && stock <= Number(item.stockWarningMin)) {
            if (!item.isExistsInOrder) {
                if (window.confirm("Item stock about to end. Add to Purchase Order?")) {
                    apiAddPurchaseOrder(item.id).then((result) => {
                        // alert("Item added in order");
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    })
                }
            }
        }

        // Code for qtyChangeable
        if (qtyChangeable()) {
            if (settings.showCustomerLastRate && customerCB.current.getValue() !== 0) {
                oldRateTB.current.setText(item.lastRate);
                oldCrtnRateTB.current.setText(item.lastCrtnRate);
            }

            if (item.crtnSize === 1 || item.isCrtnBarcode)
                crtnTB.current.focus();
            else
                qtyTB.current.focus();
        } else {
            if (item.crtnSize === 1 || item.isCrtnBarcode) {
                crtnTB.current.setText("1");
            } else {
                if (item.lessItem) {
                    qtyTB.current.setText("-1");
                } else {
                    qtyTB.current.setText("1");
                }
            }

            // Wait until total updated
            setTimeout(() => {
                insertOrUpdateData();
            }, 50);
        }

    }
    const validation = () => {
        if (formData.current === null)
            return;

        if (invoiceId.current === 0) {
            apiGetNextId().then((nextId) => {
                invoiceId.current = Number(nextId);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });

            toast.current.show("Try again", "e");
            return false;
        }

        const itemname = itemnameTB.current.getText().trim();
        const qty = convertToNumber(qtyTB.current.getText());
        const crtn = convertToInt(crtnTB.current.getText());
        const crtnSize = Number(formData.current.crtnSize);
        const total = convertToNumber(totalTB.current.getText());

        if (itemname === '') {
            toast.current.show("Itemname cannot be empty", "i");
            itemnameTB.current.focus();
            return false;
        }

        if (total === 0)
            return false;

        if (formData.current !== null && settings.itemExistsInSalesWarning && !isUpdate.current && formData.current.isExists) {
            if (!window.confirm("Item already exists in bill, You want to add?")) {
                return false;
            }
        }

        // Code for Cost Warning
        const totalQty = (qty + (crtn * crtnSize));
        if (settings.costWarning && totalQty > 0) {
            const tCost = formData.current.cost * totalQty;
            if (tCost > total) {
                if (!window.confirm("Item cost is more than sale. You want to add?"))
                    return false;
            }
        }

        // Code for Item Expiry
        if (formData.current.expirable) {
            const days = getDiffDays(formData.current.expiry, new Date());
            if (days <= 0) {
                if (!window.confirm(("Item expired. You want to add?")))
                    return false;
            }
        }

        // Code for SaleUnderStock
        const sellingStock = qty + (crtn * crtnSize);
        let currentStock = formData.current.stockPcs + (formData.current.stockCrtn * crtnSize);
        if (!saleUnderStock() && salesTypeSalesRB.current.checked) {

            // if (isUpdate.current) {
            //     const soldStock = formData.current.qty + (formData.current.crtn * formData.current.crtnSize);
            //     currentStock += soldStock;
            // }

            const diff = currentStock - sellingStock;
            if (diff < 0) {
                toast.current.show(`Available Stock=${currentStock}, You cannot sell more than ${currentStock}`, "w");
                return false;
            }
        }

        // Code for Sale Old Stock First
        const newStock = formData.current.newStockPcs + (formData.current.newStockCrtn * formData.current.newCrtnSize);
        if (newStock > 0) {
            let availableStock = currentStock - newStock;

            if (isUpdate.current) {
                const soldStock = formData.current.qty + (formData.current.crtn * formData.current.crtnSize);
                availableStock += soldStock;
            }

            if (sellingStock > availableStock) {
                toast.current.show(`Old Stock = ${availableStock}, Update New Rates to Continue...`, "w");
                return false;
            }
        }

        // If all ok
        return true;
    }
    const saleUnderStock = () => {
        if (!settings.saleUnderStock) // when saleUnderStock false we cannot sale item;
            return false;
        else {
            if (formData.current.saleUnderStock)
                return true;
            else
                return false;
        }
    }
    const qtyChangeable = () => {
        if (formData.current.qtyChangeable)
            return true;

        if (qtyChangeableGlobal.current)
            return true;

        return false;
        // if (qtyChangeableGlobal.current || settings.qtyChangeable || formData.current.qtyChangeable)
        //     return true;
        // else
        //     return false;
    }
    const CalculateGramRateAndInsertItem = () => {
        const gramsRate = convertToNumber(qtyTB.current.getText().replace("=", "").replace("/", ""));

        if (gramsRate > 0) {
            const rate = convertToNumber(rateTB.current.getText());
            qtyTB.current.setText((gramsRate / rate).toFixed(3));

            setTimeout(() => {
                insertOrUpdateData();
            }, 50);
        }
    }
    // const onPackageGenerated = ()=>{
    //     loadData()
    // }
    /* #endregion */

    /* #region Sales */
    const loadPendingBills = () => {
        pendingBillsTableRef.current.setLoading(true);
        apiLoadPendingBills().then((result) => {
            pendingBillsTableRef.current.setData([...result.rows]);
            pendingBillsTableRef.current.setLoading(false);
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const inserted1 = useRef(true);
    const insertOrUpdateSales = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationS();
        if (isValid) {
            if (isPostedBill.current) {
                const data = getFormDataS();
                apiUpdateSales(data)
                    .then((result) => {
                        inserted1.current = true;
                        // toast.current.show("Bill updated successfully.", "s");
                        clearTextboxes();
                        clearTextboxesS();
                        reloadData();

                        if (printCheckbox.current.isChecked()) {
                            // setBillData([...result]);
                            checkAndPrintBill(result);
                        }
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSales(getFormDataS())
                    .then((result) => {
                        inserted1.current = true;
                        // toast.current.show("Bill saved successfully.", "s");
                        clearTextboxes();
                        clearTextboxesS();
                        reloadData();

                        if (printCheckbox.current.isChecked()) {
                            // setBillData([...result]);
                            checkAndPrintBill(result);
                        }
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editSales = () => {
        apiGetSales({
            id: invoiceId.current,
            isPostedBill: isPostedBill.current
        }).then((result) => {
            setFormDataS(result);
            loadData();
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        })
    }
    const clearTextboxesS = () => {
        customerCB.current.setValue(0);
        balanceLB.current.setText("0");
        balanceLabel.current.setText("(R)");
        salesOnCashRB.current.checked = true;
        salesTypeSalesRB.current.checked = true;
        mopCB.current.setValue(0);
        bankCB.current.setValue(0);
        subBankCB.current.setValue(0);
        dateTB.current.setText(new Date());
        dueDateTB.current.setText(getDueDate());
        narationTB.current.setText("");
        customerNameTB.current.setText("");
        supplierCB.current.setValue(0);

        sDiscTB.current.setText("");
        sDiscTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        sTotalDiscLB.current.setText("0");
        paymentTB.current.setText("");
        paymentTB.current.enable();
        changeTB.current.setText("");
        grandTotalLB.current.setText("0");
        grandTotal.current = 0;
        grandCost.current = 0;
        grandProfit.current = 0;
        setPayments([]);

        invoiceId.current = 0;
        isPostedBill.current = false;
        isPendingBill.current = false;
        formDataS.current = null;
        account.current = null;

        salesTypeSalesRB.current.disabled = false;
        salesTypeReturnRB.current.disabled = false;
        salesTypeRejectionRB.current.disabled = false;

        itemnameTB.current.focus();
    }
    const setFormDataS = (sales) => {
        formDataS.current = sales;

        customerCB.current.setValue(sales.customerId);
        supplierCB.current.setValue(sales.supplierId);
        customerNameTB.current.setText(sales.customerName);

        // balanceLB.current.setText(sales.oldBalance);

        mopCB.current.setValue(sales.isMopCashBank === true ? 0 : 1);
        bankCB.current.setValue(sales.bankId);
        subBankCB.current.setValue(sales.subBankId);
        narationTB.current.setText(sales.naration);

        if (sales.salesOn === 1)
            salesOnCashRB.current.checked = true;
        else {
            salesOnCreditRB.current.checked = true;
            paymentTB.current.disable();
        }

        if (sales.salesType === 1)
            salesTypeSalesRB.current.checked = true;
        else if (sales.salesType === 2)
            salesTypeReturnRB.current.checked = true;
        else if (sales.salesType === 3)
            salesTypeRejectionRB.current.checked = true;

        if (isPostedBill.current)
            dateTB.current.setText(sales.date);
        else
            dateTB.current.setText(new Date());

        dueDateTB.current.setText(sales.dueDate);
        isRetailCheckbox.current.setChecked(sales.isRetail);
        sDiscTB.current.setText(sales.disc);
        sDiscTB.current.setCBValue(sales.isDiscRsPer === true ? 1 : 0);
        sTotalDiscLB.current.setText(sales.totalDisc);
        grandTotalLB.current.setText(sales.total);
        paymentTB.current.setText(sales.payment);
        changeTB.current.setText(sales.change);
        // grandTotal.current = sales.total;

        if (isPostedBill.current) {
            salesTypeSalesRB.current.disabled = true;
            salesTypeReturnRB.current.disabled = true;
            salesTypeRejectionRB.current.disabled = true;
        }

        itemnameTB.current.focus();
    }
    const getFormDataS = () => {
        const sales = {
            salesId: invoiceId.current,
            customerId: customerCB.current.getValue(),
            supplierId: supplierCB.current.getValue(),
            customerName: customerNameTB.current.getText(),
            isMopCashBank: mopCB.current.getValue() === 0 ? true : false,
            bankId: bankCB.current.getValue(),
            subBankId: subBankCB.current.getValue(),
            naration: narationTB.current.getText(),
            salesOn: salesOnCashRB.current.checked ? 1 : 2,
            salesType: salesTypeSalesRB.current.checked ? 1 : salesTypeReturnRB.current.checked ? 2 : 3,

            dueDate: dueDateTB.current.getText(),
            disc: convertToNumber(sDiscTB.current.getText()),
            isDiscRsPer: sDiscTB.current.getCBValue(),
            totalDisc: convertToNumber(sTotalDiscLB.current.getText()),
            payment: convertToNumber(paymentTB.current.getText()),
            change: convertToNumber(changeTB.current.getText()),
            total: convertToNumber(grandTotalLB.current.getText()),
            cost: grandCost.current,
            profit: grandProfit.current - convertToNumber(sTotalDiscLB.current.getText()),
            isRetail: isRetailCheckbox.current.isChecked(),
        }

        if (isPostedBill.current) {
            sales.date = concatDateTime(dateTB.current.getText(), formDataS.current.date);
        } else {
            sales.date = dateTB.current.getDateTime();
        }

        return sales;
    }
    const validationS = () => {
        if (invoiceId.current === 0) {
            apiGetNextId().then((nextId) => {
                invoiceId.current = Number(nextId);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });

            toast.current.show("Try again", "e");
            return false;
        }

        if (convertToNumber(grandTotalLB.current.getText()) === 0) {
            toast.current.show("Nothing to post", "i");
            return false;
        }

        if (salesOnCreditRB.current.checked) {
            if (customerCB.current.getValue() === 0) {
                toast.current.show("Please Select Customer", "i");
                return false;
            }
        }

        if (mopCB.current.getValue() === 1) {
            if (bankCB.current.getValue() === 0) {
                toast.current.show("Please Select Bank", "i");
                return false;
            } else {
                if (subBankCB.current.getValue() === 0) {
                    toast.current.show("Please Select Bank Account", "i");
                    return false;
                }
            }
        }

        if (settings.isPaymentNecessary && salesOnCashRB.current.checked) {
            const payment = convertToInt(paymentTB.current.getText());
            const total = grandTotal.current;
            if (payment === 0) {
                toast.current.show("Please Enter Payment or Select Credit", "i");
                return false;
            }
            if (payment > 0 && payment < total) {
                toast.current.show("Less Payment Entered, Enter Full Payment", "i");
                return false;
            }
        }

        return true;
    }
    const showPendingBillsForm = () => {
        if (isPostedBill.current) {
            toast.current.show("Close current bill first", "i");
            return;
        }

        setActiveComponent('pending');
    }
    const showPostedBillsForm = () => {
        if (isPendingBill.current) {
            toast.current.show("Close current bill first", "i");
            return;
        }

        setActiveComponent('posted');
    }
    const showEntriesForm = () => {
        if (userRights.entry)
            setActiveComponent('entries');
    }
    const showSearchItemsForm = () => {
        setActiveComponent('search');
        // searchItemsFormRef.current.show();
    }
    const showMarginForm = () => {
        if (userRights.seeMargin) {
            if (tableRef.current.getData().length > 0)
                setActiveComponent('margin');
        }
    }
    const showPackagesForm = () => {
        if (isPostedBill.current || isPendingBill.current) {
            toast.current.show("Close current bill first", "i");
            return;
        }

        if (invoiceId.current === 0) {
            toast.current.show("Wait till invoice is loading...", "i");
            return;
        }

        setActiveComponent('packages');
    }
    const showUserForm = () => {
        setActiveComponent('user');
    }
    /* #endregion */

    const tempClosing = useRef(true);
    const tempClose = () => {
        if (!isPostedBill.current) {
            if (tableRef.current.getData().length > 0) {
                if (tempClosing.current === false)
                    return;
                else
                    tempClosing.current = false;

                const data = getFormDataS();
                data.isPendingBill = isPendingBill.current;

                apiTempClose(data).then((result) => {
                    clearTextboxes();
                    clearTextboxesS();
                    reloadData();
                    tempClosing.current = true;
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                    tempClosing.current = true;
                });
            }
        } else {
            toast.current.show("You cannot temp close posted bill", "i");
        }
    }
    const onEditPendingBill = (id) => {
        clearTextboxes();
        invoiceId.current = id;
        isPendingBill.current = true;
        isPostedBill.current = false;
        editSales();
    }
    const onEditPostedBill = (id, isRetail) => {
        // clearTextboxes();
        invoiceId.current = id;
        isPendingBill.current = false;
        isPostedBill.current = true;
        isRetailCheckbox.current.setChecked(isRetail);

        editSales();
    }
    const onSearchItemSelected = (item) => {
        onBarcodeTBKeyDown(item.barcode);
        // showDataIntoTextboxes(item);
    }
    const getDueDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        return date;
    }
    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                const dataDiv = document.getElementById("salesBillData");

                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    printBill(); // Recall untill populated completely
                    return;
                }

                // const ticketElement = document.getElementsByClassName("ticket")[0];
                // ticketElement.style.display = "block";

                // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(ticketElement, options);
                // await exporter.getPdf(options);

                // For Print to Printer
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            if (!settings.showBillPreview) {
                setBillData([]);
            }
            itemnameTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    const printDuplicateBill = (data) => {
        checkAndPrintBill(data);
    }
    const checkAndPrintBill = (data) => {
        if (settings.showBillPreview) {
            setBillData([]);

            setTimeout(() => {
                setBillData([...data]);
                printBill();
            }, 100);
        } else {
            setBillData([...data]);
            printBill();
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onEditBtnClick = () => {
        editData();
    }
    const onTempCloseBtnClick = (item) => {
        tempClose();
    }
    const onPendingBillsBtnClick = () => {
        showPendingBillsForm();
    }
    const onPostedBillsBtnClick = () => {
        showPostedBillsForm();
    }
    const onNewBtnClick = () => {
        if (isPostedBill.current) {
            insertOrUpdateSales()
        } else if (isPendingBill.current) {
            tempClose();
        } else if (window.confirm("Are you sure to delete this bill and make new?")) {
            apiDeleteSales({
                id: invoiceId.current,
                isPostedBill: false
            }).then((result) => {
                clearTextboxes();
                clearTextboxesS();
                reloadData();
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    const onEntryBtnClick = () => {
        showEntriesForm();
    }
    const onSearchItemBtnClick = () => {
        showSearchItemsForm();
    }
    const onMarginBtnClick = () => {
        showMarginForm();
    }
    const onPackagesBtnClick = () => {
        showPackagesForm();
    }
    const showButtonsPanel = () => {
        // const root = document.querySelector(":root")
        // root.style.setProperty("--mainColor", "red");


        const buttonsPanel = document.querySelector('#salesForm .buttonsPanel');
        const bottomPanel = document.querySelector('#salesForm .bottomPanel');
        buttonsPanel.classList.toggle('d-none');
        bottomPanel.classList.toggle('d-none');
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "sales")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteData()
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        } else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateSales();
        } else if (e.ctrlKey && e.key.toLowerCase() === "t") {
            e.preventDefault();
            tempClose();
        } else if (e.altKey && e.key.toLowerCase() === "t") {
            e.preventDefault();
            tempClose();
        } else if (e.ctrlKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            showPendingBillsForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            showPostedBillsForm();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            showEntriesForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "m") {
            e.preventDefault();
            onMarginBtnClick();
        } else if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            showPackagesForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            onNewBtnClick();
        } else if (e.altKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            onNewBtnClick();
        } else if (e.key.toLowerCase() === "f1") {
            e.preventDefault();
            showSearchItemsForm();
        } else if (e.key.toLowerCase() === "f6") {
            e.preventDefault();
            const element = document.getElementById("salesCostLabel");
            element.classList.toggle("invisible");
        } else if (e.altKey && e.key.toLowerCase() === "w") {
            e.preventDefault();
            isRetailCheckbox.current.setChecked(!isRetailCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printCheckbox.current.setChecked(!printCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "c") {
            e.preventDefault();
            qtyChangeableGlobal.current = !qtyChangeableGlobal.current;
        }
    }
    const onBarcodeTBKeyDown = async (barcode) => {
        if (barcode !== '') {
            try {
                let actualBarcode = barcode;

                if (barcode[0] === "+" || barcode[0] === "-") {
                    actualBarcode = barcode.slice(1);
                } else if (barcode[barcode.length - 1] === "+" || barcode[barcode.length - 1] === "-") {
                    actualBarcode = barcode.slice(0, barcode.length - 1);
                }

                let customerId = 0;
                if (settings.showCustomerLastRate && customerCB.current.getValue() !== 0) {
                    customerId = customerCB.current.getValue();
                }
                const result = await apiIsBarcodeExists({
                    salesId: invoiceId.current,
                    isPostedBill: isPostedBill.current,
                    customerId: customerId,
                    barcode: actualBarcode,
                });
                if (result.isExists) {
                    const item = result.data;

                    if (barcode[0] === "+" || barcode[barcode.length - 1] === "+") {
                        item.qtyChangeable = true;
                    }

                    if (barcode[0] === "-") {
                        item.lessItem = true;
                    }

                    showDataIntoTextboxes(item);
                } else {
                    alert("Barcode not exists");
                    itemnameTB.current.setText("");
                }
            } catch (err) {
                alert(err.message);
            }
        } else {
            paymentTB.current.focus();
        }
    }
    const onQtyTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            // const text = e.target.value;
            // if (isExpression(text)) {
            //     try {
            //         qtyTB.current.setText(evaluate(text));
            //     } catch (error) {

            //     }
            // } else {
            //     if (settings.saleCartons)
            //         crtnTB.current.focus();
            //     else {
            //         rateTB.current.focus();
            //     }
            // }

            if (qtyTB.current.getText() !== "" && (qtyTB.current.getText()[0] === '=' || qtyTB.current.getText()[0] === '/'))
                CalculateGramRateAndInsertItem();
            else {
                if (settings.saleCartons && crtnTB.current.isEnabled())
                    crtnTB.current.focus();
                else {
                    if (rateTB.current.isEnabled())
                        rateTB.current.focus();
                    else
                        insertOrUpdateData();
                }
            }
        }
    }
    const onCrtnTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            // const text = e.target.value;
            // if (isExpression(text)) {
            //     try {
            //         crtnTB.current.setText(evaluate(text));
            //     } catch (error) {

            //     }
            // } else {
            //     insertOrUpdateData();
            // }

            insertOrUpdateData();
        }
    }
    const onRateTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            // const text = e.target.value;
            // if (isExpression(text)) {
            //     try {
            //         rateTB.current.setText(evaluate(text));
            //     } catch (error) {

            //     }
            // } else {
            //     if (settings.saleCartons)
            //         crtnRateTB.current.focus();
            //     else {
            //         insertOrUpdateData();
            //     }
            // }

            insertOrUpdateData();
        }
        else if (e.key === "ArrowUp") {
            if (rates.length > 0) {
                if (settings.fourRateSystem) {
                    const current = ratesCB.current.getValue() - 1;
                    if (current >= 0)
                        ratesCB.current.setValue(current);
                } else {
                    ratesCB.current.setValue(0);
                }
            }
        }
        else if (e.key === "ArrowDown") {
            if (rates.length > 0) {
                if (settings.fourRateSystem) {
                    const current = ratesCB.current.getValue() + 1;
                    if (current < 4)
                        ratesCB.current.setValue(current);
                } else {
                    ratesCB.current.setValue(1);
                }
            }
        }
    }
    const onCrtnRateTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            // const text = e.target.value;
            // if (isExpression(text)) {
            //     try {
            //         rateTB.current.setText(evaluate(text));
            //     } catch (error) {

            //     }
            // } else {
            //     insertOrUpdateData();
            // }

            insertOrUpdateData();
        }
    }
    const onDiscTBKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            // const text = e.target.value;
            // if (isExpression(text)) {
            //     try {
            //         discTB.current.setText(evaluate(text));
            //     } catch (error) {

            //     }
            // } else {
            //     insertOrUpdateData();
            // }
            insertOrUpdateData();
        }
    }
    const onPaymentTBKeyDown = (e) => {
        if (e.key.toLowerCase() === "enter") {
            e.preventDefault();

            if (paymentTB.current.getText() === '') {
                itemnameTB.current.focus();
            } else {
                const payment = convertToNumber(paymentTB.current.getText());
                if (payment >= 0) {
                    insertOrUpdateSales();
                }
            }
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onCustomerCBChange = (e) => {
        const customerId = customerCB.current.getValue();
        if (customerId !== 0) {
            apiGetBalance(customerId)
                .then((result) => {
                    balanceLB.current.setText(Math.abs(result.balance));

                    if (result.balance === 0) {
                        balanceLabel.current.setText("(R)");
                    } else {
                        balanceLabel.current.setText(result.label);
                    }

                    account.current = result.data;

                    const dueDays = convertToInt(account.current.dueDays);
                    if (dueDays > 0) {
                        dueDateTB.current.setText(addDays(new Date(), dueDays));
                    } else {
                        dueDateTB.current.setText(addDays(new Date(), 7));
                    }

                    if (!settings.fourRateSystem) {
                        isRetailCheckbox.current.setChecked(account.current.isRetail);
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });

            customerNameTB.current.setText(customerCB.current.getText());
            if (settings.autoCreditSelect) {
                salesOnCreditRB.current.checked = true;
            }
            itemnameTB.current.focus();
        } else {
            customerNameTB.current.setText("");
            balanceLB.current.setText("0");
            balanceLabel.current.setText("(R)");
            salesOnCashRB.current.checked = true;
            dueDateTB.current.setText(addDays(new Date(), 7));
        }
    }
    const mopCBOnChange = (e) => {
        if (mopCB.current.getValue() === 0) {
            bankCB.current.setValue(0);
            subBankCB.current.setValue(0);
            bankCB.current.disable();
            subBankCB.current.disable();
        } else {
            bankCB.current.enable();
            subBankCB.current.enable();
        }
    }
    const bankCBOnChange = (e) => {
        if (bankCB.current.getValue() !== 0) {
            subBankCB.current.loadData(bankCB.current.getValue());
        }
    }
    const onTextChange = () => {
        if (formData.current) {
            const cost = convertToNumber(formData.current.cost);
            const qty = convertToNumber(qtyTB.current.getText());
            const crtn = convertToInt(crtnTB.current.getText());
            const rate = convertToNumber(rateTB.current.getText());
            const crtnRate = convertToNumber(crtnRateTB.current.getText());
            const crtnSize = convertToInt(formData.current.crtnSize);
            const disc = convertToNumber(discTB.current.getText());
            const isDiscRsPer = isDiscRsPerCB.current.getValue() === 1 ? true : false;

            let total = qty * rate;
            total += crtn * crtnRate;


            if (isDiscRsPer === true) {
                total -= (disc * qty);

                totalDisc.current = (disc * qty);
                if (disc > 0)
                    calculatedDiscLB.current.setText(disc);
                else
                    calculatedDiscLB.current.setText(0);
            } else {
                const tDisc = ((disc / 100) * rate) * qty;
                total -= tDisc;
                totalDisc.current = tDisc;

                if (disc > 0)
                    calculatedDiscLB.current.setText((disc / 100) * rate);
                else
                    calculatedDiscLB.current.setText(0);
            }

            let tCost = cost * (qty + (crtn * crtnSize));

            totalTB.current.setText(total);
            totalCost.current = tCost;
            profit.current = total - tCost;
        }
        else {
            calculatedDiscLB.current.setText(0);
        }
    }
    const onDiscTextChange = () => {
        const disc = convertToNumber(sDiscTB.current.getText());
        const isDiscRsPer = sDiscTB.current.getCBValue() === 1 ? true : false;

        let total = grandTotal.current;

        if (isDiscRsPer === true) {
            total -= disc;

            sTotalDiscLB.current.setText(disc.toFixed(2));
        } else {
            const totalDisc = (disc / 100) * total;
            total -= totalDisc;

            sTotalDiscLB.current.setText(totalDisc.toFixed(2));
        }

        grandTotalLB.current.setText(total.toFixed(0));
        onPaymentTBChange();
    }
    const onPaymentTBChange = () => {
        const payment = convertToNumber(paymentTB.current.getText());
        if (payment > 0) {
            const change = payment - (grandTotal.current - convertToNumber(sTotalDiscLB.current.getText()));
            changeTB.current.setText(change);
        } else {
            changeTB.current.setText(0);
        }
    }
    const onBarcodeNotFound = () => {
        clearTextboxes()
    }
    const onSalesTypeReturnChange = () => {
        if (!userRights.salesReturn && salesTypeReturnRB.current.checked) {
            salesTypeSalesRB.current.checked = true;
        }
    }
    const onSalesTypeRejectionChange = () => {
        if (!userRights.salesReturn && salesTypeRejectionRB.current.checked) {
            salesTypeSalesRB.current.checked = true;
        }
    }
    const onBillChange = (e) => {
        if (printSizeCB.current.getValue() === printSizes.thermal && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("thermalEnglish");
        else if (printSizeCB.current.getValue() === printSizes.thermal && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("thermalUrdu");
        else if (printSizeCB.current.getValue() === printSizes.a4 && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("a4English");
        else if (printSizeCB.current.getValue() === printSizes.a4 && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("a4Urdu");
        else if (printSizeCB.current.getValue() === printSizes.a5 && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("a5English");
        else if (printSizeCB.current.getValue() === printSizes.a5 && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("a5Urdu");
    }
    const onRetailCheckboxChange = (val) => {
        if (formData.current !== null && !settings.fourRateSystem) {
            if (isRetailCheckbox.current.isChecked()) {
                ratesCB.current.setValue(0);
            } else {
                ratesCB.current.setValue(1);
            }
        }
    }
    const onCreditRBChange = () => {
        if (salesOnCreditRB.current.checked && isPostedBill.current) {
            paymentTB.current.setText("");
            paymentTB.current.disable();
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
            clearInterval(dateTimeInterval);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        paymentTotalLB.current.setText(payments.length > 0 ? payments.reduce((a, b) => a + b) : 0);
        // eslint-disable-next-line
    }, [payments]);

    const isFirstTime = useRef(true);
    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "sales" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        } else {
            setActiveComponent(null);
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "pending":
                            return <PendingBillsComponent
                                ref={pendingBillsFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                loadData={loadPendingBills}
                                isActive={isActive}
                                printBill={printDuplicateBill}
                                setActiveComponent={setActiveComponent}
                                onEdit={onEditPendingBill}
                            />

                        case "posted":
                            return <PostedBillsComponent
                                ref={postedBillsFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                printBill={printDuplicateBill}
                                setActiveComponent={setActiveComponent}
                                onEdit={onEditPostedBill}
                            />
                        case "entries":
                            return <EntriesComponent
                                ref={entriesFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        case "margin":
                            return <MarginComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                items={tableRef.current.getData()}
                                data={{ profit: grandProfit.current, total: grandTotal.current, cost: grandCost.current }}
                                setActiveComponent={setActiveComponent}
                            />
                        case "packages":
                            return <GeneratePackageComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                invoiceId={invoiceId.current}
                                isActive={isActive}
                                onPackageGenerated={loadData}
                                setActiveComponent={setActiveComponent}
                            />
                        case "user":
                            return <UserComponent
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        case "search":
                            return <SearchItemsComponent
                                ref={searchItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onItemSelect={onSearchItemSelected}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} className="mainContainer containerWidth">
                <div className="mainLayout" style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Top Panel */}
                    <div className="topPanel">
                        {/* Top Left Panel */}
                        <div className="tableLayout" style={{ display: 'flex', flexDirection: 'column' }}>

                            <div className="topSmallBoxes">
                                <div className="barcodeBox">
                                    <p></p>
                                </div>
                                <div className="qtyBox">
                                    <PTag ref={currentStockPcsLB} />
                                </div>
                                <div className={`crtnBox ${settings.saleCartons ? "" : "d-none"}`}>
                                    <PTag ref={currentStockCrtnLB} />
                                </div>
                                <div className="rateBox">
                                    <PTag ref={oldRateTB} />
                                </div>
                                <div className={`crtnRateBox ${settings.saleCartons ? "" : "d-none"}`}>
                                    <PTag ref={oldCrtnRateTB} />
                                </div>
                                <div className="discBox">
                                    <ComboBox
                                        ref={isDiscRsPerCB}
                                        // label="Search By"
                                        data={[{ id: 0, name: '%' }, { id: 1, name: 'Rs' }]}
                                        onChange={onTextChange}
                                    />
                                </div>
                                <div className="totalBox">
                                    <p></p>
                                </div>
                            </div>

                            <div className="topBoxes">
                                <div className="inputBox barcodeBox">
                                    <AutoCompleteTextboxSales
                                        ref={itemnameTB}
                                        label=""
                                        tabIndex={3}
                                        onBarcodeNotFound={onBarcodeNotFound}
                                        // onKeyDown={onItemnameTBKeyDown}
                                        onEnter={onBarcodeTBKeyDown} />
                                </div>
                                <div className="inputBox qtyBox">
                                    <Textbox
                                        ref={qtyTB}
                                        label=""
                                        tabIndex={3}
                                        autoSelect={true}
                                        onChange={onTextChange}
                                        onKeyDown={onQtyTBKeyDown} />
                                </div>
                                <div className={`inputBox crtnBox ${settings.saleCartons ? "" : "d-none"}`}>
                                    <Textbox
                                        ref={crtnTB}
                                        label=""
                                        className={``}
                                        tabIndex={3}
                                        autoSelect={true}
                                        onChange={onTextChange}
                                        onKeyDown={onCrtnTBKeyDown} />
                                </div>
                                <div className="inputBox rateBox">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Textbox
                                            ref={rateTB}
                                            label=""
                                            tabIndex={3}
                                            autoSelect={true}
                                            onChange={onTextChange}
                                            onFocus={() => { if (!userRights.changeRates) { qtyTB.current.focus() } }}
                                            onKeyDown={onRateTBKeyDown} />
                                        <ComboBox
                                            ref={ratesCB}
                                            label=""
                                            data={rates}
                                            onChange={(val, text) => { rateTB.current.setText(ratesCB.current.getText()) }}
                                        />
                                    </div>
                                </div>
                                <div className={`inputBox crtnRateBox ${settings.saleCartons ? "" : "d-none"}`}>
                                    <Textbox
                                        ref={crtnRateTB}
                                        label=""
                                        tabIndex={3}
                                        autoSelect={true}
                                        onChange={onTextChange}
                                        onFocus={() => { if (!userRights.changeRates) { qtyTB.current.focus() } }}
                                        onKeyDown={onCrtnRateTBKeyDown} />
                                </div>
                                <div className="inputBox discBox">
                                    <Textbox
                                        ref={discTB}
                                        label=""
                                        tabIndex={3}
                                        autoSelect={true}
                                        onChange={onTextChange}
                                        onFocus={() => { if (!userRights.discount) { qtyTB.current.focus() } }}
                                        onKeyDown={onDiscTBKeyDown} />
                                </div>
                                <div className="inputBox totalBox">
                                    <Textbox
                                        ref={totalTB}
                                        label=""
                                        tabIndex={3}
                                        onFocus={() => { if (true) { qtyTB.current.focus() } }}
                                        onKeyDown={() => { }} />
                                </div>
                            </div>

                            <Table
                                ref={tableRef}
                                columns={settings.saleCartons ? columnsC.current : columns.current}
                                style={{ marginTop: '0px' }}
                                myTable='salesItemsTable'
                                isActive={isActive}
                                onDoubleClick={onEditBtnClick}
                                onEnterKeyDown={(data) => { }}
                                editBtn={{ visible: false, onClick: onEditBtnClick }}
                                deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                            {/* Total Panel */}
                            <div className="totalPanel">
                                <div className="panel">
                                    <div className="heading"><p>ITEMS</p></div>
                                    <div className="label"><PTag ref={totalItems} /></div>
                                </div>
                                <div className="panel">
                                    <div className="heading"><p>TOTAL</p></div>
                                    <div className="label label1"><PTag ref={grandTotalLB} /></div>
                                </div>
                                <div className="panel">
                                    <div className="heading"><p>PAYMENT</p></div>
                                    <div className="label">
                                        <Textbox
                                            style={{ border: "none" }}
                                            ref={paymentTB}
                                            label=""
                                            onChange={onPaymentTBChange}
                                            onKeyDown={onPaymentTBKeyDown}
                                            tabIndex={3} />
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="heading"><p>CHANGE</p></div>
                                    <div className="label label2"><PTag ref={changeTB} /></div>
                                </div>
                            </div>
                        </div>

                        {/* Top Right Panel */}
                        <div className="topRight">
                            {/* User Panel */}
                            <div className="userPanel">
                                <img id="userImageBox" src={`${process.env.REACT_APP_API_URL}/images/items/2.png`} alt="" onClick={showUserForm} style={{ cursor: 'pointer' }} />
                                <div className="user">
                                    <p className="username" onClick={showUserForm}><Span ref={usernameLB} style={{ cursor: 'pointer' }} /></p>
                                    <p className="userType"><Span ref={userTypeLB} /></p>
                                    <div className="timePanel">
                                        <p><Span ref={dateLB} /></p>
                                        <p><Span ref={timeLB} /></p>
                                    </div>
                                </div>
                            </div>
                            {/* Print Panel */}
                            <div className="printPanel">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* Checkbox */}
                                    <div className="dRow jcsa">
                                        <div style={{ alignSelf: 'start', flex: '1' }}>
                                            <Checkbox
                                                ref={printCheckbox}
                                                label="Print"
                                                checked={true}
                                                onChange={() => { }} />
                                        </div>
                                        <div style={{ alignSelf: 'start', flex: '1' }} className={`${settings.fourRateSystem ? "d-none" : "d-block"}`}>
                                            <Checkbox
                                                ref={isRetailCheckbox}
                                                label="Retail"
                                                onChange={onRetailCheckboxChange} />
                                        </div>
                                    </div>
                                    {/* ComboBox */}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
                                        <PrintSizesCB
                                            ref={printSizeCB}
                                            onChange={onBillChange} />

                                        <PrintLanguagesCB
                                            ref={printLanguageCB}
                                            className="defaultMarginLeft"
                                            onChange={onBillChange} />
                                    </div>
                                </div>
                            </div>
                            {/* Buttons */}
                            <div className="buttons thinButtons">
                                <div className="row1">
                                    <ThinButton
                                        label="Temp Close"
                                        onClick={onTempCloseBtnClick} />

                                    <ThinButton
                                        label="Pending Bills"
                                        onClick={onPendingBillsBtnClick}
                                        className="centerBtnMargin" />

                                    <ThinButton
                                        label="Posted Bills"
                                        onClick={onPostedBillsBtnClick} />
                                </div>
                                <div className="row1">
                                    <ThinButton
                                        label="Entry"
                                        onClick={onEntryBtnClick} />

                                    <ThinButton
                                        label="Search Item"
                                        onClick={onSearchItemBtnClick}
                                        className="centerBtnMargin" />

                                    <ThinButton
                                        label="New Bill"
                                        onClick={onNewBtnClick} />
                                </div>
                                <div className="row1">
                                    <ThinButton
                                        label="Margin"
                                        onClick={onMarginBtnClick} />

                                    <ThinButton
                                        label="Package"
                                        className="centerBtnMargin"
                                        onClick={onPackagesBtnClick} />

                                    <ThinButton
                                        label="Short Buttons"
                                        className=""
                                        onClick={showButtonsPanel} />
                                </div>

                            </div>
                            {/* Short Buttons */}
                            <div className="shortButtons d-none">
                                <h5>Nothing here...</h5>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Panel */}
                    <div className="bottom">
                        <div className="bottomPanel">
                            <div className="bottomLeft">
                                {/* Customer Panel */}
                                <div className="customerPanel">
                                    <div className="balanceGroup">
                                        <CustomerCB
                                            ref={customerCB}
                                            onChange={onCustomerCBChange} />

                                        <div className="balance">
                                            <p><b>Balance: </b><Span ref={balanceLB} text="0" /> <Span ref={balanceLabel} text="(R)" /></p>
                                        </div>
                                    </div>

                                    {/* Radio Group */}
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-2px' }}>
                                        <div className="radioGroup" style={{ width: '200px', marginTop: 'var(--defaultMargin)' }}>
                                            <div className="header">
                                                <p>Sales On</p>
                                            </div>
                                            <div className="radioBody">
                                                <div className="myRadio">
                                                    <div className="radioItem">
                                                        <input ref={salesOnCashRB} id="salesOnCashRB" className="form-check-input" type="radio" name="salesOn" defaultChecked onChange={() => { }} />
                                                        <label htmlFor="salesOnCashRB">Cash</label>
                                                    </div>
                                                    <div className="radioItem">
                                                        <input ref={salesOnCreditRB} id="salesOnCreditRB" className="form-check-input" type="radio" name="salesOn" onChange={onCreditRBChange} />
                                                        <label htmlFor="salesOnCreditRB">Credit</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="radioGroup defaultMarginLeft" style={{ width: '300px', marginTop: 'var(--defaultMargin)' }}>
                                            <div className="header">
                                                <p>Sales Type</p>
                                            </div>
                                            <div className="radioBody">
                                                <div className="myRadio">
                                                    <div className="radioItem">
                                                        <input ref={salesTypeSalesRB} id="salesTypeSalesRB" className="form-check-input" type="radio" name="salesType" defaultChecked onChange={() => { }} />
                                                        <label htmlFor="salesTypeSalesRB">Sales</label>
                                                    </div>
                                                    <div className="radioItem">
                                                        <input ref={salesTypeReturnRB} id="salesTypeReturnRB" className="form-check-input" type="radio" name="salesType" onChange={onSalesTypeReturnChange} />
                                                        <label htmlFor="salesTypeReturnRB">Return</label>
                                                    </div>
                                                    <div className="radioItem">
                                                        <input ref={salesTypeRejectionRB} id="salesTypeRejectionRB" className="form-check-input" type="radio" name="salesType" onChange={onSalesTypeRejectionChange} />
                                                        <label htmlFor="salesTypeRejectionRB">Rejection</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* MOP Group */}
                                    <div className="mopGroup" style={{ marginTop: 'var(--defaultMargin)' }}>
                                        <div style={{ display: 'flex', flexDirection: 'colums', flex: 1 }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="mop">
                                                    <p>M.O.P</p>
                                                </div>
                                                <div className="mopCB">
                                                    <ComboBox
                                                        ref={mopCB}
                                                        data={[{ id: 0, name: 'Cash' }, { id: 1, name: 'Bank' }]}
                                                        style={{ width: '100%' }}
                                                        onChange={mopCBOnChange} />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                <BankCBMOP
                                                    ref={bankCB}
                                                    style={{ flex: 'auto' }}
                                                    className={"cb1"}
                                                    onChange={bankCBOnChange} />
                                                <SubBankCBMOP
                                                    ref={subBankCB}
                                                    style={{ flex: 'auto' }}
                                                    className={"cb2"}
                                                    onChange={() => { }} />
                                            </div>

                                        </div>

                                    </div>

                                    <DateTimePicker
                                        ref={dueDateTB}
                                        label="Due Date"
                                        time={false}
                                        text={getDueDate()}
                                        tabIndex={100} />

                                    <Textbox
                                        ref={narationTB}
                                        label="Naration"
                                        tabIndex={3}
                                        onKeyDown={() => { }} />

                                    <SuppliersCB
                                        ref={supplierCB}
                                        onChange={() => { }} />

                                    <Textbox
                                        ref={customerNameTB}
                                        label="Customer Name"
                                        tabIndex={3}
                                        onKeyDown={() => { }} />

                                    <div className="">
                                        <div className="tableFooter">
                                            <p><b>Qty: </b>{totalQty}</p>
                                            <p style={{ marginLeft: '10px' }}><b>Crtn: </b>{totalCrtn}</p>
                                            <p id="salesCostLabel" className="invisible" style={{ marginLeft: '10px' }}><b>Cost: </b>{costLabel}</p>
                                            <PTag
                                                className="d-none"
                                                ref={calculatedDiscLB}
                                                style={{ marginLeft: '10px' }}>
                                            </PTag>
                                        </div>
                                    </div>
                                </div>

                                {/* Date Panel */}
                                <div className="datePanel">
                                    <div className={`${userRights.dateWiseSales ? "d-block" : "d-none"}`}>
                                        <DateTimePicker
                                            ref={dateTB}
                                            label="Date"
                                            time={false}
                                            tabIndex={100} />
                                    </div>
                                    <div className="paymentCalculator">
                                        <div className="header">
                                            <p>Payment Calculator</p>
                                        </div>
                                        <div className="body">
                                            <Textbox
                                                ref={paymentCalculatorTB}
                                                tabIndex={13}
                                                onKeyDown={(e) => {
                                                    if (e.key.toLowerCase() === "enter") {
                                                        e.preventDefault();
                                                        const payment = convertToInt(paymentCalculatorTB.current.getText());
                                                        if (payment !== 0) {
                                                            payments.push(payment);
                                                            setPayments([...payments]);

                                                            paymentCalculatorTB.current.setText("");
                                                        }
                                                    }
                                                }}
                                            />
                                            <div className="payments">
                                                {
                                                    payments.map((e, i) => {
                                                        return <div key={i}>
                                                            <p>{e}</p>
                                                            <p className="clear" onClick={() => {
                                                                payments.splice(i, 1);
                                                                setPayments([...payments]);
                                                            }}>X</p>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="total">
                                            <p><span style={{ fontWeight: 600 }}>Total: </span><Span ref={paymentTotalLB} /></p>
                                            <p className="paymentEnterBtn" style={{ fontWeight: 600 }} onClick={() => {
                                                paymentTB.current.setText(convertToInt(paymentTotalLB.current.getText()));
                                                paymentTB.current.focus();
                                            }}>Enter</p>
                                        </div>
                                    </div>

                                    <div className="discountGroup">
                                        <TextboxCB
                                            ref={sDiscTB}
                                            label="Discount"
                                            tabIndex={13}
                                            onChange={onDiscTextChange}
                                            onCBChange={onDiscTextChange}
                                        />
                                        <div className="balance">
                                            <PTag ref={sTotalDiscLB} text="0" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Bottom Right */}
                            <div className="bottomRight">
                                <h4 className='headingLabel' style={{ textAlign: 'center' }}>Pending Bills</h4>
                                <Table
                                    ref={pendingBillsTableRef}
                                    columns={pendingBillColumns.current}
                                    myTable='salesPendingBillsTable'
                                    isActive={isActive}
                                    style={{ marginTop: '-5px' }}
                                    onDoubleClick={(row) => {
                                        onEditPendingBill(row.id);
                                    }}
                                    autoSelectFirstRow={true}
                                    onEnterKeyDown={(data) => { alert(data['itemname']) }}
                                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                                    deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                            </div>
                        </div>
                        {/* Buttons Panel */}
                        <div className="buttonsPanel d-none">
                            <h1>Short buttons will be here soon...</h1>
                        </div>
                    </div>
                </div>

            </div >

            {(() => {
                switch (activeBill) {
                    case "thermalEnglish":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <ThermalEnglish ref={billRef} data={billData} />
                        </div>
                    case "thermalUrdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <ThermalUrdu ref={billRef} data={billData} />
                        </div>
                    case "a4English":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A4English ref={billRef} data={billData} />
                        </div>
                    case "a4Urdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A4Urdu ref={billRef} data={billData} />
                        </div>
                    case "a5English":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A5English ref={billRef} data={billData} />
                        </div>
                    case "a5Urdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A5Urdu ref={billRef} data={billData} />
                        </div>
                    default:
                        return <div></div>;
                }
            })()}

        </>
    )
};

export default SalesComponent;