import { post } from 'utilities/DB.js';

export const apiLoadLedger = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/ledger", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadExpenses = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/expenses", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadReceipts = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/receipts", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPayments = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/payments", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadDebtors = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/debtors", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadCreditors = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/creditors", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadCashAccount = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/cashAccount", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadIncomeStatement = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/incomeStatement", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadCustomersBalanceList = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountReports/customersBalanceList", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
