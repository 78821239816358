/* #region Imports */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import 'style/sales/generatePackage.css';
import { apiGeneratePackage, apiLoadPackages } from "api/items/PackagesApi";
import { apiLoadPackageItems } from "api/items/PackageItemsApi";
import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import TextboxNumber from 'components/tools/TextboxNumber';
import { convertToInt } from 'utilities/Utils';
import { useSelector } from 'react-redux';
/* #endregion Imports */

const GeneratePackageComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "generatePackageForm";
    const isActive = useRef(false);
    const user = useSelector(state => state.main.user);

    const packageId = useRef();
    const searchPackageTB = useRef();
    const searchPackageItemsTB = useRef();
    const numberOfPackages = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tablePRef = useRef();
    const tablePIRef = useRef();
    const [totalPackages, setTotalPackages] = useState(0);
    const [totalPackageItems, setTotalPackageItems] = useState(0);

    const columnsP = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Package Name', row: "packageName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Total', row: "total", sortBy: "number", style: { minWidth: '100px' } },
    ]);

    const columnsPI = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Package Item', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Rate', row: "rate", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadPackages = () => {
        if (tablePRef.current !== null)
            tablePRef.current.setLoading(true);

        apiLoadPackages({
            text: searchPackageTB.current.getText()
        }).then((result) => {
            try {
                if (isActive.current) {
                    setTotalPackages(result.total.totalPackages);
                    tablePRef.current.setData([...result.rows]);
                    tablePRef.current.setLoading(false);
                }
            } catch (error) {
                tablePRef.current.setLoading(false);
            }
        }).catch((err) => {
            if (isActive.current) {
                messageBox.current.show(err.message, "Error", "e");
                tablePRef.current.setLoading(false);
            }
        });
    }
    const loadPackageItems = () => {
        if (packageId.current !== 0) {
            if (tablePIRef.current !== null)
                tablePIRef.current.setLoading(true);

            apiLoadPackageItems({
                text: searchPackageItemsTB.current.getText(),
                packageId: packageId.current,
            }).then((result) => {
                try {
                    if (isActive.current) {
                        setTotalPackageItems(result.total.totalPackageItems);
                        tablePIRef.current.setData([...result.rows]);
                        tablePIRef.current.setLoading(false);
                    }
                } catch (error) {
                    tablePIRef.current.setLoading(false);
                }
            }).catch((err) => {
                if (isActive.current) {
                    messageBox.current.show(err.message, "Error", "e");
                    tablePIRef.current.setLoading(false);
                }
            });
        } else {
            tablePIRef.current.clearData();
        }
    }
    const inserted = useRef(true);
    const generatePackage = () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const row = tablePRef.current.getSelectedRow();
        if (row) {
            const packageId = Number(row['id']);
            apiGeneratePackage({
                packageId: packageId,
                qty: convertToInt(numberOfPackages.current.getText()),
                invoiceId: props.invoiceId,
                userId: user.id,
            }).then((result) => {
                inserted.current = true;
                if (result.result) {
                    props.onPackageGenerated();
                    close();
                } else {
                    messageBox.current.show(result.message, "Warning", "i");
                }
            }).catch((err) => {
                inserted.current = true;
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            inserted.current = true;
            toast.current.show("Please Select Package.", "i");
        }
    }
    /* #endregion */

    /* #region Clicks */
    const packagesTableOnclick = (row) => {
        packageId.current = Number(row['id']);
        loadPackageItems();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    // Search Textbox Keydown
    const onPackageSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPackages();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePRef.current.focus();
        }
    }
    const onPackageItemSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadPackageItems();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tablePIRef.current.focus();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Package Combobox Change

    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        loadPackages();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

                            <div className='leftPanel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Packages</h4>
                                </div>

                                <Textbox
                                    ref={searchPackageTB}
                                    label="Search"
                                    onKeyDown={onPackageSearchTBKeydown}
                                    tabIndex={27} />

                                <Table
                                    ref={tablePRef}
                                    columns={columnsP.current}
                                    myTable='packagesTable'
                                    isActive={isActive}
                                    onClick={packagesTableOnclick}
                                    onDoubleClick={() => { }}
                                    editBtn={{ visible: false, onClick: () => { } }}
                                    deleteBtn={{ visible: true, onClick: () => { } }} />

                                <div className='dRow jce' style={{ marginBottom: '-18px' }}>
                                    <TextboxNumber
                                        ref={numberOfPackages}
                                        style={{ width: '80px', marginRight: '8px', alignSelf: 'end' }}
                                    />
                                    <ButtonIcon
                                        label="Generate"
                                        icon="icons/buttons/save.png"
                                        style={{ margin: '0px' }}
                                        onClick={generatePackage} />
                                </div>

                                <div className="tableFooter" style={{ maxWidth: '200px' }}>
                                    <p><b>Total Packages: </b>{totalPackages}</p>
                                </div>

                            </div>

                            <div className='centerPanel'>
                                <div className="centerLine"></div>
                            </div>

                            <div className='rightPanel'>
                                <div className='top'>
                                    <h4 className='headingLabel'>Package Items</h4>
                                </div>
                                <Textbox
                                    ref={searchPackageItemsTB}
                                    label="Search"
                                    onKeyDown={onPackageItemSearchTBKeydown}
                                    tabIndex={31} />

                                <Table
                                    ref={tablePIRef}
                                    columns={columnsPI.current}
                                    myTable='packageItemsTable'
                                    isActive={isActive}
                                    onDoubleClick={() => { }}
                                    editBtn={{ visible: false, onClick: () => { } }}
                                    deleteBtn={{ visible: true, onClick: () => { } }} />

                                <div className="tableFooter">
                                    <p><b>Total Packages Items: </b>{totalPackageItems}</p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default GeneratePackageComponent;