import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import CustomComboBox from '../CustomComboBox';

const VendorCBReports = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : ()=>{};
    const style = props.style;

    // Variables
    const data = useSelector(state => state.reports.vendors);
    const reference = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: reference.current.getValue,
            setValue: reference.current.setValue,
            getText: reference.current.getText,
            enable:reference.current.enable,
            disable:reference.current.disable,
        };
    });

    return (
        <>
            <CustomComboBox
                ref={reference}
                label="Vendors"
                className={className}
                data={data}
                showId={true}
                onChange={onChange}
                style={style} />
        </>
    )
});

export default VendorCBReports;