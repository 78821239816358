/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import 'style/reports/accountReports.css';
import ThinButton from "components/tools/ThinButton";
import DateTimePicker from "components/tools/DateTimePicker";
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import CustomerCBReports from "components/tools/reportsDropdowns/CustomerCBReports";
import VendorCBReports from "components/tools/reportsDropdowns/VendorCBReports";
import UsersCBReports from "components/tools/reportsDropdowns/UsersCBReports";
import { apiLoadReportsData } from "api/reports/reportsApi";
import { reportsReducerLoadData } from "state/reducers/reportsReducer";
import { useDispatch, useSelector } from "react-redux";
import { getFromDate, getToDate } from "utilities/Utils";
import ButtonIcon from "components/tools/ButtonIcon";
import ExpenseCBReports from "components/tools/reportsDropdowns/ExpenseCBReports";
import SubExpenseCBReports from "components/tools/reportsDropdowns/SubExpenseCBReports";
import BankCBReports from "components/tools/reportsDropdowns/BankCBReports";
import SubBankCBReports from "components/tools/reportsDropdowns/SubBankCBReports";
import AccountCategoryCBReports from "components/tools/reportsDropdowns/AccountCategoryCBReports";
import Ledger from "./account/Ledger";
import Expenses from "./account/Expenses";
import Receipts from "./account/Receipts";
import Payments from "./account/Payments";
import Debtors from "./account/Debtors";
import Creditors from "./account/Creditors";
import CashAccount from "./account/CashAccount";
import IncomeStatement from "components/reports/account/IncomeStatement";
import EntriesComponent from "components/accounts/EntriesComponent";
import { getBranchId } from "utilities/DB";
import ManageCashComponent from "components/reports/cash/ManageCashComponent";
import CustomersBalanceList from "./account/CustomersBalanceList";
import Checkbox from "components/tools/Checkbox";
import LedgerSmall from "./account/LedgerSmall";
/* #endregion */

const AccountReportComponent = (props) => {

    /* #region Variables */
    const formId = "accountReportsForm";
    const dispatch = useDispatch();
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const userRights = useSelector(state => state.main.userRights);
    const user = useSelector(state => state.main.user);
    const [activeReport, setActiveReport] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const branchCB = useRef();
    const fromDateTB = useRef();
    const toDateTB = useRef();
    const customerCB = useRef();
    const vendorCB = useRef();
    const bankCB = useRef();
    const subBankCB = useRef();
    const expenseCB = useRef();
    const subExpenseCB = useRef();
    const userCB = useRef();
    const bothRB = useRef();
    const onCashRB = useRef();
    const onBankRB = useRef();
    const customerCategoryCB = useRef();
    
    const smallLedgerCheckbox = useRef();
    const reportRef = useRef();
    const reportParams = useRef({});

    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const [activeComponent, setActiveComponent] = useState(null);
    const entriesFormRef = useRef(null);
    const manageCashFormRef = useRef(null);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        if (userRights.branchWiseReports) {
            const branchId = getBranchId();
            branchCB.current.setValue(branchId);
        }

        isFirstTime.current = false;
    }
    const loadData = () => {
        if (isFirstTime.current)
            return;

        apiLoadReportsData({
            branchId: branchCB.current.getValue(),
            branchGroupId: user.branchGroupId,
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const customerLedger = () => {
        if (customerCB.current.getValue() !== 0) {
            const params = getParams();
            params.accountId = customerCB.current.getValue();

            if(smallLedgerCheckbox.current.isChecked())
                loadReport("ledgerSmall", params);
            else
                loadReport("ledger", params);
        } else {
            toast.current.show("Please Select Customer.", "i");
        }
    }
    const vendorLedger = () => {
        if (vendorCB.current.getValue() !== 0) {
            const params = getParams();
            params.accountId = vendorCB.current.getValue();

            loadReport("ledger", params);
        } else {
            toast.current.show("Please Select Vendor.", "i");
        }
    }
    const bankStatement = () => {
        if (bankCB.current.getValue() !== 0) {
            if (subBankCB.current.getValue() !== 0) {
                const params = getParams();
                params.accountId = subBankCB.current.getValue();

                loadReport("bankStatement", params);
            } else {
                toast.current.show("Please Select Bank Account.", "i");
            }
        } else {
            toast.current.show("Please Select Bank.", "i");
        }
    }
    const expenses = (type) => {
        const params = getParams();

        if (type === "expense") {
            if (expenseCB.current.getValue() !== 0) {
                params.expenseId = expenseCB.current.getValue();
            } else {
                toast.current.show("Please Select Expense.", "i");
                return;
            }
        }
        else if (type === "subExpense") {
            if (subExpenseCB.current.getValue() !== 0) {
                params.subExpenseId = subExpenseCB.current.getValue();
            } else {
                toast.current.show("Please Select Sub-Expense.", "i");
                return;
            }
        }

        loadReport("expenses", params);
    }
    const receipts = () => {
        const params = getParams();

        if (userCB.current.getValue() !== 0)
            params.userId = userCB.current.getValue();

        // Receipts on cash,bank or all
        params.on = onCashRB.current.checked ? 1 : onBankRB.current.checked ? 2 : 0;

        loadReport("receipts", params);
    }
    const payments = () => {
        const params = getParams();

        if (userCB.current.getValue() !== 0)
            params.userId = userCB.current.getValue();

        // Receipts on cash,bank or all
        params.on = onCashRB.current.checked ? 1 : onBankRB.current.checked ? 2 : 0;

        loadReport("payments", params);
    }
    const debtors = () => {
        const params = getParams();
        loadReport("debtors", params);
    }
    const creditors = () => {
        const params = getParams();
        loadReport("creditors", params);
    }
    const cashAccount = () => {
        const params = getParams();
        loadReport("cashAccount", params);
    }
    const incomeStatement = () => {
        const params = getParams();
        loadReport("incomeStatement", params);
    }
    const customersBalanceList = (type) => {
        const params = getParams();

        if (type === "category") {
            if (customerCategoryCB.current.getValue() !== 0) {
                params.categoryId = customerCategoryCB.current.getValue();
            } else {
                toast.current.show("Please Select Category.", "i");
                return;
            }
        }

        loadReport("customersBalanceList", params);
    }
    const loadReport = (report, params) => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                reportParams.current = params;

                setActiveReport("");
                setTimeout(() => {
                    setActiveReport(report);
                }, 10);
            } else {
                toast.current.show("Please Select Branch.", "i");
            }
        } else {
            params.branchId = user.branchId;
            reportParams.current = params;

            setActiveReport("");
            setTimeout(() => {
                setActiveReport(report);
            }, 10);
        }
    }
    const getParams = () => {
        return {
            branchId: branchCB.current.getValue(),
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
        }
    }
    const printReport = useReactToPrint({
        content: () => reportRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            // setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    const showEntriesForm = () => {
        if (userRights.entry)
            setActiveComponent('entries');
    }
    const showManageCashForm = () => {
        setActiveComponent('manageCash');
    }
    /* #endregion */

    /* #region Clicks */
    const onResetTimeBtnClick = () => {
        fromDateTB.current.setText(getFromDate());
        toDateTB.current.setText(getToDate());
    }
    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "reports")
            return;

        if (activeTab.current !== "accounts")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printReport();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onBankCBChange = (selectedValue, selectedText) => {
        subBankCB.current.loadData(selectedValue);
    }
    const onExpenseCBChange = (selectedValue, selectedText) => {
        subExpenseCB.current.loadData(selectedValue);
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "accounts" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "accounts" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "entries":
                            return <EntriesComponent
                                ref={entriesFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                activeTab={props.activeTab}
                                mainActiveTab={props.mainActiveTab}
                                className="accountReportsForm"
                                setActiveComponent={setActiveComponent}
                            />
                        case "manageCash":
                            return <ManageCashComponent
                                ref={manageCashFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                activeTab={props.activeTab}
                                mainActiveTab={props.mainActiveTab}
                                className="accountReportsForm"
                                setActiveComponent={setActiveComponent}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className="dateGroup">
                    <div className={`${userRights.branchWiseReports ? 'd-block' : 'd-none'}`}>
                        <BranchesCB
                            ref={branchCB}
                            title="Select Branch"
                            style={{ minWidth: '400px' }}
                            className="defaultMarginRight"
                            onChange={loadData} />
                    </div>
                    <div className="dRow" style={{ minWidth: '500px' }}>
                        <DateTimePicker
                            ref={fromDateTB}
                            label="From Date"
                            time={false}
                            text={getFromDate()}
                            onChange={() => { }}
                            style={{ flex: 1 }}
                            tabIndex={100} />

                        <DateTimePicker
                            ref={toDateTB}
                            label="To Date"
                            time={false}
                            text={getToDate()}
                            onChange={() => { }}
                            className="defaultMarginLeft"
                            style={{ flex: 1 }}
                            tabIndex={100} />

                        <div className='resetTimeBtn' onClick={onResetTimeBtnClick}>
                            <img src="/icons/refresh.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="mainPanel">
                    <div className="leftPanel">
                        {/* Top */}
                        <div className="dCol">
                            <h5 className='headingLabel'>Bank Statement</h5>
                            <BankCBReports
                                ref={bankCB}
                                onChange={onBankCBChange} />
                            <SubBankCBReports
                                ref={subBankCB}
                                onChange={() => { }} />

                            <ThinButton
                                label="Show"
                                className="totalBtn"
                                onClick={bankStatement} />
                        </div>

                        {/* Bottom */}
                        <div className="dCol" style={{ marginTop: '8px' }}>
                            <h5 className='headingLabel' style={{ marginRight: '20px' }}>Ledger</h5>
                            <div className="reportShowBtnGroup">
                                <CustomerCBReports
                                    ref={customerCB}
                                    onChange={() => { }} />
                                <ThinButton
                                    label="Show"
                                    onClick={customerLedger} />
                            </div>
                            <div className="reportShowBtnGroup">
                                <VendorCBReports
                                    ref={vendorCB}
                                    onChange={() => { }} />
                                <ThinButton
                                    label="Show"
                                    onClick={vendorLedger} />
                            </div>
                        </div>

                        <div className="groupBox" style={{ marginTop: '7px' }}>
                            <div className="body">
                                <div className="reportShowBtnGroup">
                                    <AccountCategoryCBReports
                                        ref={customerCategoryCB}
                                        label="Customers by Category"
                                        onChange={() => { }} />
                                    <ThinButton
                                        label="Show"
                                        onClick={() => { customersBalanceList("category"); }} />
                                    <ThinButton
                                        label="Show All"
                                        style={{ marginLeft: '6px', minWidth: '80px' }}
                                        onClick={() => { customersBalanceList("all"); }} />
                                </div>
                            </div>
                        </div>
                        <Checkbox
                            ref={smallLedgerCheckbox}
                            style={{marginTop:'2px'}}
                            label="Small Ledger"
                            onChange={() => { }} />
                    </div>

                    <div className="rightPanel">
                        {/* Top */}
                        <div className="dCol">
                            <h5 className='headingLabel' style={{ marginRight: '70px' }}>Expenses</h5>
                            <div className="reportShowBtnGroup">
                                <ExpenseCBReports
                                    ref={expenseCB}
                                    onChange={onExpenseCBChange} />
                                <ThinButton
                                    label="Show"
                                    onClick={() => { expenses("expense"); }} />
                            </div>
                            <div className="reportShowBtnGroup">
                                <SubExpenseCBReports
                                    ref={subExpenseCB}
                                    onChange={() => { }} />
                                <ThinButton
                                    label="Show"
                                    onClick={() => { expenses("subExpense"); }} />
                            </div>

                            <ThinButton
                                label="Total Expenses"
                                className="totalBtn"
                                style={{ marginRight: '70px' }}
                                onClick={() => { expenses("total"); }} />
                        </div>

                        {/* Bottom */}
                        <div className="dCol" style={{ marginTop: '8px' }}>
                            <h5 className='headingLabel' style={{ marginRight: '70px' }}>Receipts & Payments</h5>
                            <div className="groupBox" style={{ marginTop: '8px' }}>
                                <div className="body">
                                    <UsersCBReports
                                        ref={userCB}
                                        onChange={() => { }} />
                                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                                        <div className="radioGroup" style={{ minWidth: '200px', marginTop: '6px' }}>
                                            <div className="radioBody">
                                                <div className="myRadio">
                                                    <div className="radioItem">
                                                        <input
                                                            ref={bothRB}
                                                            id="receiptsOnBothRB"
                                                            className="form-check-input" type="radio" name="receiptsEntryOn" defaultChecked
                                                            onChange={() => { }} />
                                                        <label htmlFor="receiptsOnBothRB">Both</label>
                                                    </div>
                                                    <div className="radioItem">
                                                        <input
                                                            ref={onCashRB}
                                                            id="receiptsOnCashRB"
                                                            className="form-check-input" type="radio" name="receiptsEntryOn" onChange={() => { }} />
                                                        <label htmlFor="receiptsOnCashRB">Cash</label>
                                                    </div>
                                                    <div className="radioItem">
                                                        <input
                                                            ref={onBankRB}
                                                            id="receiptsOnBankRB"
                                                            className="form-check-input" type="radio" name="receiptsEntryOn" onChange={() => { }} />
                                                        <label htmlFor="receiptsOnBankRB">Bank</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="dRow" style={{ marginTop: '3px' }}>
                                            <ThinButton
                                                label="Receipts"
                                                className=""
                                                onClick={receipts} />
                                            <ThinButton
                                                label="Payments"
                                                className=""
                                                style={{ marginRight: '0px' }}
                                                onClick={payments} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="buttons dRow">
                                <ThinButton
                                    label="Customers List"
                                    className=""
                                    onClick={debtors} />
                                <ThinButton
                                    label="Vendors List"
                                    className="t"
                                    onClick={creditors} />
                                <ThinButton
                                    label="Cash Account"
                                    className=""
                                    onClick={cashAccount} />
                                <ThinButton
                                    label="Manage Cash"
                                    onClick={showManageCashForm} />
                            </div>
                            <div className="buttons dRow">
                                <ThinButton
                                    label="Income Statement"
                                    className=""
                                    style={{ minWidth: '248px' }}
                                    onClick={incomeStatement} />
                                <ThinButton
                                    label="Entry"
                                    className=""
                                    onClick={showEntriesForm} />
                            </div>

                        </div>
                    </div>
                </div>

                {/* Report & Viewer */}
                <div className="reportViewer" style={{ marginTop: '8px' }}>
                    <div className={`loadingBarContainer ${isLoading ? 'd-block' : 'd-none'}`}>
                        <div className="loadingBar"></div>
                    </div>

                    {(() => {
                        switch (activeReport) {
                            case "ledgerSmall":
                                return <LedgerSmall
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current}
                                    header="Ledger" />
                            case "ledger":
                                return <Ledger
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current}
                                    header="Ledger" />
                            case "bankStatement":
                                return <Ledger
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current}
                                    header="Bank Statement" />
                            case "expenses":
                                return <Expenses
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "receipts":
                                return <Receipts
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "payments":
                                return <Payments
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "debtors":
                                return <Debtors
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "creditors":
                                return <Creditors
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "cashAccount":
                                return <CashAccount
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "incomeStatement":
                                return <IncomeStatement
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "customersBalanceList":
                                return <CustomersBalanceList
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            default:
                                return <div></div>;
                        }
                    })()}

                    <div className="printPanel">
                        <ButtonIcon
                            icon="icons/buttons/print.png"
                            onClick={printReport} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default AccountReportComponent;