import { post, getUserId, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadPurchaseItems = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/loadPurchaseItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertPurchaseItem = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/insertPurchaseItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePurchaseItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/updatePurchaseItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePurchaseItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/deletePurchaseItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPurchaseItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/getPurchaseItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsPurchaseItemExists = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/isPurchaseItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsReplaceExists = (itemId) => {
    const params = {
        itemId: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/isReplaceExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeExists = (params) => {
    params.branchGroupId = getBranchGroupId();
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchaseItems/isBarcodeExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
