/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/accounts/vendors.css';
import { mainReducerLoadAccountCategories, mainReducerLoadVendors } from 'state/reducers/mainReducer';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadVendors,
    apiInsertVendor,
    apiUpdateVendor,
    apiDeleteVendor,
    apiGetVendor,
    apiIsVendorExists,
    apiUploadImage as apiUploadVendorImage
} from "api/accounts/VendorsApi";
import { convertToNumber, isFileSizeValid, selectNextElement } from "utilities/Utils";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import TextboxCB from 'components/tools/TextboxCB';
import AccountCategoryCB from 'components/tools/dropdowns/AccountCategoryCB';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import Image from "components/tools/Image";
import Pagination from "components/tools/Pagination";
import AccountCategoriesComponent from 'components/accounts/AccountCategoriesComponent';
import { getClientId } from 'utilities/DB';
/* #endregion Imports */

const VendorsComponent = (props) => {

    /* #region Variables */
    const formId = "vendorsForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.main.settings);
    const passwords = useSelector(state => state.main.passwords);

    const vendorNameTB = useRef();
    const contactTB = useRef();
    const emailTB = useRef();
    const cityTB = useRef();
    const addressTB = useRef();
    const openingBalanceTB = useRef();
    const discTB = useRef();
    const categoryCB = useRef();

    const searchTB = useRef();
    const searchCategoryCB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const paginationRef = useRef();
    const [totalVendors, setTotalVendors] = useState(0);

    // Modals
    const accountCategoriesFormRef = useRef(null);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Vendor Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Contact', row: "contact", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'Email', row: "email", sortBy: "string", style: { minWidth: '200px' } },
        { column: 'City', row: "city", sortBy: "string", style: { minWidth: '150px' } },
        // { column: 'Address', row: "address", sortBy: "string", style: { minWidth: '100px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();
        discTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        vendorNameTB.current.focus();
    }
    const loadData = (page, itemsPerPage) => {
        if (page === undefined) {
            page = paginationRef.current.page
            itemsPerPage = paginationRef.current.itemsPerPage
        }

        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadVendors({
            page: page,
            itemsPerPage: itemsPerPage,
            categoryId: searchCategoryCB.current.getValue(),
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalVendors(result.total.totalVendors);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const loadVendorsCB = (result) => {
        dispatch(mainReducerLoadVendors(result));
        // apiLoadMainVendors().then((result) => {
        //     dispatch(mainReducerLoadVendors(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const loadAccountCategoriesCB = (result) => {
        dispatch(mainReducerLoadAccountCategories(result));
        // apiLoadAccountCategories().then((result) => {
        //     dispatch(mainReducerLoadAccountCategories(result));
        //     categoryCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateVendor(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Vendor updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadVendorsCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertVendor(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Vendor saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadVendorsCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetVendor(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteVendor(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Vendor deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadVendorsCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Vendor?")) {
                    apiDeleteVendor(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Vendor deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadVendorsCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }


        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteVendor(updateId.current)
                            .then((result) => {
                                toast.current.show("Vendor deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadVendorsCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Vendor?")) {
                    apiDeleteVendor(updateId.current)
                        .then((result) => {
                            toast.current.show("Vendor deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadVendorsCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxes = () => {
        vendorNameTB.current.setText("");
        contactTB.current.setText("");
        emailTB.current.setText("");
        cityTB.current.setText("");
        addressTB.current.setText("");
        openingBalanceTB.current.setText("");
        discTB.current.setText("");
        discTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        categoryCB.current.setValue(0);

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        vendorNameTB.current.focus();

        document.getElementById("vendorImageDialog").value = "";
        document.getElementById("vendorImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (vendor) => {
        formData.current = vendor;

        vendorNameTB.current.setText(vendor.accountName);
        contactTB.current.setText(vendor.contact);
        emailTB.current.setText(vendor.email);
        cityTB.current.setText(vendor.city);
        addressTB.current.setText(vendor.address);

        discTB.current.setText(vendor.disc);
        discTB.current.setCBValue(vendor.isDiscRsPer ? 1 : 0);

        categoryCB.current.setValue(vendor.categoryId);

        vendorNameTB.current.focus();

        if (vendor.imageUrl !== '')
            document.getElementById("vendorImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${vendor.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("vendorImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const vendor = {
            accountName: vendorNameTB.current.getText().trim(),
            contact: contactTB.current.getText(),
            email: emailTB.current.getText(),
            city: cityTB.current.getText(),
            address: addressTB.current.getText(),
            openingBalance: convertToNumber(openingBalanceTB.current.getText()),
            disc: convertToNumber(discTB.current.getText()),
            isDiscRsPer: discTB.current.getCBValue() === 1 ? true : false,
            categoryId: categoryCB.current.getValue(),
        }

        return vendor;
    }
    const validation = async () => {
        const vendorName = vendorNameTB.current.getText().trim();
        const isValid = await isValidVendor(vendorName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidVendor = async (vendorName) => {
        if (vendorName === '') {
            toast.current.show("Please Enter Vendor Name.", "i");
            vendorNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (vendorName.toLowerCase() === formData.current.accountName.toLowerCase())
                    return true;
                else
                    return isVendorExists(vendorName);
            }
            else
                return isVendorExists(vendorName);
        }
    }
    const isVendorExists = async (vendorName) => {
        const result = await apiIsVendorExists(vendorName);
        if (result.isExists) {
            toast.current.show("Vendor Name already exists.", "i");
            vendorNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onVendorImageUpload = (imageDialog) => {
        if (isUpdate.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateId.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadVendorImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("vendorImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    const onVendorUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (activeTab.current !== "vendors")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onSearchCBChange = (selectedValue, selectedText) => {
        loadData();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "vendors" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "vendors" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <AccountCategoriesComponent
                ref={accountCategoriesFormRef}
                messageBox={messageBox}
                toast={toast}
                isActive={isActive}
                loadAccountCategoriesCB={loadAccountCategoriesCB} />

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div className='top'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='panel'>
                            <Textbox
                                ref={vendorNameTB}
                                label="Vendor Name"
                                tabIndex={501}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={contactTB}
                                label="Contact"
                                tabIndex={502}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textbox
                                ref={emailTB}
                                label="Email"
                                tabIndex={503}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textarea
                                ref={addressTB}
                                label="Address"
                                tabIndex={504}
                                onKeyDown={onTextboxesKeyDown} />

                        </div>

                        <div className="panel defaultMarginLeft">
                            <div className='imagePanel'>
                                <div style={{ flex: 2, marginRight: '20px', minWidth: '200px' }}>

                                    <Textbox
                                        ref={cityTB}
                                        label="City"
                                        tabIndex={505}
                                        onKeyDown={onTextboxesKeyDown} />

                                    <Textbox
                                        ref={openingBalanceTB}
                                        label="Opening Balance"
                                        tabIndex={506}
                                        onKeyDown={onTextboxesKeyDown} />

                                    <TextboxCB
                                        ref={discTB}
                                        label="Discount"
                                        tabIndex={507}
                                        onKeyDown={onLastTextboxKeyDown}
                                        data={[{ id: 0, name: '%' }, { id: 1, name: 'Rs' }]} />

                                    <div className='d-none'>
                                        <AccountCategoryCB
                                            ref={categoryCB} />
                                    </div>

                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                                    <Image
                                        width={180}
                                        height={136}
                                        id="vendorImageId"
                                        imageId="vendorImage"
                                        imageDialogId="vendorImageDialog"
                                        onUploadBtnClick={(e) => {
                                            onVendorUploadBtnClick(e);
                                        }}
                                        onUpload={(e) => {
                                            onVendorImageUpload(e);
                                        }}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        {/* <ButtonIcon
                            label="Category"
                            icon="icons/buttons/categories.png"
                            onClick={onCategoryBtnClick} /> */}

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onCancelBtnClick} />
                    </div>

                    <div className="searchContainer" style={{ display: 'flex' }}>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={508} />

                        <AccountCategoryCB
                            ref={searchCategoryCB}
                            onChange={onSearchCBChange}
                            style={{ maxWidth: '350px', display: 'none' }}
                            className="defaultMarginLeft" />

                    </div>
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='vendorsTable'
                    // autoSelectFirstRow={true}
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="paginationFooter">
                    <p><b>Total Vendors: </b>{totalVendors}</p>
                    <Pagination
                        ref={paginationRef}
                        totalItems={totalVendors}
                        reload={loadData}
                        showTotalLabel={false}
                        totalLabel="Total Vendors: " />
                </div>

            </div>
        </>
    )
}

export default VendorsComponent;