import { createSlice } from "@reduxjs/toolkit";

const mainReducer = createSlice({
    name: 'main',
    initialState: {
        items: [],
        categories: [],
        subCategories: [],
        customers: [],
        vendors: [],
        suppliers: [],
        fixedAccounts: [],
        users: [],
        packages: [],
        purchaseOrders: [],
        accountCategories: [],
        expenses: [],
        subExpenses: [],
        banks: [],
        subBanks: [],
        user: {},
        userRights: {},
        settings: {},
        passwords: {},
        accounts: [],
        clients: [],
        branches: [],
        branchGroups: [],
        bills: [],
        printSizes: [],
        printLanguages: [],
        billsCode: {},
        warehouses: [],
    },
    reducers: {
        mainReducerLoadData: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.items = action.payload.items;
            state.categories = temp.concat(action.payload.categories);
            state.subCategories = temp.concat(action.payload.subCategories);
            state.customers = temp.concat(action.payload.customers);
            state.vendors = temp.concat(action.payload.vendors);
            state.suppliers = temp.concat(action.payload.suppliers);
            state.fixedAccounts = temp.concat(action.payload.fixedAccounts);
            state.users = temp.concat(action.payload.users);
            state.accountCategories = temp.concat(action.payload.accountCategories);
            state.expenses = temp.concat(action.payload.expenses);
            state.subExpenses = temp.concat(action.payload.subExpenses);
            state.banks = temp.concat(action.payload.banks);
            state.subBanks = temp.concat(action.payload.subBanks);
            state.branches = temp.concat(action.payload.branches);
            state.branchGroups = temp.concat(action.payload.branchGroups);
            state.bills = temp.concat(action.payload.bills);
            state.printSizes = action.payload.printSizes;
            state.printLanguages = action.payload.printLanguages;
            state.billsCode = action.payload.billsCode;
            state.warehouses = temp.concat(action.payload.warehouses);
            // state.gatepasses = temp.concat(action.payload.gatepasses);
        },
        mainReducerLoadCategories: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.categories = temp.concat(action.payload.categories);
        },
        mainReducerLoadSubCategories: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.subCategories = temp.concat(action.payload.subCategories);
        },
        mainReducerLoadExpenses: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.expenses = temp.concat(action.payload.expenses);
        },
        mainReducerLoadSubExpenses: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.subExpenses = temp.concat(action.payload.subExpenses);
        },
        mainReducerLoadBanks: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.banks = temp.concat(action.payload.banks);
        },
        mainReducerLoadSubBanks: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.subBanks = temp.concat(action.payload.subBanks);
        },
        mainReducerLoadCustomers: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.customers = temp.concat(action.payload.customers);
        },
        mainReducerLoadVendors: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.vendors = temp.concat(action.payload.vendors);
        },
        mainReducerLoadSuppliers: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.suppliers = temp.concat(action.payload.suppliers);
        },
        mainReducerLoadFixedAccounts: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.fixedAccounts = temp.concat(action.payload.fixedAccounts);
        },
        mainReducerLoadPackages: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.packages = temp.concat(action.payload.packages);
        },
        mainReducerLoadPurchaseOrders: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.purchaseOrders = temp.concat(action.payload.purchaseOrders);
        },
        mainReducerLoadAccountCategories: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];
            
            state.accountCategories = temp.concat(action.payload.accountCategories);
        },
        mainReducerLoadUser: (state, action) => {
            state.user = action.payload;
        },
        mainReducerLoadUserRights: (state, action) => {
            state.userRights = action.payload;
        },
        mainReducerLoadSettings: (state, action) => {
            state.settings = action.payload;
        },
        mainReducerLoadPasswords: (state, action) => {
            state.passwords = action.payload;
        },
        mainReducerLoadAccounts: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.accounts = temp.concat(action.payload.accounts);
        },
        mainReducerLoadClients: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.clients = temp.concat(action.payload.clients);
        },
        mainReducerLoadBranches: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.branches = temp.concat(action.payload.branches);
        },
        mainReducerLoadBranchGroups: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.branchGroups = temp.concat(action.payload.branchGroups);
        },
        mainReducerLoadBills: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.bills = temp.concat(action.payload.bills);
        },
        mainReducerLoadBillsCode: (state, action) => {
            state.billsCode = action.payload.billsCode;
        },
        mainReducerLoadWarehouses: (state, action) => {
            let temp = [
                { id: 0, name: 'None' }
            ];

            state.warehouses = temp.concat(action.payload.warehouses);
        },
    }
})

export const { 
    mainReducerLoadData, 
    mainReducerLoadCategories,
    mainReducerLoadSubCategories,
    mainReducerLoadExpenses,
    mainReducerLoadSubExpenses,
    mainReducerLoadBanks,
    mainReducerLoadSubBanks,
    mainReducerLoadCustomers,
    mainReducerLoadVendors,
    mainReducerLoadSuppliers,
    mainReducerLoadFixedAccounts,
    mainReducerLoadPackages,
    mainReducerLoadPurchaseOrders,
    mainReducerLoadAccountCategories,
    mainReducerLoadUser,
    mainReducerLoadUserRights,
    mainReducerLoadSettings,
    mainReducerLoadPasswords,
    mainReducerLoadAccounts,
    mainReducerLoadClients,
    mainReducerLoadBranches,
    mainReducerLoadBranchGroups,
    mainReducerLoadBills,
    mainReducerLoadBillsCode,
    mainReducerLoadWarehouses,
} = mainReducer.actions;

export default mainReducer.reducer;