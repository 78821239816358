import React from "react";

const ReportA5Style = () => {
    return (
        <>
            <div>
                <style>
                    {`@media print {
                                @page{
                                    size: A5 portrait;
                                    margin: 4.5mm;
                                }
                            }
                        `}
                </style>
            </div>
        </>
    )
}

export default ReportA5Style;