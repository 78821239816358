import { post } from 'utilities/DB.js';

export const apiLoadAccountCategories = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountCategories/loadAccountCategories", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertAccountCategory = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountCategories/insertAccountCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateAccountCategory = (params) => {
    return new Promise((resolve, reject) => {
        post("api/accountCategories/updateAccountCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteAccountCategory = (categoryId) => {
    const params = {
        id: categoryId
    }
    return new Promise((resolve, reject) => {
        post("api/accountCategories/deleteAccountCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetAccountCategory = (categoryId) => {
    const params = {
        id: categoryId
    }
    return new Promise((resolve, reject) => {
        post("api/accountCategories/getAccountCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsAccountCategoryExists = (categoryName) => {
    const params = {
        categoryName: categoryName
    }
    return new Promise((resolve, reject) => {
        post("api/accountCategories/isAccountCategoryExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
