import React from "react";

const Image = (props) => {
    // Props
    const className = props.className;
    const style = props.style ? props.style : {};
    const width = props.width ? props.width : 230;
    const height = props.height ? props.height : 173;
    const id = props.id;
    const imageId = props.imageId;
    const imageDialogId = props.imageDialogId;
    const onUploadBtnClick = props.onUploadBtnClick;
    const onUpload = props.onUpload;

    const onImageUpload = (e) => {
        if (e.target.files.length > 0) {
            const dialog = document.getElementById(imageDialogId);
            onUpload(dialog);
            dialog.value = null;
        }
    }

    const onCancel = (e) => {
        document.getElementById(imageDialogId).value = "";
        document.getElementById(imageId).src = `${process.env.REACT_APP_API_URL}/images/0.png`;

        const dialog = document.getElementById(imageDialogId);
        onUpload(dialog);
        dialog.value = null;
    }

    return (
        <>
            <div
                id={id}
                className={`image ${className}`}
                style={{ ...style, ...{ width: width, height: height } }}
                onMouseEnter={() => {
                    const element = document.querySelector(`#${id} .imageHover`);
                    element.style.display = "flex";
                }}
                onMouseLeave={() => {
                    const element = document.querySelector(`#${id} .imageHover`);
                    element.style.display = "none";
                }}>
                <div className="imageHover" style={{ width: width, height: height }} >
                    <img src="/icons/clear.png" alt="" onClick={() => {
                        const dialog = document.getElementById(imageDialogId);
                        onCancel(dialog);
                    }} />
                    <img src="/icons/upload.png" alt="" onClick={() => {
                        const dialog = document.getElementById(imageDialogId);
                        onUploadBtnClick(dialog);
                    }} />
                </div>

                <img id={imageId} src={`${process.env.REACT_APP_API_URL}/images/0.png`} alt="" style={{ width: width, height: height }} />
                <input type="file" id={imageDialogId} accept="image/*" className="d-none" onChange={onImageUpload}/>
            </div>
        </>
    )
}

export default Image;