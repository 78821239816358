/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import 'style/reports/otherReports.css';
import ThinButton from "components/tools/ThinButton";
import BranchesCB from "components/tools/dropdowns/BranchesCB";
import VendorCBReports from "components/tools/reportsDropdowns/VendorCBReports";
import { apiLoadReportsData } from "api/reports/reportsApi";
import { reportsReducerLoadData } from "state/reducers/reportsReducer";
import { useDispatch, useSelector } from "react-redux";
import ButtonIcon from "components/tools/ButtonIcon";
import CategoryCBReports from "components/tools/reportsDropdowns/CategoryCBReports";
import SubCategoryCBReports from "components/tools/reportsDropdowns/SubCategoryCBReports";
import BranchGroupsCB from "components/tools/dropdowns/BranchGroupsCB";
import ItemsList from "./other/ItemsList";
import { getReportsData } from "utilities/Utils";
import { getBranchGroupId, getBranchId } from "utilities/DB";
import Checkbox from "components/tools/Checkbox";
import ItemsListWithImages from "./other/ItemsListWithImages";
/* #endregion */

const OtherReportComponent = (props) => {

    /* #region Variables */
    const formId = "otherReportsForm";
    const dispatch = useDispatch();
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const userRights = useSelector(state => state.main.userRights);
    const user = useSelector(state => state.main.user);
    const [activeReport, setActiveReport] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const branchCB = useRef();
    const branchGroupCB = useRef();

    const categoryCB = useRef();
    const subCategoryCB = useRef();
    const vendorCB = useRef();
    const imageListCheckbox = useRef();

    const reportRef = useRef();
    const reportParams = useRef({});

    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        if (userRights.branchWiseReports) {
            const branchId = getBranchId();
            branchCB.current.setValue(branchId);

            const branchGroupId = getBranchGroupId();
            branchGroupCB.current.setValue(branchGroupId);

            // if (user.branchGroupId === 0) {
            //     // const branchId = user.branchId;
            //     const branchId = getBranchId();
            //     branchCB.current.setValue(branchId);
            // } else {
            //     // const branchGroupId = user.branchGroupId;
            //     const branchId = getBranchId();
            //     branchCB.current.setValue(branchId);

            //     const branchGroupId = getBranchGroupId();
            //     branchGroupCB.current.setValue(branchGroupId);
            // }
        }

        isFirstTime.current = false;
    }
    const loadData = (branchId, branchGroupId) => {
        if (isFirstTime.current)
            return;

        apiLoadReportsData({
            branchId: branchId,
            branchGroupId: branchGroupId,
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const itemsList = (type) => {
        const params = {
            branchId: branchCB.current.getValue(),
            branchGroupId: branchGroupCB.current.getValue(),
        };

        if (type === "category") {
            if (categoryCB.current.getValue() !== 0) {
                params.categoryId = categoryCB.current.getValue();
            } else {
                toast.current.show("Please Select Category.", "i");
                return;
            }
        }

        if (type === "subCategory") {
            if (subCategoryCB.current.getValue() !== 0) {
                params.subCategoryId = subCategoryCB.current.getValue();
            } else {
                toast.current.show("Please Select Sub-Category.", "i");
                return;
            }
        }

        if (type === "vendor") {
            if (vendorCB.current.getValue() !== 0) {
                params.vendorId = vendorCB.current.getValue();
            } else {
                toast.current.show("Please Select Vendor.", "i");
                return;
            }
        }

        if (imageListCheckbox.current.isChecked())
            loadReport("itemsListWithImages", params);
        else
            loadReport("itemsList", params);
    }
    const loadReport = (report, params) => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                reportParams.current = params;

                setActiveReport("");
                setTimeout(() => {
                    setActiveReport(report);
                }, 10);
            } else {
                toast.current.show("Please Select Branch.", "i");
            }
        } else {
            params.branchId = user.branchId;
            reportParams.current = params;

            setActiveReport("");
            setTimeout(() => {
                setActiveReport(report);
            }, 10);
        }
    }
    const printReport = useReactToPrint({
        content: () => reportRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            // setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */

    /* #endregion */

    /* #region Keydown */
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "reports")
            return;

        if (activeTab.current !== "others")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printReport();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onBranchCBChange = (selectedValue, selectedText) => {
        if (branchCB.current.getValue() === 0) {
            if (branchGroupCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(0, branchGroupCB.current.getValue());
            }
        } else {
            loadData(branchCB.current.getValue(), 0);
        }
    }
    const onBranchGroupCBChange = (selectedValue, selectedText) => {
        if (branchGroupCB.current.getValue() === 0) {
            if (branchCB.current.getValue() === 0) {
                dispatch(reportsReducerLoadData(getReportsData()));
            } else {
                loadData(branchCB.current.getValue(), 0);
            }
        } else {
            loadData(branchCB.current.getValue(), branchGroupCB.current.getValue());
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "others" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "others" && isFirstTime.current) {
            // isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>

                <div className="mainPanel">
                    <div className={`${userRights.branchWiseReports ? 'd-block' : 'd-none'}`} style={{ marginBottom: '16px' }}>
                        <BranchesCB
                            ref={branchCB}
                            title="Select Branch"
                            style={{ margin: '0px' }}
                            onChange={onBranchCBChange} />
                        <BranchGroupsCB
                            ref={branchGroupCB}
                            title="Select Branch Group"
                            onChange={onBranchGroupCBChange} />
                    </div>
                    <h5 className='headingLabel' style={{ marginRight: '70px' }}>Items List</h5>
                    <div className="reportShowBtnGroup">
                        <CategoryCBReports
                            ref={categoryCB}
                            onChange={() => { }} />
                        <ThinButton
                            label="Show"
                            onClick={() => { itemsList("category"); }} />
                    </div>
                    <div className="reportShowBtnGroup">
                        <SubCategoryCBReports
                            ref={subCategoryCB}
                            onChange={() => { }} />
                        <ThinButton
                            label="Show"
                            onClick={() => { itemsList("subCategory"); }} />
                    </div>
                    <div className="reportShowBtnGroup">
                        <VendorCBReports
                            ref={vendorCB}
                            onChange={() => { }} />
                        <ThinButton
                            label="Show"
                            onClick={() => { itemsList("vendor"); }} />
                    </div>

                    <div className="dRow jcsb">
                        <ThinButton
                            label="Total Items List"
                            className="totalBtn"
                            style={{ marginRight: '70px' }}
                            onClick={() => { itemsList("total"); }} />

                        <Checkbox
                            ref={imageListCheckbox}
                            label="Images List"
                            style={{marginTop:'0px', marginLeft:'-20px'}}
                            checked={false}
                            onChange={() => { }} />
                    </div>
                </div>

                {/* Report & Viewer */}
                <div className="reportViewer" style={{ marginTop: '8px' }}>
                    <div className={`loadingBarContainer ${isLoading ? 'd-block' : 'd-none'}`}>
                        <div className="loadingBar"></div>
                    </div>

                    {(() => {
                        switch (activeReport) {
                            case "itemsList":
                                return <ItemsList
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            case "itemsListWithImages":
                                return <ItemsListWithImages
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            default:
                                return <div></div>;
                        }
                    })()}

                    <div className="printPanel">
                        <ButtonIcon
                            icon="icons/buttons/print.png"
                            onClick={printReport} />
                    </div>


                </div>

            </div>
        </>
    )
}

export default OtherReportComponent;