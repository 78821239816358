import { post } from 'utilities/DB.js';

export const apiLoadGatepasses = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepass/loadGatepasses", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertGatepass = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepass/insertGatepass", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateGatepass = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepass/updateGatepass", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteGatepass = (gatepassId) => {
    const params = {
        id: gatepassId,
    }
    return new Promise((resolve, reject) => {
        post("api/gatepass/deleteGatepass", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetGatepass = (gatepassId) => {
    const params = {
        id: gatepassId
    }
    return new Promise((resolve, reject) => {
        post("api/gatepass/getGatepass", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsGatepassExists = (gatepassName, date, warehouseId) => {
    const params = {
        gatepassName: gatepassName,
        date: date,
        warehouseId: warehouseId,
    }
    return new Promise((resolve, reject) => {
        post("api/gatepass/isGatepassExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadGatepass = (id) => {
    const params = {
        id:id
    }
    return new Promise((resolve, reject) => {
        post("api/gatepass/loadGatepass", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
