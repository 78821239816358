import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import dateFormat from "dateformat";
import { apiLoadIncomeStatement } from "api/reports/accountReportsApi";
import ReportA4Style from "../styles/ReportA4Style";

const IncomeStatement = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [branchName, setBranchName] = useState('');

    // Footer
    const [sales, setSales] = useState(0);
    const [cgs, setCGS] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [grossProfitLabel, setGrossProfitLabel] = useState("Gross Profit: ");
    const [netProfit, setNetProfit] = useState(0);
    const [netProfitLabel, setNetProfitLabel] = useState("Net Profit: ");
    const [totalExpenses, setTotalExpenses] = useState(0);

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadIncomeStatement(params)
            .then((result) => {
                console.log(result);
                if (result.rows && result.total) {
                    setData(result.rows);

                    setCGS(result.total.cgs);
                    setSales(result.total.sales);

                    setGrossProfit(result.total.grossProfit);
                    setNetProfit(result.total.netProfit);
                    setTotalExpenses(result.total.totalExpenses);

                    setGrossProfitLabel(result.total.grossProfit >= 0 ? "Gross Profit: " : "Gross Loss: ");
                    setNetProfitLabel(result.total.netProfit >= 0 ? "Net Profit: " : "Net Loss: ");

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 ledger" ref={ref}>
                <ReportA4Style />
                
                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Income Statement</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader" style={{ marginTop: '4px' }}>
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">From Date:</p>
                                <p className="totalLabel alignL">{fromDate}</p>
                            </div>
                        </div>

                        {/* Center */}
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">To Date:</p>
                            </div>
                            <div className="dCol">
                                <p className="totalLabel alignL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <table>
                    <thead>
                        <tr>
                            <th>Details</th>
                            <th>Amount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '2px' }}>
                            <td style={{ textAlign: 'left' }}>Sales</td>
                            <td>{sales}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'left' }}><b>Less:</b> Cost of goods sold</td>
                            <td>{cgs}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }}><b>{grossProfitLabel}</b></td>
                            <td></td>
                            <td><b>{grossProfit}</b></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center' }}><b>Expenses</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    <td className="naration" key={rowIndex + 10} title={row['expense']}>{row['expense']}</td>
                                    <td title={row['amount']}>{row['amount']}</td>
                                    {/* <td title={row['credit']}>{row['credit']}</td> */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">

                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">{grossProfitLabel}</p>
                                <p className="totalHeading">Total Expenses:</p>
                                <p className="totalHeading">{netProfitLabel}</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel">{grossProfit}</p>
                                <p className="totalLabel underline">{totalExpenses}</p>
                                <p className="totalLabel dUnderline">{netProfit}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default IncomeStatement;