/* #region Imports */
import React, { useEffect, useState, useRef } from "react";
import 'style/purchase/purchase.css';
import {
    apiGetBalance,
} from "api/accounts/AccountsApi";
import {
    apiDeletePurchaseItem,
    apiGetPurchaseItem,
    apiInsertPurchaseItem,
    apiIsBarcodeExists,
    apiLoadPurchaseItems,
    apiUpdatePurchaseItem
} from "api/purchase/PurchaseItemsApi";
import {
    apiInsertPurchase,
    apiUpdatePurchase,
    apiGetNextId,
    apiIsReplaceExists as apiIsReplaceExistsByVendor,
    apiGetPurchase,
    apiDeletePurchase,
    apiTempClose,
    apiGenerateBarcodeLabels,
} from "api/purchase/PurchaseApi";

import ButtonIcon from "components/tools/ButtonIcon";
import ComboBox from "components/tools/ComboBox";
import Table from "components/tools/Table";
import Textbox from "components/tools/Textbox";
import ThinButton from "components/tools/ThinButton";
import VendorCB from "components/tools/dropdowns/VendorCB";
import BankCBMOP from "components/tools/dropdowns/BankCBMOP";
import SubBankCBMOP from "components/tools/dropdowns/SubBankCBMOP";
import DateTimePicker from "components/tools/DateTimePicker";
import TextboxCB from "components/tools/TextboxCB";
import Label from "components/tools/Label";
import Span from "components/tools/Span";
import PTag from "components/tools/PTag";
import Checkbox from "components/tools/Checkbox";
import PostedBillsComponent from "components/purchase/PostedBillsComponent";
import { convertToInt, convertToNumber, isExpression, printLanguages, printSizes, selectNextElement, selectNextElement2, toDecimal } from "utilities/Utils";
import { evaluate } from "mathjs";
import PendingBillsComponent from "components/purchase/PendingBillsComponent";
import { useReactToPrint } from "react-to-print";
import SearchItemsComponent from "components/items/SearchItemsComponent";
import EntriesComponent from "components/accounts/EntriesComponent";
import { useSelector } from 'react-redux';
import PrintSizesCB from "components/tools/dropdowns/PrintSizesCB";
import PrintLanguagesCB from "components/tools/dropdowns/PrintLanguagesCB";
import ThermalEnglish from "components/purchase/bills/ThermalEnglish";
import ThermalUrdu from "components/purchase/bills/ThermalUrdu";
import A4English from "components/purchase/bills/A4English";
import A4Urdu from "components/purchase/bills/A4Urdu";
import A5English from "components/purchase/bills/A5English";
import A5Urdu from "components/purchase/bills/A5Urdu";
import RejectedItemsComponent from "./RejectedItemsComponent";
import AutocompleteTextboxPurchase from "components/tools/AutocompleteTextboxPurchase";
import PurchaseOrdersComponent from "./PurchaseOrdersComponent";
import WarehouseCB from "components/tools/dropdowns/WarehouseCB";

/* #endregion */

const PurchaseComponent = (props) => {

    /* #region Variables */
    const formId = "purchaseForm";
    const isActive = useRef(true);
    const mainActiveTab = useRef(null);
    const settings = useSelector(state => state.main.settings);
    const userRights = useSelector(state => state.main.userRights);

    const invoiceId = useRef(0);
    const isPendingBill = useRef(false);
    const isPostedBill = useRef(false);

    // Purchase
    const vendorCB = useRef();
    const warehouseCB = useRef();
    const vendorDiscountLB = useRef();
    const updateVendorCheckbox = useRef();
    const balanceLB = useRef(0);
    const balanceLabel = useRef("(P)");
    const grandTotal = useRef(0);
    const grandTotalLB = useRef(0);
    const bankCB = useRef();
    const subBankCB = useRef();
    const mopCB = useRef();
    const narationTB = useRef();
    const purchaseOnCashRB = useRef();
    const purchaseOnCreditRB = useRef();
    const purchaseTypePurchaseRB = useRef();
    const purchaseTypeReturnRB = useRef();
    const purchaseTypeRejectionRB = useRef();
    const dateTB = useRef();
    const refInvoiceNoTB = useRef();
    const pDiscTB = useRef();
    const pTotalDiscLB = useRef(0);

    // Purchase Items
    const itemnameTB = useRef();
    const costTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const crtnSizeTB = useRef();
    const discTB = useRef();
    const totalDiscLB = useRef(0);
    const taxTB = useRef();
    const totalTaxLB = useRef(0);
    const totalLB = useRef(0);
    const costLB = useRef(0);
    const costCrtnLB = useRef(0);
    const discLB = useRef(0);
    const taxLB = useRef(0);
    const totalQtyLB = useRef(0);
    const grossTotalLB = useRef(0);
    const retailTB = useRef();
    const wholesaleTB = useRef();
    const rate3TB = useRef();
    const rate4TB = useRef();
    const crtnRateTB = useRef();
    const marketPriceTB = useRef();
    const expiryTB = useRef();
    const currentStockPcsLB = useRef();
    const currentStockCrtnLB = useRef();
    const saleCurrentStockFirstCheckbox = useRef();
    const locationLB = useRef();
    const lockPcsCheckbox = useRef();
    const lockCrtnCheckbox = useRef();
    const freezeDiscCheckbox = useRef();
    const freezeTaxCheckbox = useRef();


    const tableRef = useRef();
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);
    const formDataP = useRef(null);
    const account = useRef(null);

    const printCheckbox = useRef();
    const printSizeCB = useRef();
    const printLanguageCB = useRef();

    const purchaseOrderRef = useRef();
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const [totalItems, setTotalItems] = useState(0);
    const [totalQty, setTotalQty] = useState(0);
    const [totalCrtn, setTotalCrtn] = useState(0);
    const [oldRates, setOldRates] = useState("");
    const [activeComponent, setActiveComponent] = useState(null);
    const [activeComponent2, setActiveComponent2] = useState(null);
    const [activeBill, setActiveBill] = useState(null);


    // Modals
    const pendingBillsFormRef = useRef(null);
    const postedBillsFormRef = useRef(null);
    const entriesFormRef = useRef(null);
    const searchItemsFormRef = useRef(null);

    const billRef = useRef();
    const [billData, setBillData] = useState([]);

    const columnsC = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Cost', row: "finalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Gross Cost', row: "cost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Disc', row: "calculatedDisc", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Tax', row: "tax", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '120px' } },
    ]);

    const columns = useRef([
        { column: 'Sr.', row: "sr", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '350px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Cost', row: "finalCost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Gross Cost', row: "cost", sortBy: "number", style: { minWidth: '100px' } },
        { column: 'Disc', row: "calculatedDisc", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Tax', row: "tax", sortBy: "number", style: { minWidth: '90px' } },
        { column: 'Total', row: "total", sortBy: "string", style: { minWidth: '120px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    /* #region Purchase Items */
    const formLoad = () => {
        loadSettings();
        reloadData();

        itemnameTB.current.focus();
    }
    const loadSettings = () => {
        printSizeCB.current.setValue(settings.printSize);
        printLanguageCB.current.setValue(settings.printLanguage);
        pDiscTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        discTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);

        crtnSizeTB.current.disable();
        if (!settings.saleCartons) {
            crtnTB.current.disable();
            crtnRateTB.current.disable();
        }
    }
    const reloadData = () => {
        apiGetNextId().then((nextId) => {
            invoiceId.current = Number(nextId);
            loadData();
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadPurchaseItems({
            purchaseId: invoiceId.current,
            isPostedBill: isPostedBill.current,
            isPendingBill: isPendingBill.current,
        }).then((result) => {
            try {
                grandTotalLB.current.setText(result.total.grandTotal);
                grandTotal.current = result.total.grandTotal;

                setTotalItems(result.total.totalItems);
                setTotalQty(result.total.totalQty);
                setTotalCrtn(result.total.totalCrtn);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);

                onDiscTextChange();
            } catch (error) {
                tableRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableRef.current.setLoading(false);
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();
        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdatePurchaseItem(data)
                    .then((result) => {
                        inserted.current = true;
                        // toast.current.show("Item updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertPurchaseItem(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        // toast.current.show("Item saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
                // if (formData.current !== null && formData.current.isExists) {
                //     if (window.confirm("Item already exists in bill, You want to add?")) {
                //         apiInsertPurchaseItem(getFormData())
                //             .then((result) => {
                //                 inserted.current = true;
                //                 // toast.current.show("Item saved successfully.", "s");
                //                 loadData();
                //                 clearTextboxes();
                //             }).catch((err) => {
                //                 inserted.current = true;
                //                 messageBox.current.show(err.message, "Error", "e");
                //             });
                //     } else {
                //         inserted.current = true;
                //     }
                // } else {
                //     apiInsertPurchaseItem(getFormData())
                //         .then((result) => {
                //             inserted.current = true;
                //             // toast.current.show("Item saved successfully.", "s");
                //             loadData();
                //             clearTextboxes();
                //         }).catch((err) => {
                //             inserted.current = true;
                //             messageBox.current.show(err.message, "Error", "e");
                //         });
                // }
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetPurchaseItem({
                id: tempId,
                isPostedBill: isPostedBill.current
            })
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        if (window.confirm("Are you sure to delete this item?")) {
            const row = tableRef.current.getSelectedRow();
            if (row != null) {
                apiDeletePurchaseItem({
                    id: Number(row['id']),
                    isPostedBill: isPostedBill.current
                })
                    .then((result) => {
                        toast.current.show("Item deleted successfully.", "s");
                        loadData();
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this item?")) {
                apiDeletePurchaseItem(updateId.current)
                    .then((result) => {
                        toast.current.show("Item deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        itemnameTB.current.setText("");
        costTB.current.setText("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");
        crtnSizeTB.current.setText("");
        if (!freezeDiscCheckbox.current.isChecked())
            discTB.current.setText("");
        if (!freezeTaxCheckbox.current.isChecked())
            taxTB.current.setText("");
        totalDiscLB.current.setText("0");

        retailTB.current.setText("");
        wholesaleTB.current.setText("");
        rate3TB.current.setText("");
        rate4TB.current.setText("");
        crtnRateTB.current.setText("");
        marketPriceTB.current.setText("");
        expiryTB.current.setText(new Date());

        currentStockPcsLB.current.setText("0");
        currentStockCrtnLB.current.setText("0");
        saleCurrentStockFirstCheckbox.current.setChecked(false);
        locationLB.current.setText("");
        lockPcsCheckbox.current.setChecked(false);
        lockCrtnCheckbox.current.setChecked(false);

        totalLB.current.setText("0");
        setOldRates("");

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        itemnameTB.current.focus();
    }
    const setFormData = (item) => {
        formData.current = item;

        itemnameTB.current.setText(item.itemname);
        costTB.current.setText(item.cost);
        qtyTB.current.setText(item.qty);
        crtnTB.current.setText(item.crtn);
        crtnSizeTB.current.setText(item.crtnSize);
        discTB.current.setText(item.disc);
        discTB.current.setCBValue(item.isDiscRsPer ? 1 : 0);
        taxTB.current.setText(item.tax);

        retailTB.current.setText(item.retail);
        wholesaleTB.current.setText(item.wholesale);
        rate3TB.current.setText(item.rate3);
        rate4TB.current.setText(item.rate4);
        crtnRateTB.current.setText(item.crtnRate);
        marketPriceTB.current.setText(item.marketPrice);
        expiryTB.current.setText(item.expiry);

        currentStockPcsLB.current.setText(item.stockPcs);
        currentStockCrtnLB.current.setText(item.stockCrtn);
        saleCurrentStockFirstCheckbox.current.setChecked(item.isNewStock);
        locationLB.current.setText(item.location);
        lockPcsCheckbox.current.setChecked(item.lockPcs);
        lockCrtnCheckbox.current.setChecked(item.lockCrtn);

        totalDiscLB.current.setText(item.totalDisc);
        totalLB.current.setText(item.total);

        if (item.oldRates)
            setOldRates(item.oldRates.join(", "));

        costTB.current.focus();
    }
    const getFormData = () => {
        const item = {
            itemId: formData.current.itemId,
            purchaseId: invoiceId.current,
            itemname: itemnameTB.current.getText().trim(),
            cost: convertToNumber(costTB.current.getText()),
            finalCost: convertToNumber(costLB.current.getText()),
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToInt(crtnTB.current.getText()),
            crtnSize: convertToInt(crtnSizeTB.current.getText()),
            disc: convertToNumber(discTB.current.getText()),
            isDiscRsPer: discTB.current.getCBValue() === 1 ? true : false,
            calculatedDisc: convertToNumber(discLB.current.getText()),
            totalDisc: convertToNumber(totalDiscLB.current.getText()),
            tax: convertToNumber(taxTB.current.getText()),
            totalTax: convertToNumber(totalTaxLB.current.getText()),
            retail: convertToNumber(retailTB.current.getText()),
            wholesale: convertToNumber(wholesaleTB.current.getText()),
            rate3: convertToNumber(rate3TB.current.getText()),
            rate4: convertToNumber(rate4TB.current.getText()),
            crtnRate: convertToNumber(crtnRateTB.current.getText()),
            marketPrice: convertToNumber(marketPriceTB.current.getText()),
            expiry: expiryTB.current.getText(),
            isNewStock: saleCurrentStockFirstCheckbox.current.isChecked(),
            lockPcs: lockPcsCheckbox.current.isChecked(),
            lockCrtn: lockCrtnCheckbox.current.isChecked(),
            total: convertToNumber(totalLB.current.getText()),

            isPostedBill: isPostedBill.current,
        }

        return item;
    }
    const showDataIntoTextboxes = (item) => {
        formData.current = item;
        formData.current.itemId = item.id;

        itemnameTB.current.setText(item.itemname);
        costTB.current.setText(item.lastCost === 0 ? item.cost : item.lastCost);
        crtnSizeTB.current.setText(item.crtnSize);
        retailTB.current.setText(item.retail);
        wholesaleTB.current.setText(item.wholesale);
        crtnRateTB.current.setText(item.crtnRate);
        marketPriceTB.current.setText(item.marketPrice);
        expiryTB.current.setText(item.expiry);

        currentStockPcsLB.current.setText(item.stockPcs);
        currentStockCrtnLB.current.setText(item.stockCrtn);
        lockPcsCheckbox.current.setChecked(item.lockPcs);
        lockCrtnCheckbox.current.setChecked(item.lockCrtn);
        locationLB.current.setText(item.location);

        costTB.current.focus();

        const crtnSize = item.crtnSize;
        if (crtnSize === 0) {
            crtnTB.current.disable();
            crtnRateTB.current.disable();
        } else {
            crtnTB.current.enable();
            crtnRateTB.current.enable();
        }

        if (crtnSize === 1) {
            qtyTB.current.disable();
            retailTB.current.disable();
            wholesaleTB.current.disable();
        } else {
            qtyTB.current.enable();
            retailTB.current.enable();
            wholesaleTB.current.enable();
        }

        if (item.isReplaceExists) {
            messageBox.current.show("You have some replace of this item", "Replace found", "i");
        }

        if (item.oldRates)
            setOldRates(item.oldRates.join(", "));
    }
    const validation = () => {
        let allOk = false;
        if (formData.current === null)
            return;

        if (invoiceId.current === 0) {
            apiGetNextId().then((nextId) => {
                invoiceId.current = Number(nextId);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });

            toast.current.show("Try again", "e");
            return false;
        }

        const itemname = itemnameTB.current.getText().trim();
        const total = convertToNumber(totalLB.current.getText());

        if (formData.current !== null && !isUpdate.current && formData.current.isExists) {
            if (!window.confirm("Item already exists in bill, You want to add?")) {
                return false;
            }
        }

        // Code for over stock warning
        if (formData.current !== null && settings.stockWarning) {
            const oldStock = formData.current.stockPcs + (formData.current.stockCrtn * formData.current.crtnSize);
            const newStock = convertToNumber(qtyTB.current.getText()) + (convertToInt(crtnTB.current.getText()) * formData.current.crtnSize);
            const totalStock = oldStock + newStock;

            if (formData.current.stockWarningMax > 0 && totalStock >= formData.current.stockWarningMax) {
                if (window.confirm("Item stock will be over if you purchase this item, You want to purchase?")) {
                    allOk = true;
                } else {
                    return false;
                }
            }
        }

        if (itemname === '') {
            toast.current.show("Itemname cannot be empty", "i");
            itemnameTB.current.focus();
            return false;
        }

        if (total !== 0) {
            allOk = true;
        } else {
            toast.current.show("Please enter cost or qty", "i");
            qtyTB.current.focus();
            return false;
        }

        return allOk;
    }
    const onRejectedItemsSelect = (data) => {
        onBarcodeTBKeyDown(data.itemname);
        qtyTB.current.setText(data.qty);
        crtnTB.current.setText(data.crtn);
    }
    /* #endregion */

    /* #region Purchase */
    const inserted1 = useRef(true);
    const insertOrUpdatePurchase = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationP();
        if (isValid) {
            if (isPostedBill.current) {
                const data = getFormDataP();
                if (window.confirm("Continue to Post?")) {
                    apiUpdatePurchase(data)
                        .then((result) => {
                            inserted1.current = true;
                            toast.current.show("Bill updated successfully.", "s");
                            clearTextboxes();
                            clearTextboxesP();
                            reloadData();

                            if (printCheckbox.current.isChecked()) {
                                checkAndPrintBill(result);
                            }
                        }).catch((err) => {
                            inserted1.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        });
                } else {
                    inserted1.current = true;
                }
            } else {
                if (window.confirm("Continue to Post?")) {
                    apiInsertPurchase(getFormDataP())
                        .then((result) => {
                            inserted1.current = true;
                            toast.current.show("Bill saved successfully.", "s");
                            clearTextboxes();
                            clearTextboxesP();
                            reloadData();

                            if (printCheckbox.current.isChecked()) {
                                checkAndPrintBill(result);
                            }
                        }).catch((err) => {
                            inserted1.current = true;
                            messageBox.current.show(err.message, "Error", "e");
                        });
                } else {
                    inserted1.current = true;
                }
            }
        } else {
            inserted1.current = true;
        }
    }
    const editPurchase = () => {
        apiGetPurchase({
            id: invoiceId.current,
            isPostedBill: isPostedBill.current
        }).then((result) => {
            setFormDataP(result);
            loadData();
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        })
    }
    const clearTextboxesP = () => {
        vendorCB.current.setValue(0);
        warehouseCB.current.setValue(0);
        vendorDiscountLB.current.setText("");
        updateVendorCheckbox.current.setChecked(false);
        balanceLB.current.setText("0");
        balanceLabel.current.setText("(P)");
        mopCB.current.setValue(0);
        bankCB.current.setValue(0);
        subBankCB.current.setValue(0);
        narationTB.current.setText("");
        purchaseOnCashRB.current.checked = true;
        purchaseTypePurchaseRB.current.checked = true;

        dateTB.current.setText(new Date());
        refInvoiceNoTB.current.setText("");
        pDiscTB.current.setText("");
        pDiscTB.current.setCBValue(settings.isDefaultDiscRs ? 1 : 0);
        pTotalDiscLB.current.setText("0");
        grandTotalLB.current.setText("0");
        grandTotal.current = 0;

        discTB.current.setText("");
        taxTB.current.setText("");

        invoiceId.current = 0;
        isPostedBill.current = false;
        isPendingBill.current = false;
        formDataP.current = null;
        account.current = null;

        purchaseTypePurchaseRB.current.disabled = false;
        purchaseTypeReturnRB.current.disabled = false;
        purchaseTypeRejectionRB.current.disabled = false;

        itemnameTB.current.focus();
    }
    const setFormDataP = (purchase) => {
        formDataP.current = purchase;

        vendorCB.current.setValue(purchase.vendorId);
        warehouseCB.current.setValue(purchase.warehouseId);
        // balanceLB.current.setText(purchase.oldBalance);

        mopCB.current.setValue(purchase.isMopCashBank === true ? 0 : 1);
        bankCB.current.setValue(purchase.bankId);
        subBankCB.current.setValue(purchase.subBankId);
        narationTB.current.setText(purchase.naration);

        if (purchase.purchaseOn === 1)
            purchaseOnCashRB.current.checked = true;
        else
            purchaseOnCreditRB.current.checked = true;

        if (purchase.purchaseType === 1)
            purchaseTypePurchaseRB.current.checked = true;
        else if (purchase.purchaseType === 2)
            purchaseTypeReturnRB.current.checked = true;
        else if (purchase.purchaseType === 3)
            purchaseTypeRejectionRB.current.checked = true;

        dateTB.current.setText(purchase.date);
        refInvoiceNoTB.current.setText(purchase.refInvoiceNo);
        pDiscTB.current.setText(purchase.disc);
        pDiscTB.current.setCBValue(purchase.isDiscRsPer === true ? 1 : 0);
        pTotalDiscLB.current.setText(purchase.totalDisc);
        grandTotalLB.current.setText(purchase.total);

        if (isPostedBill.current) {
            purchaseTypePurchaseRB.current.disabled = true;
            purchaseTypeReturnRB.current.disabled = true;
            purchaseTypeRejectionRB.current.disabled = true;
        }

        itemnameTB.current.focus();
    }
    const getFormDataP = () => {
        const purchase = {
            purchaseId: invoiceId.current,
            vendorId: vendorCB.current.getValue(),
            warehouseId: warehouseCB.current.getValue(),
            updateVendor: updateVendorCheckbox.current.isChecked(),
            isMopCashBank: mopCB.current.getValue() === 0 ? true : false,
            bankId: bankCB.current.getValue(),
            subBankId: subBankCB.current.getValue(),
            naration: narationTB.current.getText(),
            purchaseOn: purchaseOnCashRB.current.checked ? 1 : 2,
            purchaseType: purchaseTypePurchaseRB.current.checked ? 1 : purchaseTypeReturnRB.current.checked ? 2 : 3,
            date: dateTB.current.getText(),
            refInvoiceNo: refInvoiceNoTB.current.getText(),
            disc: convertToNumber(pDiscTB.current.getText()),
            isDiscRsPer: pDiscTB.current.getCBValue(),
            totalDisc: convertToNumber(pTotalDiscLB.current.getText()),
            total: convertToNumber(grandTotalLB.current.getText()),
        }

        return purchase;
    }
    const validationP = () => {
        if (invoiceId.current === 0) {
            apiGetNextId().then((nextId) => {
                invoiceId.current = Number(nextId);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });

            toast.current.show("Try again", "e");
            return false;
        }

        if (convertToNumber(grandTotalLB.current.getText()) === 0) {
            toast.current.show("Nothing to post", "i");
            return false;
        }

        if (vendorCB.current.getValue() === 0) {
            toast.current.show("Please Select Vendor", "i");
            return false;
        }

        if (mopCB.current.getValue() === 1) {
            if (bankCB.current.getValue() === 0) {
                toast.current.show("Please Select Bank", "i");
                return false;
            } else {
                if (subBankCB.current.getValue() === 0) {
                    toast.current.show("Please Select Bank Account", "i");
                    return false;
                }
            }
        }

        return true;
    }
    const onDiscTextChange = () => {
        const disc = convertToNumber(pDiscTB.current.getText());
        const isDiscRsPer = pDiscTB.current.getCBValue() === 1 ? true : false;

        let total = grandTotal.current;

        if (isDiscRsPer === true) {
            total -= disc;

            pTotalDiscLB.current.setText(disc.toFixed(2));
        } else {
            const totalDisc = (disc / 100) * total;
            total -= totalDisc;

            pTotalDiscLB.current.setText(totalDisc.toFixed(2));
        }

        grandTotalLB.current.setText(total.toFixed(0));
    }
    const showPendingBillsForm = () => {
        if (isPostedBill.current) {
            toast.current.show("Close current bill first", "i");
            return;
        }

        // pendingBillsFormRef.current.show();
        setActiveComponent('pending');
    }
    const showPostedBillsForm = () => {
        if (isPendingBill.current) {
            toast.current.show("Close current bill first", "i");
            return;
        }

        // postedBillsFormRef.current.show();
        setActiveComponent('posted');
    }
    const showEntriesForm = () => {
        if (userRights.entry)
            setActiveComponent('entries');
    }
    const showSearchItemsForm = () => {
        setActiveComponent('search');
    }
    const showRejectedItemsForm = () => {
        setActiveComponent('rejectedItems');
    }
    const showOrdersForm = () => {
        setActiveComponent('orders');
    }
    /* #endregion */

    const tempClosing = useRef(true);
    const tempClose = () => {
        if (!isPostedBill.current) {
            if (vendorCB.current.getValue() === 0) {
                toast.current.show("Please Select Vendor", "i");
            } else if (tableRef.current.getData().length > 0) {
                if (tempClosing.current === false)
                    return;
                else
                    tempClosing.current = false;

                const data = getFormDataP();
                data.isPendingBill = isPendingBill.current;

                apiTempClose(data).then((result) => {
                    clearTextboxes();
                    clearTextboxesP();
                    reloadData();
                    tempClosing.current = true;
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                    tempClosing.current = true;
                });
            }
        } else {
            toast.current.show("You cannot temp close posted bill", "i");
        }
    }
    const onEditPendingBill = (id) => {
        clearTextboxes();
        invoiceId.current = id;
        isPendingBill.current = true;
        isPostedBill.current = false;
        editPurchase();
    }
    const onEditPostedBill = (id) => {
        clearTextboxes();
        invoiceId.current = id;
        isPendingBill.current = false;
        isPostedBill.current = true;

        editPurchase();
    }
    const onSearchItemSelected = (item) => {
        onBarcodeTBKeyDown(item.barcode);
        // showDataIntoTextboxes(item);
    }
    const generateBarcodes = (shelfTags = false) => {
        if (tableRef.current.getData().length > 0) {
            apiGenerateBarcodeLabels({
                purchaseId: invoiceId.current,
                isPostedBill: isPostedBill.current,
                shelfTags: shelfTags,
            }).then((result) => {
                if (result) {
                    toast.current.show("Barcode labels generated successfully.", "s");
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    const printBill = useReactToPrint({
        content: () => billRef.current,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                const dataDiv = document.getElementById("purchaseBillData");

                // If data not exists
                if (!dataDiv) {
                    // Data not populated yet
                    printBill(); // Recall untill populated completely
                    return;
                }

                // const ticketElement = document.getElementsByClassName("ticket")[0];
                // ticketElement.style.display = "block";

                // For export to PDF
                // const options = {
                //     margin: 0,
                //     filename: "Report.pdf",
                //     jsPDF: { unit: "px", format: [595, 842], orientation: "portrait" },
                // };
                // const exporter = new Html2Pdf(ticketElement, options);
                // await exporter.getPdf(options);

                // For Print to Printer
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            if (!settings.showBillPreview) {
                setBillData([]);
            }
            itemnameTB.current.focus();
        },
        onPrintError: (error) => alert(error),
    })
    const printDuplicateBill = (data) => {
        checkAndPrintBill(data);
    }
    const checkAndPrintBill = (data) => {
        if (settings.showBillPreview) {
            setBillData([]);

            setTimeout(() => {
                setBillData([...data]);
                printBill();
            }, 100);
        } else {
            setBillData([...data]);
            printBill();
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }
    const onEditBtnClick = () => {
        editData();
    }
    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }
    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    const onTempCloseBtnClick = (item) => {
        tempClose();
    }
    const onPostBtnClick = () => {
        insertOrUpdatePurchase();
    }
    const onPendingBillsBtnClick = () => {
        showPendingBillsForm();
    }
    const onPostedBillsBtnClick = () => {
        showPostedBillsForm();
    }
    const onNewBtnClick = () => {
        if (isPostedBill.current) {
            insertOrUpdatePurchase()
        } else if (isPendingBill.current) {
            tempClose();
        } else if (window.confirm("Are you sure to delete this bill and make new?")) {
            apiDeletePurchase({
                id: invoiceId.current,
                isPostedBill: false
            }).then((result) => {
                clearTextboxes();
                clearTextboxesP();
                reloadData();
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "purchase")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        } else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdatePurchase();
        } else if (e.ctrlKey && e.key.toLowerCase() === "t") {
            e.preventDefault();
            e.stopPropagation();
            tempClose();
        } else if (e.altKey && e.key.toLowerCase() === "t") {
            e.preventDefault();
            e.stopPropagation();
            tempClose();
        } else if (e.ctrlKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            showPendingBillsForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            showPostedBillsForm();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            showEntriesForm();
        } else if (e.ctrlKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            onNewBtnClick();
        } else if (e.altKey && e.key.toLowerCase() === "n") {
            e.preventDefault();
            onNewBtnClick();
        } else if (e.ctrlKey && e.key.toLowerCase() === "o") {
            e.preventDefault();
            showOrdersForm();
        } else if (e.altKey && e.key.toLowerCase() === "r") {
            e.preventDefault();
            showRejectedItemsForm();
        } else if (e.altKey && e.key.toLowerCase() === "l") {
            e.preventDefault();
            generateBarcodes(false);
        } else if (e.key.toLowerCase() === "f1") {
            e.preventDefault();
            showSearchItemsForm();
        } else if (e.altKey && e.key.toLowerCase() === "l") {
            e.preventDefault();
            lockPcsCheckbox.current.setChecked(!lockPcsCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "p") {
            e.preventDefault();
            printCheckbox.current.setChecked(!printCheckbox.current.isChecked());
        } else if (e.altKey && e.key.toLowerCase() === "k") {
            e.preventDefault();
            lockCrtnCheckbox.current.setChecked(!lockCrtnCheckbox.current.isChecked());
        }
    }
    const onBarcodeTBKeyDown = async (barcode) => {
        if (barcode !== '') {
            try {
                const result = await apiIsBarcodeExists({
                    purchaseId: invoiceId.current,
                    isPostedBill: isPostedBill.current,
                    barcode: barcode,
                });

                if (result.isExists) {
                    const item = result.data;
                    showDataIntoTextboxes(item);
                } else {
                    alert("Barcode not exists");
                    itemnameTB.current.setText("");
                }
            } catch (err) {
                alert(err.message);
            }
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const text = e.target.value;
            if (isExpression(text)) {
                try {
                    switch (e.currentTarget.id) {
                        case "tb302": //cost
                            costTB.current.setText(evaluate(text));
                            break;
                        case "tb303": //qty
                            qtyTB.current.setText(evaluate(text));
                            break;
                        case "tb304": //crtn
                            crtnTB.current.setText(evaluate(text));
                            break;
                        case "tb307": //retail
                            retailTB.current.setText(evaluate(text));
                            break;
                        case "tb308": // tb8 wholesale
                            wholesaleTB.current.setText(evaluate(text));
                            break;
                        case "tb309": // rate 3
                            rate3TB.current.setText(evaluate(text));
                            break;
                        case "tb310": // tb10 rate 4
                            rate4TB.current.setText(evaluate(text));
                            break;
                        case "tb311": // crtn rate
                            crtnRateTB.current.setText(evaluate(text));
                            break;
                        case "tb312": // market price
                            marketPriceTB.current.setText(evaluate(text));
                            break;
                        default:
                            break;
                    }
                } catch (err) {

                }
            } else {
                const id = e.target.id;
                if (id === "tb308") { // tb8 wholesale
                    if (settings.fourRateSystem === false) {
                        if (crtnRateTB.current.isDisabled())
                            marketPriceTB.current.focus();
                        else
                            crtnRateTB.current.focus();
                        // selectNextElement2(311);
                    }
                } else if (id === "tb310") { // tb10
                    if (settings.fourRateSystem === true) {
                        selectNextElement2(312);
                    }
                } else if (id === "tb303") { // qty
                    if (crtnTB.current.isDisabled())
                        discTB.current.focus();
                    else
                        crtnTB.current.focus();
                }
                selectNextElement(e);
            }
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onVendorCBChange = (e) => {
        const vendorId = vendorCB.current.getValue();
        if (vendorId !== 0) {
            apiGetBalance(vendorId)
                .then((result) => {
                    balanceLB.current.setText(Math.abs(result.balance));
                    balanceLabel.current.setText(result.label);
                    account.current = result.data;

                    if (account.current.disc > 0) {
                        vendorDiscountLB.current.setText(`${account.current.disc}%`);
                    } else {
                        vendorDiscountLB.current.setText("");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });

            apiIsReplaceExistsByVendor(vendorId)
                .then((result) => {
                    if (result.isExists) {
                        messageBox.current.show("You have some replace of this vendor", "Replace found", "i");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });

            itemnameTB.current.focus();
        } else {
            balanceLB.current.setText("0");
            balanceLabel.current.setText("(P)");
            vendorDiscountLB.current.setText("");
            purchaseOnCashRB.current.checked = true;
        }
    }
    const mopCBOnChange = (e) => {
        if (mopCB.current.getValue() === 0) {
            bankCB.current.setValue(0);
            subBankCB.current.setValue(0);
            bankCB.current.disable();
            subBankCB.current.disable();
        } else {
            bankCB.current.enable();
            subBankCB.current.enable();
        }
    }
    const bankCBOnChange = (e) => {
        if (bankCB.current.getValue() !== 0) {
            subBankCB.current.loadData(bankCB.current.getValue());
        }
    }
    const onTextChange = () => {
        const cost = convertToNumber(costTB.current.getText());
        const qty = convertToNumber(qtyTB.current.getText());
        const crtn = convertToInt(crtnTB.current.getText());
        const crtnSize = convertToInt(crtnSizeTB.current.getText());
        const disc = convertToNumber(discTB.current.getText());
        const tax = convertToNumber(taxTB.current.getText());
        const isDiscRsPer = discTB.current.getCBValue() === 1 ? true : false;

        const totalQty = qty + (crtn * crtnSize);

        // Set Discount Labels
        let calculatedDisc = 0;
        if (isDiscRsPer === true) {
            calculatedDisc = disc;
        } else {
            calculatedDisc = (disc / 100) * cost;
        }
        discLB.current.setText(calculatedDisc.toFixed(2));
        if (totalQty !== 0) {
            totalDiscLB.current.setText((calculatedDisc * totalQty).toFixed(2));
        }

        // Set Tax Labels
        let calculatedTax = 0;
        calculatedTax = (tax / 100) * (cost - calculatedDisc);
        taxLB.current.setText(calculatedTax.toFixed(2));
        if (totalQty !== 0) {
            totalTaxLB.current.setText((calculatedTax * totalQty).toFixed(2));
        }

        // Set Cost & Total Qty Labels
        const finalCost = (cost - calculatedDisc) + calculatedTax;
        costLB.current.setText(toDecimal(finalCost, 3));
        costCrtnLB.current.setText(toDecimal(finalCost * crtnSize, 3));
        totalQtyLB.current.setText(totalQty.toFixed(2));

        // Set Total Label
        let total = cost * totalQty;
        let grossTotal = cost * totalQty;
        if (totalQty !== 0) {
            total = (total - (calculatedDisc * totalQty)) + (calculatedTax * totalQty);
        }
        grossTotalLB.current.setText(toDecimal(grossTotal));
        totalLB.current.setText(toDecimal(total));
    }
    const onBarcodeNotFound = () => {
        clearTextboxes()
    }
    const onBillChange = (e) => {
        if (printSizeCB.current.getValue() === printSizes.thermal && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("thermalEnglish");
        else if (printSizeCB.current.getValue() === printSizes.thermal && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("thermalUrdu");
        else if (printSizeCB.current.getValue() === printSizes.a4 && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("a4English");
        else if (printSizeCB.current.getValue() === printSizes.a4 && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("a4Urdu");
        else if (printSizeCB.current.getValue() === printSizes.a5 && printLanguageCB.current.getValue() === printLanguages.english)
            setActiveBill("a5English");
        else if (printSizeCB.current.getValue() === printSizes.a5 && printLanguageCB.current.getValue() === printLanguages.urdu)
            setActiveBill("a5Urdu");
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "purchase" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        } else {
            setActiveComponent(null);
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>

            {/* Modals */}
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "pending":
                            return <PendingBillsComponent
                                ref={pendingBillsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                printBill={printDuplicateBill}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onEdit={onEditPendingBill}
                            />
                        case "posted":
                            return <PostedBillsComponent
                                ref={postedBillsFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isActive={isActive}
                                printBill={printDuplicateBill}
                                setActiveComponent={setActiveComponent}
                                onEdit={onEditPostedBill}
                            />
                        case "entries":
                            return <EntriesComponent
                                ref={entriesFormRef}
                                messageBox={messageBox}
                                passwordBox={passwordBox}
                                toast={toast}
                                isPayment={true}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                            />
                        case "search":
                            return <SearchItemsComponent
                                ref={searchItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                setActiveComponent={setActiveComponent}
                                onItemSelect={onSearchItemSelected}
                            />
                        case "rejectedItems":
                            return <RejectedItemsComponent
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                mainActiveTab={props.mainActiveTab}
                                setActiveComponent={setActiveComponent}
                                onItemSelect={onRejectedItemsSelect}
                            />
                        case "orders":
                            return <PurchaseOrdersComponent
                                ref={purchaseOrderRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                mainActiveTab={props.mainActiveTab}
                                setActiveComponent={setActiveComponent}
                                setActiveComponent2={setActiveComponent2}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div>
                {(() => {
                    switch (activeComponent2) {
                        case "search":
                            return <SearchItemsComponent
                                ref={searchItemsFormRef}
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                // setActiveComponent={setActiveComponent}
                                setActiveComponent2={setActiveComponent2}
                                onItemSelect={(item) => { purchaseOrderRef.current.onSearchItemSelected(item); }}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} className="mainContainer containerWidth">
                {/* Top */}
                <div className="topPanel" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* Left */}
                        <div style={{ minWidth: '460px', maxWidth: '480px', width: '100%', }}>
                            {/* Vendor Group */}
                            <div className="balanceGroup">
                                <VendorCB
                                    ref={vendorCB}
                                    tabIndex={301}
                                    onChange={onVendorCBChange} />

                                <div className="balanceGroupBottom">
                                    <div className="left">
                                        <PTag
                                            ref={vendorDiscountLB}
                                            style={{ margin: '0px', fontSize: '14px' }}
                                        />
                                    </div>

                                    <div className="balance center" style={{ marginLeft: '64px' }}>
                                        <p><b>Balance: </b><Span ref={balanceLB} text="0" /> <Span ref={balanceLabel} text="(P)" /></p>
                                    </div>

                                    <div className="right">
                                        <Checkbox
                                            ref={updateVendorCheckbox}
                                            label="Update"
                                            style={{ marginTop: '1px' }}
                                            onChange={() => { }} />
                                    </div>
                                </div>
                            </div>

                            {/* MOP Group */}
                            <div className="mopGroup" style={{ marginTop: 'var(--defaultMargin)' }}>
                                <div style={{ display: 'flex', flexDirection: 'colums', width: '100%' }}>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="mop">
                                            <p>M.O.P</p>
                                        </div>
                                        <div className="mopCB">
                                            <ComboBox
                                                ref={mopCB}
                                                data={[{ id: 0, name: 'Cash' }, { id: 1, name: 'Bank' }]}
                                                style={{ width: '100%' }}
                                                onChange={mopCBOnChange} />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <BankCBMOP
                                            ref={bankCB}
                                            style={{ flex: 'auto', width: '100%' }}
                                            className={"cb1"}
                                            onChange={bankCBOnChange} />
                                        <SubBankCBMOP
                                            ref={subBankCB}
                                            style={{ flex: 'auto', width: '100%' }}
                                            className={"cb2"}
                                            onChange={() => { }} />
                                    </div>

                                </div>

                            </div>

                            <Textbox
                                ref={narationTB}
                                label="Naration"
                                tabIndex={313}
                                onKeyDown={() => { }} />

                            {/* Radio Group */}
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-2px' }}>
                                <div className="radioGroup" style={{ width: '200px', marginTop: 'var(--defaultMargin)' }}>
                                    <div className="header">
                                        <p>Purchase On</p>
                                    </div>
                                    <div className="radioBody">
                                        <div className="myRadio">
                                            <div className="radioItem">
                                                <input ref={purchaseOnCashRB} id="purchaseOnCashRB" className="form-check-input" type="radio" name="purchaseOn" defaultChecked onChange={() => { }} />
                                                <label htmlFor="purchaseOnCashRB">Cash</label>
                                            </div>
                                            <div className="radioItem">
                                                <input ref={purchaseOnCreditRB} id="purchaseOnCreditRB" className="form-check-input" type="radio" name="purchaseOn" onChange={() => { }} />
                                                <label htmlFor="purchaseOnCreditRB">Credit</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="radioGroup defaultMarginLeft" style={{ width: '300px', marginTop: 'var(--defaultMargin)' }}>
                                    <div className="header">
                                        <p>Purchase Type</p>
                                    </div>
                                    <div className="radioBody">
                                        <div className="myRadio">
                                            <div className="radioItem">
                                                <input ref={purchaseTypePurchaseRB} id="purchaseTypePurchaseRB" className="form-check-input" type="radio" name="purchaseType" defaultChecked onChange={() => { }} />
                                                <label htmlFor="purchaseTypePurchaseRB">Purchase</label>
                                            </div>
                                            <div className="radioItem">
                                                <input ref={purchaseTypeReturnRB} id="purchaseTypeReturnRB" className="form-check-input" type="radio" name="purchaseType" onChange={() => { }} />
                                                <label htmlFor="purchaseTypeReturnRB">Return</label>
                                            </div>
                                            <div className="radioItem">
                                                <input ref={purchaseTypeRejectionRB} id="purchaseTypeRejectionRB" className="form-check-input" type="radio" name="purchaseType" onChange={() => { }} />
                                                <label htmlFor="purchaseTypeRejectionRB">Rejection</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Middle */}
                        <div className="defaultMarginLeft" style={{ minWidth: '270px', maxWidth: '270px', width: '100%', }}>
                            <WarehouseCB
                                ref={warehouseCB} />

                            <div className={`${userRights.dateWisePurchase ? "d-block" : "d-none"}`}>
                                <DateTimePicker
                                    ref={dateTB}
                                    label="Date"
                                    time={false} />
                            </div>

                            <Textbox
                                ref={refInvoiceNoTB}
                                label="Ref. Invoice No."
                                tabIndex={314}
                                // onKeyDown={onTextboxesKeyDown}
                                style={{ flex: 'auto' }} />

                            <div className="discountGroup">
                                <TextboxCB
                                    ref={pDiscTB}
                                    label="Discount"
                                    tabIndex={315}
                                    onChange={onDiscTextChange}
                                    onCBChange={onDiscTextChange}
                                // onKeyDown={onTextboxesKeyDown}
                                />
                                <div className="balance">
                                    <PTag ref={pTotalDiscLB} text="0" />
                                </div>
                            </div>

                            <div className="grandTotal">
                                <p><b>Total: </b><Span ref={grandTotalLB} /></p>
                            </div>
                        </div>
                        {/* Right */}
                        <div className="buttons thinButtons">
                            <div className="dRow">
                                <div className="dRow" style={{ marginTop: '-8px', width: '100%' }}>
                                    <PrintSizesCB
                                        ref={printSizeCB}
                                        style={{ width: '100%' }}
                                        onChange={onBillChange} />

                                    <PrintLanguagesCB
                                        ref={printLanguageCB}
                                        style={{ width: '100%' }}
                                        className="defaultMarginLeft"
                                        onChange={onBillChange} />
                                </div>
                                <Checkbox
                                    ref={printCheckbox}
                                    label="Print"
                                    style={{ marginTop: '0px' }}
                                    className="defaultMarginLeft"
                                    onChange={() => { }} />
                            </div>
                            <div className="row1" style={{ marginTop: '8px' }}>
                                <ThinButton
                                    label="Temp Close"
                                    onClick={onTempCloseBtnClick} />

                                <ThinButton
                                    label="Pending Bills"
                                    onClick={onPendingBillsBtnClick}
                                    className="centerBtnMargin" />

                                <ThinButton
                                    label="Posted Bills"
                                    onClick={onPostedBillsBtnClick} />
                            </div>
                            <div className="row1">
                                <ThinButton
                                    label="Entry"
                                    onClick={showEntriesForm} />

                                <ThinButton
                                    label="Search Item"
                                    onClick={showSearchItemsForm}
                                    className="centerBtnMargin" />

                                <ThinButton
                                    label="New Bill"
                                    onClick={onNewBtnClick} />
                            </div>
                            <div className="row1">
                                <ThinButton
                                    label="Orders"
                                    onClick={showOrdersForm} />

                                <ThinButton
                                    label="Rejections"
                                    onClick={showRejectedItemsForm}
                                    className="centerBtnMargin" />

                                <ThinButton
                                    label="Barcodes"
                                    onClick={() => { generateBarcodes(false) }} />
                            </div>
                            <div className="row1">
                                <ThinButton
                                    label="Orders"
                                    onClick={showOrdersForm}
                                    className="invisible" />

                                <ThinButton
                                    label="Rejections"
                                    onClick={showRejectedItemsForm}
                                    className="centerBtnMargin invisible" />

                                <ThinButton
                                    label="Shelf Tags"
                                    onClick={() => { generateBarcodes(true) }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="separator"></div>

                {/* Middle */}
                <div className="middlePanel" style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Left */}
                    <div style={{ minWidth: '460px', maxWidth: '480px', width: '100%' }}>
                        <AutocompleteTextboxPurchase
                            ref={itemnameTB}
                            label="Barcode/Itemname"
                            tabIndex={301}
                            onBarcodeNotFound={onBarcodeNotFound}
                            onEnter={onBarcodeTBKeyDown}
                            style={{ width: '100%' }} />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <Textbox
                                    ref={costTB}
                                    label="Cost"
                                    tabIndex={302}
                                    onChange={onTextChange}
                                    onKeyDown={onTextboxesKeyDown}
                                    style={{ flex: 'auto' }} />

                                <Textbox
                                    ref={qtyTB}
                                    label="Qty"
                                    tabIndex={303}
                                    onChange={onTextChange}
                                    onKeyDown={onTextboxesKeyDown}
                                    style={{ flex: 'auto' }} />
                                <Textbox
                                    ref={crtnTB}
                                    label="Cartons"
                                    tabIndex={304}
                                    onFocus={() => {
                                        if (!settings.saleCartons) {
                                            costTB.current.focus();
                                        }
                                    }}
                                    onChange={onTextChange}
                                    onKeyDown={onTextboxesKeyDown}
                                    style={{ flex: 'auto' }} />

                                <Textbox
                                    ref={crtnSizeTB}
                                    label="PCS in Carton"
                                    onFocus={() => {
                                        costTB.current.focus();
                                    }}
                                    style={{ flex: 'auto' }} />
                            </div>
                            <div style={{ marginLeft: 'var(--defaultMargin)', display: 'flex', flexDirection: 'column', flex: 1 }}>

                                <div className="dCol jcsb" style={{ height: '100%' }}>
                                    <div>
                                        <div className="dRow">
                                            <TextboxCB
                                                ref={discTB}
                                                label="Discount"
                                                tabIndex={305}
                                                style={{ flex: '1' }}
                                                onChange={onTextChange}
                                                onCBChange={onTextChange}
                                                onKeyDown={onTextboxesKeyDown}
                                            />
                                            <Checkbox
                                                ref={freezeDiscCheckbox}
                                                className="defaultMarginLeft "
                                                onChange={() => { }} />
                                        </div>
                                        <div className="dRow">
                                            <Textbox
                                                ref={taxTB}
                                                label="Advance Tax"
                                                tabIndex={306}
                                                style={{ flex: '1' }}
                                                onChange={onTextChange}
                                                onCBChange={onTextChange}
                                                onKeyDown={onTextboxesKeyDown}
                                            />
                                            <Checkbox
                                                ref={freezeTaxCheckbox}
                                                className="defaultMarginLeft"
                                                checked={true}
                                                onChange={() => { }} />
                                        </div>

                                        <div className="cost dRow jcsb">
                                            <span><b>Disc: </b><Span ref={discLB} text="0" /></span>
                                            <span><b>Tax: </b><Span ref={taxLB} text="0" /></span>
                                        </div>
                                        <div className="cost dRow jcsb">
                                            <span><b>Cost: </b><Span ref={costLB} text="0" /></span>
                                            <span><b>Cost Crtn: </b><Span ref={costCrtnLB} text="0" /></span>
                                        </div>
                                        <div className="cost dRow jcsb" style={{ marginTop: '8px' }}>
                                            <span><b>Total Qty: </b><Span ref={totalQtyLB} text="0" /></span>
                                            <span><b>Total: </b><Span ref={grossTotalLB} text="0" /></span>
                                        </div>
                                        <div className="cost dRow jcsb d-none">
                                            <span><b>T.Disc: </b><Span ref={totalDiscLB} text="0" /></span>
                                            <span><b>T.Tax: </b><Span ref={totalTaxLB} text="0" /></span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Middle */}
                    <div className="defaultMarginLeft" style={{ minWidth: '120px', maxWidth: '240px', width: '100%' }}>
                        <Textbox
                            ref={retailTB}
                            label="Retail"
                            tabIndex={307}
                            onKeyDown={onTextboxesKeyDown}
                        />
                        <Textbox
                            ref={wholesaleTB}
                            label="Wholesale"
                            tabIndex={308}
                            onKeyDown={onTextboxesKeyDown}
                        />

                        <div className={`${settings.fourRateSystem ? "d-block" : "d-none"}`}>
                            <Textbox
                                ref={rate3TB}
                                label="Rate 3"
                                tabIndex={309}
                                onKeyDown={onTextboxesKeyDown}
                            />

                            <Textbox
                                ref={rate4TB}
                                label="Rate 4"
                                tabIndex={310}
                                onKeyDown={onLastTextboxKeyDown}
                            />

                        </div>

                        <div className={`${!settings.fourRateSystem ? "d-block" : "d-none"}`}>
                            <Textbox
                                ref={crtnRateTB}
                                label="Carton Rate"
                                tabIndex={311}
                                onFocus={() => {
                                    if (!settings.saleCartons) {
                                        costTB.current.focus();
                                    }
                                }}
                                onKeyDown={onTextboxesKeyDown}
                            />

                            <Textbox
                                ref={marketPriceTB}
                                label="Market Price"
                                tabIndex={312}
                                onKeyDown={onLastTextboxKeyDown}
                            />

                            <div className="total dRow jcs">
                                <span><b>Net Total: </b><Span ref={totalLB} text="0" /></span>
                            </div>
                        </div>


                    </div>
                    {/* Right */}
                    <div style={{ marginLeft: 'var(--defaultMargin)', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ minWidth: '200px', marginTop: '-10px' }}>
                                <div className="headerTb">
                                    <label>Current Stock</label>
                                    <div className="header">
                                        <label>Qty</label>
                                        <label>Crtn</label>
                                    </div>
                                    <div className="doubleTb">
                                        <Label
                                            ref={currentStockPcsLB}
                                            text="0"
                                        />
                                        <div></div>
                                        <Label
                                            ref={currentStockCrtnLB}
                                            text="0"
                                        />
                                    </div>
                                </div>

                                <div className="tableFooter" style={{ marginTop: '2px' }}>
                                    <p><b>Location: </b><Span ref={locationLB} /></p>
                                </div>

                                <DateTimePicker
                                    ref={expiryTB}
                                    label="Expiry"
                                    time={false} />
                            </div>
                            <div className="defaultMarginLeft" style={{ marginTop: '-3px' }}>
                                <Checkbox
                                    ref={saleCurrentStockFirstCheckbox}
                                    label="Sale Current Stock First"
                                    onChange={() => { }} />

                                <Checkbox
                                    ref={lockPcsCheckbox}
                                    label="Lock PCS"
                                    onChange={() => { }} />
                                <Checkbox
                                    ref={lockCrtnCheckbox}
                                    label="Lock CRTN"
                                    onChange={() => { }} />
                            </div>
                        </div>

                        <div className="buttons" style={{ height: '100%' }}>
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>
                    </div>
                </div>

                {/* Bottom */}
                <Table
                    ref={tableRef}
                    columns={settings.saleCartons ? columnsC.current : columns.current}
                    style={{ marginTop: '2px' }}
                    myTable='purchaseItemsTable'
                    // autoSelectFirstRow={true}
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    onEnterKeyDown={(data) => { alert(data['itemname']) }}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: true, onClick: () => { deleteData() } }} />

                <div className="bottom">
                    <div className="tableFooter">
                        <p><b>Total Items: </b>{totalItems}</p>
                        <p style={{ marginLeft: '10px' }}><b>Total Qty: </b>{totalQty}</p>
                        <p style={{ marginLeft: '10px' }}><b>Total Crtn: </b>{totalCrtn}</p>
                        <p style={{ marginLeft: '10px' }}><b>Item Cost: </b>{formData.current ? toDecimal(formData.current.cost, 3) : null}</p>
                        <p style={{ marginLeft: '10px' }}><b>Old Costs: </b>{oldRates}</p>
                    </div>
                    <div>
                        <ButtonIcon
                            label="Post"
                            icon="icons/buttons/save.png"
                            onClick={onPostBtnClick} />
                    </div>
                </div>
            </div>


            {(() => {
                switch (activeBill) {
                    case "thermalEnglish":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <ThermalEnglish ref={billRef} data={billData} />
                        </div>
                    case "thermalUrdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <ThermalUrdu ref={billRef} data={billData} />
                        </div>
                    case "a4English":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A4English ref={billRef} data={billData} />
                        </div>
                    case "a4Urdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A4Urdu ref={billRef} data={billData} />
                        </div>
                    case "a5English":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A5English ref={billRef} data={billData} />
                        </div>
                    case "a5Urdu":
                        return <div style={{ display: `${settings.showBillPreview ? "block" : "none"}`, margin: '15px' }}>
                            <A5Urdu ref={billRef} data={billData} />
                        </div>
                    default:
                        return <div></div>;
                }
            })()}

        </>
    )
}

export default PurchaseComponent;