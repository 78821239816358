import { post } from 'utilities/DB.js';

export const apiLoadGatepassItems = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/loadGatepassItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertGatepassItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/insertGatepassItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateGatepassItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/updateGatepassItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteGatepassItem = (gatepassItemId) => {
    const params = {
        id: gatepassItemId
    }
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/deleteGatepassItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetGatepassItem = (gatepassItemId) => {
    const params = {
        id: gatepassItemId
    }
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/getGatepassItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsGatepassItemExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/gatepassItems/isGatepassItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
