import { post, uploadImage, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadSubCategories = (params) => {
    params.branchGroupId = getBranchGroupId();;
    return new Promise((resolve, reject) => {
        post("api/subCategories/loadSubCategories", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertSubCategory = (params) => {
    params.branchGroupId = getBranchGroupId();;
    return new Promise((resolve, reject) => {
        post("api/subCategories/insertSubCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSubCategory = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/subCategories/updateSubCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteSubCategory = (subCategoryId) => {
    const params = {
        id: subCategoryId,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/subCategories/deleteSubCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetSubCategory = (subCategoryId) => {
    const params = {
        id: subCategoryId
    }
    return new Promise((resolve, reject) => {
        post("api/subCategories/getSubCategory", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsSubCategoryExists = (params) => {
    params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/subCategories/isSubCategoryExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/subCategories/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
