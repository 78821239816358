import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const TextboxNoLabel = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const onKeyDown = props.onKeyDown ? props.onKeyDown : () => { };
    const onFocus = props.onFocus ? props.onFocus : () => { };
    const style = props.style;
    const tabIndex = props.tabIndex;

    // Variables
    const [value, setValue] = useState("");
    const [readonly, setReadonly] = useState(false);

    // eslint-disable-next-line
    const [focused, setFocused] = useState(false);
    const reference = useRef();
    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            getText:()=> current.current,
            setText:(val)=>{setValue(val); },
            focus:()=>{reference.current.focus();},
            enable:()=>{reference.current.disabled = false},
            disable:()=>{reference.current.disabled = true},
            setReadonly:setReadonly,
        };
    });

    const onChangeHandle = (e) => {
        setValue(e.target.value);
    }

    const onFocusHandle = (e) => {
        setFocused(true);
        onFocus();
    }

    useEffect(()=>{
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <input 
            ref={reference} 
            type="text" 
            className={`${className}`} 
            style={style} 
            value={value} 
            onChange={onChangeHandle} 
            onFocus={onFocusHandle}
            onKeyDown={onKeyDown} tabIndex={tabIndex} id={`tb${tabIndex}`} spellCheck="false" readOnly={readonly}/>
        </>
    )
});

export default TextboxNoLabel;