export const post = async (url, body, addBranchId = true, addClientId = true) => {
    return new Promise((resolve, reject) => {

        if (addBranchId === true) {
            body.branchId = getBranchId();
        }
        
        if (addClientId === true) {
            body.clientId = getClientId();
        }

        fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }).then(async (res) => {
            if (res.status === 200)
                return res.json();
            else {
                const error = await res.text();
                throw new Error(error);
            }
        }).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    })
}

export const uploadImage = async (url, body, addBranchId = true, addClientId = true) => {
    return new Promise((resolve, reject) => {
        
        if (addBranchId === true) {
            body.append('branchId', getBranchId());
        }
        
        if (addClientId === true) {
            body.append('clientId', getClientId());
        }

        fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
            method: "POST",
            body: body
        }).then(async (res) => {
            if (res.status === 200)
                return res.json();
            else {
                const error = await res.text();
                throw new Error(error);
            }
        }).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    })

}

export const getClientId = () => {
    return Number(localStorage.getItem('clientId')) || 0;
}

export const getBranchId = () => {
    return Number(sessionStorage.getItem('branchId')) || 0;
}

export const getBranchGroupId = () => {
    return Number(sessionStorage.getItem('branchGroupId')) || 0;
}

export const getUserId = () => {
    return Number(sessionStorage.getItem('userId')) || 0;
}


export const setClientId = (clientId) => {
    localStorage.setItem("clientId", clientId);
}

export const setBranchId = (branchId) => {
    sessionStorage.setItem("branchId", branchId);
}

export const setBranchGroupId = (branchGroupId) => {
    sessionStorage.setItem("branchGroupId", branchGroupId);
}

export const setUserId = (userId) => {
    sessionStorage.setItem("userId", userId);
}
