import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import ComboBox from 'components/tools/ComboBox.js';
import { useSelector } from 'react-redux';

const SubExpenseCB = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const style = props.style;

    // Variables
    const subExpenses = useSelector(state => state.main.subExpenses);
    const [data, setData] = useState(subExpenses);
    let reference = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: reference.current.getValue,
            setValue: reference.current.setValue,
            getText: reference.current.getText,
            loadData: loadSubExpenses,
            enable:reference.current.enable,
            disable:reference.current.disable,
        };
    });

    useEffect(() => {
        setData([...subExpenses]);
        // eslint-disable-next-line
    }, [subExpenses]);

    const loadSubExpenses = (expenseId) => {
        if(expenseId === 0){
            setData([...subExpenses]);
            reference.current.setValue(0);
        }else{
            let temp1 = [
                { id: 0, name: 'None', expenseId: 0 }
            ];
    
            const temp2 = subExpenses.filter((subExpense) => Number(subExpense.expenseId) === expenseId);
            if (temp2.length > 0) {
                temp1 = temp1.concat(temp2);
            }
            setData([...temp1]);
        }
    }

    return (
        <>
            <ComboBox
                ref={reference}
                label="Sub-Expense"
                className={className}
                data={data}
                onChange={onChange}
                style={style} />
        </>
    )
});

export default SubExpenseCB;