import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect} from 'react';

const Textarea = forwardRef((props, ref) => {
    // Props
    const label = props.label;
    const className = props.className;
    const onChange = props.onChange ? props.onChange : ()=>{};
    const onKeyDown = props.onKeyDown ? props.onKeyDown : ()=>{};
    const style = props.style;
    const tabIndex = props.tabIndex;

    // Variables
    const [value, setValue] = useState("");
    const reference = useRef();
    const current = useRef(value);
    useImperativeHandle(ref, () => {
        return {
            getText:()=> current.current,
            setText:(val)=>{setValue(val)},
            focus:()=>{reference.current.focus();}
        };
    });

    const onChangeHandle = (e)=>{
        setValue(e.target.value);
    }

    useEffect(() => {
        current.current = value;
        onChange(value);
        // eslint-disable-next-line
    }, [value]);

    return (
        <>
            <div className={`tb ${className}`} style={style}>
                <div><label>{label}</label></div>
                <textarea ref={reference} type="text" value={value} onChange={onChangeHandle} onKeyDown={onKeyDown} tabIndex={tabIndex} id={`tb${tabIndex}`} spellCheck="false"/>
            </div>
        </>
    )
});

export default Textarea;