import { post } from 'utilities/DB.js';

export const apiLoadPackages = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packages/loadPackages", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertPackage = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packages/insertPackage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePackage = (params) => {
    // params.branchGroupId = getBranchGroupId();
    return new Promise((resolve, reject) => {
        post("api/packages/updatePackage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePackage = (packageId) => {
    const params = {
        id: packageId,
        // branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/packages/deletePackage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPackage = (packageId) => {
    const params = {
        id: packageId
    }
    return new Promise((resolve, reject) => {
        post("api/packages/getPackage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsPackageExists = (packageName) => {
    const params = {
        packageName: packageName,
        // branchGroupId : getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/packages/isPackageExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGeneratePackage = (params) => {
    return new Promise((resolve, reject) => {
        post("api/packages/generatePackage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
