/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/warehouse/transferStock.css';

import { convertToInt, convertToNumber } from "utilities/Utils";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import WarehouseCB from 'components/tools/dropdowns/WarehouseCB';
import AutocompleteTextbox from 'components/tools/AutocompleteTextbox';
import DateTimePicker from 'components/tools/DateTimePicker';
import ThinButton from 'components/tools/ThinButton';
import { apiDeleteStockEntry, apiGetStockEntry, apiInsertStockEntry, apiIsBarcodeExists, apiIsStockEntryExists, apiLoadStockEntries, apiLoadWarehouseEntries, apiLoadWarehouseEntryItems, apiStockReceiveFromWarehouse, apiStockTransferToWarehouse, apiUpdateStockEntry } from 'api/warehouse/StockEntryApi';
import Label from 'components/tools/Label';
import LoadGatepassComponent from './LoadGatepassComponent';
/* #endregion Imports */

const TransferStockComponent = (props) => {

    /* #region Variables */
    const formId = "transferStockForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);

    const itemnameTB = useRef();
    const itemId = useRef(0);
    const qtyTB = useRef();
    const crtnTB = useRef();
    const dateTB = useRef();
    const warehouseCB = useRef();
    const stockPcs = useRef();
    const stockCrtn = useRef();
    const wStockPcs = useRef();
    const wStockCrtn = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const tableWRef = useRef();
    const tableWIRef = useRef();
    const [totalStockEntries, setTotalStockEntries] = useState(0);
    const [totalWarehouseEntries, setTotalWarehouseEntries] = useState(0);
    const [totalWarehouseEntryItems, setTotalWarehouseEntryItems] = useState(0);
    const [activeComponent, setActiveComponent] = useState(null);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    const columnsW = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Type', row: "type", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Date', row: "date", sortBy: "string", style: { minWidth: '200px' } },
    ]);

    const columnsWI = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Itemname', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        // loadData();

        // warehouseNameTB.current.focus();
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadStockEntries({
            warehouseId: warehouseCB.current.getValue()
        }).then((result) => {
            try {
                setTotalStockEntries(result.total.totalStockEntries);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadWarehosueEntries = () => {
        if (tableWRef.current !== null)
            tableWRef.current.setLoading(true);

        apiLoadWarehouseEntries({
            warehouseId: warehouseCB.current.getValue(),
            date: dateTB.current.getText(),
        }).then((result) => {
            try {
                if (result.rows.length > 0) {
                    setTotalWarehouseEntries(result.total.totalWarehouseEntries);
                    tableWRef.current.setData([...result.rows]);
                    tableWRef.current.setLoading(false);

                    const warehouseEntryId = result.rows[0]['id'];
                    loadWarehosueEntryItems(warehouseEntryId);
                } else {
                    tableWRef.current.setLoading(false);
                    tableWRef.current.clearData();
                    tableWIRef.current.clearData();
                }
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadWarehosueEntryItems = (warehouseEntryId) => {
        if (tableWIRef.current !== null)
            tableWIRef.current.setLoading(true);

        apiLoadWarehouseEntryItems({
            warehouseEntryId: warehouseEntryId,
        }).then((result) => {
            try {
                setTotalWarehouseEntryItems(result.total.totalWarehouseEntryItems);
                tableWIRef.current.setData([...result.rows]);
                tableWIRef.current.setLoading(false);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateStockEntry(data)
                    .then((result) => {
                        inserted.current = true;
                        // toast.current.show("Entry updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertStockEntry(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        // toast.current.show("Entry saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetStockEntry({
                id:tempId,
                warehouseId: warehouseCB.current.getValue(),
            }).then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Warehouse?")) {
                apiDeleteStockEntry(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Entry deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (window.confirm("Are you sure to delete this Warehouse?")) {
                apiDeleteStockEntry(updateId.current)
                    .then((result) => {
                        toast.current.show("Entry deleted successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxes = () => {
        itemnameTB.current.setText("");
        qtyTB.current.setText("");
        crtnTB.current.setText("");
        stockPcs.current.setText("");
        stockCrtn.current.setText("");
        wStockPcs.current.setText("");
        wStockCrtn.current.setText("");

        qtyTB.current.enable();
        crtnTB.current.enable();

        updateId.current = 0;
        isUpdate.current = false;
        itemId.current = 0;
        formData.current = null;

        itemnameTB.current.focus();
    }
    const setFormData = (entry) => {
        formData.current = entry;

        itemnameTB.current.setText(entry.itemname);
        itemId.current = entry.itemId;
        qtyTB.current.setText(entry.qty);
        crtnTB.current.setText(entry.crtn);

        stockPcs.current.setText(entry.stockPcs);
        stockCrtn.current.setText(entry.stockCrtn);
        wStockPcs.current.setText(entry.wStockPcs);
        wStockCrtn.current.setText(entry.wStockCrtn);

        if(entry.crtnSize === 0){
            qtyTB.current.enable();
            crtnTB.current.disable();
        }else if(entry.crtnSize === 1){
            qtyTB.current.disable();
            crtnTB.current.enable();
        }else if(entry.crtnSize > 1){
            qtyTB.current.enable();
            crtnTB.current.enable();
        }

        qtyTB.current.focus();
    }
    const getFormData = () => {
        const warehouse = {
            warehouseId: warehouseCB.current.getValue(),
            itemId: itemId.current,
            qty: convertToNumber(qtyTB.current.getText()),
            crtn: convertToInt(crtnTB.current.getText()),
        }

        return warehouse;
    }
    const showDataIntoTextboxes = (item) => {
        itemId.current = Number(item.id);
        itemnameTB.current.setText(item.itemname);

        stockPcs.current.setText(item.stockPcs);
        stockCrtn.current.setText(item.stockCrtn);
        wStockPcs.current.setText(item.wStockPcs);
        wStockCrtn.current.setText(item.wStockCrtn);

        if(item.crtnSize === 0){
            qtyTB.current.enable();
            crtnTB.current.disable();
        }else if(item.crtnSize === 1){
            qtyTB.current.disable();
            crtnTB.current.enable();
        }else if(item.crtnSize > 1){
            qtyTB.current.enable();
            crtnTB.current.enable();
        }

        qtyTB.current.focus();
    }
    const validation = async () => {
        if (warehouseCB.current.getValue() === 0) {
            toast.current.show("Please Select Warehouse.", "i");
            itemnameTB.current.focus();
            return false;
        } else if (itemId.current === 0) {
            toast.current.show("Please Select Item.", "i");
            itemnameTB.current.focus();
            return false;
        } else if (convertToNumber(qtyTB.current.getText()) === 0 && convertToInt(crtnTB.current.getText()) === 0) {
            toast.current.show("Please Enter Quantity.", "i");
            qtyTB.current.focus();
            return false;
        } else {
            if (isUpdate.current) {
                if (itemId.current === formData.current.itemId)
                    return true;
                else
                    return isStockEntryExists();
            }
            else
                return isStockEntryExists();
        }
    }
    const isStockEntryExists = async () => {
        const result = await apiIsStockEntryExists({
            warehouseId: warehouseCB.current.getValue(),
            itemId: itemId.current,
        });
        if (result.isExists) {
            toast.current.show("Item already exists.", "i");
            itemnameTB.current.focus();
            return false;
        } else
            return true;
    }
    const transfered = useRef(true);
    const stockTransferToWarehouse = () => {
        if (warehouseCB.current.getValue() === 0) {
            toast.current.show("Please Select Warehouse.", "i");
            itemnameTB.current.focus();
            return false;
        } else if (tableRef.current.getData().length === 0) {
            return false;
        } else {
            if (transfered.current === false)
                return;
            else
                transfered.current = false;


            apiStockTransferToWarehouse(warehouseCB.current.getValue())
                .then((result) => {
                    transfered.current = true;
                    if (result.result) {
                        toast.current.show("Stock Transfered successfully.", "s");
                        loadData();
                        loadWarehosueEntries();
                        clearTextboxes();
                    } else {
                        messageBox.current.show(result.message, "Warning", "i");
                    }
                }).catch((err) => {
                    transfered.current = true;
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    const received = useRef(true);
    const stockReceiveFromWarehouse = () => {
        if (warehouseCB.current.getValue() === 0) {
            toast.current.show("Please Select Warehouse.", "i");
            itemnameTB.current.focus();
            return false;
        } else if (tableRef.current.getData().length === 0) {
            return false;
        } else {
            if (received.current === false)
                return;
            else
                received.current = false;


            apiStockReceiveFromWarehouse(warehouseCB.current.getValue())
                .then((result) => {
                    received.current = true;
                    if (result.result) {
                        toast.current.show("Stock Received successfully.", "s");
                        loadData();
                        loadWarehosueEntries();
                        clearTextboxes();
                    } else {
                        messageBox.current.show(result.message, "Warning", "i");
                    }
                }).catch((err) => {
                    received.current = true;
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    const showGatepassForm = () => {
        setActiveComponent('gatepass');
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "warehouse")
            return;

        if (activeTab.current !== "transferstock")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onBarcodeTBKeyDown = async (barcode) => {
        if (barcode !== '') {
            const warehouseId = warehouseCB.current.getValue();
            if (warehouseId === 0) {
                toast.current.show("Please Select Warehouse.", "i");
            } else {
                try {
                    const result = await apiIsBarcodeExists({
                        warehouseId: warehouseCB.current.getValue(),
                        barcode: barcode,
                    });

                    if (result.isExists) {
                        const item = result.data;
                        console.log(item);
                        showDataIntoTextboxes(item);
                    } else {
                        alert("Barcode not exists");
                        itemnameTB.current.setText("");
                    }
                } catch (err) {
                    alert(err.message);
                }
            }
        }
    }
    const onQtyTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            if(crtnTB.current.isDisabled())
                insertOrUpdateData();
            else
                crtnTB.current.focus();
        }
    }
    const onLastTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateData();
        }
    }
    const onWarehouseEntriesTableEnterKeyDown = () => {
        const row = tableWRef.current.getSelectedRow();
        const id = Number(row['id']);
        loadWarehosueEntryItems(id);
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onWarehouseCBChange = () => {
        loadData();
        loadWarehosueEntries();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "transferstock" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "warehouse" && props.activeTab === "transferstock" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div>
                {(() => {
                    switch (activeComponent) {
                        case "gatepass":
                            return <LoadGatepassComponent
                                messageBox={messageBox}
                                toast={toast}
                                isActive={isActive}
                                data={{ warehouseId: warehouseCB.current.getValue() }}
                                loadGatepass={loadData}
                                setActiveComponent={setActiveComponent}
                            />
                        default:
                            return <div></div>;
                    }
                })()}
            </div>

            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <WarehouseCB
                    ref={warehouseCB}
                    onChange={onWarehouseCBChange} />

                <div className="mainPanel">
                    <div className='leftPanel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Stock Transfer Entry</h4>
                        </div>
                        <AutocompleteTextbox
                            ref={itemnameTB}
                            label="Select Item"
                            tabIndex={1}
                            fetchData={false}
                            onEnter={onBarcodeTBKeyDown}
                            onBarcodeNotFound={() => { itemnameTB.current.focus() }}
                            showAlert={true} />

                        <div className='dRow jcsb'>
                            <div className="headerTb" style={{ flex: '1' }}>
                                <label>Current Stock</label>
                                <div className="header">
                                    <label>Qty</label>
                                    <label>Crtn</label>
                                </div>
                                <div className="doubleTb">
                                    <Label
                                        ref={stockPcs}
                                    />
                                    <div></div>
                                    <Label
                                        ref={stockCrtn}
                                    />
                                </div>
                            </div>
                            <div className="headerTb" style={{ flex: '1', marginLeft: '16px' }}>
                                <label>Stock in Warehouse</label>
                                <div className="header">
                                    <label>Qty</label>
                                    <label>Crtn</label>
                                </div>
                                <div className="doubleTb">
                                    <Label
                                        ref={wStockPcs}
                                    />
                                    <div></div>
                                    <Label
                                        ref={wStockCrtn}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%' }}>
                            <Textbox
                                ref={qtyTB}
                                label="Qty"
                                tabIndex={1401}
                                onKeyDown={onQtyTBKeydown}
                                style={{ flex: 1, marginRight: 'var(--defaultMargin)' }} />

                            <Textbox
                                ref={crtnTB}
                                label="Crtn"
                                tabIndex={1402}
                                onKeyDown={onLastTBKeydown}
                                style={{ flex: 1 }} />
                        </div>

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onDeleteBtnClick} />

                            <ButtonIcon
                                label="Gatepass"
                                icon="icons/buttons/package.png"
                                onClick={showGatepassForm} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCancelBtnClick} />
                        </div>

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            isActive={isActive}
                            onDoubleClick={onEditBtnClick}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Items: </b>{totalStockEntries}</p>
                        </div>
                        <div className="dRow">
                            <ThinButton
                                label="Transfer to Warehouse"
                                style={{ width: '100%', marginTop: 'var(--defaultMargin)', backgroundColor: 'rgb(214, 3, 3)' }}
                                onClick={stockTransferToWarehouse} />
                            <ThinButton
                                label="Receive from Warehouse"
                                style={{ width: '100%', marginTop: 'var(--defaultMargin)', marginRight: '0px', backgroundColor: 'green' }}
                                onClick={stockReceiveFromWarehouse} />
                        </div>

                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='rightPanel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Entries</h4>
                        </div>
                        <DateTimePicker
                            ref={dateTB}
                            label="Date"
                            time={false}
                            onChange={loadWarehosueEntries} />
                        <Table
                            ref={tableWRef}
                            columns={columnsW.current}
                            className='warehouseEntryTable'
                            isActive={isActive}
                            autoSelectFirstRow={true}
                            onEnterKeyDown={onWarehouseEntriesTableEnterKeyDown}
                            onClick={onWarehouseEntriesTableEnterKeyDown}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Entries: </b>{totalWarehouseEntries}</p>
                        </div>

                        <Table
                            ref={tableWIRef}
                            columns={columnsWI.current}
                            style={{ marginTop: '8px' }}
                            className='warehouseEntryItemsTable'
                            isActive={isActive}
                            editBtn={{ visible: false, onClick: onEditBtnClick }}
                            deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                        <div className="tableFooter">
                            <p><b>Total Items: </b>{totalWarehouseEntryItems}</p>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

export default TransferStockComponent;