import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const CustomComboBox = forwardRef((props, ref) => {
    // Props
    const label = props.label ? props.label : "Customers";
    const className = props.className;
    const style = props.style;
    const onChange = props.onChange ? props.onChange : () => { };
    const showId = props.showId === true ? true : false;
    const showType = props.showType === true ? true : false;
    const data = props.data;

    const tabIndex = props.tabIndex;
    const customCB = useRef("customCB" + Math.ceil(Math.random() * 1000000));
    const selectedRowClass = `cbSelectedRow`;
    const [showList, setShowList] = useState(false);

    // Variables
    // const data = useSelector(state => state.main.customers);
    const [readonly, setReadonly] = useState(false);
    const [filteredData, setFilteredData] = useState(props.data);
    const [text, setText] = useState("");
    const reference = useRef();
    const selectedRow = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            getValue: getValue,
            setValue: setValue,
            getText: () => { return selectedRow.current === null ? 0 : selectedRow.current.name },
            focus: () => { reference.current.focus(); },
            enable: () => { reference.current.disabled = false },
            disable: () => { reference.current.disabled = true },
            setReadonly: setReadonly,
        };
    });

    const getCustomCB = () => {
        return customCB.current;
    }
    const querySelector = () => {
        return document.querySelector(`#${getCustomCB()} .list div.${selectedRowClass}`);
    }
    const querySelectorAll = () => {
        return document.querySelectorAll(`#${getCustomCB()} .list div`);
    }
    const listQuerySelector = () => {
        return document.querySelector(`#${getCustomCB()} .list`);
    }

    const onChangeHandle = (e) => {
        const val = e.target.value;
        setText(val);

        filterData(val);

        // if (val !== '')
        //     filterData(val);
        // else{
        //     setShowList(true);
        //     setFilteredData([...data])
        // }

        // When user type text set selectedRow null untill selected by keyboard or mouse
        // if (selectedRow.current !== null)
        //     onChange(0, '');

        selectedRow.current = null;
    }
    const onKeyDownHandle = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            const rows = querySelectorAll();
            const list = listQuerySelector();
            const currentRow = querySelector();
            if (!currentRow && rows.length > 0) {
                rows[0].classList.add(selectedRowClass);
                return;
            }

            if (currentRow) {
                var nextRow = currentRow.nextElementSibling;
                if (nextRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    list.scrollTop += rowRect.height;

                    currentRow.classList.remove(selectedRowClass);
                    nextRow.classList.add(selectedRowClass);
                }
            }
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            const rows = querySelectorAll();
            const list = listQuerySelector();
            const currentRow = querySelector();
            if (!currentRow && rows.length > 0) {
                rows[rows.length - 1].classList.add(selectedRowClass);
                list.scrollTop = list.scrollHeight;
                return;
            }

            if (currentRow) {
                var prevRow = currentRow.previousElementSibling;
                if (prevRow) {
                    const rowRect = currentRow.getBoundingClientRect();
                    list.scrollTop -= rowRect.height;

                    currentRow.classList.remove(selectedRowClass);
                    prevRow.classList.add(selectedRowClass);
                }
            }
        } else if (e.key === "Enter") {
            e.preventDefault();
            onRowSelect();
        } else if (e.key === "Escape") {
            e.preventDefault();
            onLeaveHandle();
        }
    }
    const onListClick = (e) => {
        const currentRow = querySelector();
        if (currentRow) {
            currentRow.classList.remove(selectedRowClass);
        }
        e.currentTarget.classList.add(selectedRowClass);
        
        onRowSelect();
    }
    const onRowSelect = () => {
        const currentRow = querySelector();
        if (currentRow) {
            const row = JSON.parse(currentRow.getAttribute('data-row'));
            selectedRow.current = row;
            setText(row.name);
            onChange(Number(row.id), row.name);
            setShowList(false);
        } else {
            selectedRow.current = null;
        }
    }
    const onLeaveHandle = (e) => {
        setTimeout(() => {
            setShowList(false);
            if (getValue() === 0) {
                setValue(0);
                // onChange(0, 'None');
                setFilteredData([...data]);
            }
        }, 100);
    }
    const onFocusHandle = () => {
        if(getValue() === 0){
            setText('');
        }

        setShowList(true);
    }
    const filterData = (val) => {
        if (val === '') {
            setFilteredData([...data]);
        } else {
            const temp = data.filter((e) => {
                return e.name.toLowerCase().includes(val.toLowerCase());
            })

            setFilteredData([...temp]);
            setShowList(true);
        }
    }
    const getValue = ()=>{
        return selectedRow.current === null ? 0 : selectedRow.current.id
    }
    const setValue = (val) => {
        const temp = data.filter((e) => {
            return Number(e.id) === Number(val);
        });

        if (temp.length > 0) {
            selectedRow.current = temp[0];
            setText(selectedRow.current.name);
            onChange(Number(selectedRow.current.id), selectedRow.current.name);
        }
    }

    useEffect(() => {
        setValue(0)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setValue(0);
        // setText('');
        setFilteredData([...props.data]);
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <>
            {/* <div className='customCB'> */}
            <div className={`customCB ${className}`} id={`${getCustomCB()}`} style={style}>
                <div><label>{label}</label></div>
                <input
                    ref={reference}
                    type="text"
                    value={text}
                    onBlur={onLeaveHandle}
                    onFocus={onFocusHandle}
                    onChange={onChangeHandle}
                    onKeyDown={onKeyDownHandle}
                    tabIndex={tabIndex}
                    id={`tb${tabIndex}`}
                    readOnly={readonly} />

                {
                    filteredData.length > 0 && <div className={`list ${showList ? 'd-block' : 'd-none'}`}>
                        {filteredData.map((e) => {
                            return (
                                // <p onClick={onListClick} key={e.id} data-row={JSON.stringify(e)}>{e.name}</p>
                                <div onClick={onListClick} data-row={JSON.stringify(e)} key={e.id}>
                                    {showId && <p className='id'>{e.id}</p>}
                                    <p>{e.name}</p>
                                    {showType && <p className='type'>{e.type}</p>}
                                </div>
                            );
                        })}
                    </div>
                }

            </div>
            {/* </div> */}
        </>
    )
});

export default CustomComboBox;