import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import dateFormat from "dateformat";
import { apiLoadPayments } from "api/reports/accountReportsApi";
import ReportA4Style from "../styles/ReportA4Style";

const Payments = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);
    const [branchName, setBranchName] = useState('');

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    // Footer
    const [totalEntries, setTotalEntries] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    const columns = [
        { column: 'Date', row: "date", sortBy: "string" },
        { column: 'Details', row: "naration", sortBy: "string", className: "naration" },
        { column: 'Amount', row: "amount", sortBy: "number" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadPayments(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalEntries(result.total.totalEntries);
                    setGrandTotal(result.total.grandTotal);

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 ledger" ref={ref}>
                <ReportA4Style />
                
                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Payments</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader" style={{ marginTop: '4px' }}>
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">From Date:</p>
                                <p className="totalLabel alignL">{fromDate}</p>
                            </div>
                        </div>

                        {/* Center */}
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">To Date:</p>
                            </div>
                            <div className="dCol">
                                <p className="totalLabel alignL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={columns}
                    data={data}
                    setData={setData}
                    onDoubleClick={(row) => { }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Entries:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalEntries}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Total:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel dUnderline" style={{textAlign:'center', minWidth: '120px'}}>{grandTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Payments;