import React, { forwardRef } from "react";
import 'style/purchase/purchaseBill.css';

const Bill = forwardRef((props, ref) => {
    const formId = "purchaseBill";

    const data = props.data;

    return (
        <>
            <div id={formId} className={"main"} ref={ref} style={{ border: '1px solid black' }}>
                <div>
                    <p className="shopName">Mian Brothers Super Store</p>
                    <p className="address">Chungi Amar Sidhu, Lahore</p>
                    <p className="address2">چونگی امر سدھو، لاہور</p>
                    <p className="contact">0303-0002872, 0323-8604698</p>
                </div>
                {
                    data.length > 0 &&
                    <div className="dataDiv">
                        {data.map((e, i) => {
                            return (
                                <div key={i}>
                                    <span className="details">{e.itemname}, {e.rate}</span>
                                </div>
                            )
                        })}
                    </div>
                }

            </div>
        </>
    )
});

export default Bill;