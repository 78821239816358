/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/accounts/banks.css';
import { mainReducerLoadBanks, mainReducerLoadSubBanks } from 'state/reducers/mainReducer';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadBanks,
    apiInsertBank,
    apiUpdateBank,
    apiDeleteBank,
    apiGetBank,
    apiIsBankExists
} from "api/accounts/BanksApi";

import {
    apiLoadSubBanks,
    apiInsertSubBank,
    apiUpdateSubBank,
    apiDeleteSubBank,
    apiGetSubBank,
    apiIsSubBankExists
} from "api/accounts/SubBanksApi";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import BankCB from "components/tools/dropdowns/BankCB";
import { selectNextElement } from 'utilities/Utils';
/* #endregion Imports */

const BanksComponent = (props) => {

    /* #region Variables */
    const formId = "banksForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const passwords = useSelector(state => state.main.passwords);

    const bankNameTB = useRef();
    const remarksTB = useRef();
    const searchBankTB = useRef();

    const bankCB = useRef();
    const subBankNameTB = useRef();
    const accountNumberTB = useRef();
    const branchCodeTB = useRef();
    const branchAddressTB = useRef();
    const searchSubBankTB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableBRef = useRef();
    const tableSBRef = useRef();
    const [totalBanks, setTotalBanks] = useState(0);
    const [totalSubBanks, setTotalSubBanks] = useState(0);

    // Other Variables
    const updateIdB = useRef(0);
    const isUpdateB = useRef(false);
    const updateIdSB = useRef(0);
    const isUpdateSB = useRef(false);

    const formDataB = useRef(null);
    const formDataSB = useRef(null);

    const columnsB = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Bank Name', row: "bankName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    const columnsSB = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Bank Account Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadBanks();

        bankNameTB.current.focus();
    }
    /* #region Banks */
    const loadBanks = () => {
        if (tableBRef.current !== null)
            tableBRef.current.setLoading(true);

        apiLoadBanks({
            text: searchBankTB.current.getText()
        }).then((result) => {
            try {
                setTotalBanks(result.total.totalBanks);
                tableBRef.current.setData([...result.rows]);
                tableBRef.current.setLoading(false);
            } catch (error) {
                tableBRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
            tableBRef.current.setLoading(false);
        });
    }
    const loadBanksCB = (result) => {
        dispatch(mainReducerLoadBanks(result));
        bankCB.current.setValue(0);
        // apiLoadMainBanks().then((result) => {
        //     dispatch(mainReducerLoadBanks(result));
        //     bankCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateBank = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationC();

        if (isValid) {
            if (isUpdateB.current) {
                const data = getFormDataC();
                data.id = updateIdB.current;
                apiUpdateBank(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Bank updated successfully.", "s");
                        loadBanks();
                        clearTextboxesC();
                        loadBanksCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertBank(getFormDataC())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Bank saved successfully.", "s");
                        loadBanks();
                        clearTextboxesC();
                        loadBanksCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editBank = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetBank(tempId)
                .then((result) => {
                    updateIdB.current = tempId;
                    isUpdateB.current = true;

                    setFormDataC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteBank = () => {
        const row = tableBRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteBank(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Bank deleted successfully.", "s");
                                loadBanks();
                                clearTextboxesC();
                                loadBanksCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Bank?")) {
                    apiDeleteBank(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Bank deleted successfully.", "s");
                            loadBanks();
                            clearTextboxesC();
                            loadBanksCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }


        };
    }
    const deleteBankIsUpdate = () => {
        if (isUpdateB.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteBank(updateIdB.current)
                            .then((result) => {
                                toast.current.show("Bank deleted successfully.", "s");
                                loadBanks();
                                clearTextboxesC();
                                loadBanksCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Bank?")) {
                    apiDeleteBank(updateIdB.current)
                        .then((result) => {
                            toast.current.show("Bank deleted successfully.", "s");
                            loadBanks();
                            clearTextboxesC();
                            loadBanksCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }


        }
    }
    const clearTextboxesC = () => {
        bankNameTB.current.setText("");
        remarksTB.current.setText("");

        updateIdB.current = 0;
        isUpdateB.current = false;
        formDataB.current = null;

        bankNameTB.current.focus();
    }
    const setFormDataC = (bank) => {
        formDataB.current = bank;

        bankNameTB.current.setText(bank.bankName);
        remarksTB.current.setText(bank.remarks);

        bankNameTB.current.focus();

    }
    const getFormDataC = () => {
        const bank = {
            bankName: bankNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText().trim(),
        }

        return bank;
    }
    const validationC = async () => {
        const bankName = bankNameTB.current.getText().trim();
        const isValid = await isValidBank(bankName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidBank = async (bankName) => {
        if (bankName === '') {
            toast.current.show("Please Enter Bank Name.", "i");
            bankNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateB.current) {
                if (bankName.toLowerCase() === formDataB.current.bankName.toLowerCase())
                    return true;
                else
                    return isBankExists(bankName);
            }
            else
                return isBankExists(bankName);
        }
    }
    const isBankExists = async (bankName) => {
        const result = await apiIsBankExists(bankName);
        if (result.isExists) {
            toast.current.show("Bank Name already exists.", "i");
            bankNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Sub-Banks */
    const loadSubBanks = () => {
        const bankId = bankCB.current.getValue();
        if (bankId !== 0) {

            if (tableSBRef.current !== null)
                tableSBRef.current.setLoading(true);

            apiLoadSubBanks({
                text: searchSubBankTB.current.getText(),
                bankId: bankId,
            }).then((result) => {
                try {
                    setTotalSubBanks(result.total.totalSubBanks);
                    tableSBRef.current.setData([...result.rows]);
                    tableSBRef.current.setLoading(false);
                } catch (error) {
                    tableSBRef.current.setLoading(false);
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                tableSBRef.current.setLoading(false);
            });
        } else {
            tableSBRef.current.clearData();
        }
    }
    const loadSubBanksCB = (result) => {
        dispatch(mainReducerLoadSubBanks(result));
        // apiLoadMainSubBanks().then((result) => {
        //     dispatch(mainReducerLoadSubBanks(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted1 = useRef(true);
    const insertOrUpdateSubBank = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationSC();

        if (isValid) {
            if (isUpdateSB.current) {
                const data = getFormDataSC();
                data.id = updateIdSB.current;
                apiUpdateSubBank(data)
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Bank updated successfully.", "s");
                        loadSubBanks();
                        clearTextboxesSC();
                        loadSubBanksCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSubBank(getFormDataSC())
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Bank saved successfully.", "s");
                        loadSubBanks();
                        clearTextboxesSC();
                        loadSubBanksCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editSubBank = () => {
        const row = tableSBRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSubBank(tempId)
                .then((result) => {
                    updateIdSB.current = tempId;
                    isUpdateSB.current = true;

                    setFormDataSC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteSubBank = () => {
        const row = tableSBRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSubBank(Number(row['id']))
                            .then((result) => {
                                toast.current.show("Sub-Bank deleted successfully.", "s");
                                loadSubBanks();
                                clearTextboxesSC();
                                loadSubBanksCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Sub-Bank?")) {
                    apiDeleteSubBank(Number(row['id']))
                        .then((result) => {
                            toast.current.show("Sub-Bank deleted successfully.", "s");
                            loadSubBanks();
                            clearTextboxesSC();
                            loadSubBanksCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }


        };
    }
    const deleteSubBankIsUpdate = () => {
        if (isUpdateSB.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteSubBank(updateIdSB.current)
                            .then((result) => {
                                toast.current.show("Sub-Bank deleted successfully.", "s");
                                loadSubBanks();
                                clearTextboxesSC();
                                loadSubBanksCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this Sub-Bank?")) {
                    apiDeleteSubBank(updateIdSB.current)
                        .then((result) => {
                            toast.current.show("Sub-Bank deleted successfully.", "s");
                            loadSubBanks();
                            clearTextboxesSC();
                            loadSubBanksCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxesSC = () => {
        subBankNameTB.current.setText("");
        accountNumberTB.current.setText("");
        branchCodeTB.current.setText("");
        branchAddressTB.current.setText("");

        updateIdSB.current = 0;
        isUpdateSB.current = false;
        formDataSB.current = null;

        subBankNameTB.current.focus();
    }
    const setFormDataSC = (subBank) => {
        formDataSB.current = subBank;

        subBankNameTB.current.setText(subBank.accountName);
        accountNumberTB.current.setText(subBank.accountNumber);
        branchCodeTB.current.setText(subBank.branchCode);
        branchAddressTB.current.setText(subBank.branchAddress);

        subBankNameTB.current.focus();
    }
    const getFormDataSC = () => {
        const subBank = {
            bankId: bankCB.current.getValue(),
            accountName: subBankNameTB.current.getText().trim(),
            accountNumber: accountNumberTB.current.getText(),
            branchCode: branchCodeTB.current.getText(),
            branchAddress: branchAddressTB.current.getText(),
        }

        return subBank;
    }
    const validationSC = async () => {
        const subBankName = subBankNameTB.current.getText().trim();
        const isValid = await isValidSubBank(subBankName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidSubBank = async (subBankName) => {
        if (bankCB.current.getValue() === 0) {
            toast.current.show("Please Select Bank.", "i");
            return false;
        }
        else if (subBankName === '') {
            toast.current.show("Please Enter Account Name.", "i");
            subBankNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateSB.current) {
                if (subBankName.toLowerCase() === formDataSB.current.accountName.toLowerCase())
                    return true;
                else
                    return isSubBankExists(subBankName);
            }
            else
                return isSubBankExists(subBankName);
        }
    }
    const isSubBankExists = async (subBankName) => {
        const result = await apiIsSubBankExists(subBankName);
        if (result.isExists) {
            toast.current.show("Account Name already exists.", "i");
            subBankNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */
    /* #endregion */

    /* #region Clicks */
    /* #region Banks */
    const onBankSaveBtnClick = () => {
        insertOrUpdateBank();
    }
    const onBankEditBtnClick = () => {
        editBank();
    }
    const onBankDeleteBtnClick = () => {
        deleteBankIsUpdate();
    }
    const onBankCancelBtnClick = () => {
        clearTextboxesC();
    }
    const banksTableOnclick = (row) => {
        const id = Number(row['id']);
        bankCB.current.setValue(id);
    }
    /* #endregion */

    /* #region Sub-Banks */
    const onSubBankSaveBtnClick = () => {
        insertOrUpdateSubBank();
    }
    const onSubBankEditBtnClick = () => {
        editSubBank();
    }
    const onSubBankDeleteBtnClick = () => {
        deleteSubBankIsUpdate();
    }
    const onSubBankCancelBtnClick = () => {
        clearTextboxesSC();
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (activeTab.current !== "banks")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateBank();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editBank();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteBankIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableBRef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateSubBank();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editSubBank();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteSubBankIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesSC();
        } else if (e.altKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableSBRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onBankSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadBanks();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableBRef.current.focus();
        }
    }
    const onSubBankSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadSubBanks();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableSBRef.current.focus();
        }
    }
    const onBankNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateBank();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateSubBank();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Bank Combobox Change
    const onBankCBChange = (selectedValue, selectedText) => {
        loadSubBanks();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "banks" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "accounts" && props.activeTab === "banks" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className='banksPanel' style={{ display: 'flex', flexDirection: 'row' }}>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Banks</h4>
                        </div>
                        <Textbox
                            ref={bankNameTB}
                            label="Bank Name"
                            tabIndex={701}
                            onKeyDown={onBankNameTBKeydown} />

                        <Textarea
                            ref={remarksTB}
                            label="Remarks"
                            tabIndex={702}
                            onKeyDown={onRemarksTBKeydown} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onBankSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onBankEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onBankDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onBankCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchBankTB}
                            label="Search"
                            onKeyDown={onBankSearchTBKeydown}
                            tabIndex={703} />

                        <Table
                            ref={tableBRef}
                            columns={columnsB.current}
                            myTable='banksTable'
                            isActive={isActive}
                            onEnterKeyDown={banksTableOnclick}
                            onClick={banksTableOnclick}
                            onDoubleClick={onBankEditBtnClick}
                            editBtn={{ visible: false, onClick: onBankEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteBank() } }} />

                        <div className="tableFooter">
                            <p><b>Total Banks: </b>{totalBanks}</p>
                        </div>

                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Bank Accounts</h4>
                        </div>

                        <BankCB
                            ref={bankCB}
                            onChange={onBankCBChange} />

                        <Textbox
                            ref={subBankNameTB}
                            label="Account Name"
                            tabIndex={704}
                            onKeyDown={onTextboxesKeyDown} />

                        <Textbox
                            ref={accountNumberTB}
                            label="Account Number"
                            tabIndex={705}
                            onKeyDown={onTextboxesKeyDown} />

                        <Textbox
                            ref={branchCodeTB}
                            label="Branch Code"
                            tabIndex={706}
                            onKeyDown={onTextboxesKeyDown} />

                        <Textarea
                            ref={branchAddressTB}
                            label="Branch Address"
                            tabIndex={707}
                            onKeyDown={onLastTextboxKeyDown} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSubBankSaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onSubBankEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onSubBankDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onSubBankCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchSubBankTB}
                            label="Search"
                            onKeyDown={onSubBankSearchTBKeydown}
                            tabIndex={708} />

                        <Table
                            ref={tableSBRef}
                            columns={columnsSB.current}
                            myTable='subBanksTable'
                            isActive={isActive}
                            onDoubleClick={onSubBankEditBtnClick}
                            editBtn={{ visible: false, onClick: onSubBankEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteSubBank() } }} />

                        <div className="tableFooter">
                            <p><b>Total Bank Accounts: </b>{totalSubBanks}</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default BanksComponent;