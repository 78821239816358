import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/reportSmall.css';
import dateFormat from "dateformat";
import { apiLoadLedger } from "api/reports/accountReportsApi";
import ReportThermalStyle from "../styles/ReportThermalStyle";

const LedgerSmall = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [accountName, setAccountName] = useState('');
    const [contact, setContact] = useState('');
    const [address, setAddress] = useState('');

    // Footer
    // const [totalDebit, setTotalDebit] = useState(0);
    // const [totalCredit, setTotalCredit] = useState(0);
    const [oldBalance, setOldBalance] = useState(0);
    const [newBalance, setNewBalance] = useState(0);
    // const [totalEntries, setTotalEntries] = useState(0);

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadLedger(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    // setTotalDebit(result.total.totalDebit);
                    // setTotalCredit(result.total.totalCredit);
                    setOldBalance(result.total.oldBalance);
                    setNewBalance(result.total.newBalance);
                    // setTotalEntries(result.total.totalEntries);
                    setAccountName(result.total.accountName);
                    setContact(result.total.contact);
                    setAddress(result.total.address);

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="reportSmall reportThermal ledger" ref={ref}>
                <ReportThermalStyle />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>{props.header}</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader" style={{ marginTop: '4px' }}>
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Name:</p>
                                <p className="labelMarginL">{accountName}</p>
                            </div>
                            <div className="dRow">
                                <p className="totalHeading">Contact:</p>
                                <p className="labelMarginL">{contact}</p>
                            </div>
                            <div className="dRow">
                                <p className="totalHeading">Address:</p>
                                <p className="labelMarginL">{address}</p>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dCol" style={{ justifyContent: 'space-end' }}>
                            <div className="dRow" style={{ justifyContent: 'end' }}>
                                <p className="totalHeading">From Date:</p>
                                <p className="labelMarginL">{fromDate}</p>
                            </div>
                            <div className="dRow" style={{ justifyContent: 'end' }}>
                                <p className="totalHeading">To Date:</p>
                                <p className="labelMarginL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Details</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            {/* <th>Balance</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>Old Balance</td>
                            <td>{oldBalance >= 0 ? oldBalance : 0}</td>
                            <td>{oldBalance < 0 ? oldBalance : 0}</td>
                        </tr>
                        {data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    <td style={{maxWidth:'20px'}} title={row['date']}>{`${row['date'].split("/")[0]}/${row['date'].split("/")[1]}`}</td>
                                    <td className="naration" key={rowIndex + 10} title={row['naration']}>{row['naration']}</td>
                                    <td title={row['debit']}>{row['debit']}</td>
                                    <td title={row['credit']}>{row['credit']}</td>
                                    {/* <td title={row['balance']}>{row['balance'] + oldBalance} {(row['balance'] + oldBalance) >= 0 ? "Dr" : "Cr"}</td> */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        {/* <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Entries:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalEntries}</p>
                            </div>
                        </div> */}

                        {/* Right Side */}
                        <div className="dCol" style={{ justifyContent: 'space-end' }}>
                            {/* <div className="dRow labelMarginR">
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalDebit}</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center', marginRight: '72px' }}>{totalCredit}</p>
                            </div> */}
                            <div className="dRow labelMarginR" style={{ marginTop: '16px', marginBottom: '8px', marginLeft: '0px', justifyContent: 'flex-end' }}>
                                <p className="totalHeading" style={{ fontSize: '14px' }}>Balance:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ fontSize:'14px', textAlign: 'center', minWidth: '110px' }}>{newBalance} {newBalance >= 0 ? "Dr" : "Cr"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default LedgerSmall;