import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import { apiLoadItemslist } from "api/reports/otherReportsApi";
import ReportA4Style from "../styles/ReportA4Style";
import { useSelector } from "react-redux";

const ItemsList = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const settings = useSelector(state => state.main.settings);
    const params = props.params;
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : ()=>{};
    const [data, setData] = useState([]);

    // Header
    const [branchName, setBranchName] = useState('');
    const [branchGroupName, setBranchGroupName] = useState('');

    // Footer
    const [totalItems, setTotalItems] = useState(0);

    const columns = [
        { column: 'Barcode', row: "barcode", sortBy: "string" },
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Retail', row: "retail", sortBy: "number" },
        { column: 'W.Sale', row: "wholesale", sortBy: "number" },
        { column: 'Ref', row: "refCode", sortBy: "string" },
    ];

    const columnsC = [
        { column: 'Barcode', row: "barcode", sortBy: "string" },
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Retail', row: "retail", sortBy: "number" },
        { column: 'W.Sale', row: "wholesale", sortBy: "number" },
        { column: 'C.Rate', row: "crtnRate", sortBy: "number" },
        { column: 'Ref', row: "refCode", sortBy: "string" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadItemslist(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setTotalItems(result.total.totalItems);
                    setBranchName(result.total.branchName);
                    setBranchGroupName(result.total.branchGroupName);

                    props.setIsLoading(false);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 itemsList" ref={ref}>
                <ReportA4Style />
                
                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Items List</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'flex-start' }}>
                        
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>
                        <div className="dRow" style={{ justifyContent: 'center', marginLeft:'16px' }}>
                            <p className="totalHeading">Branch Group:</p>
                            <p className="labelMarginL">{branchGroupName}</p>
                        </div>

                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={settings.saleCartons ? columnsC : columns}
                    data={data}
                    setData={setData}
                    onDoubleClick={(row) => { onDoubleClick(row) }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Items:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalItems}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default ItemsList;