import React, { useRef, useEffect } from "react";
import 'style/main/login.css';
import { apiClientLogin } from "api/main/ClientsApi";
import { setClientId } from "utilities/DB";

const ClientLoginComponent = (props) => {

    const formId = "loginForm";
    const usernameTB = useRef();
    const passwordTB = useRef();

    const messageBox = props.messageBox;
    const toast = props.toast;

    const usernameTbKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            passwordTB.current.focus();
        }
    }
    const passwordTbKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            login();
        }
    }
    const loginBtnClick = () => {
        login();
    }
    const login = () => {
        const username = usernameTB.current.value;
        const password = passwordTB.current.value;

        if (username === '') {
            toast.current.show("Please Enter Username.", "i");
            usernameTB.current.focus();
        } else if (password === '') {
            toast.current.show("Please Enter Password.", "i");
            passwordTB.current.focus();
        } else {
            apiClientLogin(username, password)
            .then((result) => {
                if (result.isExists) {
                    const client = result.data;

                    setClientId(client.id);
                    
                    props.setActiveComponent("loginForm");
                } else {
                    toast.current.show("Incorrect username or password!", "e")
                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        }
    }

    useEffect(() => {
        usernameTB.current.focus();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId}>
                <div className="loginContainer">
                    <div className="loginHeader">
                        <img src="/icons/statspos_logo.png" alt="" />
                    </div>
                    <div className="loginBody">
                        <img className="mainLogo" src="/icons/statspos_login.png" alt="" />
                        <p className="welcome">Client Login!</p>
                        {/* <p className="business">Let's Start Today's Business.</p> */}

                        <div className="box">
                            <img className="icon" src="/icons/username.png" alt="" />
                            <input ref={usernameTB} type="text" tabIndex={1} onKeyDown={usernameTbKeydown} />
                        </div>
                        <div className="box">
                            <img className="icon" src="/icons/password.png" alt="" />
                            <input ref={passwordTB} type="password" tabIndex={2} onKeyDown={passwordTbKeydown} />
                        </div>

                        <button className="loginBtn" onClick={loginBtnClick}>Login</button>

                        <img className="graphees" src="/icons/graphees.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientLoginComponent;