/* #region Imports */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import 'style/warehouse/loadGatepass.css';
import Table from 'components/tools/Table';
import ComboBox from "components/tools/ComboBox";
import { apiLoadGatepassItems } from "api/warehouse/GatepassItemsApi";
import { apiLoadGatepasses } from "api/warehouse/GatepassApi";
import ButtonIcon from "components/tools/ButtonIcon";
import { apiGenerateStockEntries } from "api/warehouse/StockEntryApi";
/* #endregion Imports */

const LoadGatepassComponent = forwardRef((props, ref) => {

    /* #region Variables */
    const formId = "loadGatepassForm";
    const isActive = useRef(false);

    const gatepassCB = useRef();
    const [gatepassData, setGatepassData] = useState([]);

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalGatepassItems, setTotalGatepassItems] = useState(0);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Gatepass Item', row: "itemname", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
        { column: 'Qty', row: "qty", sortBy: "number", style: { minWidth: '80px' } },
        { column: 'Crtn', row: "crtn", sortBy: "number", style: { minWidth: '80px' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const loadGatepassesCB = (result) => {
        let temp = [
            { id: 0, name: 'None' }
        ];

        const data = temp.concat(result.gatepasses);
        setGatepassData([...data]);
    }
    const loadGatepasses = () => {
        const warehouseId = props.data.warehouseId;
        const date = new Date();
        if (warehouseId) {
            apiLoadGatepasses({
                warehouseId: props.data.warehouseId,
                text: '',
                date: date,
            }).then((result) => {
                console.log(result);
                try {
                    loadGatepassesCB(result);
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            toast.current.show("Please Select Warehouse.", "i");
        }

    }
    const loadGatepassItems = () => {
        const gatepassId = gatepassCB.current.getValue();
        if (gatepassId !== 0) {
            if (tableRef.current !== null)
                tableRef.current.setLoading(true);

            apiLoadGatepassItems({
                text: '',
                gatepassId: gatepassId,
            }).then((result) => {
                try {
                    setTotalGatepassItems(result.total.totalGatepassItems);
                    tableRef.current.setData([...result.rows]);
                    tableRef.current.setLoading(false);
                } catch (error) {

                }
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
            });
        } else {
            tableRef.current.clearData();
        }
    }
    const generateStockEntries = ()=>{
        const warehouseId = props.data.warehouseId;
        const gatepassId = gatepassCB.current.getValue();
        if (warehouseId) {
            if(gatepassId !== 0){
                apiGenerateStockEntries({
                    warehouseId: warehouseId,
                    gatepassId: gatepassId,
                }).then((result) => {
                    props.loadGatepass();
                    close();
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
            } else {
                toast.current.show("Please Select Gatepass.", "i");
            }            
        } else {
            toast.current.show("Please Select Warehouse.", "i");
        }
    }
    /* #endregion */

    /* #region Clicks */

    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const onGatepassCBChange = (selectedValue, selectedText) => {
        loadGatepassItems();
    }
    /* #endregion */

    const show = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "block";

        props.isActive.current = false;
        isActive.current = true;

        formLoad();
    }

    const close = () => {
        const confirmDiv = document.getElementById(formId);
        confirmDiv.style.display = "none";

        props.isActive.current = true;
        isActive.current = false;

        props.setActiveComponent(null);
    }

    const formLoad = () => {
        loadGatepasses();
    }

    useImperativeHandle(ref, () => {
        return {
            show: show,
            close: close,
        };
    });

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        show();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id={formId} style={{ display: 'none' }}>
                <div className="myModalBg"></div>
                <div className="myModal">
                    <div className="myModalHeader">
                        <img className="closeBtn" src="icons/close.png" alt="" onClick={close} />
                    </div>
                    <div className="myModalBody" style={{ height: '100%', overflow: 'auto' }}>

                        <ComboBox
                            ref={gatepassCB}
                            label="Gatepass"
                            data={gatepassData}
                            onChange={onGatepassCBChange} />

                        <Table
                            ref={tableRef}
                            columns={columns.current}
                            isActive={isActive}
                            onDoubleClick={() => { }}
                            editBtn={{ visible: false, onClick: () => { } }}
                            deleteBtn={{ visible: true, onClick: () => { } }} />

                        <div className='dRow jce' style={{ marginBottom: '-18px' }}>
                            <ButtonIcon
                                label="Load"
                                icon="icons/buttons/save.png"
                                style={{ margin: '0px' }}
                                onClick={generateStockEntries} />
                        </div>

                        <div className="tableFooter">
                            <p><b>Total Items: </b>{totalGatepassItems}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

export default LoadGatepassComponent;