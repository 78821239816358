/* #region Imports */
import React, { useRef, useEffect, useState } from "react";
import 'style/accounts/stockEntry.css';
import ComboBox from "components/tools/ComboBox";
import DateTimePicker from "components/tools/DateTimePicker";
import Table from "components/tools/Table";
import Textbox from "components/tools/Textbox";
import BankCBMOP from "components/tools/dropdowns/BankCBMOP";
import SubBankCBMOP from "components/tools/dropdowns/SubBankCBMOP";
import ButtonIcon from "components/tools/ButtonIcon";
import { apiLoadEntries, apiPassEntry } from "api/accounts/AccountsApi";
import { accounts, convertToNumber, entryTypes, selectNextElement } from "utilities/Utils";
import ExpensesCB from "components/tools/dropdowns/ExpenseCB";
import SubExpenseCB from "components/tools/dropdowns/SubExpenseCB";
import AutocompleteTextbox from "components/tools/AutocompleteTextbox";
import { useSelector } from "react-redux";
/* #endregion */

const StockEntryComponent = (props) => {

    /* #region Variables */
    const formId = "stockEntryForm";
    const isActive = useRef(true);
    const userRights = useSelector(state => state.main.userRights);

    const selectedItem = useRef(null);
    const itemId = useRef(0);
    const itemnameTB = useRef();
    const qtyTB = useRef();
    const crtnTB = useRef();
    const expenseCB = useRef();
    const subExpenseCB = useRef();
    const amountTB = useRef();
    const dateTB = useRef();
    const mopCB = useRef();
    const bankCB = useRef();
    const subBankCB = useRef();
    const narationTB = useRef();
    const searchFromDateTB = useRef();
    const searchToDateTB = useRef();

    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalEntries, setTotalEntries] = useState(0);

    const columns = useRef([
        { column: 'id', row: "id", sortBy: "number", style: { minWidth: '45px' } },
        { column: 'Naration', row: "naration", sortBy: "string", style: { minWidth: '400px', width: '100%' } },
        { column: 'Amount', row: "amount", sortBy: "number", style: { minWidth: '120px' } },
        { column: 'Date', row: "date", sortBy: "number", style: { minWidth: '150px' } },
        { column: 'User', row: "username", sortBy: "string", style: { minWidth: '150px' } },
    ]);
    /* #endregion */

    /* #region Methods */
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadEntries({
            entryType: entryTypes.stock,
            text: '',
            fromDate: searchFromDateTB.current.getText(),
            toDate: searchToDateTB.current.getText(),
        }).then((result) => {
            try {
                setTotalEntries(result.total.totalEntries);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {
                if (tableRef.current) {
                    tableRef.current.setLoading(false);
                }
            }
        }).catch((err) => {
            if (tableRef.current) {
                messageBox.current.show(err.message, "Error", "e");
                tableRef.current.setLoading(false);
            }
        });
    }
    const inserted = useRef(true);
    const passEntry = () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = validation();
        if (isValid) {
            apiPassEntry(getFormData())
                .then((result) => {
                    if (isActive.current) {
                        inserted.current = true;
                        toast.current.show("Entry Posted Successfully.", "s");
                        loadData();
                        clearTextboxes();
                    }
                }).catch((err) => {
                    if (isActive.current) {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    }
                });
        } else {
            inserted.current = true;
        }
    }
    const clearTextboxes = () => {
        itemId.current = 0;
        selectedItem.current = null;
        itemnameTB.current.setText('');
        qtyTB.current.setText('');
        crtnTB.current.setText('');

        expenseCB.current.setValue(0);
        subExpenseCB.current.setValue(0);

        amountTB.current.setText("");
        dateTB.current.setText(new Date());
        mopCB.current.setValue(0);
        bankCB.current.setValue(0);
        subBankCB.current.setValue(0);
        narationTB.current.setText("");

        itemnameTB.current.focus();
    }
    const getFormData = () => {
        const qty = convertToNumber(qtyTB.current.getText());
        const crtn = convertToNumber(crtnTB.current.getText());
        const stock = (qty + (crtn * selectedItem.current.crtnSize)) * -1;

        const entry = {
            debitAccountId: subExpenseCB.current.getValue(),
            creditAccountId: mopCB.current.getValue() === 0 ? accounts.cash : subBankCB.current.getValue(),
            amount: convertToNumber(amountTB.current.getText()),
            naration: narationTB.current.getText(),
            date: dateTB.current.getText(),
            entryType: entryTypes.stock,

            itemId: itemId.current,
            stock: stock
        }

        return entry;
    }
    const validation = () => {
        if (itemId.current === 0) {
            toast.current.show("Please Select Item", "i");
            return false;
        }

        const qty = convertToNumber(qtyTB.current.getText());
        const crtn = convertToNumber(crtnTB.current.getText());
        const stock = qty + (crtn * selectedItem.current.crtnSize);
        if (stock === 0 || stock < 0) {
            toast.current.show("Please Enter Quantity", "i");
            return false;
        }

        if (expenseCB.current.getValue() === 0) {
            toast.current.show("Please Select Expense", "i");
            return false;
        }

        if (subExpenseCB.current.getValue() === 0) {
            toast.current.show("Please Select Sub-Expense", "i");
            return false;
        }

        if (convertToNumber(amountTB.current.getText()) === 0) {
            toast.current.show("Please Enter Amount", "i");
            return false;
        }

        if (mopCB.current.getValue() === 1) {
            if (bankCB.current.getValue() === 0) {
                toast.current.show("Please Select Bank", "i");
                return false;
            } else {
                if (subBankCB.current.getValue() === 0) {
                    toast.current.show("Please Select Bank Account", "i");
                    return false;
                }
            }
        }

        return true;
    }
    const changeNaration = () => {
        if (selectedItem.current !== null) {
            if (mopCB.current.getValue() === 0) {
                narationTB.current.setText(`Stock loss of (${selectedItem.current.itemname}) as ${subExpenseCB.current.getText()} Expense and Paid in Cash`);
            } else {
                narationTB.current.setText(`Stock loss of (${selectedItem.current.itemname}) as ${subExpenseCB.current.getText()} Expense and Paid from ${subBankCB.current.getText()}`);
            }
        }
    }
    /* #endregion */

    /* #region Clicks */
    const onPostBtnClick = () => {
        passEntry();
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            passEntry();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    const onBarcodeTBKeyDown = (item) => {
        selectedItem.current = item;

        itemId.current = Number(item.id);
        itemnameTB.current.setText(item.itemname);
        qtyTB.current.focus();
    }
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            selectNextElement(e);
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    const expenseCBOnChange = (e) => {
        if (expenseCB.current.getValue() !== 0) {
            subExpenseCB.current.loadData(expenseCB.current.getValue());
        }
    }
    const subExpenseCBOnChange = (e) => {
        changeNaration();
    }
    const mopCBOnChange = (e) => {
        if (mopCB.current.getValue() === 0) {
            bankCB.current.setValue(0);
            subBankCB.current.setValue(0);
            bankCB.current.disable();
            subBankCB.current.disable();
        } else {
            bankCB.current.enable();
            subBankCB.current.enable();
        }

        changeNaration();
    }
    const bankCBOnChange = (e) => {
        if (bankCB.current.getValue() !== 0) {
            subBankCB.current.loadData(bankCB.current.getValue());
        }
    }
    const subBankCBOnChange = (e) => {
        changeNaration();
    }
    const onItemnameChange = () => {
        selectedItem.current = null;
        itemId.current = 0;
    }
    const onQtyChange = () => {
        if (selectedItem.current !== null) {
            const qty = convertToNumber(qtyTB.current.getText());
            const crtn = convertToNumber(crtnTB.current.getText());

            if (qty > 0 || crtn > 0) {
                const cost = selectedItem.current.cost * (qty + (crtn * selectedItem.current.crtnSize));
                amountTB.current.setText(cost);
            } else {
                amountTB.current.setText(0);
            }
        } else {
            amountTB.current.setText(0);
        }
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        // loadData();

        itemnameTB.current.focus();
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.activeTab !== 'stock') {
            isActive.current = false;
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                <AutocompleteTextbox
                    ref={itemnameTB}
                    label="Select Item"
                    tabIndex={100}
                    onChange={onItemnameChange}
                    onEnter={onBarcodeTBKeyDown}
                />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>

                    <Textbox
                        ref={qtyTB}
                        label="Qty"
                        tabIndex={101}
                        style={{ flex: 1 }}
                        onChange={onQtyChange}
                        onKeyDown={onTextboxesKeyDown} />

                    <Textbox
                        ref={crtnTB}
                        label="Crtn"
                        tabIndex={102}
                        style={{ flex: 1 }}
                        className="defaultMarginLeft"
                        onChange={onQtyChange}
                        onKeyDown={onTextboxesKeyDown} />

                </div>

                {/* Expenses */}
                <ExpensesCB
                    ref={expenseCB}
                    onChange={expenseCBOnChange} />
                <SubExpenseCB
                    ref={subExpenseCB}
                    onChange={subExpenseCBOnChange} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Textbox
                        ref={amountTB}
                        label="Amount"
                        tabIndex={103}
                        style={{ flex: 1 }}
                        onKeyDown={onTextboxesKeyDown} />
                    <div className={`${userRights.dateWiseEntry ? "d-block" : "d-none"}`} style={{ flex: '1' }}>
                        <DateTimePicker
                            ref={dateTB}
                            label="Date"
                            time={false}
                            style={{ flex: 1 }}
                            className="defaultMarginLeft" />
                    </div>
                </div>

                {/* MOP Group */}
                <div className="mopGroup" style={{ marginTop: 'var(--defaultMargin)' }}>
                    <div style={{ display: 'flex', flexDirection: 'colums', flex: 1 }}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="mop">
                                <p>M.O.P</p>
                            </div>
                            <div className="mopCB">
                                <ComboBox
                                    ref={mopCB}
                                    data={[{ id: 0, name: 'Cash' }, { id: 1, name: 'Bank' }]}
                                    style={{ width: '100%' }}
                                    onChange={mopCBOnChange} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <BankCBMOP
                                ref={bankCB}
                                style={{ flex: 'auto' }}
                                className={"cb1"}
                                onChange={bankCBOnChange} />
                            <SubBankCBMOP
                                ref={subBankCB}
                                style={{ flex: 'auto' }}
                                className={"cb2"}
                                onChange={subBankCBOnChange} />
                        </div>
                    </div>
                </div>

                <Textbox
                    ref={narationTB}
                    label="Naraion"
                    tabIndex={104}
                />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 'var(--defaultMargin)' }}>
                    <ButtonIcon
                        label="Post"
                        icon="icons/buttons/save.png"
                        onClick={onPostBtnClick} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2px' }}>

                    <DateTimePicker
                        ref={searchFromDateTB}
                        label="From Date"
                        time={false}
                        style={{ flex: 1 }}
                        onChange={loadData} />

                    <DateTimePicker
                        ref={searchToDateTB}
                        label="To Date"
                        time={false}
                        style={{ flex: 1 }}
                        className="defaultMarginLeft"
                        onChange={loadData} />
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='stockEntryTable'
                    isActive={isActive}
                    onDoubleClick={() => { }}
                    onEnterKeyDown={(data) => { }}
                    editBtn={{ visible: false, onClick: () => { } }}
                    deleteBtn={{ visible: true, onClick: () => { } }} />

                <div className="tableFooter">
                    <p><b>Total Entries: </b>{totalEntries}</p>
                </div>

            </div>
        </>
    )
}

export default StockEntryComponent;