import { post } from 'utilities/DB.js';

export const apiLoadPurchaseOrderItems = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/loadPurchaseOrderItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertPurchaseOrderItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/insertPurchaseOrderItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePurchaseOrderItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/updatePurchaseOrderItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePurchaseOrderItem = (purchaseOrderItemId) => {
    const params = {
        id: purchaseOrderItemId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/deletePurchaseOrderItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPurchaseOrderItem = (purchaseOrderItemId) => {
    const params = {
        id: purchaseOrderItemId
    }
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/getPurchaseOrderItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsPurchaseOrderItemExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchaseOrderItems/isPurchaseOrderItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
