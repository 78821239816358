import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

const Pagination = forwardRef((props, ref) => {
    // Props
    const totalItems = props.totalItems;
    const className = props.className;
    const style = props.style;
    const showTotalLabel = props.showTotalLabel===undefined ? true : props.showTotalLabel;
    const reload = props.reload;
    const totalLabel = props.totalLabel;

    // Variables
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPageRef = useRef(null);

    const prevPage = () => {
        const temp = page - 1;
        if (temp !== 0) {
            setPage(temp);
            reload(temp, Number(itemsPerPageRef.current.value));
        }
    }
    const nextPage = () => {
        const temp = page + 1;
        if (temp <= totalPages) {
            setPage(temp);
            reload(temp, Number(itemsPerPageRef.current.value));
        }
    }
    const firstPage = () => {
        setPage(1);
        reload(1, Number(itemsPerPageRef.current.value));
    }
    const lastPage = () => {
        if(totalPages > 0){
            setPage(totalPages);
            reload(totalPages, Number(itemsPerPageRef.current.value));
        }
    }
    const onItemsPerPageChange = (e) => {
        const temp = Number(e.target.value);
        if (temp === 0) {
            setPage(1);
            setTotalPages(1);
            reload(1, 0);
            updateTotalPages();
        } else {
            reload(page, Number(temp));
            updateTotalPages();
        }
    }
    const updateTotalPages = () => {
        if (Number(itemsPerPageRef.current.value) !== 0) {
            let temp = Math.ceil(totalItems / Number(itemsPerPageRef.current.value));
            setTotalPages(temp);
        } else {
            setTotalPages(1);
        }
    }
    const loadData = () => {
        reload(page, Number(itemsPerPageRef.current.value));
    }
    useEffect(() => {
        updateTotalPages();
        // eslint-disable-next-line
    }, [totalItems]);

    useImperativeHandle(ref, () => {
        return {
            page: page,
            itemsPerPage: Number(itemsPerPageRef.current.value),
            loadData:loadData
        };
    });

    return (
        <>
            <div className={`pagination ${className}`} style={style}>
                <p className={`${showTotalLabel ? 'visible' : 'invisible'}`}><b>{totalLabel}</b>{totalItems}</p>
                <img src="/icons/first.png" alt="" onClick={firstPage} />
                <img src="/icons/prev.png" alt="" onClick={prevPage} />
                <div className="noOfRecords">
                    <select defaultValue={25} onChange={onItemsPerPageChange} ref={itemsPerPageRef}>
                        <option value={0}>All</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                </div>
                <p>{page} of {totalPages}</p>
                <img src="/icons/next.png" alt="" onClick={nextPage} />
                <img src="/icons/last.png" alt="" onClick={lastPage} />
            </div>
        </>
    )
});

export default Pagination;