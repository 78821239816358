import { getBranchGroupId, post } from 'utilities/DB.js';

export const apiLoadData = () => {
    return new Promise((resolve, reject) => {
        const params = {
            branchGroupId : getBranchGroupId()
        };
        
        post("api/main/loadData", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

// export const apiLoadCategories = () => {
//     return new Promise((resolve, reject) => {
//         const params = {
//             branchGroupId : getBranchGroupId()
//         };
//         post("api/main/loadCategories", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadSubCategories = () => {
//     return new Promise((resolve, reject) => {
//         const params = {
//             branchGroupId : getBranchGroupId()
//         };
//         post("api/main/loadSubCategories", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadExpenses = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadExpenses", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadSubExpenses = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadSubExpenses", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadBanks = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadBanks", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadSubBanks = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadSubBanks", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadCustomers = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadCustomers", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadVendors = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadVendors", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

// export const apiLoadSuppliers = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadSuppliers", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }
// 
// export const apiLoadFixedAccounts = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadFixedAccounts", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

export const apiLoadPackages = () => {
    return new Promise((resolve, reject) => {
        const params = {
            branchGroupId : getBranchGroupId()
        };
        post("api/main/LoadPackages", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPurchaseOrders = () => {
    return new Promise((resolve, reject) => {
        const params = {};
        post("api/main/loadPurchaseOrders", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

// export const apiLoadAccountCategories = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadAccountCategories", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }

export const apiLoadAccounts = () => {
    return new Promise((resolve, reject) => {
        const params = {};
        post("api/main/loadAccounts", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadClients = () => {
    return new Promise((resolve, reject) => {
        const params = {};
        post("api/main/loadClients", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadBranches = (params) => {
    return new Promise((resolve, reject) => {
        post("api/main/loadBranches", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadBranchGroups = (params) => {
    return new Promise((resolve, reject) => {
        post("api/main/loadBranchGroups", params, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

// export const apiLoadSettings = () => {
//     return new Promise((resolve, reject) => {
//         const params = {};
//         post("api/main/loadSettings", params).then((result) => {
//             resolve(result);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// }
