import { post, uploadImage } from 'utilities/DB.js';

export const apiGetSettings = () => {
    return new Promise((resolve, reject) => {
        post("api/settings/getSettings", {}).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateSettings = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/updateSettings", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBill = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/getBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertBill = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/insertBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateBill = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/updateBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteBill = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/deleteBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadImages = () => {
    return new Promise((resolve, reject) => {
        post("api/settings/loadImages", {}).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteImage = (params) => {
    return new Promise((resolve, reject) => {
        post("api/settings/deleteImage", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/settings/uploadImage", formData, false, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadBillsCode = () => {
    return new Promise((resolve, reject) => {
        post("api/settings/loadBillsCode", {}).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
