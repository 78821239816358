import { post, getUserId } from 'utilities/DB.js';

export const apiInsertPurchase = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/insertPurchase", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdatePurchase = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchase/updatePurchase", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeletePurchase = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/deletePurchase", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetPurchase = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/getPurchase", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiTempClose = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/tempClose", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetNextId = () => {
    const params = {
        userId: getUserId()
    }
    return new Promise((resolve, reject) => {
        post("api/purchase/getNextId", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsReplaceExists = (vendorId) => {
    const params = {
        vendorId: vendorId
    }
    return new Promise((resolve, reject) => {
        post("api/purchase/isReplaceExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetBill = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/getBill", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPendingBills = () => {
    const params = {
        userId: getUserId()
    }
    
    return new Promise((resolve, reject) => {
        post("api/purchase/loadPendingBills", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPendingBillItems = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/loadPendingBillItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPostedBills = (params) => {
    return new Promise((resolve, reject) => {
        post("api/purchase/loadPostedBills", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiLoadPostedBillItems = (params) => {
    // params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/loadPostedBillItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGenerateBarcodeLabels = (params) => {
    params.userId = getUserId();
    return new Promise((resolve, reject) => {
        post("api/purchase/generateBarcodeLabels", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
