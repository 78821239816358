import { post } from 'utilities/DB.js';

export const apiLoadReportsData = (params) => {
    return new Promise((resolve, reject) => {
        post("api/reports/loadData", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeExists = (params) => {
    return new Promise((resolve, reject) => {
        post("api/items/isBarcodeExists", params, false).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
