/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/reports/cash/cashClosing.css';
import { useDispatch, useSelector } from "react-redux";
import { accounts, convertToNumber, getFromDate, getToDate } from 'utilities/Utils';
import ButtonIcon from 'components/tools/ButtonIcon';
import CashAccount from '../account/CashAccount';
import ThinButton from 'components/tools/ThinButton';
import FixedAccountsCBReports from 'components/tools/reportsDropdowns/FixedAccountsCBReports';
import DateTimePicker from 'components/tools/DateTimePicker';
import Textbox from 'components/tools/Textbox';
import BranchesCB from 'components/tools/dropdowns/BranchesCB';
import { apiLoadReportsData } from 'api/reports/reportsApi';
import { reportsReducerLoadData } from 'state/reducers/reportsReducer';
import { useReactToPrint } from 'react-to-print';
import Ledger from '../account/Ledger';
import { apiPassEntry } from 'api/accounts/AccountsApi';
/* #endregion Imports */

const CashClosingComponent = (props) => {

    /* #region Variables */
    const formId = "cashClosingForm";
    const [activeReport, setActiveReport] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.main.user);
    const userRights = useSelector(state => state.main.userRights);

    const dateTB = useRef();
    const amountTB = useRef();
    const narationTB = useRef();
    const cashInRB = useRef();
    const cashOutRB = useRef();

    const branchCB = useRef();
    const fromDateTB = useRef();
    const toDateTB = useRef();
    const accountCB = useRef();
    const accountEntryCB = useRef();

    const reportRef = useRef();
    const reportParams = useRef({});

    const messageBox = props.messageBox;
    const toast = props.toast;

    /* #endregion */

    /* #region Methods */
    const isFirstTime = useRef(true);
    const formLoad = () => {
        if (userRights.branchWiseReports) {
            branchCB.current.setValue(user.branchId);
        }

        isFirstTime.current = false;
    }
    const loadData = () => {
        if (isFirstTime.current)
            return;

        apiLoadReportsData({
            branchId: branchCB.current.getValue(),
            branchGroupId: user.branchGroupId,
        }).then((result) => {
            dispatch(reportsReducerLoadData(result));
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const inserted = useRef(true);
    const passEntry = () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = validation();
        if (isValid) {
            apiPassEntry(getFormData())
                .then((result) => {
                    inserted.current = true;
                    toast.current.show("Entry Posted Successfully.", "s");
                    // loadData();
                    clearTextboxes();
                }).catch((err) => {
                    inserted.current = true;
                    messageBox.current.show(err.message, "Error", "e");
                });
        } else {
            inserted.current = true;
        }
    }
    const clearTextboxes = () => {
        accountEntryCB.current.setValue(0);;

        amountTB.current.setText("");
        dateTB.current.setText(new Date());
        narationTB.current.setText("");

        amountTB.current.focus();
    }
    const getFormData = () => {
        const entry = {
            debitAccountId: cashInRB.current.checked ? accounts.cash : accountEntryCB.current.getValue(),
            creditAccountId: cashOutRB.current.checked ? accounts.cash : accountEntryCB.current.getValue(),
            amount: convertToNumber(amountTB.current.getText()),
            naration: narationTB.current.getText(),
            date: dateTB.current.getText(),
            // entryType: entryTypes.receipt,
            // isMopCashBank: mopCB.current.getValue() === 0 ? true : false,
        }

        return entry;
    }
    const validation = () => {
        if (accountEntryCB.current.getValue() === 0) {
            toast.current.show("Please Select Account", "i");
            return false;
        }

        if (convertToNumber(amountTB.current.getText()) === 0) {
            toast.current.show("Please Enter Amount", "i");
            return false;
        }

        if (narationTB.current.getText() === '') {
            toast.current.show("Please Enter Naration", "i");
            return false;
        }

        return true;
    }
    const ledger = () => {
        if (accountCB.current.getValue() !== 0) {
            const params = getParams();
            params.accountId = accountCB.current.getValue();

            loadReport("ledger", params);
        } else {
            toast.current.show("Please Select Account.", "i");
        }
    }
    const cashAccount = () => {
        const params = getParams();
        loadReport("cashAccount", params);
    }
    const loadReport = (report, params) => {
        if (userRights.branchWiseReports) {
            if (branchCB.current.getValue() !== 0) {
                reportParams.current = params;

                setActiveReport("");
                setTimeout(() => {
                    setActiveReport(report);
                }, 10);
            } else {
                toast.current.show("Please Select Branch.", "i");
            }
        } else {
            params.branchId = user.branchId;
            reportParams.current = params;

            setActiveReport("");
            setTimeout(() => {
                setActiveReport(report);
            }, 10);
        }
    }
    const getParams = () => {
        return {
            branchId: branchCB.current.getValue(),
            fromDate: fromDateTB.current.getText(),
            toDate: toDateTB.current.getText(),
        }
    }
    const printReport = useReactToPrint({
        content: () => reportRef.current,
        // pageStyle: `@media print {
        //     @page {
        //       size: 794px 1123px;
        //       margin: 0;
        //     }
        //   }`,
        print: async (printIframe) => {

            const document = printIframe.contentDocument;
            if (document) {
                printIframe.contentWindow.print();
            }
        },
        // onBeforeGetContent: () => {

        // },
        onAfterPrint: () => {
            // setBillData([]);
        },
        onPrintError: (error) => alert(error),
    })
    /* #endregion */

    /* #region Clicks */
    const onPostBtnClick = () => {
        passEntry();
    }
    /* #endregion */

    /* #region Keydown */

    /* #endregion */

    /* #region Other Event Listeners */

    /* #endregion */

    // Onload
    useEffect(() => {
        formLoad();

        return () => {

        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                <div className="dateGroup">
                    <div className={`${userRights.branchWiseReports ? 'd-block' : 'd-none'}`}>
                        <BranchesCB
                            ref={branchCB}
                            title="Select Branch"
                            style={{ minWidth: '400px' }}
                            onChange={loadData} />
                    </div>

                </div>

                {/* Main panel */}
                <div className='mainPanel'>
                    {/* Left Panel */}
                    <div className="panel">

                        <div className="dCol">
                            <h5 className='headingLabel' style={{ textAlign: 'center' }}>Ledger</h5>
                            <div className="dRow" style={{ minWidth: '500px' }}>
                                <DateTimePicker
                                    ref={fromDateTB}
                                    label="From Date"
                                    time={false}
                                    text={getFromDate()}
                                    onChange={() => { }}
                                    style={{ flex: 1 }}
                                    tabIndex={100} />

                                <DateTimePicker
                                    ref={toDateTB}
                                    label="To Date"
                                    time={false}
                                    text={getToDate()}
                                    onChange={() => { }}
                                    className="defaultMarginLeft"
                                    style={{ flex: 1 }}
                                    tabIndex={100} />
                            </div>
                            <div className="reportShowBtnGroup">
                                <div className="reportShowBtnGroup">
                                    <FixedAccountsCBReports
                                        ref={accountCB}
                                        onChange={() => { }} />
                                    <ThinButton
                                        label="Show"
                                        onClick={ledger} />
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* Right Panel */}
                    <div className="panel" style={{ marginLeft: '16px' }}>
                        <div className="dCol">
                            <h5 className='headingLabel' style={{ textAlign: 'center' }}>Closing of Cash</h5>

                            {/* Radio Group */}
                            <div className="radioGroup">
                                <div className="radioBody">
                                    <div className="myRadio">
                                        <div className="radioItem">
                                            <input
                                                ref={cashInRB}
                                                className="form-check-input" id='cashInRB' type="radio" name="cashEntryType" defaultChecked
                                                onChange={() => { }} />
                                            <label htmlFor='cashInRB'>Cash In</label>
                                        </div>
                                        <div className="radioItem">
                                            <input
                                                ref={cashOutRB}
                                                className="form-check-input" id='cashOutRB' type="radio" name="cashEntryType" onChange={() => { }} />
                                            <label htmlFor='cashOutRB'>Cash Out</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <FixedAccountsCBReports
                                ref={accountEntryCB}
                                onChange={() => { }} />

                            <div className='dRow'>
                                <div style={{ flex: '1' }}>
                                    <DateTimePicker
                                        ref={dateTB}
                                        label="Date"
                                        time={false}
                                        style={{ flex: 1 }} />
                                </div>
                                <Textbox
                                    ref={amountTB}
                                    label="Amount"
                                    className="defaultMarginLeft"
                                    tabIndex={100}
                                    style={{ flex: 1 }}
                                    onKeyDown={() => { }} />
                            </div>
                            <Textbox
                                ref={narationTB}
                                label="Naration"
                                tabIndex={100}
                                style={{ flex: 1 }}
                                onKeyDown={() => { }} />


                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 'var(--defaultMargin)' }}>
                                <ButtonIcon
                                    label="Post"
                                    icon="icons/buttons/save.png"
                                    onClick={onPostBtnClick} />
                            </div>

                        </div>

                    </div>
                </div>

                <div>
                    <ThinButton
                        label="Cash Account"
                        style={{ width: '120px' }}
                        onClick={cashAccount} />
                </div>

                {/* Report & Viewer */}
                <div className="reportViewer" style={{ marginTop: '8px' }}>
                    <div className={`loadingBarContainer ${isLoading ? 'd-block' : 'd-none'}`}>
                        <div className="loadingBar"></div>
                    </div>

                    {(() => {
                        switch (activeReport) {
                            case "ledger":
                                return <Ledger
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current}
                                    header="Ledger" />
                            case "cashAccount":
                                return <CashAccount
                                    ref={reportRef}
                                    setIsLoading={setIsLoading}
                                    messageBox={messageBox}
                                    params={reportParams.current} />
                            default:
                                return <div></div>;
                        }
                    })()}

                    <div className="printPanel">
                        <ButtonIcon
                            icon="icons/buttons/print.png"
                            onClick={printReport} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default CashClosingComponent;