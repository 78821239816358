import { post, uploadImage, getBranchGroupId } from 'utilities/DB.js';

export const apiLoadItems = (params) => {
    return new Promise((resolve, reject) => {
        params.branchGroupId = getBranchGroupId();
        post("api/items/loadItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertItem = (params) => {
    return new Promise((resolve, reject) => {
        params.branchGroupId = getBranchGroupId();
        post("api/items/insertItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/items/updateItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteItem = (itemId) => {
    const params = {
        id: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/items/deleteItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetItem = (itemId) => {
    const params = {
        id: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/items/getItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetItemByBarcode = (barcode) => {
    const params = {
        barcode: barcode,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/items/getItemByBarcode", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsBarcodeExists = (barcode) => {
    const params = {
        barcode: barcode,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/items/isBarcodeExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiValidation = (barcode, refCode, itemname) => {
    const params = {
        barcode: barcode,
        refCode: refCode,
        itemname: itemname,
        branchGroupId: getBranchGroupId(),
    }
    return new Promise((resolve, reject) => {
        post("api/items/validation", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUploadImage = (formData) => {
    return new Promise((resolve, reject) => {
        uploadImage("api/items/uploadImage", formData).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
