import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import { apiLoadBriefReport } from "api/reports/purchaseReportsApi";
import ReportA4Style from "../styles/ReportA4Style";

const BriefPurchaseReport = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const params = props.params;
    const [data, setData] = useState([]);

    const columns = [
        { column: 'Description', row: "description", sortBy: "string" },
        { column: 'Bills', row: "totalBills", sortBy: "number" },
        { column: 'Total', row: "grandTotal", sortBy: "number" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadBriefReport(params)
            .then((result) => {
                if (result) {
                    setData(result);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 briefReport" ref={ref}>
                <ReportA4Style />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Purchase Report</h4>
                </div>

                {data.rows && data.rows.map((row, rowIndex) => {
                    return (
                        row.data.length > 0 &&
                        <div key={rowIndex} className="reportHeader briefHeader" style={{ marginTop: '8px' }}>
                            {/* Header Date & Branch */}
                            <div className="dRow" style={{ justifyContent: 'start', border: '0px solid black' }}>
                                <div className="dRow">
                                    <p className="totalHeading labelMarginR">Date:</p>
                                    <p className="totalLabel alignL">{row.date}</p>
                                </div>
                                <div className="dRow" style={{ marginLeft: '16px' }}>
                                    <p className="totalHeading labelMarginR bold">Branch:</p>
                                    <p className="totalLabel alignL">{row.branchName}</p>
                                </div>
                            </div>

                            {/* Body */}
                            <Report
                                columns={columns}
                                data={row.data}
                                setData={setData}
                                onDoubleClick={(row) => {  }}
                                sort={false} />
                        </div>
                    );
                })}

                {data.rows && <div>
                    <div className="dRow reportHeader" style={{ justifyContent: 'center', marginTop: '8px' }}>
                        <div className="dRow">
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">Grand Total</p>
                            </div>
                        </div>
                    </div>
                    {/* Body */}
                    <Report
                        columns={columns}
                        data={data.total}
                        setData={setData}
                        onDoubleClick={(row) => { }}
                        sort={false} />
                </div>}

            </div>
        </>
    )
});

export default BriefPurchaseReport;