/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/reports/cash/fixedAccounts.css';
import { useDispatch, useSelector } from "react-redux";

import {
    apiLoadFixedAccounts,
    apiInsertFixedAccount,
    apiUpdateFixedAccount,
    apiDeleteFixedAccount,
    apiGetFixedAccount,
    apiIsFixedAccountExists
} from "api/accounts/FixedAccountsApi.js";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import { mainReducerLoadFixedAccounts } from 'state/reducers/mainReducer';
import { selectNextElement } from 'utilities/Utils';
import { reportsReducerLoadFixedAccounts } from 'state/reducers/reportsReducer';
/* #endregion Imports */

const FixedAccountsComponent = (props) => {

    /* #region Variables */
    const formId = "fixedAccountsForm";
    const isActive = useRef(true);
    const activeTab = useRef(null);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();
    const passwords = useSelector(state => state.main.passwords);

    const fixedAccountNameTB = useRef();
    const remarksTB = useRef();

    const searchTB = useRef();

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableRef = useRef();
    const [totalFixedAccounts, setTotalFixedAccounts] = useState(0);

    // Other Variables
    const updateId = useRef(0);
    const isUpdate = useRef(false);
    const formData = useRef(null);

    const columns = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Account Name', row: "accountName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = () => {
        loadData();

        fixedAccountNameTB.current.focus();
    }
    const loadData = () => {
        if (tableRef.current !== null)
            tableRef.current.setLoading(true);

        apiLoadFixedAccounts({
            text: searchTB.current.getText()
        }).then((result) => {
            try {
                setTotalFixedAccounts(result.total.totalFixedAccounts);
                tableRef.current.setData([...result.rows]);
                tableRef.current.setLoading(false);
            } catch (error) {

            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadFixedAccountsCB = (result) => {
        dispatch(mainReducerLoadFixedAccounts(result));
        dispatch(reportsReducerLoadFixedAccounts(result));
    }
    const inserted = useRef(true);
    const insertOrUpdateData = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validation();

        if (isValid) {
            if (isUpdate.current) {
                const data = getFormData();
                data.id = updateId.current;
                apiUpdateFixedAccount(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("FixedAccount updated successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadFixedAccountsCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertFixedAccount(getFormData())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("FixedAccount saved successfully.", "s");
                        loadData();
                        clearTextboxes();
                        loadFixedAccountsCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetFixedAccount(tempId)
                .then((result) => {
                    updateId.current = tempId;
                    isUpdate.current = true;

                    setFormData(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteData = () => {
        const row = tableRef.current.getSelectedRow();
        if (row != null) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteFixedAccount(Number(row['id']))
                            .then((result) => {
                                toast.current.show("FixedAccount deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadFixedAccountsCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this FixedAccount?")) {
                    apiDeleteFixedAccount(Number(row['id']))
                        .then((result) => {
                            toast.current.show("FixedAccount deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadFixedAccountsCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        };
    }
    const deleteDataIsUpdate = () => {
        if (isUpdate.current) {
            if (passwords.useDeleteAccount) {
                passwordBox.current.show("deleteAccount", (result) => {
                    if (result) {
                        apiDeleteFixedAccount(updateId.current)
                            .then((result) => {
                                toast.current.show("FixedAccount deleted successfully.", "s");
                                loadData();
                                clearTextboxes();
                                loadFixedAccountsCB(result);
                            }).catch((err) => {
                                messageBox.current.show(err.message, "Error", "e");
                            });
                    }
                });
            } else {
                if (window.confirm("Are you sure to delete this FixedAccount?")) {
                    apiDeleteFixedAccount(updateId.current)
                        .then((result) => {
                            toast.current.show("FixedAccount deleted successfully.", "s");
                            loadData();
                            clearTextboxes();
                            loadFixedAccountsCB(result);
                        }).catch((err) => {
                            messageBox.current.show(err.message, "Error", "e");
                        });
                }
            }
        }
    }
    const clearTextboxes = () => {
        fixedAccountNameTB.current.setText("");
        remarksTB.current.setText("");;

        updateId.current = 0;
        isUpdate.current = false;
        formData.current = null;

        fixedAccountNameTB.current.focus();

        // document.getElementById("fixedAccountImageDialog").value = "";
        // document.getElementById("fixedAccountImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormData = (fixedAccount) => {
        formData.current = fixedAccount;

        fixedAccountNameTB.current.setText(fixedAccount.accountName);
        remarksTB.current.setText(fixedAccount.address);

        fixedAccountNameTB.current.focus();

        // if (fixedAccount.imageUrl !== '')
        //     document.getElementById("fixedAccountImage").src = `${process.env.REACT_APP_API_URL}${fixedAccount.imageUrl}?` + new Date().getTime();
        // else
        //     document.getElementById("fixedAccountImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormData = () => {
        const fixedAccount = {
            accountName: fixedAccountNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText(),
        }

        return fixedAccount;
    }
    const validation = async () => {
        const fixedAccountName = fixedAccountNameTB.current.getText().trim();
        const isValid = await isValidFixedAccount(fixedAccountName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidFixedAccount = async (fixedAccountName) => {
        if (fixedAccountName === '') {
            toast.current.show("Please Enter Account Name.", "i");
            fixedAccountNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdate.current) {
                if (fixedAccountName.toLowerCase() === formData.current.accountName.toLowerCase())
                    return true;
                else
                    return isFixedAccountExists(fixedAccountName);
            }
            else
                return isFixedAccountExists(fixedAccountName);
        }
    }
    const isFixedAccountExists = async (fixedAccountName) => {
        const result = await apiIsFixedAccountExists(fixedAccountName);
        if (result.isExists) {
            toast.current.show("Account Name already exists.", "i");
            fixedAccountNameTB.current.focus();
            return false;
        } else
            return true;
    }
    /* #endregion */

    /* #region Clicks */
    const onSaveBtnClick = () => {
        insertOrUpdateData();
    }

    const onEditBtnClick = () => {
        editData();
    }

    const onDeleteBtnClick = () => {
        deleteDataIsUpdate();
    }

    const onCancelBtnClick = () => {
        clearTextboxes();
    }

    // eslint-disable-next-line
    const onFixedAccountUploadBtnClick = (imageDialog) => {
        if (isUpdate.current) {
            imageDialog.click();
        }
    }
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "reports")
            return;

        if (activeTab.current !== "accounts")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editData();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteDataIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxes();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableRef.current.focus();
        }

    }
    // Search Textbox Keydown
    const onSearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadData();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableRef.current.focus();
        }
    }
    // Textboxes Keydown
    const onTextboxesKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            selectNextElement(e);
        }
    }
    // Last Textbox Keydown
    const onLastTextboxKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            insertOrUpdateData();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    
    /* #endregion */

    const isFirstTime = useRef(true);
    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        formLoad();

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        activeTab.current = props.activeTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "accounts" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.activeTab]);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if (props.mainActiveTab === "reports" && props.activeTab === "accounts" && isFirstTime.current) {
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} style={{ flex: 'auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                <div className='top'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='panel'>
                            <Textbox
                                ref={fixedAccountNameTB}
                                label="Account Name"
                                tabIndex={901}
                                onKeyDown={onTextboxesKeyDown} />

                            <Textarea
                                ref={remarksTB}
                                label="Remarks"
                                tabIndex={902}
                                style={{ height: '120px !important' }}
                                onKeyDown={onLastTextboxKeyDown} />

                        </div>

                    </div>

                    <div className="buttons">
                        <ButtonIcon
                            label="Save"
                            icon="icons/buttons/save.png"
                            onClick={onSaveBtnClick} />

                        <ButtonIcon
                            label="Edit"
                            icon="icons/buttons/edit.png"
                            onClick={onEditBtnClick} />

                        <ButtonIcon
                            label="Delete"
                            icon="icons/buttons/delete.png"
                            onClick={onDeleteBtnClick} />

                        <ButtonIcon
                            label="Cancel"
                            icon="icons/buttons/cancel.png"
                            onClick={onCancelBtnClick} />
                    </div>

                    <div className="searchContainer" style={{ display: 'flex' }}>

                        <Textbox
                            ref={searchTB}
                            label="Search"
                            onKeyDown={onSearchTBKeydown}
                            tabIndex={903} />

                    </div>
                </div>

                <Table
                    ref={tableRef}
                    columns={columns.current}
                    myTable='fixedAccountsTable'
                    isActive={isActive}
                    onDoubleClick={onEditBtnClick}
                    editBtn={{ visible: false, onClick: onEditBtnClick }}
                    deleteBtn={{ visible: false, onClick: () => { deleteData() } }} />

                <div className="paginationFooter">
                    <p><b>Total Accounts: </b>{totalFixedAccounts}</p>
                </div>

            </div>
        </>
    )
}

export default FixedAccountsComponent;