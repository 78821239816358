import { post } from 'utilities/DB.js';

export const apiLoadExpenses = (params) => {
    return new Promise((resolve, reject) => {
        post("api/expenses/loadExpenses", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertExpense = (params) => {
    return new Promise((resolve, reject) => {
        post("api/expenses/insertExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateExpense = (params) => {
    return new Promise((resolve, reject) => {
        post("api/expenses/updateExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteExpense = (expenseId) => {
    const params = {
        id: expenseId
    }
    return new Promise((resolve, reject) => {
        post("api/expenses/deleteExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetExpense = (expenseId) => {
    const params = {
        id: expenseId
    }
    return new Promise((resolve, reject) => {
        post("api/expenses/getExpense", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsExpenseExists = (expenseName) => {
    const params = {
        expenseName: expenseName
    }
    return new Promise((resolve, reject) => {
        post("api/expenses/isExpenseExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
