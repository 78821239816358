import { post } from 'utilities/DB.js';

export const apiLoadRejectedItems = (params) => {
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/loadRejectedItems", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiInsertRejectedItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/insertRejectedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiUpdateRejectedItem = (params) => {
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/updateRejectedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiDeleteRejectedItem = (rejectedItemId) => {
    const params = {
        id: rejectedItemId
    }
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/deleteRejectedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiGetRejectedItem = (rejectedItemId) => {
    const params = {
        id: rejectedItemId
    }
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/getRejectedItem", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const apiIsRejectedItemExists = (itemId) => {
    const params = {
        itemId: itemId
    }
    return new Promise((resolve, reject) => {
        post("api/rejectedItems/isRejectedItemExists", params).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
