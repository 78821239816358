import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";

const MessageBoxComponent = forwardRef((props, ref) => {
    const [title, setTitle] = useState("Title");
    const [message, setMessage] = useState("Message will be here");
    const [icon, setIcon] = useState("i");

    const okBtnRef = useRef(null);

    const show = (message, title, icon) => {
        setTitle(title);
        setMessage(message);

        if (icon === "i")
            setIcon("information");
        else if (icon === "w")
            setIcon("warning");
        else if (icon === "e")
            setIcon("error");

        const confirmDiv = document.getElementById("messageBox");
        confirmDiv.style.display = "block";

        okBtnRef.current.focus();
    }

    const close = () => {
        const confirmDiv = document.getElementById("messageBox");
        confirmDiv.style.display = "none";
    }

    const onMBKeyDown = (e) => {
        if (e.key && e.key.toLowerCase() === "escape") {
            e.preventDefault();
            close();
        }
    }

    useImperativeHandle(ref, () => {
        return {
            show: show
        };
    });

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onMBKeyDown);

        return () => {
            document.removeEventListener("keydown", onMBKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div id="messageBox">
                <div className="messageBoxBg"></div>
                <div className="messageBoxContainer">
                    <div className="messageBoxHeader"><p>{title}</p></div>
                    <div className="messageBoxBody">
                        <img src={`icons/${icon}.png`} alt="" />
                        <p>{message}</p>
                    </div>
                    <div className="messageBoxFooter">
                        <button ref={okBtnRef} className="okBtn" onClick={close}>Ok</button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default MessageBoxComponent;