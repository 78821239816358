import React, { useState, useEffect, useRef } from "react";
import 'style/accounts/accounts.css';
import { useSelector } from "react-redux";
// import FlatButtonIcons from 'components/AccountsFlatButtonIcons';
import FlatButtonIcons from 'components/FlatButtonIcons';
import CustomersComponent from "components/accounts/CustomersComponent";
import VendorsComponent from "./VendorsComponent";
import ExpensesComponent from "./ExpensesComponent";
import BanksComponent from "./BanksComponent";
import SuppliersComponent from "./SuppliersComponent";

const AccountsComponent = (props) => {
    const formId = "accountsForm";
    const flatButtons = props.flatButtons;

    const activeBtn = useRef(null);
    const mainActiveTab = useRef(null);

    // For the top Tabs
    const [activeTab, setActiveTab] = useState(flatButtons.current[0].toLowerCase().replace(" ", ""));
    // eslint-disable-next-line
    const [activeComponent, setActiveComponent] = useState(flatButtons.current[0].toLowerCase().replace(" ", ""));
    const userRights = useSelector(state => state.main.userRights);

    // Tools References
    const passwordBox = props.passwordBox;
    const messageBox = props.messageBox;
    const toast = props.toast;

    // const flatButtons = [
    //     "Customers",
    //     "Vendors",
    //     "Expenses",
    //     "Banks",
    // ];

    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "accounts")
            return;

        if (e.ctrlKey && e.key === "1") {
            e.preventDefault();
            if (userRights.customers)
                shortKeyClick("Customers");
        }
        else if (e.ctrlKey && e.key === "2") {
            e.preventDefault();
            if (userRights.vendors)
                shortKeyClick("Vendors");
        }
        else if (e.ctrlKey && e.key === "3") {
            e.preventDefault();
            if (userRights.expenses)
                shortKeyClick("Expenses");
        }
        else if (e.ctrlKey && e.key === "4") {
            e.preventDefault();
            if (userRights.banks)
                shortKeyClick("Banks");
        }
        else if (e.ctrlKey && e.key === "5") {
            e.preventDefault();
            if (userRights.banks)
                shortKeyClick("Suppliers");
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);

        if (activeBtn.current == null) {
            // const btn = document.getElementById('flatBtnCustomers');
            const btn = document.getElementById(`flatBtn${flatButtons.current[0].replace(" ", "")}`);
            btn.classList.add("flatBtnClicked");

            const img = document.querySelector(`#${btn.id} img`);
            // img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
            img.classList.add("flatBtnImgSelected");

            activeBtn.current = btn.id;
        }

        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    const flatBtnClick = (e) => {
        changeActiveBtn(e.currentTarget);

        const btnName = e.currentTarget.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    const changeActiveBtn = (btn) => {
        // Get Old Button
        const oldBtn = document.getElementById(activeBtn.current);
        oldBtn.classList.remove("flatBtnClicked");
        const oldImg = document.querySelector(`#${oldBtn.id} img`);
        // oldImg.setAttribute("src", `${FlatButtonIcons[oldBtn.getAttribute("data-name")]}`);
        oldImg.classList.remove("flatBtnImgSelected");

        // Set Button
        btn.classList.add("flatBtnClicked");
        activeBtn.current = btn.id;
        const img = document.querySelector(`#${btn.id} img`);
        // img.setAttribute("src", `${FlatButtonIcons[btn.getAttribute("data-name") + "S"]}`);
        img.classList.add("flatBtnImgSelected");
    }

    const shortKeyClick = (button) => {
        const btn = document.getElementById(`flatBtn${button}`);
        changeActiveBtn(btn);
        const btnName = btn.getAttribute('data-name');
        setActiveComponent(btnName);
        setActiveTab(btnName);
    };

    return (
        <>
            <div id={formId} className="flatBtnBody">
                <div className="topBar"></div>
                <div className="flatBtnTop">
                    {flatButtons.current.map((e, i) => {
                        return (
                            <div id={`flatBtn${e}`} className="flatBtn" key={e} data-name={e.toLowerCase()} onClick={flatBtnClick}>
                                <img className="my-auto flatBtnImg" src={FlatButtonIcons[e.toLowerCase()]} alt="" />
                                <p className="my-auto">{e}</p>
                            </div>
                        );
                    })}
                </div>

                <div className="flatBtnContainer containerWidth">

                    <div>
                        <div className={`${activeTab === "customers" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.customers &&
                                <CustomersComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "vendors" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.vendors &&
                                <VendorsComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "expenses" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.expenses &&
                                <ExpensesComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "banks" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.banks &&
                                <BanksComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                        <div className={`${activeTab === "suppliers" ? 'd-block' : 'd-none'}`}>
                            {
                                userRights.suppliers &&
                                <SuppliersComponent passwordBox={passwordBox} messageBox={messageBox} toast={toast} activeTab={activeTab} mainActiveTab={props.mainActiveTab} />
                            }
                        </div>
                    </div>

                    {/* {(() => {
                        switch (activeComponent) {
                            case "customers":
                                return <CustomersComponent messageBox={messageBox} toast={toast} />;
                            case "vendors":
                                return <VendorsComponent messageBox={messageBox} toast={toast} />;
                            case "expenses":
                                return <ExpensesComponent messageBox={messageBox} toast={toast} />;
                            case "banks":
                                return <BanksComponent messageBox={messageBox} toast={toast} />;
                            default:
                                return <div></div>;
                        }
                    })()} */}
                </div>
            </div>
        </>
    )
}

export default AccountsComponent;