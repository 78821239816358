import React, { useEffect, forwardRef, useState } from "react";
import 'style/reports/report.css';
import Report from "components/reports/Report";
import { apiLoadItemsReport } from "api/reports/profitReportsApi";
import dateFormat from "dateformat";
import ReportA4Style from "../styles/ReportA4Style";
import { useSelector } from "react-redux";

const ItemsProfitReport = forwardRef((props, ref) => {
    const messageBox = props.messageBox;

    const settings = useSelector(state => state.main.settings);
    const params = props.params;
    const onClick = props.onClick ? props.onClick : () => { };
    const onDoubleClick = props.onDoubleClick ? props.onDoubleClick : () => { };
    const [data, setData] = useState([]);

    // Header
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [branchName, setBranchName] = useState('');

    // Footer
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalMargin, setTotalMargin] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [totalQty, setTotalQty] = useState(0);
    const [totalCrtn, setTotalCrtn] = useState(0);

    const columns = [
        { column: 'Date', row: "date", sortBy: "string" },
        { column: 'Inv #', row: "invoiceNo", sortBy: "number" },
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        // { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Pcs', row: "qty", sortBy: "number" },
        { column: 'Sale', row: "total", sortBy: "number" },
        { column: 'T.Cost', row: "totalCost", sortBy: "number" },
        // { column: 'Disc', row: "totalDisc", sortBy: "number" },
        { column: '(%)', row: "margin", sortBy: "number" },
        { column: 'Profit', row: "profit", sortBy: "number" },
    ];

    const columnsC = [
        { column: 'Date', row: "date", sortBy: "string" },
        { column: 'Inv #', row: "invoiceNo", sortBy: "number" },
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        // { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Pcs', row: "qty", sortBy: "number" },
        { column: 'Crtn', row: "crtn", sortBy: "number" },
        { column: 'Sale', row: "total", sortBy: "number" },
        { column: 'T.Cost', row: "totalCost", sortBy: "number" },
        // { column: 'Disc', row: "totalDisc", sortBy: "number" },
        { column: '(%)', row: "margin", sortBy: "number" },
        { column: 'Profit', row: "profit", sortBy: "number" },
    ];

    const columnsSum = [
        { column: 'Itemname', row: "itemname", sortBy: "string", className: "itemname" },
        // { column: 'Cost', row: "cost", sortBy: "number" },
        { column: 'Pcs', row: "qty", sortBy: "number" },
        { column: 'Crtn', row: "crtn", sortBy: "number" },
        { column: 'Sale', row: "total", sortBy: "number" },
        { column: 'T.Cost', row: "totalCost", sortBy: "number" },
        // { column: 'Disc', row: "totalDisc", sortBy: "number" },
        { column: '(%)', row: "margin", sortBy: "number" },
        { column: 'Profit', row: "profit", sortBy: "number" },
    ];

    const loadReport = () => {
        props.setIsLoading(true);
        apiLoadItemsReport(params)
            .then((result) => {
                if (result.rows && result.total) {
                    setData(result.rows);

                    setGrandTotal(result.total.grandTotal);
                    setTotalMargin(result.total.totalMargin);
                    setTotalProfit(result.total.totalProfit);
                    setTotalCost(result.total.totalCost);

                    if (params.sum === true) {
                        setTotalItems(result.rows.length);
                    } else {
                        setTotalItems(result.total.totalItems);
                    }

                    setTotalQty(result.total.totalQty);
                    setTotalCrtn(result.total.totalCrtn);

                    setFromDate(dateFormat(params.fromDate, "dd/mm/yyyy"));
                    setToDate(dateFormat(params.toDate, "dd/mm/yyyy"));
                    setBranchName(result.total.branchName);
                }
                props.setIsLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                props.setIsLoading(false);
            });
    }

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="report reportA4 itemsReport" ref={ref}>
                <ReportA4Style />

                {/* Page Header */}
                <div className="pageHeader">
                    <h4>Profit Report</h4>
                </div>

                {/* Report Header */}
                <div className="reportHeader">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading labelMarginR">From Date:</p>
                                <p className="totalLabel alignL">{fromDate}</p>
                            </div>
                        </div>

                        {/* Center */}
                        <div className="dRow" style={{ justifyContent: 'center' }}>
                            <p className="totalHeading">Branch:</p>
                            <p className="labelMarginL">{branchName}</p>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">To Date:</p>
                            </div>
                            <div className="dCol">
                                <p className="totalLabel alignL">{toDate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Report body */}
                <Report
                    columns={params.sum === true ? columnsSum : settings.saleCartons ? columnsC : columns}
                    data={data}
                    setData={setData}
                    onClick={(row) => { onClick(row.salesId) }}
                    onDoubleClick={(row) => { onDoubleClick(row.salesId) }} />

                {/* Report Footer */}
                <div className="reportFooter">
                    <div className="dRow" style={{ justifyContent: 'space-between' }}>
                        {/* Left Side */}
                        <div className="dCol" style={{ justifyContent: 'space-start' }}>
                            <div className="dRow">
                                <p className="totalHeading">Total Items:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalItems}</p>
                                <p className="totalHeading" style={{ marginLeft: '16px' }}>Total Pcs:</p>
                                <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalQty}</p>
                                <div className={`dRow ${settings.saleCartons ? "d-flex" : "d-none"}`}>
                                    <p className="totalHeading" style={{ marginLeft: '16px' }}>Total Crtn:</p>
                                    <p className="totalLabel labelMarginL dUnderline" style={{ textAlign: 'center' }}>{totalCrtn}</p>
                                </div>
                            </div>
                        </div>

                        {/* Right Side */}
                        <div className="dRow" style={{ justifyContent: 'space-end' }}>
                            <div className="dCol labelMarginR">
                                <p className="totalHeading">Total Sale:</p>
                                <p className="totalHeading">Total Cost:</p>
                                <p className="totalHeading">Margin:</p>
                                <p className="totalHeading">Total Profit:</p>
                            </div>
                            <div className="dCol labelMarginR">
                                <p className="totalLabel">{grandTotal}</p>
                                <p className="totalLabel">{totalCost}</p>
                                <p className="totalLabel underline">{totalMargin}%</p>
                                <p className="totalLabel dUnderline">{totalProfit}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default ItemsProfitReport;