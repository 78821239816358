import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import ComboBox from 'components/tools/ComboBox.js';
import { useSelector } from 'react-redux';

const SubBankCBMOP = forwardRef((props, ref) => {
    // Props
    const className = props.className;
    const onChange = props.onChange ? props.onChange : () => { };
    const style = props.style;

    // Variables
    const subBanks = useSelector(state => state.main.subBanks);
    const [data, setData] = useState(subBanks);
    let reference = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: reference.current.getValue,
            setValue: reference.current.setValue,
            getText: reference.current.getText,
            loadData: loadSubBanks,
            enable:reference.current.enable,
            disable:reference.current.disable,
        };
    });

    useEffect(() => {
        setData([...subBanks]);
        reference.current.disable();
        // eslint-disable-next-line
    }, [subBanks]);

    const loadSubBanks = (bankId) => {
        if(bankId === 0){
            setData([...subBanks]);
            reference.current.setValue(0);
        }else{
            let temp1 = [
                { id: 0, name: 'None', bankId: 0 }
            ];
    
            const temp2 = subBanks.filter((subBank) => Number(subBank.bankId) === bankId);
            if (temp2.length > 0) {
                temp1 = temp1.concat(temp2);
            }
            
            setData([...temp1]);
        }
    }

    return (
        <>
            <ComboBox
                ref={reference}
                className={className}
                data={data}
                onChange={onChange}
                style={style} />
        </>
    )
});

export default SubBankCBMOP;