/* #region Imports */
import React, { useRef, useState, useEffect } from 'react';
import 'style/items/categories.css';
import { mainReducerLoadCategories, mainReducerLoadSubCategories } from 'state/reducers/mainReducer';
import { useDispatch } from "react-redux";

import {
    apiLoadCategories,
    apiInsertCategory,
    apiUpdateCategory,
    apiDeleteCategory,
    apiGetCategory,
    apiIsCategoryExists,
    apiUploadImage as apiUploadCategoryImage
} from "api/items/CategoriesApi";

import {
    apiLoadSubCategories,
    apiInsertSubCategory,
    apiUpdateSubCategory,
    apiDeleteSubCategory,
    apiGetSubCategory,
    apiIsSubCategoryExists,
    apiUploadImage as apiUploadSubCategoryImage
} from "api/items/SubCategoriesApi";

import Table from 'components/tools/Table';
import Textbox from 'components/tools/Textbox';
import ButtonIcon from 'components/tools/ButtonIcon';
import Textarea from 'components/tools/Textarea';
import Image from "components/tools/Image";
import CategoryCB from "components/tools/dropdowns/CategoryCB";
import { isFileSizeValid } from 'utilities/Utils';
import { getClientId } from 'utilities/DB';
/* #endregion Imports */

const CategoriesComponent = (props) => {

    /* #region Variables */
    const formId = "categoriesForm";
    const isActive = useRef(true);
    const mainActiveTab = useRef(null);
    const dispatch = useDispatch();

    const categoryNameTB = useRef();
    const remarksTB = useRef();
    const searchCategoryTB = useRef();

    const categoryCB = useRef();
    const subCategoryNameTB = useRef();
    const searchSubCategoryTB = useRef();

    // Tools References
    const messageBox = props.messageBox;
    const toast = props.toast;
    const tableCRef = useRef();
    const tableSCRef = useRef();
    const [totalCategories, setTotalCategories] = useState(0);
    const [totalSubCategories, setTotalSubCategories] = useState(0);

    // Other Variables
    const updateIdC = useRef(0);
    const isUpdateC = useRef(false);
    const updateIdSC = useRef(0);
    const isUpdateSC = useRef(false);

    const formDataC = useRef(null);
    const formDataSC = useRef(null);

    const columnsC = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Category Name', row: "categoryName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    const columnsSC = useRef([
        { column: 'Id', row: "id", sortBy: "number", style: { minWidth: '55px' } },
        { column: 'Sub-Category Name', row: "subCategoryName", sortBy: "string", style: { minWidth: '200px', width: '100%' } },
    ]);

    /* #endregion */

    /* #region Methods */
    const formLoad = ()=>{
        loadCategories();

        categoryNameTB.current.focus();
    }
    /* #region Categories */
    const loadCategories = () => {
        if (tableCRef.current !== null)
            tableCRef.current.setLoading(true);

        apiLoadCategories({
            text: searchCategoryTB.current.getText()
        }).then((result) => {
            try {
                setTotalCategories(result.total.totalCategories);
                tableCRef.current.setData([...result.rows]);
                tableCRef.current.setLoading(false);
            } catch (error) {
                tableCRef.current.setLoading(false);
            }
        }).catch((err) => {
            messageBox.current.show(err.message, "Error", "e");
        });
    }
    const loadCategoriesCB = (result) => {
        dispatch(mainReducerLoadCategories(result));
        categoryCB.current.setValue(0);
        // apiLoadMainCategories().then((result) => {
        //     dispatch(mainReducerLoadCategories(result));
        //     categoryCB.current.setValue(0);
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted = useRef(true);
    const insertOrUpdateCategory = async () => {
        if (inserted.current === false)
            return;
        else
            inserted.current = false;

        const isValid = await validationC();

        if (isValid) {
            if (isUpdateC.current) {
                const data = getFormDataC();
                data.id = updateIdC.current;
                apiUpdateCategory(data)
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Category updated successfully.", "s");
                        loadCategories();
                        clearTextboxesC();
                        loadCategoriesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertCategory(getFormDataC())
                    .then((result) => {
                        inserted.current = true;
                        toast.current.show("Category saved successfully.", "s");
                        loadCategories();
                        clearTextboxesC();
                        loadCategoriesCB(result);
                    }).catch((err) => {
                        inserted.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted.current = true;
        }
    }
    const editCategory = () => {
        const row = tableCRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetCategory(tempId)
                .then((result) => {
                    updateIdC.current = tempId;
                    isUpdateC.current = true;

                    setFormDataC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteCategory = () => {
        const row = tableCRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Category?")) {
                apiDeleteCategory(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Category deleted successfully.", "s");
                        loadCategories();
                        loadCategoriesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteCategoryIsUpdate = () => {
        if (isUpdateC.current) {
            if (window.confirm("Are you sure to delete this Category?")) {
                apiDeleteCategory(updateIdC.current)
                    .then((result) => {
                        toast.current.show("Category deleted successfully.", "s");
                        loadCategories();
                        clearTextboxesC();
                        loadCategoriesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesC = () => {
        categoryNameTB.current.setText("");
        remarksTB.current.setText("");

        updateIdC.current = 0;
        isUpdateC.current = false;
        formDataC.current = null;

        categoryNameTB.current.focus();

        document.getElementById("categoryImageDialog").value = "";
        document.getElementById("categoryImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const setFormDataC = (category) => {
        formDataC.current = category;

        categoryNameTB.current.setText(category.categoryName);
        remarksTB.current.setText(category.remarks);

        categoryNameTB.current.focus();

        if (category.imageUrl !== '')
            document.getElementById("categoryImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${category.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("categoryImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;

    }
    const getFormDataC = () => {
        const category = {
            categoryName: categoryNameTB.current.getText().trim(),
            remarks: remarksTB.current.getText().trim(),
        }

        return category;
    }
    const validationC = async () => {
        const categoryName = categoryNameTB.current.getText().trim();
        const isValid = await isValidCategory(categoryName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidCategory = async (categoryName) => {
        if (categoryName === '') {
            toast.current.show("Please Enter Category Name.", "i");
            categoryNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateC.current) {
                if (categoryName.toLowerCase() === formDataC.current.categoryName.toLowerCase())
                    return true;
                else
                    return isCategoryExists(categoryName);
            }
            else
                return isCategoryExists(categoryName);
        }
    }
    const isCategoryExists = async (categoryName) => {
        const result = await apiIsCategoryExists(categoryName);
        if (result.isExists) {
            toast.current.show("Category Name already exists.", "i");
            categoryNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onCategoryImageUpload = (imageDialog) => {
        if (isUpdateC.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateIdC.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadCategoryImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("categoryImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */

    /* #region Sub-Categories */
    const loadSubCategories = () => {
        const categoryId = categoryCB.current.getValue();
        if (categoryId !== 0) {
            tableSCRef.current.setLoading(true);
            apiLoadSubCategories({
                text: searchSubCategoryTB.current.getText(),
                categoryId: categoryId,
            }).then((result) => {
                setTotalSubCategories(result.total.totalSubCategories);
                tableSCRef.current.setData([...result.rows]);
                tableSCRef.current.setLoading(false);
            }).catch((err) => {
                messageBox.current.show(err.message, "Error", "e");
                tableSCRef.current.setLoading(false);
            });
        } else {
            tableSCRef.current.clearData();
        }
    }
    const loadSubCategoriesCB = (result) => {
        dispatch(mainReducerLoadSubCategories(result));

        // apiLoadMainSubCategories().then((result) => {
        //     dispatch(mainReducerLoadSubCategories(result));
        // }).catch((err) => {
        //     messageBox.current.show(err.message, "Error", "e");
        // });
    }
    const inserted1 = useRef(true);
    const insertOrUpdateSubCategory = async () => {
        if (inserted1.current === false)
            return;
        else
            inserted1.current = false;

        const isValid = await validationSC();

        if (isValid) {
            if (isUpdateSC.current) {
                const data = getFormDataSC();
                data.id = updateIdSC.current;
                apiUpdateSubCategory(data)
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Category updated successfully.", "s");
                        loadSubCategories();
                        clearTextboxesSC();
                        loadSubCategoriesCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            } else {
                apiInsertSubCategory(getFormDataSC())
                    .then((result) => {
                        inserted1.current = true;
                        toast.current.show("Sub-Category saved successfully.", "s");
                        loadSubCategories();
                        clearTextboxesSC();
                        loadSubCategoriesCB(result);
                    }).catch((err) => {
                        inserted1.current = true;
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        } else {
            inserted1.current = true;
        }
    }
    const editSubCategory = () => {
        const row = tableSCRef.current.getSelectedRow();
        if (row != null) {
            const tempId = Number(row['id']);
            apiGetSubCategory(tempId)
                .then((result) => {
                    updateIdSC.current = tempId;
                    isUpdateSC.current = true;

                    setFormDataSC(result);
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                })
        }
    }
    const deleteSubCategory = () => {
        const row = tableSCRef.current.getSelectedRow();
        if (row != null) {
            if (window.confirm("Are you sure to delete this Sub-Category?")) {
                apiDeleteSubCategory(Number(row['id']))
                    .then((result) => {
                        toast.current.show("Sub-Category deleted successfully.", "s");
                        loadSubCategories();
                        loadSubCategoriesCB(result);
                        // clearTextboxes();
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        };
    }
    const deleteSubCategoryIsUpdate = () => {
        if (isUpdateSC.current) {
            if (window.confirm("Are you sure to delete this Sub-Category?")) {
                apiDeleteSubCategory(updateIdSC.current)
                    .then((result) => {
                        toast.current.show("Sub-Category deleted successfully.", "s");
                        loadSubCategories();
                        clearTextboxesSC();
                        loadSubCategoriesCB(result);
                    }).catch((err) => {
                        messageBox.current.show(err.message, "Error", "e");
                    });
            }
        }
    }
    const clearTextboxesSC = () => {
        subCategoryNameTB.current.setText("");

        updateIdSC.current = 0;
        isUpdateSC.current = false;
        formDataSC.current = null;

        subCategoryNameTB.current.focus();

        document.getElementById("subCategoryImageDialog").value = "";
        document.getElementById("subCategoryImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;

    }
    const setFormDataSC = (subCategory) => {
        formDataSC.current = subCategory;

        subCategoryNameTB.current.setText(subCategory.subCategoryName);

        subCategoryNameTB.current.focus();

        if (subCategory.imageUrl !== '')
            document.getElementById("subCategoryImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${subCategory.imageUrl}?` + new Date().getTime();
        else
            document.getElementById("subCategoryImage").src = `${process.env.REACT_APP_API_URL}/images/0.png`;
    }
    const getFormDataSC = () => {
        const subCategory = {
            categoryId: categoryCB.current.getValue(),
            subCategoryName: subCategoryNameTB.current.getText().trim(),
        }

        return subCategory;
    }
    const validationSC = async () => {
        const subCategoryName = subCategoryNameTB.current.getText().trim();
        const isValid = await isValidSubCategory(subCategoryName);
        if (isValid)
            return true;
        else
            return false;
    }
    const isValidSubCategory = async (subCategoryName) => {
        if (categoryCB.current.getValue() === 0) {
            toast.current.show("Please Select Category.", "i");
            return false;
        }
        else if (subCategoryName === '') {
            toast.current.show("Please Enter Sub-Category Name.", "i");
            subCategoryNameTB.current.focus();
            return false;
        }
        else {
            if (isUpdateSC.current) {
                if (subCategoryName.toLowerCase() === formDataSC.current.subCategoryName.toLowerCase())
                    return true;
                else
                    return isSubCategoryExists(subCategoryName);
            }
            else
                return isSubCategoryExists(subCategoryName);
        }
    }
    const isSubCategoryExists = async (subCategoryName) => {
        const result = await apiIsSubCategoryExists({
            categoryId: categoryCB.current.getValue(),
            subCategoryName: subCategoryName
        });
        if (result.isExists) {
            toast.current.show("Sub-Category Name already exists.", "i");
            subCategoryNameTB.current.focus();
            return false;
        } else
            return true;
    }
    const onSubCategoryImageUpload = (imageDialog) => {
        if (isUpdateSC.current) {
            if(imageDialog.files.length > 0 && !isFileSizeValid(imageDialog)){
                messageBox.current.show("File size too large", "Error", "e");
                return;
            }

            const formData = new FormData();
            formData.append('id', updateIdSC.current);
            formData.append('image', imageDialog.files[0]);

            apiUploadSubCategoryImage(formData)
                .then((result) => {
                    if (result.data !== '') {
                        document.getElementById("subCategoryImage").src = `${process.env.REACT_APP_API_URL}/${getClientId()}${result.data}?` + new Date().getTime();
                        toast.current.show("Image uploaded successfylly.", "s");
                    }
                }).catch((err) => {
                    messageBox.current.show(err.message, "Error", "e");
                });
        }
    }
    /* #endregion */
    /* #endregion */

    /* #region Clicks */
    /* #region Categories */
    const onCategorySaveBtnClick = () => {
        insertOrUpdateCategory();
    }

    const onCategoryEditBtnClick = () => {
        editCategory();
    }

    const onCategoryDeleteBtnClick = () => {
        deleteCategoryIsUpdate();
    }

    const onCategoryCancelBtnClick = () => {
        clearTextboxesC();
    }

    const onCategoryUploadBtnClick = (imageDialog) => {
        if (isUpdateC.current) {
            imageDialog.click();
        }
    }
    const categoriesTableOnclick = (row) => {
        const id = Number(row['id']);
        categoryCB.current.setValue(id);
    }
    /* #endregion */

    /* #region Sub-Categories */
    const onSubCategorySaveBtnClick = () => {
        insertOrUpdateSubCategory();
    }

    const onSubCategoryEditBtnClick = () => {
        editSubCategory();
    }

    const onSubCategoryDeleteBtnClick = () => {
        deleteSubCategoryIsUpdate();
    }

    const onSubCategoryCancelBtnClick = () => {
        clearTextboxesSC();
    }

    const onSubCategoryUploadBtnClick = (imageDialog) => {
        if (isUpdateSC.current) {
            imageDialog.click();
        }
    }
    /* #endregion */
    /* #endregion */

    /* #region Keydown */
    // Shortcut Keys
    const onWindowKeyDown = (e) => {
        if (mainActiveTab.current !== "categories")
            return;

        if (!isActive.current)
            return;

        if (e.ctrlKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateCategory();
        } else if (e.ctrlKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editCategory();
        } else if (e.ctrlKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteCategoryIsUpdate();
        } else if (e.ctrlKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.key.toLowerCase() === "escape") {
            e.preventDefault();
            clearTextboxesC();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableCRef.current.focus();
        }

        else if (e.altKey && e.key.toLowerCase() === "s") {
            e.preventDefault();
            insertOrUpdateSubCategory();
        } else if (e.altKey && e.key.toLowerCase() === "e") {
            e.preventDefault();
            editSubCategory();
        } else if (e.altKey && e.key.toLowerCase() === "d") {
            e.preventDefault();
            deleteSubCategoryIsUpdate();
        } else if (e.altKey && e.key.toLowerCase() === "q") {
            e.preventDefault();
            clearTextboxesSC();
        } else if (e.ctrlKey && e.key.toLowerCase() === "g") {
            e.preventDefault();
            tableSCRef.current.focus();
        }
    }
    // Search Textbox Keydown
    const onCategorySearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadCategories();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableCRef.current.focus();
        }
    }
    const onSubCategorySearchTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loadSubCategories();
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            tableSCRef.current.focus();
        }
    }
    const onCategoryNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            remarksTB.current.focus();
        }
    }
    const onRemarksTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateCategory();
        }
    }
    const onSubCategoryNameTBKeydown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            insertOrUpdateSubCategory();
        }
    }
    /* #endregion */

    /* #region Other Event Listeners */
    // On Category Combobox Change
    const onCategoryCBChange = (selectedValue, selectedText) => {
        loadSubCategories();
        // subCategoryNameTB.current.focus();
    }
    /* #endregion */

    // Onload
    useEffect(() => {
        document.addEventListener("keydown", onWindowKeyDown);
        
        return () => {
            document.removeEventListener("keydown", onWindowKeyDown);
        }
        // eslint-disable-next-line
    }, []);

    const isFirstTime = useRef(true);
    useEffect(() => {
        mainActiveTab.current = props.mainActiveTab;

        if(props.mainActiveTab === "categories" && isFirstTime.current){
            isFirstTime.current = false;
            formLoad();
        }
        // eslint-disable-next-line
    }, [props.mainActiveTab]);

    return (
        <>
            <div id={formId} className="mainContainer containerWidth">
                <div className='categoriesPanel' style={{ display: 'flex', flexDirection: 'row' }}>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Categories</h4>
                            <Image
                                width={180}
                                height={123}
                                id="categoryImageId"
                                imageId="categoryImage"
                                imageDialogId="categoryImageDialog"
                                onUploadBtnClick={(e) => {
                                    onCategoryUploadBtnClick(e);
                                }}
                                onUpload={(e) => {
                                    onCategoryImageUpload(e);
                                }}
                            />
                        </div>
                        <Textbox
                            ref={categoryNameTB}
                            label="Category Name"
                            tabIndex={100}
                            onKeyDown={onCategoryNameTBKeydown}
                            style={{}} />

                        <Textarea
                            ref={remarksTB}
                            label="Remarks"
                            tabIndex={101}
                            onKeyDown={onRemarksTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onCategorySaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onCategoryEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onCategoryDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onCategoryCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchCategoryTB}
                            label="Search"
                            onKeyDown={onCategorySearchTBKeydown}
                            tabIndex={103} />

                        <Table
                            ref={tableCRef}
                            columns={columnsC.current}
                            myTable='categoriesTable'
                            isActive={isActive}
                            onEnterKeyDown={categoriesTableOnclick}
                            onClick={categoriesTableOnclick}
                            onDoubleClick={onCategoryEditBtnClick}
                            editBtn={{ visible: false, onClick: onCategoryEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteCategory() } }} />

                        <div className="tableFooter">
                            <p><b>Total Categories: </b>{totalCategories}</p>
                        </div>

                    </div>

                    <div className='centerPanel'>
                        <div className="centerLine"></div>
                    </div>

                    <div className='panel'>
                        <div className='top'>
                            <h4 className='headingLabel'>Sub-Categories</h4>
                            <Image
                                width={180}
                                height={123}
                                id="subCategoryImageId"
                                imageId="subCategoryImage"
                                imageDialogId="subCategoryImageDialog"
                                onUploadBtnClick={(e) => {
                                    onSubCategoryUploadBtnClick(e);
                                }}
                                onUpload={(e) => {
                                    onSubCategoryImageUpload(e);
                                }}
                            />
                        </div>

                        <CategoryCB
                            ref={categoryCB}
                            tabIndex={104}
                            onChange={onCategoryCBChange} />

                        <Textbox
                            ref={subCategoryNameTB}
                            label="Sub-Category Name"
                            tabIndex={105}
                            onKeyDown={onSubCategoryNameTBKeydown}
                            style={{}} />

                        <div className="buttons">
                            <ButtonIcon
                                label="Save"
                                icon="icons/buttons/save.png"
                                onClick={onSubCategorySaveBtnClick} />

                            <ButtonIcon
                                label="Edit"
                                icon="icons/buttons/edit.png"
                                onClick={onSubCategoryEditBtnClick} />

                            <ButtonIcon
                                label="Delete"
                                icon="icons/buttons/delete.png"
                                onClick={onSubCategoryDeleteBtnClick} />

                            <ButtonIcon
                                label="Cancel"
                                icon="icons/buttons/cancel.png"
                                onClick={onSubCategoryCancelBtnClick} />
                        </div>

                        <Textbox
                            ref={searchSubCategoryTB}
                            label="Search"
                            onKeyDown={onSubCategorySearchTBKeydown}
                            tabIndex={106} />

                        <Table
                            ref={tableSCRef}
                            columns={columnsSC.current}
                            myTable='subCategoriesTable'
                            isActive={isActive}
                            onDoubleClick={onSubCategoryEditBtnClick}
                            editBtn={{ visible: false, onClick: onSubCategoryEditBtnClick }}
                            deleteBtn={{ visible: true, onClick: () => { deleteSubCategory() } }} />

                        <div className="tableFooter">
                            <p><b>Total Sub-Categories: </b>{totalSubCategories}</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default CategoriesComponent;